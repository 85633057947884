import React, { createContext, useState, ReactNode, useEffect } from 'react';

interface ComparisonContextProps {
    compareDetails: any;
    setCompareDetails: React.Dispatch<React.SetStateAction<any>>;
}

const ComparisonContext = createContext<ComparisonContextProps>({ compareDetails: '', setCompareDetails: () => { } });

const ComparisonContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [compareDetails, setCompareDetails] = useState<any>({});

    return (
        <ComparisonContext.Provider value={{ compareDetails, setCompareDetails }}>
            {children}
        </ComparisonContext.Provider>
    );
};

export { ComparisonContext, ComparisonContextProvider };
