
const AdminFooter = () => {

    const currrentTime = new Date();
    const currentYear = currrentTime.getFullYear()
    return (
        <>
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>{" "}
                            &copy; {currentYear} Popular Maruti. Kerala | Chennai | All rights
                            reserved</span>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default AdminFooter;