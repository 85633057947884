import { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"
import HTTP from "../../config/axios";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { encryptLocalStorage } from "../../config/utils/storage";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const PopularBrands = () => {

    const carouselRef = React.useRef<any>(null);
    let locationName = encryptLocalStorage.getItem("selectedLocation");
    const navigate = useNavigate();
    interface IPopularBrands {
        id: number;
        logo: string;
        keyName: string;
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    const [popularArray, setPopularArray] = useState<IPopularBrands[]>([]);
    const [isMobile, setIsMobile] = useState(false)


    useEffect(() => {
         
        setIsMobile(window.innerWidth <= 565);
         
      }, []);
      
    useEffect(() => {
        const getAssuredData = async () => {
            const response = await HTTP.get(`/api/vehicle/make/popular`);
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setPopularArray(data)
            } else {
                setPopularArray([])
            }
        }
        getAssuredData()
    }, []);

    const handlePopularBrand = async (keyName: any) => {
        const searchCriteria = { makeList: [keyName] }
        navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}`)
    }

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNextClick = () => {

        if (carouselRef.current) {
            carouselRef.current.next();
            console.log(carouselRef.current)
        }
    };

    const CustomLeftArrow = () => (
        <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} size={isMobile ? '1x' : '2x'} />
        </button>
    );

    const CustomRightArrow = () => (
        <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
            <FontAwesomeIcon icon={faChevronRight} size={isMobile ? '1x' : '2x'} />
        </button>
    );

    return (
        <Container>
            <Row className={isMobile ? 'mt-5' : 'my-5'}>
                <Col md={1} className=" d-none flex-row-reverse align-items-center  d-md-flex ">
                    <CustomLeftArrow />
                </Col>
                <Col col={12} md={3} lg={2} className="border-end text-center">
                    <h2 className="text-dark pt-3">Top brands</h2>
                </Col>
                <Col col={12} md={7} lg={8} className="ms-0 text-center has-popular-brand-carousel">
                    <Carousel
                        className="home-type-carousel"
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        ssr={true} // render carousel on server-side.
                        infinite={true}
                        arrows={false}
                        ref={carouselRef}
                        autoPlay={isMobile}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        itemClass="carousel-item-padding-40-px"
                        showDots={false}
                    >
                        {popularArray.map((data) => (
                            <div key={data.id} >
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}${data.logo}`} style={{ cursor: 'pointer' }} onClick={() => handlePopularBrand(data.keyName)} height={"50px"} className="m-3" />
                            </div>
                        ))}
                    </Carousel>
                </Col>
                <Col md={1} className="d-none align-items-center d-md-flex">
                    <CustomRightArrow />
                </Col>
            </Row>
            {isMobile && 
                <Row className="mb-5"> 
                    <Col md={6} sm={6} xs={6} className="  flex-row-reverse align-items-center  d-flex ">
                        <CustomLeftArrow />
                    </Col>
                    <Col md={6} sm={6} xs={6} className="align-items-center  d-flex">
                        <CustomRightArrow />
                    </Col> 
                </Row>
            }
        </Container>
    );
};

export default PopularBrands;
