import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

import { useForm } from 'react-hook-form';

import InputTextField from '../../form/InputTextField';
import DropDownField from '../../form/DropDownField';

import { useEffect, useState } from "react";
import HTTP from '../../../config/axios';
import { fieldRequired, phoneNumberPattern } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';

interface IReferAndEarnInfo {
    name: string;
    phoneNumber: string;
    state: string;
    district: string;
}

interface AddReferAndEarnProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    referOptions:any;
    ReferAndEarnDetails?: IReferAndEarnInfo;
    userIdToEdit: number | null;
    userDataToEdit:any;
    
}



const ReferAndEarnModal: React.FC<AddReferAndEarnProps> = ({ show, handleClose, referOptions, userIdToEdit ,userDataToEdit}) => {
    const { control, handleSubmit, formState: { errors },setError, clearErrors, reset, watch,getValues, setValue} = useForm<Record<string, any>>({});
    const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
   const selectedStateId = watch("stateId");
useEffect(() => {
    if (userDataToEdit) {
       console.log(userDataToEdit)
        Object.keys(userDataToEdit).forEach((key) => {
            setValue(key, userDataToEdit[key]);
        //    reset({
        //       name: userDataToEdit.name,
        //       phone: userDataToEdit.phone,
        //     //   stateId: userDataToEdit.states,
        //       districtId: userDataToEdit.districtId });
        });
    } else {
        reset(); 
    }
}, [userDataToEdit, setValue]);

const onSubmit = async (data: any) => {
    try {
        setIsLoading(true);
        let response;

        if (userIdToEdit) {
            response = await HTTP.put(`/api/edit-referral/${userIdToEdit}`, data);
        } else {
            response = await HTTP.post(`/api/add-referral`, data);
        }

        if (response.data.status === 1) {
            setSuccessMsg(true);
            setToast({ open: true, header: 'Success', message: userIdToEdit?response.data.message:'Referral added successfully', variant: 'success' });
        } else {
            setError(response.data.type ? response.data.type : "serverError", {
                type: "manual", message: response.data.message,
            });
            setToast({ open: true, header: 'Failed', message: response.data.message, variant: 'danger' });
        }
    } catch (err) {
        setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' });
    } finally {
        setIsLoading(false);
        reset();
        closeModal()
        
    }
};


     useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await HTTP.post(`/api/booking/states/fetch-all`);
        const { data } = response.data;
        setStates(data);
        if (userDataToEdit && userDataToEdit.states) {
                const selectedState = data.find((state: { name: string; id: number }) => state.name === userDataToEdit.states);
                if (selectedState) {
                    setValue("stateId", selectedState.id);
                    setValue("districtId",'')
                 }
            }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, [userDataToEdit]);
  
  

  useEffect(() => {
    console.log("Checking this working at the beginning")
    if (selectedStateId) {
        
      const fetchDistricts = async () => {
        try {
          const response = await HTTP.post(
            // `/api/booking/districts/fetch-all/${selectedStateId}`

            `/api/booking/districts/fetch-all/${watch("stateId")}`
          );
          const { data } = response.data;
          setDistricts(data);
           if (userDataToEdit && userDataToEdit.districtId) {
                const selectedDistrict = data.find((district: { name: string; id: number }) => district.id === userDataToEdit.districtId);
                if(selectedDistrict){
                    setValue("districtId", selectedDistrict.id);
                } else{
                    setValue("districtId", '');
                }
            }
        } catch (error) {
          console.error("Error fetching districts:", error);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
    }
  }, [selectedStateId]);




const closeModal = () => {

    handleClose();
   
    reset();
};
    return (
        <>
         <Modal show={show} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>Refer A Friend</Modal.Title>
            </Modal.Header>
           
                <Form onSubmit={handleSubmit(onSubmit)}>
                     <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <InputTextField
                                name="name"
                                label="Name"
                                control={control}
                                clearErrors={clearErrors}
                                rule={{ required: fieldRequired }}
                                placeholder="Enter Name"
                            />
                        </Col>
                        <Col md={12}>
                            <InputTextField
                                name="phone"
                                type="tel"
                                label="Phone number"
                                control={control}
                                rule={{ required: fieldRequired, pattern: phoneNumberPattern }}
                                placeholder="Enter Phone Number"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                           <DropDownField name="stateId" label="State"
                                    options={states} placeholder=" State name "
                                    control={control} rule={{ required: fieldRequired }} />
                      
                        </Col>
                        <Col md={12}>
                             <DropDownField name="districtId" label="District"
                                    options={districts} placeholder="District name"
                                    control={control} rule={{ required: fieldRequired }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <DropDownField
                                name="referType"
                                label="Type"
                                options={referOptions}
                                placeholder="Choose Type"
                                control={control}
                                rule={{ required: fieldRequired }}
                            />
                        </Col>
                    </Row>
                  </Modal.Body>

                   <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {userIdToEdit? 'Update' : 'Save Changes'}
                        </Button>
                    </Modal.Footer>
                </Form>
                
         
        </Modal>

        <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>

       
    );
};

export default ReferAndEarnModal;