import React, { useEffect, useState } from 'react';
import DealershipTable from '../../../components/admin/location/DealershipTable';
import DealershipAddEditModal from '../../../components/admin/location/DealershipAddEditModal';

const ListDealerships: React.FC = () => {
    const [show, setShow] = useState(false);
    const [countAddEdit, setCountAddEdit] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [sortOrder, setSortOrder] = useState(0)
    const [dealershipDetails, setDealershipDetails] = useState({ id: 0, name: '', keyName: '', image: '', sortOrder: sortOrder + 1, address: '' });
    const newModal = () => {
        setShow(true);
        setDealershipDetails({ id: 0, name: '', keyName: '', image: '', sortOrder: sortOrder + 1, address: '' })
    }
    const handleClose = () => setShow(false);

    const editModal = (dealership: any) => {

        setDealershipDetails(dealership)
        setShow(true)
    }

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <DealershipTable newModal={newModal} showModal={editModal} handleClose={handleClose} countAddEdit={countAddEdit} totalRows={totalRows} setTotalRows={setTotalRows} setSortOrder={setSortOrder} />
                            <DealershipAddEditModal show={show} handleClose={handleClose} dealershipDetails={dealershipDetails} setCountAddEdit={setCountAddEdit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListDealerships;