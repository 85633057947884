import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import InputTextField from "../form/InputTextField";
import HTTP from "../../config/axios";
import DropDownField from "../form/DropDownField";
import SimpleToast from "./Toast";
import {
  fieldRequired,
  emailPattern,
  phoneNumberPattern,
} from "../../config/validations/rules";
import { encryptLocalStorage } from "../../config/utils/storage";
import { useLocation, useNavigate } from "react-router-dom";

interface responseType {
  success: boolean;
  error: any;
  data: {
    err?: string;
    status: number;
    error: string;
    message: string;
    type: string;
    data: any;
  };
}

const EnquiryForm = () => {
  const [toast, setToast] = useState({
    open: false,
    header: "",
    message: "",
    variant: "",
  });

  interface IMake {
    id: number;
    name: string;
    keyName: string;
}


  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [modelArray, setModelArray] = useState<IMake[]>([]);
  const currentLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

     useEffect(() => {
        const getCarMakeData = async () => {
            const response = await HTTP.get(`/api/model/fetch-all`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                resetField('modelId', {
                    keepDirty: false,
                    keepError: false,
                    keepTouched: false,
                    defaultValue: null
                })
                setModelArray(data)
            } else {
                setModelArray([])
            }
        }
        getCarMakeData()
    }, []);
    
  

  const SALE_LEADS = [
    { id: 10, name: "Sell" },
    { id: 20, name: "Buy" },
    { id: 30, name: "Finance" },
    { id: 40, name: "Premium Park & Sell" },
  ];

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
    reset,
    resetField,
    getValues,
    setValue
  } = useForm();

  const onSubmit = async (formData: any) => {
    try {
      const {
        data: { data, status, message },
      }: responseType = await HTTP({
        method: "post",
        url: '/api/leads/contact-us/',
        data: formData,
      });

      setToast({
        open: true,
        header: status ? "Success" : "Warning",
        message,
        variant: status ? "success" : "danger",
      });

    } catch (error) {
      setToast({
        open: true,
        header: "Failed",
        message: "Something went wrong",
        variant: "danger",
      });
    } finally {
      encryptLocalStorage.removeItem("enquiryDetails");
      reset({
        modelId:"",
        name:"",
        email:"",
        mobileNo:"",
        saleLead:""
      })
    }
  };

  const handleSignIn = () => {
    const formValues = getValues();
    encryptLocalStorage.setItem("enquiryDetails", JSON.stringify(formValues));
    encryptLocalStorage.setItem("previousLoggedoutLocation",currentLocation.pathname)
    navigate("/sign-in")
}

useEffect(() => {
  const storedData = encryptLocalStorage.getItem("enquiryDetails");

  if (storedData) {
      reset(storedData);
  }
}, []);

useEffect(() => {
  const storedData = encryptLocalStorage.getItem("enquiryDetails");

  if (storedData) {
      setValue("modelId", storedData.modelId )
  }
},[modelArray])

useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
       if(token){
         try{
           
            const fetchUser = async () => {
            const response = await HTTP.get(`/api/fetch-profile`);
            const { data: { status, data, msg, error } } = response
            if(status==1){
                setValue('name', `${data.firstName} ${data.lastName}`);
                setValue('mobileNo', data.phone)
                setValue('email', data.email)
            }
        }
        fetchUser()
        }
        catch(error){
            console.log("Error in states fetch API", error)
        }
       }
        
    }, [])
  return (
    <>
      <Container className="pb-5">
        <Row className="text-center">
          <Col md={12}>
            <div className="text-center pb-4">
              <h2 className="text-primary head-bold">
                ENQUIRY
              </h2>
            </div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center">
            <Col md={2}>
              <div className="mb-3">
                <DropDownField
                  label=""
                  name="modelId"
                  options={modelArray}
                  control={control}
                  rule={{ required: fieldRequired }}
                  clearErrors={clearErrors}
                  placeholder="Choose a model"
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="name"
                  label=""
                  placeholder="Name"
                  clearErrors={clearErrors}
                  control={control}
                  rule={{ required: fieldRequired }}
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="email"
                  label=""
                  placeholder="Email"
                  clearErrors={clearErrors}
                  control={control}
                  rule={{ pattern: emailPattern }}
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="mobileNo"
                  label=""
                  placeholder="Mobile No "
                  clearErrors={clearErrors}
                  control={control}
                  rule={{
                    required: fieldRequired,
                    pattern: phoneNumberPattern,
                  }}
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <DropDownField
                  label=""
                  name="saleLead"
                  options={SALE_LEADS}
                  control={control}
                  rule={{ required: fieldRequired }}
                  clearErrors={clearErrors}
                  placeholder="Choose an option"
                />
              </div>
            </Col>
            <Col md={2} className="text-start">
              {isLoggedIn ? (<Button variant="primary" type="submit"  className=""  >
                Contact&nbsp;us
              </Button>
              ) : (
                <Button variant="primary" onClick={handleSignIn}>
                  Contact&nbsp;us
              </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
      <SimpleToast
        show={toast.open}
        header={toast.header}
        message={toast.message}
        variant={toast.variant}
        onClose={() => setToast({ ...toast, open: false })}
      />
    </>
  );
};

export default EnquiryForm;
