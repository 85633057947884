import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { useEffect, useState } from "react";
import HTTP from "../../../config/axios";
import { useParams } from 'react-router-dom';


interface BlogData {
    image: string;
    content: string;
    title: string;
}
const initialBlogData: BlogData = {
    image: '',
    content: '',
    title: ' '
};

const Blog = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [isLoading, setIsLoading] = useState(false);
    const [blogData, setBlogData] = useState<BlogData>(initialBlogData)

    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "Blogs", path: "/blogs", active: false, linkClass: "breadcrumb-text" },
        { title: "Blog", path: "/blog", active: true, linkClass: "text-primary fw-semibold" }
    ]

    const { id, keyName } = useParams()

    useEffect(() => {
        try {
            const getAllBlog = async () => {
                setIsLoading(true)
                const response = await HTTP.get(`/api/blog/fetch-one/${id}`);
                const { data: { status, data, msg, error, totalRecords } } = response;
                setBlogData(data);
            }
            getAllBlog()
        }
        catch (error) {
            console.log("Error in blog fetch api")
        }
        finally {
            setIsLoading(false)
        }
    }, []);


    return (
        <>
            <Container className="mt-120">
                <Breadcrumbs breadcrumbData={breadCrumbList} />
                {!isLoading && (
                    <Row className="justify-content-center align-items-center mt-3 mb-5">
                        <Col md={8}>
                            {blogData.image && (
                                <div className="text-center mb-3">
                                    <img
                                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${blogData.image}`}
                                        alt="Blog Image"
                                        className="img-fluid"
                                    />
                                </div>
                            )}
                            {blogData.title && <h2 className="text-break">{blogData.title}</h2>}
                            {blogData.content && (
                                <div dangerouslySetInnerHTML={{ __html: blogData.content }} />
                            )}
                        </Col>
                    </Row>
                )}
            </Container>


        </>
    );
}

export default Blog;
