import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import HTTP from '../../../config/axios';
import { useNavigate } from "react-router-dom";
import { encryptLocalStorage } from '../../../config/utils/storage';
import Carousel from "react-multi-carousel";

const CarsByLifestyle = () => {

    const navigate = useNavigate();
    let locationName = encryptLocalStorage.getItem("selectedLocation");
    const [carsArray, setCarsArray] = useState<CarInterface[]>([]);
    const [isMobile, setIsMobile] = useState(false)

    interface CarInterface {
        id: number;
        name: string;
        description: string;
        image: string;
        keyName: string;
    }

    useEffect(() => {
        const getAllLifestyle = async () => {
            const lifestyleData = await HTTP.get(`/api/vehicle/lifestyle/all`);
            const { data: { status, data, msg, error } } = lifestyleData
            if (status == 1) {
                setCarsArray(data)
            }
            else {
                setCarsArray([])
            }
        }
        getAllLifestyle()
    }, []);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 775)
        window.addEventListener('resize', handleResize);
        setIsMobile(window.innerWidth <= 775);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLifestyleClick = (keyName: string) => {
        const searchCriteria = { "lifestyle": [keyName] };
        // navigate({
        //     pathname: `/buy-used-car/${locationName || ""}/`,
        //     search: `search-criteria=${JSON.stringify(searchCriteria)}`,
        // });
        // window.location.href = `${pathname}?search-criteria=${JSON.stringify(searchCriteria)}`
        navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}`)
    }

    const responsive = {
        tablet: {
            breakpoint: { max: 775, min: 0 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
            paritialVisibilityGutter: 70
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 30
        }
    };

    return (
        <div className="App">
            <Container>
                <Row>
                    <div className="text-center pb-4 pt-lg-4 pt-sm-0">
                        <h2 className="text-primary head-bold">
                            CARS BY LIFESTYLE
                        </h2>
                    </div>
                    {isMobile ? (
                        <Carousel
                            partialVisbile={true}
                            className="home-type-carousel"
                            swipeable={true}
                            draggable={true}
                            responsive={responsive}
                            ssr={true} // render carousel on server-side.
                            infinite={true}
                            arrows={false}
                            autoPlay={true}
                            autoPlaySpeed={4000}
                            keyBoardControl={true}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["mobile"]}
                            itemClass="carousel-item-padding-40-px"
                            showDots={true}
                            renderDotsOutside={false}
                            dotListClass=""
                        >
                            {
                                carsArray.map((car) => (
                                    <Col key={car.id} md={4} sm={6} onClick={() => handleLifestyleClick(car.keyName)} >
                                        <div>
                                            <Image className="mobile-image-width link-pointer"
                                                src={`${process.env.REACT_APP_CLOUDFRONT_URL}${car.image}`} />
                                            <div className="bottomleft text-light">
                                                <h3 className="mb-0 fs-4">{car.name}</h3>
                                                <p>{car.description}</p>
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Carousel>) : (carsArray.map((car) => (
                            <Col key={car.id} md={4} sm={6} onClick={() => handleLifestyleClick(car.keyName)} >
                                <div className="is_zoom_in link-pointer">
                                    <figure>
                                        <Image className="w-100"
                                            src={`${process.env.REACT_APP_CLOUDFRONT_URL}${car.image}`} />
                                    </figure>
                                    <div className="bottomleft text-light">
                                        <h3 className="mb-0 fs-4">{car.name}</h3>
                                        <p>{car.description}</p>
                                    </div>
                                </div>
                            </Col>
                        )))}
                    {/* {carsArray.map((car) => (
                        <Col key={car.id} md={4} sm={6} onClick={() => handleLifestyleClick(car.keyName)} >
                            <div className="is_zoom_in">
                                <figure>
                                    <Image className="w-100"
                                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${car.image}`} />
                                </figure>
                                <div className="bottomleft text-light">
                                    <h3 className="mb-0 fs-4">{car.name}</h3>
                                    <p>{car.description}</p>
                                </div>
                            </div>
                        </Col>
                    ))} */}
                </Row>
            </Container>
        </div >
    );
};

export default CarsByLifestyle;
