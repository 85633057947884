import React from "react";
import { Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface IFileField {
    name: string;
    label: string;
    control?: Control;
    placeholder?: string;
    classList?: string;
    rule?: any;
    clearErrors?: Function;
    disabled?: boolean;
    accept?: string;
    errText?: string;
}

const FileUpload: React.FC<IFileField> = ({
    name,
    label,
    control,
    placeholder = '',
    classList,
    rule,
    clearErrors,
    disabled = false,
    accept = '',
    errText
}) => {

    const handleError = () => {
        if (clearErrors) clearErrors();
    }

    if (rule?.required && typeof rule.required == 'function') {
        rule.required = rule.required(label)
    }

    const validateFile = (file: any) => {
        if (!file) return

        const maxSize = 2097152; // 2MB in bytes
        if (file.size > maxSize) {
            return 'File size should not exceed 2MB.';
        }
        const fileExtension = file.name.split('.').pop().toLowerCase();
        const allowedExtensions = ['jpeg', 'jpg', 'png'];
        if (!allowedExtensions.includes(fileExtension)) {
            return 'Invalid file type. Only JPEG, JPG, and PNG files are allowed.';
        }
        return true;
    };


    return (<>

        <div className={classList}>
            <Controller
                name={name}
                control={control}
                rules={{ validate: validateFile, ...rule }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <>
                        {label &&
                            <Form.Label htmlFor={`${name}-id`} className="text-dark fw-semibold fs-6">
                                {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                            </Form.Label>}
                        <Form.Control
                            placeholder={placeholder}
                            aria-label={label}
                            type="file"
                            disabled={disabled}
                            accept={accept}
                            onChange={(e: any) => { handleError(); onChange(e.target.files[0]) }}
                        />
                        {error?.message &&
                            <Form.Text className="text-danger">
                                {/* Show custom error if present and required message is not triggeres */}
                                {error.type != 'required' && errText ? errText : error.message}
                            </Form.Text>}
                    </>)
                }
            />

        </div>


    </>)
}


export default FileUpload