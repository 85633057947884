import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import { faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import { Button, Row, Col, Form } from 'react-bootstrap';
import InputGroupField from '../../form/InputGroupField';
import DropDownField from '../../form/DropDownField';
import { useForm } from 'react-hook-form';
import { getLongFormatOfDateAndTime, dateIsToday, dateTimeDistance } from '../../../config/utils/dateFunctions';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from "../../common/Spinner";
import MultiRadio from '../../form/MultiRadio';
import { downloadFileFromApi } from '../../../config/utils/helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const LoanApplicationTable = () => {
    const { control, watch, getValues } = useForm<Record<string, any>>({ defaultValues: { leads: 'All', search: "", contactStatus: "All" } });
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [isChecked, setIschecked] = useState(false);
    const [enquiryData, setEnquiryData] = useState([]);
    const searchText = watch('search');
    const leadStatus = watch('leads');
    const contactStatus = watch('contactStatus');
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [checkedStatus, setCheckedStatus] = useState<{ [key: number]: boolean }>({});
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    useEffect(() => {
        try {
            const getAllEnquiries = async () => {
                setIsLoading(true);
                const params = {
                    searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                    leadStatus: leadStatus, contactStatus: contactStatus
                };
                const response = await HTTP.get(`/api/admin/loan-application/fetch-all/${currentPage}/${perPage}`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                const updatedRowCheckedStatus: { [key: number]: boolean } = {};
                // data.rows.forEach((row: any) => {
                //     updatedRowCheckedStatus[row.id] = row.status === 1; // Assuming 1 means checked
                //     setCheckedStatus(updatedRowCheckedStatus)
                // });
                setEnquiryData(data.rows)
                setTotalRows(data.count)


            }
            getAllEnquiries()
        }
        catch (e) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsLoading(false)
        }


    }, [currentPage, perPage, searchText, sortDetails, leadStatus, contactStatus]);

    //Options for lead status

    //Defining the column for the data table
    const column: any = [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            keyName: 'name',
        }, {
            name: <div>Email</div>,
            selector: (row: { email: string; }) => row.email,
            wrap: true,
            sortable: true,
            keyName: 'email',
        }, {
            name: 'Contact no',
            selector: (row: { phone: string; }) => row.phone,
            keyName: 'phone',
        }, {
            name: 'PAN no',
            selector: (row: { panNumber: string; }) => row.panNumber,
            keyName: 'panNumber',
        }, {
            name: 'Date of birth',
            selector: (row: { dob: string; }) => row.dob,
            keyName: 'dob',
        }, {
            name: 'Address',
            selector: (row: { address: string; }) => row.address,
            keyName: 'address',
        }, {
            name: 'Pincode',
            selector: (row: { pincode: string; }) => row.pincode,
            keyName: 'pincode',
        }, {
            name: 'Contacted At',
            sortable: true,
            selector: (row: { createdAt: string; }) => dateIsToday(row.createdAt) ? dateTimeDistance(row.createdAt)
                : getLongFormatOfDateAndTime(row.createdAt),
        },

        // {
        //     name: 'Contact Status',
        //     selector: (row: { id: number, status: number }) => <>
        //         <Form.Check className='ms-5' checked={checkedStatus[row.id]} type='checkbox' id={row.id + 'contactStatus'}
        //             onChange={(e) => {
        //                 statusChange(row.id, e.target.checked)
        //             }}
        //         /> </>,
        // },
    ]

    //Handle the page change
    const statusChange = async (id: number, checked: boolean) => {
        try {
            setCheckedStatus((prevStatus) => ({
                ...prevStatus,
                [id]: checked,
            }));

            const params = { id: id, status: checked ? 1 : 0 };
            const response = await HTTP.post(`/api/admin/leads/updateContactStatus`, params);
            const { data: { status, message, error } } = response;
            setToast({ open: true, header: status ? 'Success' : "Failed", message: status ? message : 'Something went wrong', variant: status ? 'success' : 'danger' })
        }

        catch (e) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
    }

    //Handling the page changes
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };


    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    // const getAllEnquiriesExcel = async () => {
    //     try {
    //         setIsLoading(true);
    //         const params = {
    //             searchText: searchText, sortDetails: JSON.stringify(sortDetails),
    //             leadStatus: leadStatus, contactStatus: contactStatus
    //         };
    //         const response = await HTTP.get(`/api/admin/leads/fetch-all/excel`, { params, responseType: 'blob', });
    //         const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
    //         downloadFileFromApi(response.data, filename)
    //         setIsLoading(false);
    //     }
    //     catch (error) {
    //         console.log("Error in enquery download api", error);
    //         setIsLoading(false);
    //     }

    // }


    return (<>
        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Loan enquiries</h2></div>
                {/* <div className="p-2 mt-2 text-success" title="Download Excel Report">
                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={getAllEnquiriesExcel} style={{ cursor: 'pointer' }} />
                </div> */}
                {/* <div className="p-2 mt-2 d-flex " style={{ cursor: "pointer" }}>
                    <MultiRadio name="contactStatus" control={control}
                        options={contactStatusArray} classList="mb-3 pt-0 px-2" selectedValue={getValues("contactStatus")} />
                </div> */}
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={column}
            data={enquiryData}
            striped={true}
            // progressPending={isLoading}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
        <SimpleSpinner show={isLoading} />

    </>)

}

export default LoanApplicationTable