import React, { useEffect, useState } from 'react';
import TransmissionDataTable from '../../../components/admin/transmission/TransmissionDataTable';
import TransmissionAddEditModal from '../../../components/admin/transmission/TransmissionAddEditModal';
import { Button } from 'react-bootstrap';

const ListTransmission: React.FC = () => {
    const [show, setShow] = useState(false);
    const [transmissionDetails, setTransmissionDetails] = useState({ id: 0, name: '', keyName: '' });
    const handleClose = () => setShow(false);
    const [addEditCount, setAddEditCount] = useState(0)

    const editModal = (make: any) => {
        setTransmissionDetails(make)
        setShow(true)
    }

    const newTransmission = () => {
        setShow(true);
        setTransmissionDetails({ id: 0, name: '', keyName: '' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <TransmissionDataTable newTransmission={newTransmission} showModal={editModal}  addEditCount={addEditCount} />
                            <TransmissionAddEditModal show={show} handleClose={handleClose} transmissionDetails={transmissionDetails} setAddEditCount={setAddEditCount}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListTransmission;