import AdminDefaultHeader from './AdminDefaultHeader';
import AdminFooter from './AdminFooter';
import { Outlet, useParams } from 'react-router-dom';

const AdminLayout = () => {

    return (
        <>
            <div id="wrapper">
                <AdminDefaultHeader />
            </div>
            <AdminFooter />
        </>
    );
};

export default AdminLayout;