import { faAngleRight, faLocationDot, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import ContactUs from "../../components/common/ContactUs";

const Career = () => {
    return (
        <>
            <Container fluid className="hero-section who_are_we_banner d-flex">
                <Container className="my-auto">
                    <Col md={12}>
                        <h3 className="text-primary mb-1">Career</h3>
                        <h2 className="text-light">Put your career in next gear</h2>
                        <p className="text-light">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis massa aliquam lectus et nulla sit quis. Dignissim urna aliquet vestibulum
                            sed urna, quis augue risus rhoncus.</p>
                    </Col>
                </Container>
            </Container>
            <Container>
                <Row className="my-5">
                    <div className="d-flex mb-4">
                        <div className="me-auto">
                            <h2 className="text-dark is-testimonials">Open Positions</h2>
                        </div>
                        <div className="align-self-center">
                            <InputGroup className="">
                                <InputGroup.Text id="basic-addon1">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    className="border-start-0"
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <Col md={4}>
                        <div className="mb-3">
                            <Form.Select aria-label="Default select example">
                                <option>Choose Department</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <Form.Select aria-label="Default select example">
                                <option>Choose Work type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <Form.Select aria-label="Default select example">
                                <option>Choose Location</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </div>
                    </Col>
                    <Col md={12}>
                        <hr />
                        <h3 className="text-primary mb-1">Technology</h3>
                        <p>Two open roles</p>
                        <ol className="list-numbered text-dark ps-3">
                            <li>
                                <div>Frontend Developer - React JS</div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum vulputate in amet, porttitor morbi est, nec. Amet, bibendum ultrices
                                    tincidunt non urna. Urna justo, fringilla consequat pellentesque tellus</p>
                            </li>
                            <div className="d-flex mb-3">
                                <div className="pe-3 text-primary"><FontAwesomeIcon icon={faLocationDot} /></div>
                                <div>Bangalore</div>
                            </div>
                            <Button variant="primary" type="submit" >
                                Full time <FontAwesomeIcon icon={faAngleRight} />
                            </Button>
                            <hr />
                            <li>
                                <div>Backend Developer</div>
                                <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum vulputate in amet, porttitor morbi est, nec. Amet, bibendum ultrices
                                    tincidunt non urna. Urna justo, fringilla consequat pellentesque tellus</p>
                            </li>
                            <div className="d-flex mb-3">
                                <div className="pe-3 text-primary"><FontAwesomeIcon icon={faLocationDot} /></div>
                                <div>Bangalore</div>
                            </div>
                            <Button variant="primary" type="submit" >
                                Full time <FontAwesomeIcon icon={faAngleRight} />
                            </Button>
                        </ol>
                    </Col>
                </Row>
            </Container>
            <ContactUs />
        </>
    );
}

export default Career;