import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '../../form/Checkbox';


interface QueryParams {
    searchText?: string;
    sortDetails?: {};
}

interface IgetAllColour {
    page: number, limit: number,
    searchText?: string,
    sortColumn?: { sortColumn: string, sortDirection: string }
}

const ColourTable: React.FC<any> = ({ newModal, showModal, handleClose, countAddEdit, totalRows, setTotalRows }) => {
    const [colourData, setColourData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedColour = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Sl.no',
            selector: (row: { name: string; }, rowIndex: number) => `${rowIndex + 1}.` ,
            width: '15%'
        },
        {
            name: 'Generic Colours',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            keyName: 'name',
            defaultExpanded: true,
            width: '20%'
        },
         
         
    ];

    useEffect(() => {
        try {
            const getAllColours = async () => {
                setIsLoading(true)
                const colourStatus = archivedColour ? 0 : 1;
                const params = { searchText, currentPage, perPage, sortDetails: JSON.stringify(sortDetails), colourStatus, type: 1 };
                const response = await HTTP.get(`/api/admin/colour/fetch-all`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setColourData(data);
                setTotalRows(totalRecords)
            }
            getAllColours()
        }
        catch (error) {
            console.log("Error in colour fetch api")
        }
        finally {
            setIsLoading(false)
        }
    }, [countAddEdit, currentPage, perPage, searchText, sortDetails, removedIds, archivedColour]);



    //Invokng the edit modal for editing the colour
    const editColour = (colour: any) => {
        showModal(colour)
    }

    const currentSearchText = watch('search');
    useEffect(() => {
        setSearchText(currentSearchText)
    }, [currentSearchText])


    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    //handling Delete of colours
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }

    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
    }



    const handleRecover = async () => {
        setIsOpenModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/colour/retrieve/${selectedRecord}`);
        const { data: { status, error, message } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message, variant: 'success' })
        } else {
            setToast({ open: true, header: 'Failed', message, variant: 'danger' })
        }
        setIsLoading(false);
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/colour/remove/${selectedRecord}`);
        const { data: { status, error, message, type } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message, variant: 'success' })
        } else if (status == 0 && type == "assigned") {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: message, variant: 'danger' })

        } else {
            setToast({ open: true, header: 'Failed', message, variant: 'danger' })
        }
        setIsLoading(false);
    }

    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    // data provides access to your row data
    const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({ data: parentData }) => {

        const [childColours, setChildColours] = useState([])
         //Column Declaraion for the data table
    const childColumns: any = [
        {
            name: 'Sl.no',
            selector: (row: { name: string; }, rowIndex: number) => `${rowIndex + 1}.` ,
        },
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            keyName: 'name'
        },
        {
            name: 'Colour',
            cell: (row: { hexCode: string; }) => <>
                <div className='me-2 rounded' style={{ backgroundColor: row.hexCode, height: '20px', width: '20px' }} >&nbsp;</div>
            </>,
            center: true,
        },
        {
            name: 'Hex Code',
            cell: (row: { hexCode: string; }) => <>
                {row.hexCode}
            </>,
            center: true,
        },
        {
            name: 'Action',
            selector: (row: { id: number; }) => ((!archivedColour) ? <>
                <Button variant="success" onClick={() => editColour(row)} size="sm" className='me-3' >
                    <FontAwesomeIcon icon={faPen} />
                </Button>

                <Button onClick={() => handleDeleteClick(row.id)} size="sm">
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
            </> :
                <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id)} title="Make active" >
                    <FontAwesomeIcon icon={faUndo} />
                </Button>

            ),
        }
    ];

    useEffect(() => {
        try{
            const getChildColours = async() => {
                const response = await HTTP.get(`/api/admin/colour/fetch-all`, {params: {type: 2, parentId: parentData.id}});
                const { data: { status, data, msg, error, totalRecords } } = response;
                setChildColours(data)
            }
            getChildColours()
        }
        catch(error){

        }
    },[])

        
        return <DataTable
                    columns={childColumns}
                    data={childColours}
                    striped={true}
                    highlightOnHover={true}
                    customStyles={customStyles}
                />;
    };

    return (<>


        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Colours</h2></div>
                {/* <div className="p-2 mt-2" style={{ cursor: "pointer" }}><Checkbox name="archived" control={control} label="Show removed colours" /></div> */}
                <div className="p-2">
                    <Button variant="success" onClick={newModal}>
                        Add new colour
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>
        <DataTable
            columns={columns}
            data={colourData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
            expandableRows 
            expandableRowsComponent={ExpandedComponent}
            expandableRowExpanded={(row:any) =>  row.id <= 2}
        />

        <CustomModal isOpen={isOpenModal} title="Delete colour"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover Colour"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />


    </>)
}

export default ColourTable;