import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import InputTextField from "../form/InputTextField";
import HTTP from "../../config/axios";
import DropDownField from "../form/DropDownField";
import SimpleToast from "../common/Toast";
import {
  fieldRequired,
  emailPattern,
  phoneNumberPattern,
} from "../../config/validations/rules";
import { encryptLocalStorage } from "../../config/utils/storage";
import { useLocation, useNavigate } from "react-router-dom";

interface responseType {
  success: boolean;
  error: any;
  data: {
    err?: string;
    status: number;
    error: string;
    message: string;
    type: string;
    data: any;
  };
}

const ContactUs = ({ isParkAndSell }: { isParkAndSell?: boolean }) => {
  const [toast, setToast] = useState({
    open: false,
    header: "",
    message: "",
    variant: "",
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const contactUsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const SALE_LEADS = [
    { id: 10, name: "Sell" },
    { id: 20, name: "Buy" },
    { id: 30, name: "Finance" },
    { id: 40, name: "Premium Park & Sell" },
  ];

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    control,
    reset,
    getValues,
    setValue
  } = useForm();

  const onSubmit = async (formData: any) => {
    try {
      if (isParkAndSell) {
        formData.saleLead = 40
      }
      const {
        data: { data, status, message },
      }: responseType = await HTTP({
        method: "post",
        url: '/api/leads/contact-us/',
        data: formData,
      });

      setToast({
        open: true,
        header: status ? "Success" : "Warning",
        message,
        variant: status ? "success" : "danger",
      });

    } catch (error) {
      setToast({
        open: true,
        header: "Failed",
        message: "Something went wrong",
        variant: "danger",
      });
    } finally {
      reset()
    }
  };

  useEffect(() => {
    const hash = currentLocation.hash;
    if (hash === '#contact' && contactUsRef.current) {
       setTimeout(()=>{
          contactUsRef?.current?.scrollIntoView({ behavior: 'smooth' });
      },1000)
      // contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentLocation]);

  const handleSignIn = () => {
    const formValues = getValues();
    encryptLocalStorage.setItem("contactDetails", JSON.stringify(formValues));
    encryptLocalStorage.setItem("previousLoggedoutLocation",currentLocation.pathname+"#contact")
    navigate("/sign-in")
  }

  useEffect(() => {
    const storedData = encryptLocalStorage.getItem("contactDetails");
    encryptLocalStorage.removeItem("contactDetails");
    if (storedData) {
        reset(storedData);
    }
  }, []);
     useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
       if(token){
         try{
           
            const fetchUser = async () => {
            const response = await HTTP.get(`/api/fetch-profile`);
            const { data: { status, data, msg, error } } = response
            if(status==1){
                setValue('name', `${data.firstName} ${data.lastName}`);
                setValue('mobileNo', data.phone)
                setValue('email', data.email)
            }
          
        }
        fetchUser()
        }
        catch(error){
            console.log("Error in states fetch API", error)
        }
       }
        
   
    }, [])
  

  return (
    <>
      <Container className="pb-5" ref={contactUsRef}>
        <Row className="text-center">
          <Col md={12}>
            <div className="text-center pb-4">
              <h2 className="text-primary head-bold">
                NEED FURTHER SUPPORT
              </h2>
            </div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center">
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="name"
                  label=""
                  placeholder="Name"
                  clearErrors={clearErrors}
                  control={control}
                  rule={{ required: fieldRequired }}
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="email"
                  label=""
                  placeholder="Email"
                  clearErrors={clearErrors}
                  control={control}
                  rule={{ required: fieldRequired, pattern: emailPattern }}
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="mobileNo"
                  label=""
                  placeholder="Mobile No "
                  clearErrors={clearErrors}
                  control={control}
                  rule={{
                    required: fieldRequired,
                    pattern: phoneNumberPattern,
                  }}
                />
              </div>
            </Col>
            {!isParkAndSell && <Col md={2}>
              <div className="mb-3">
                <DropDownField
                  label=""
                  name="saleLead"
                  options={SALE_LEADS}
                  control={control}
                  rule={{ required: fieldRequired }}
                  clearErrors={clearErrors}
                  placeholder="Choose an option"
                />
              </div>
            </Col>}
            <Col md={2} className="text-start">
              {isLoggedIn ? (<Button variant="primary" type="submit" >
                Contact&nbsp;us
              </Button>
              ) : (
                <Button variant="primary" onClick={handleSignIn}>
                  Contact&nbsp;us
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
      <SimpleToast
        show={toast.open}
        header={toast.header}
        message={toast.message}
        variant={toast.variant}
        onClose={() => setToast({ ...toast, open: false })}
      />
    </>
  );
};

export default ContactUs;
