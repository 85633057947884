import React, { useState, useEffect, ReactNode, Fragment } from 'react';
import { Container } from 'react-bootstrap';

interface IStepperProps {
    steps:number,
    active:number,
    labels:string[]
}

const Stepper = ({steps,active,labels}:IStepperProps)=>{
    return (<>
        <Container className=" my-3"> 
            <div className="d-flex flex-row justify-content-center justify-content-md-start ">
                {labels.map((step,index)=>{
                    return(
                        <Fragment key={index}>
                            {(index+1 ===  labels.length)?<div className={`p-3 border border-primary  step  ${active >= index+1?'text-white bg-primary ':'text-primary'}`}>{step}</div>
                            :<><div className={`p-3 border border-primary text-primary ${active >= index+1?'text-white bg-primary ':'text-primary'}`}>{step}</div>
                                <hr className={`my-auto stepperConnector opacity-100 ${active > index+1?'completed':''}`}/>
                            </>   
                            }
                        </Fragment>    
                    )  
                })}
                
            </div>
        </Container>
        
    </>)
}

export default Stepper