import { Row, Col, Form, Container, Button } from 'react-bootstrap'
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import { fieldRequired, validateDecimalPlaces, validateOnlySpaces } from '../../../config/validations/rules';
import { useEffect, useState } from "react";
import HTTP from '../../../config/axios';
import DropDownField from '../../form/DropDownField';
interface IVehicleDetails {
    vehicleId: string | number,
    setToast: Function
    setIsLoading: Function
    setActiveTab: Function
}

const SpecificationForm: React.FC<IVehicleDetails> = ({ vehicleId, setToast, setIsLoading, setActiveTab }) => {
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, resetField, watch, getValues } = useForm();
    const [specificationArray, setSpecificationArray] = useState([])
    const [specificationDetails, setSpecificationDetails] = useState({})

    useEffect(() => {
        reset(specificationDetails)
    }, [specificationDetails])

    useEffect(() => {
        const getSpecificationData = async () => {
            const response = await HTTP.get(`/api/admin/specification/all`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setSpecificationArray(data)
            }
        }

        const getVehicleSpecificationDetails = async () => {
            try {
                const params = { vehicleId: vehicleId, basic: 0 }
                const response = await HTTP.get(`/api/admin/vehicle/specificationDetails`, { params });
                const { data: { status, data, msg, error } } = response
                if (status == 1 && data.length) {
                    const currentlySelectedOption: any = {}
                    data.map((specification: any) => {
                        currentlySelectedOption[specification.keyName] = specification.value
                    })
                    setSpecificationDetails(currentlySelectedOption)
                }
            }
            catch (error) {

            }

        }

        getSpecificationData()
        getVehicleSpecificationDetails()

    }, []);



    const onSubmit = async (formData: any) => {
        try {
            setIsLoading(true);
            const specifications: any = []
            const removedSpecification: any = []
            specificationArray.forEach((spec: any) => {
                if (formData[spec.keyName]) {
                    specifications.push({ id: spec.id, value: formData[spec.keyName], keyName: spec.keyName });
                }
            });

            Object.keys(specificationDetails).map((key: any) => {
                // Check if key exists in specificationArray
                const foundItem: any = specifications.find((item: any) => item.keyName === key);
                if (!foundItem) {
                    // Push the  item to removed specifications 
                    removedSpecification.push(key);
                }
            });

            const reqBody = { vehicleId: vehicleId, vehicleSpecification: specifications, removedFeatures: removedSpecification }
            const response = await HTTP.post(`/api/admin/vehicle/specification/add`, reqBody);
            const { data: { status, data, message, error } } = response
            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })
            if (status) {
                setActiveTab('images')
            }
        }
        catch (error) {
            console.log(error)
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsLoading(false);
        }
    }  
    const renderInputField = (data:any) =>{
        switch(data.fieldType){

            case "number":
                return <InputTextField label={data.name} name={data.keyName} type="number"
                    control={control}  min={0}
                    clearErrors={clearErrors} placeholder={` Enter ${data.name}`} unit={data.unit} />

            case "text":
                return <InputTextField label={data.name} name={data.keyName} type="text"
                    control={control} 
                    clearErrors={clearErrors} placeholder={` Enter ${data.name}`} unit={data.unit} 
                    rule={{ validate: validateOnlySpaces }}
                    />
            case "decimal":
                return <InputTextField  label={data.name} name={data.keyName}
                    type="number" control={control} 
                    step = {'any'}
                    min={0} placeholder={`Enter ${data.name}`} unit={data.unit} />

            case "dropdown":   
                const options = JSON.parse(data.options || "[]");
                return (
                    <DropDownField
                        label={data.name} name={data.keyName} options={options} control={control}
                        placeholder={`Select ${data.name}`} />
                );

            default:
                return <InputTextField label={data.name} name={data.keyName} type="number"
                    control={control} min={0}
                    clearErrors={clearErrors} placeholder={` Enter ${data.name}`} unit={data.unit} />;
        }
    }

    return (
        <>
            <Container fluid>
                <h1>Vehicle Specification</h1>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        {specificationArray.map((data: any, index: number) => (
                            <Col md={4} key={data.id}>
                                {renderInputField(data)}
                            </Col>
                        ))}
                    </Row>
                    <Button type="submit" className='mt-3'>Save & Continue</Button>
                </Form>
            </Container>

        </>)
}
export default SpecificationForm

