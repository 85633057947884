import { faAngleRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Nav, Row, Tab, Image } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import CarCard from "../../common/CarCard";
import HTTP from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { ClientContext } from "../../../config/context/clientContext"

const DealOfTheDay = () => {
  const navigate = useNavigate();
  let locationName = encryptLocalStorage.getItem("selectedLocation");
  interface DealDayInterface {
    id: number;
    km: number;
    image: string;
    fuel: string;
    transmission: string;
    price: any;
    makeAndModel: string;
    variant: string;
  }
  interface IFilter {
    name: string,
    keyName: string,
    image: string
  }
  const [bodyType, setBodyType] = useState<IFilter[]>([]);
  const [isMobile, setIsMobile] = useState(false)
  const carouselRef = React.useRef<any>(null);
  const [dealDayContent, setDealDayContent] = useState<DealDayInterface[]>([]);
  const { locationContext }: any = useContext(ClientContext);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 1400, min: 767 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1100, min: 767 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tabletSmall: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 565)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 565);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    try{
        const getDealOfTheDays = async () => {
            const response = await HTTP.post(`/api/vehicle/search`,
              {
                searchCriteria: { dealOfTheDay: true }, perPage: 4, currentPage: 1,
                getTotal: true
              })
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1 && data.length > 0) {
              setDealDayContent(data)
            } else {
              setDealDayContent([])
            }
        }

        getDealOfTheDays()
        
    }
    catch(err){
        console.log("Error in deal of the day api")
    }
  },[locationContext])

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleNextClick = () => {

    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const CustomLeftArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
      <FontAwesomeIcon icon={faChevronLeft} size={'2x'} />
    </button>
  );

  const CustomRightArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
      <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
    </button>
  );

  return (<>
     
      { dealDayContent.length > 0 &&
        
        <Container fluid >
          <div className="text-center">
            <h2 className="text-primary  pb-lg-4 pb-2">Deal of the day</h2>
          </div>
           <Row>
            <Col md={2} className=" d-none flex-row-reverse align-items-center  d-md-flex ">
              <CustomLeftArrow />
            </Col>
            <Col md={8}>
              <Carousel
                className="home-type-carousel"
                swipeable={true}
                draggable={true}
                arrows={false}
                ref={carouselRef}
                responsive={responsive}
                ssr={true} // render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "tabletSmall"]}
                itemClass="carousel-item-padding-50-px"
                // showDots={isMobile}
                // renderDotsOutside={false}
                autoPlay={isMobile}
                autoPlaySpeed={4000}
              >
                {dealDayContent.map((data) => (
                  <div key={data.id} className="px-2" >
                    <CarCard detail={data} isCarousel={true} />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col md={2} className="d-none  align-items-center  d-md-flex">
              <CustomRightArrow />
            </Col>
          </Row> 


    </Container>
      }
     
     </>);
};

export default DealOfTheDay;
