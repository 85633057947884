import "bootstrap/dist/css/bootstrap.min.css";
import CarBooking from "../../../components/modules/Booking/CarBooking";
 
const Booking = () => {
    return (
        <div className="App">
             <CarBooking />
        </div>
    );
}

export default Booking;