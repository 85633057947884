import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import HTTP from "../../config/axios";
import StarRating from "../../components/common/StarRating";
import { getFormatMMMddyyy } from "../../config/utils/dateFunctions";
import { Image } from "react-bootstrap";

const Testimonials = () => {

    interface ITestimonial {
        testimonial: string;
        image: string;
        date: string;
        id: number;
        title: string;
        name: string;
        content: string;
        rating: number
    }

    const [testimonialArray, setTestimonialArray] = useState<ITestimonial[]>([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const getTestimonialData = async () => {
            const response = await HTTP.get(`/api/cms/testimonials/fetch-all/${currentPage}/${perPage}`)
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setTestimonialArray(data)
            } else {
                setTestimonialArray([])
            }
        }
        getTestimonialData()
    }, []);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 766)
        window.addEventListener('resize', handleResize);
        setIsMobile(window.innerWidth <= 770);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        try{
            const scrollToSections = () => {
                // Scroll to the testimonial section when the component mounts
                const hash = window.location.hash;

                console.log("vvvvvvvvvvv",hash);
                
                if (hash) {
                    const element = document.querySelector(hash);
                    console.log("elementppppppppp",element);
                    if (element) {  console.log("elementp");
                        // 'start' aligns the scrolled element to the start of the containing block
                        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                        // Calculate the offset to account for the fixed header height
                        const headerHeight = document.querySelector('header')?.offsetHeight || 0;
                        const offset = headerHeight > 0 ? headerHeight : 0;
                        console.log("offset",offset);

                        // Adjust the scroll position to ensure the section is not hidden behind the header
                        // window.scrollBy({
                        //     top: -10,
                             
                        // });
                    }
                }
            }

            scrollToSections()
        }
        catch(error){
            console.log("Error in scrol to section code");
            
        }
    }, [testimonialArray]);

    return (
        <>
            <Container fluid className="hero-section who_are_we_banner d-flex mt-90">
                <Container className="my-auto">
                    <Col md={12} className="text-center my-5 py-4">
                        <h1 className="text-light mb-0">Testimonials</h1>
                    </Col>
                </Container>
            </Container>
            <Container>


                <Row className="pb-5">
                    {testimonialArray.map((testimonial) => (
                        <Col md={12} className="d-flex justify-content-center mt-4" key={testimonial.id} id={`section-${testimonial.id}`}>
                            {!isMobile ? (<div className="col-lg-9 col-md-12 border rounded-3 p-3">
                                <div className="d-flex justify-content-center">
                                    <div className="testmonial-img-outer pe-4 col-md-3">
                                        {testimonial.image ? (<Image className="object-fit-cover" src={`${process.env.REACT_APP_S3_BASE_URL}/${testimonial.image}`} height={"140px"} width={"140px"} alt="Description" />) :
                                            (<Image src={`${process.env.REACT_APP_S3_BASE_URL}/testimonial/testimonial_image/avatar.png`} height={"140px"} width={"140px"} />)}
                                    </div>
                                    <div className="col-md-9">
                                        <div className="d-flex d-flex align-items-center">
                                            <div>
                                                <StarRating rating={testimonial.rating} />
                                            </div>
                                            <p className="ps-3 mb-0 is-description text-dark">{getFormatMMMddyyy(testimonial.date)}</p>
                                        </div>
                                        <div className="cntnt-width">
                                            <h3 className="is-testimonials text-dark mb-0 ">
                                                {testimonial.name}
                                            </h3>
                                            <p className="mb-0 text-dark fw-bold ">{testimonial.title}</p>

                                            <p className="text-dark mt-1 ">
                                                {testimonial.testimonial}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>) : (<div className="col-lg-9 col-md-12 border rounded-3 p-3">
                                <div className="d-flex">
                                    <div className="pe-2 col-md-3">
                                        {testimonial.image ? (<Image className="object-fit-cover" src={`${process.env.REACT_APP_S3_BASE_URL}/${testimonial.image}`} height={"130px"} width={"130px"} alt="Description" />) :
                                            (<Image src={`${process.env.REACT_APP_S3_BASE_URL}/testimonial/testimonial_image/avatar.png`} height={"130px"} width={"130px"} />)}
                                    </div>
                                    <div className="col-md-9">
                                        <div>
                                            <div>
                                                <StarRating rating={testimonial.rating} />
                                            </div>
                                            <p className="mb-0 is-description text-dark">{getFormatMMMddyyy(testimonial.date)}</p>
                                        </div>
                                        <div className="cntnt-width">
                                            <h3 className="is-testimonials text-dark mb-0 ">
                                                {testimonial.name}
                                            </h3>

                                        </div>
                                    </div>
                                </div>
                                <p className="mb-0 mt-2 text-dark fw-bold ">{testimonial.title}</p>
                                <p className="text-dark mt-1 mb-0">
                                    {testimonial.testimonial}
                                </p>
                            </div>)}
                            {/* <div className="col-lg-9 col-md-12 border rounded-3 p-3">
                                <div className="d-flex justify-content-center">
                                    <div className="testmonial-img-outer pe-4 col-md-3">
                                        {testimonial.image ? (<Image className="object-fit-cover" src={`${process.env.REACT_APP_S3_BASE_URL}/${testimonial.image}`} height={"150px"} width={"150px"} alt="Description" />) :
                                            (<Image src={`${process.env.REACT_APP_S3_BASE_URL}/testimonial/testimonial_image/avatar.png`} height={"150px"} width={"150px"} />)}
                                    </div>
                                    <div className="col-md-9">
                                        <div className="d-flex">
                                            <div className="mt-1">
                                                <StarRating rating={testimonial.rating} />
                                            </div>
                                            <p className="ps-3 mb-0 is-description text-dark">{getFormatMMMddyyy(testimonial.date)}</p>
                                        </div>
                                        <div className="cntnt-width">
                                            <h3 className="is-testimonials text-dark mb-0 ">
                                                {testimonial.name}
                                            </h3>
                                            <p className="mb-0 text-dark fw-bold ">{testimonial.title}</p>

                                            <p className="text-dark mt-1 ">
                                                {testimonial.testimonial}
                                            </p>
                                        </div>
                                    </div>
                                </div> 
                        </div>*/}
                        </Col>
                    ))}
                </Row>
            </Container >
        </>
    );
}

export default Testimonials;
