import React, { useState, useEffect } from 'react';
import { Button, Card, Container, Row, Col, CardFooter } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { truncateTextWithHtml } from '../../../config/utils/helper'
import styled from 'styled-components'; 

const StyledCardFooter = styled(CardFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  background-color: white;
  padding: 10px;
`;

export interface BlogCardProps {
  id: Number
  image: string;
  title: string;
  keyName: string;
  content: string;
  summary: string;
}

const BlogCard: React.FC<BlogCardProps> = ({ id, image,summary, title, keyName, content }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 565)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 565);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  const truncatedText = truncateTextWithHtml(content, 400);
  return (
    <section>
      <Container>
        <Row xs={1} md={1} lg={1} className={isMobile ? "mt-3 mb-4" : "mt-3 mb-4"}>
          <div>
            <Card className='blog-card'>
             <Link to={`/blog/${id}/${keyName}`}>
                <div className="blog-image">
                  <Card.Img variant="top" src={`${process.env.REACT_APP_CLOUDFRONT_URL}${image}`} alt="Blog Image" className="card-image" />
                  <div className="image-overlay rounded"></div>
                </div>
              </Link>
              <Card.Body>
                <Card.Title className='mb-3 fs-5 text-break'>{title}</Card.Title>
                <Card.Text className="text-break">{summary}</Card.Text>
                 <StyledCardFooter className='rounded-3'>
                  <Link to={`/blog/${id}/${keyName}`} className="text-primary fs-5">
                    Read More »
                  </Link>
                </StyledCardFooter>
               
              </Card.Body>
            </Card>
          </div>
        </Row>
      </Container>
      
    </section>
  );
};

export default BlogCard;
