import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroupField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired, maxLength, minLength } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import FileUpload from '../../form/FileUpload';
import { Image } from 'react-bootstrap';
import SimpleSpinner from '../../common/Spinner';
import InputTextAreaField from '../../form/InputTextAreaField';
import DateTimePicker from '../../form/DateTimePicker';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        testimonialExist: boolean
    };
}
interface ITestimonialInfo {
    id: number;
    date: string;
    name: string;
    image: string;
    title: string;
    testimonial: string;
}

interface AddEditTestimonialProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    testimonialDetails: ITestimonialInfo;
    setCountAddEdit: Function;
    totalRows: number;
}

const AddEditTestimonial: React.FC<AddEditTestimonialProps> = ({ show, handleClose, testimonialDetails, setCountAddEdit, totalRows }) => {

    const [imageURL, setImageURL] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm();
    const uploadedImage = watch('uploadedImage')

    useEffect(() => {
        reset(testimonialDetails);
        setImageURL(testimonialDetails.image ? `${process.env.REACT_APP_CLOUDFRONT_URL}${testimonialDetails.image}` : '')
    }, [testimonialDetails]);

    useEffect(() => {
        uploadedImage && setImageURL(URL.createObjectURL(uploadedImage) || '')
    }, [uploadedImage])

    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append('id', submitData.id)
            formData.append('name', submitData.name);
            formData.append('date', submitData.date);
            formData.append('title', submitData.title);
            formData.append('testimonial', submitData.testimonial);
            formData.append('rating', submitData.rating);
            formData.append('image', uploadedImage);

            const { data: { status, message, testimonialExist } }: responseType = await HTTP({
                method: submitData.id ? 'put' : 'post',
                url: `/api/admin/testimonial/${submitData.id ? 'edit' : 'add'}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })
            setIsLoading(false)

            // if (!status && testimonialExist) return // Don't close modal and reset form , if lcoation already exist
            setCountAddEdit((prev: number) => prev + 1)
            handleClose()
            reset()

        }
        catch (error) {
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
            handleClose()
            reset()
            setIsLoading(false)
        }
    }
    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        {testimonialDetails.id === 0 && <Modal.Title>Add Testimonial</Modal.Title>}
                        {testimonialDetails.id !== 0 && <Modal.Title>Update Testimonial</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputGroupField name="name" label="Name"
                                placeholder="Enter name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className="mb-1">
                            <DateTimePicker label="Date" name="date" type="date" control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Manufacture date" />
                        </div>
                        <div className='mb-1'>
                            <InputGroupField name="title" label="Title"
                                placeholder="Enter Title" clearErrors={clearErrors}
                                control={control} />
                        </div>
                        <div className='mb-1'>
                            <InputTextAreaField name="testimonial" label="Testimonial"
                                placeholder="Enter Testimonial" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <InputGroupField name="rating" label="Rating"
                                placeholder="Enter rating" clearErrors={clearErrors} type='number' min={1} max={5}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <FileUpload name="uploadedImage" label="Testimonial Image" accept=".jpeg,.jpg,.png"
                                control={control} />
                            <Form.Text className="text-black-50">Upload images in png/jpg/jpeg format with 500 * 500 pixel, and less than 2 MB size</Form.Text>
                        </div>
                        <div>
                            {imageURL && <Image src={imageURL} width={"30%"} />}
                        </div>

                        <InputGroupField name="id" label=""
                            placeholder="" type="hidden" clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {testimonialDetails.id ? 'Update' : 'Save Changes'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditTestimonial;
