import Header from './Header';
import Footer from './Footer';
import { Outlet, useParams } from 'react-router-dom';
import { useLocation } from "react-router";
import LocationModal from "../components/modules/Location/LocationModal";
import { useEffect, useState } from 'react';
import { encryptLocalStorage } from '../config/utils/storage';
import { ClientContextProvider } from "../config/context/clientContext";

const DefaultLayout = () => {

    const location = useLocation();
    const { locationParam } = useParams();
    const [showLoactionModal, setLocationModal] = useState(false)

    useEffect(() => {
        if (locationParam && !encryptLocalStorage.getItem('selectedLocation')) {
            encryptLocalStorage.setItem("selectedLocation", locationParam);
        }
        if (location.pathname != '/' && !encryptLocalStorage.getItem('selectedLocation')) {
            setLocationModal(true)
        }
    }, [location.pathname])

    return (
        <>
            {/* <LocationModal isOpen={showLoactionModal} closeModal={(value: boolean) => setLocationModal(value)} /> */}
            <ClientContextProvider>
                <Header />

                <div>
                    <Outlet />
                </div>
            </ClientContextProvider>
            <Footer />
        </>
    );
};

export default DefaultLayout;