import { Container } from "react-bootstrap";

const WhoAreWe: React.FC<any> = ({ whoAreWeCms }) => {

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/who_are_we_banner.png`;

    return (
        <Container fluid className="top-banner-class-fit d-flex mb-5" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
            <Container className="my-auto">
                <div dangerouslySetInnerHTML={{ __html: whoAreWeCms && whoAreWeCms.content }}></div>
            </Container>
        </Container>
    );
};

export default WhoAreWe;
