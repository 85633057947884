import {Row,Col, Form,Container,Button} from 'react-bootstrap'

import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import HTTP from '../../../config/axios';
import VehicleImageModal from './vehicleImageFormModal'
import VehicleImageDataTable from './vehiclImageDataTable'


interface IVehicleDetails {
    vehicleId: string | number
    setToast:Function,
    setIsLoading:Function,
    vehicleUuid:string
}

const ImageForm:React.FC<IVehicleDetails> = ({vehicleId,setToast,setIsLoading,vehicleUuid})=>{
    
    const [show, setShow] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [imageDetails, setImageDetails] = useState({ id: 0, sortOrder:totalRows+1,imageUrl:'',uuid:vehicleUuid,vehicleId:vehicleId});
    const [addEditCount, setAddEditCount] = useState(0)
    
    const newImage = () => {
        setShow(true);
        setImageDetails({ id: 0, sortOrder:totalRows+1,imageUrl:'',uuid:vehicleUuid,vehicleId:vehicleId})
    }
    const handleClose = () => setShow(false);

    const editModal = (image: any) => {
        const images = { id: image.imageId,sortOrder:image.sortOrder,imageUrl:image.thumbnailUrl,uuid:vehicleUuid,vehicleId:vehicleId }
        setImageDetails(images)
        setShow(true)
    }



    return(<>
        <Container  fluid>
            <h1>Vehicle Images</h1>
            <VehicleImageModal show={show} 
                setToast = {setToast} 
                setIsLoading= {setIsLoading} 
                handleClose={handleClose} 
                imageDetails={imageDetails} 
                setAddEditCount={setAddEditCount}/>
            <VehicleImageDataTable 
                newImage={newImage} 
                vehicleUuid={vehicleUuid} 
                vehicleId={vehicleId} 
                editModalDetails={editModal} 
                addEditCount={addEditCount}
                totalRows = {totalRows}
                setTotalRows = {setTotalRows}
                 />
        </Container>
    
    </>)
}
export default ImageForm