import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '../../form/Checkbox';

const ModelTable: React.FC<any> = ({ newModel, editModalDetails, addEditCount }) => {
    const [vehicleModelData, setVehicleModelData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [totalRows, setTotalRows] = useState(0);
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [make, setMake] = useState('makeAll')
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedModels = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            keyName: 'name'
        },
        {
            name: 'Make Name',
            selector: (row: { makeName: string; }) => row.makeName,
            sortable: true,
            keyName: 'makeName'
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedModels ? <> <Button variant="success" size="sm" className="me-3" onClick={() => editModel(row)} title="Edit info">
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                        <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id)} title="Remove">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}

                </div>
            ),
            center: true,
        }
    ];

    //Fetching the intial data for the data table
    useEffect(() => {
        const getAllModels = async () => {
            setIsLoading(true)
            const modelStatus = archivedModels ? 0 : 1
            const params = { searchText: currentSearchText, sortDetails: JSON.stringify(sortDetails), status: modelStatus };
            const response = await HTTP.get(`/api/admin/model/fetch-all/${currentPage}/${perPage}/${make}`, { params });
            const { data: { status, data, msg, error, totalRecords } } = response;
            setVehicleModelData(data.rows);
            setTotalRows(data.count)
            setIsLoading(false)
        }
        getAllModels()
    }, [addEditCount, currentPage, perPage, sortDetails, make, removedIds, currentSearchText, archivedModels]);



    //Invokng the edit modal for editing the model
    const editModel = (varaint: any) => {
        editModalDetails(varaint)
    }


    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
        //setIsLoading(true);
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
        //setIsLoading(true);
    };

    //handling Delete of models
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }


    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false);
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        const response = await HTTP.patch(`/api/admin/model/remove/${selectedRecord}`);
        const { data: { status, data, totalCount, message, error, type } } = response
        if (status == 1) {
            //getAllModels({page:currentPage,limit:limit,makeId:'makeAll'})
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: 'Model deleted successfully', variant: 'success' })
        } else if (status == 0 && type == "assigned") {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: message, variant: 'danger' })

        } else {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
    }


    const handleRecover = async () => {
        setOpenActivateModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/model/recover/${selectedRecord}`);
        const { data: { status, data, totalCount, msg, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setIsLoading(false);
            setToast({ open: true, header: 'Success', message: 'Model recovered successfully', variant: 'success' })
        } else {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }
    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
        //getAllModels({page:1,limit,'makeId',columnDetails})
    }

    return (<>

        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Vehicle model</h2></div>
                <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <Checkbox name="archived" control={control} label="Show removed models"
                        classList="" /></div>
                <div className="p-2">
                    <Button variant="success" onClick={newModel}>
                        Add model
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={vehicleModelData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete vehicle model"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover model"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
    </>)
}

export default ModelTable;