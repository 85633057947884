import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container } from "react-bootstrap";
import BlogList from "../../../pages/module/blog/BlogList";


const Blogs = () => {

  
    return (
        <>
                <Container className="mt-3">
                    <Col md={12}>
                  <BlogList/>
                    </Col>
                </Container>
        
          
        </>
    );
}

export default Blogs;
