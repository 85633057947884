import { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Tab, Image, Form, Tabs } from "react-bootstrap";
import HTTP from "../../../config/axios";
import { getFormatEEEDoMMMyyyy, getFormatTimehhmmaa } from "../../../config/utils/dateFunctions"
import { SALE_PICKUP_HOME, VehicleSaleStatusLabel } from "../../../config/utils/helper";
import CustomPopover from "../../common/CustomPopover";

const SellOrderList = ({ isMobile }: { isMobile?: boolean }) => {

  const [saleOrders, setSaleOrders] = useState([])

  useEffect(() => {
    try {
      const getSaleOrders = async () => {
        const response = await HTTP.get(`/api/vehicle/sale-orders`)
        const { data: { status, data, msg, error } } = response
        if (status == 1) {
          setSaleOrders(data)
        }
      }
      getSaleOrders()
    }
    catch (err) {
      console.log("Error in sale order fetch api", err)
    }
  }, [])

  return (
    <Tab.Pane eventKey="sideTab_3" className="profile-nav-scroll">
      <h2 className="text-dark is-testimonials pb-3">
        Sell orders
      </h2>
      {saleOrders.length ? saleOrders.map((order: any, index: number) => (
        <div key={index}>
          {!isMobile ? (
            <Row className="pt-4">
              <Col lg={3} md={4} sm={4} xs={5}>
                <Image
                  // src={`${process.env.REACT_APP_CLOUDFRONT_URL}${order.image}`}
                  // STATIC IMAGE: temperory fix
                  src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/skoda.png`}
                  className="rounded-circle" height={"120px"} width={"120px"} />
              </Col>
              <Col lg={9} md={8} sm={8} xs={7} className="align-self-center ps-1" >
                <div className="d-flex justify-content-between">
                  <h3 className="text-dark mb-0">
                    {`${order.year} ${order.makeName} ${order.modelName} ${order.variantName}`}
                  </h3>
                  <CustomPopover placement="top"
                    body="<p>  Contact us at:</p>
                    <strong class='text-primary'>1800 123 8090</strong><br />
                    <strong class='text-primary'>+91 73061 12802</strong><br />
                    Or message us on WhatsApp!"

                    component={<p className="text-primary mb-0"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click from bubbling up to the parent
                      }}> Need&nbsp;help?</p>} />
                </div>
                <p className="mb-0">{getFormatEEEDoMMMyyyy(order.evaluationDate)}, {order.evaluationTime}</p>
                <p className="mb-0">
                  Mode - <span className="text-primary" > {SALE_PICKUP_HOME == order.evaluationType ? 'Pickup' : 'Drop'}</span>
                </p>
                <hr />
                <h6 className="text-dark">Estimated price</h6>
                <div className="d-flex justify-content-between pb-3">
                  <h3 className="text-primary  mb-0">
                    ₹28.90 Lakhs - ₹30.24 Lakhs
                  </h3>
                  <p className="text-dark mb-0">Status : {VehicleSaleStatusLabel[order.saleStatus]}</p>
                </div>
              </Col>
              <hr className="mt-4" />
            </Row>) : (<Row className="pt-2 pb-4">
              <Col lg={3} md={4} sm={4} xs={4}>
                <Image
                  // src={`${process.env.REACT_APP_CLOUDFRONT_URL}${order.image}`}
                  // STATIC IMAGE: temperory fix
                  src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/skoda.png`}
                  className="rounded-circle" height={"100px"} width={"100px"} />
              </Col>
              <Col lg={9} md={8} sm={8} xs={8} className="align-self-center ps-1" >
                <div className="d-flex justify-content-between">
                  <h3 className="text-dark mb-0">
                    {`${order.makeName} ${order.modelName} ${order.variantName}`}
                  </h3>

                </div>
                <p className="mb-0">{getFormatEEEDoMMMyyyy(order.evaluationDate)}</p>
                <p className="mb-0"> {order.evaluationTime}</p>
                <p className="mb-0">
                  Mode - <span className="text-primary" > {SALE_PICKUP_HOME == order.evaluationType ? 'Pickup' : 'Drop'}</span>
                </p>
                <CustomPopover placement="top"
                  body="<p>  Contact us at:</p>
                    <strong class='text-primary'>1800 123 8090</strong><br />
                   <strong class='text-primary'>+91 73061 12802</strong><br />
                    Or message us on WhatsApp!"

                  component={<p className="text-primary mb-0"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from bubbling up to the parent
                    }}> Need&nbsp;help?</p>} />
                <hr />
                <h6 className="text-dark">Estimated price</h6>
                <div className="d-flex justify-content-between pb-3">
                  <h3 className="text-primary  mb-0">
                    ₹28.90 Lakhs - ₹30.24 Lakhs
                  </h3>
                </div>
                <p className="text-dark mb-0">Status : {VehicleSaleStatusLabel[order.saleStatus]}</p>
              </Col>
              {saleOrders.length >= 2 && <hr className="mt-4" />}
            </Row>)}
        </div>
      )) :

        <Row className="pt-4 text-dark">
          <p>No sell orders found </p>
        </Row>
      }

    </Tab.Pane>
  )
}

export default SellOrderList