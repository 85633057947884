import { Container, Col, Card, Row, Form, Button } from "react-bootstrap";
import InputTextField from "../../form/InputTextField";
import DropDownField from "../../form/DropDownField";
import FileUpload from "../../form/FileUpload";
import { useLocation } from "react-router-dom"
import { useForm } from "react-hook-form";
import { fieldRequired, maxLength, minLength, panCardEqualTo, validatePAN } from '../../../config/validations/rules';
import { useEffect, useState, useRef } from "react";
import HTTP from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import SimpleToast from "../../common/Toast";
import SimpleSpinner from "../../common/Spinner";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faImage, faLink
} from "@fortawesome/free-solid-svg-icons";



const CarBooking = () => {

    interface LocationInterface {
        id: number;
        name: string;
        keyName: string;
        image: string;
    }

    const [locationArray, setLocationArray] = useState<LocationInterface[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const defaultState = { fullCarName: '', vehicleId: '', price: '' };
    const { state } = useLocation();
    const { fullCarName, vehicleId, price } = state || defaultState;
    const { control, handleSubmit, register, setValue } = useForm()
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [addressProofValidation, setAddressProofValidation] = useState(false)
    const [isImageLarger, setIsImageLarger] = useState(false)

    if (!vehicleId) navigate(`/buy-used-car`)

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (!token) {
            window.location.replace("/sign-in");
        }
    }, []);

    useEffect(() => {
        try {
            const getAllLocation = async () => {
                const locationData = await HTTP.get(`/api/location/fetch-all`, { params: { status: 1 } });
                const { data: { status, data, msg, error } } = locationData

                if (status == 1) {
                    setLocationArray(data.map((location: any) => { return { id: location.id, name: location.name } }))
                } else {
                    setLocationArray([])
                }
            }
            getAllLocation()
        }
        catch (err) { console.log("Error in Booking location Api", err) }
    }, []);


    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true);
            if (!submitData.address_proof) {
                setAddressProofValidation(true)
                setIsLoading(false);
                return
            }
            const formData = new FormData()
            formData.append('vehicleId', vehicleId);
            formData.append('panNumber', submitData.panNumber);
            formData.append('address_proof', submitData.address_proof);

            const response = await HTTP.post(`/api/booking/verifyAndAdd`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            setIsLoading(false);
            const { data: { status, bookingStatus, bookingId, proofUrl, msg } } = response

            if (status && bookingStatus) {
                let  selectedLocation;
                locationArray.forEach((option:any) => {
                    if(submitData.locationId == option.id) selectedLocation = option.name
                })
                navigate(`/checkout`, { state: { vehicleId, bookingId, proofUrl, panNumber: submitData.panNumber, price, selectedLocation } })
            } else {
                setToast({ open: true, header: 'Failed', message: msg, variant: 'danger' })
            }

        }
        catch (error: any) {
            console.log("Error in Booking submit Api", error)
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: 'Something went wrong. Please try again', variant: 'danger' })
        }
    }
    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const { name, size, type } = file;
            const maxSize = 2 * 1024 * 1024;
            if (size > maxSize) {
                setIsImageLarger(true)
                return
            } else {
                setIsImageLarger(false)

            }
            

            const fileExt = file.name.split('.').pop();
            if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type) ||
                !['jpeg', 'jpg', 'png'].includes(fileExt.toLowerCase())) {
                setToast({ open: true, header: 'Failed', message: 'Only files with these extensions are allowed: jpg, jpeg, png.', variant: 'danger' })
                return
            }

            setValue("address_proof", file)


            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileSelect = () => {
        setAddressProofValidation(false)
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/checkout-banner.png`;

    return (<>
        {vehicleId &&
            <Container fluid className="banner-class-fit" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col
                        md={{ span: 7, offset: 5 }}
                        lg={{ span: 5, offset: 7 }}
                        sm={{ span: 12, offset: 0 }}
                    >
                        <Card className="p-4 booking-card mt-90">
                            <div className="text-center">
                                <h3 className="text-primary mb-0 ">Online booking</h3>
                                <h3 className="text-dark is-testimonials">
                                    {fullCarName}
                                </h3>
                            </div>
                            <h3 className="text-primary">Details</h3>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md={8}>
                                        <div className="mb-3">
                                            <InputTextField name="panNumber" label="PAN card number"
                                                placeholder="Enter PAN card no" control={control} maxLength={10}
                                                rule={{ required: fieldRequired, pattern: validatePAN }} upperCase={true} />
                                        </div>
                                    </Col>
                                    <label className="text-dark fw-semibold form-label fs-6">Address proof <span className="text-danger fw-bold">*</span></label> {addressProofValidation && <small className="text-danger form-text">Address proof is required</small>}
                                    <div className="booking-upload-box">

                                        <div className="mb-3">
                                            <div className="mb-3"
                                            >
                                                {selectedImage ? (
                                                    <img src={selectedImage} alt="Preview" className="image-preview-image" />
                                                ) : (
                                                    <div className="image-preview-box">
                                                        <FontAwesomeIcon icon={faImage} className="fs-3" />
                                                    </div>
                                                )}
                                            </div>
                                            <input {...register("address_proof")}
                                                type="file"
                                                ref={fileInputRef}
                                                accept=".jpeg,.jpg,.png"
                                                style={{ display: "none" }}
                                                onChange={handleImageChange}
                                            />
                                            <p onClick={handleFileSelect} className="text-primary fw-semibold" style={{ cursor: "pointer" }} title="Upload addres proof"><span><FontAwesomeIcon icon={faLink} className="fs-5 me-2" /></span> Choose file</p>
                                            {/* <Form.Text className="text-black-50">Only Jpeg, png and jpg formats accepted</Form.Text> */}
                                            {isImageLarger ? (<Form.Text className="text-danger">Upload image less than 2 MB size</Form.Text>) : (<Form.Text className="text-black-50">Upload image in png/jpg/jpeg format greater than 150 * 150 pixel and less than 2 MB size</Form.Text>)}

                                        </div>
                                    </div>

                                </Row>
                                <Row className="mt-2">
                                    <Col md={12}>
                                        <Button variant="primary" type="submit">
                                            Book now
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Container>
            </Container >}
        <SimpleSpinner show={isLoading} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
    </>)


}




export default CarBooking