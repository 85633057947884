import React, { useEffect, useState } from 'react';
import LocationTable from '../../../components/admin/location/LocationTable';
import { Button } from 'react-bootstrap';
import LocationAddEditModal from '../../../components/admin/location/LocationAddEditModal';

const ListLocations: React.FC = () => {
    const [show, setShow] = useState(false);
    const [countAddEdit, setCountAddEdit] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [sortOrder, setSortOrder] = useState(0)
    const [locationDetails, setLocationDetails] = useState({ id: 0, name: '', keyName: '', image: '', sortOrder: sortOrder+1, address: '' });
    const newModal = () => {
        setShow(true);
        setLocationDetails({ id: 0, name: '', keyName: '', image: '', sortOrder: sortOrder+1, address: '' })
    }
    const handleClose = () => setShow(false);

    const editModal = (location: any) => {

        setLocationDetails(location)
        setShow(true)
    }

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <LocationTable newModal={newModal} showModal={editModal} handleClose={handleClose} countAddEdit={countAddEdit} totalRows={totalRows} setTotalRows={setTotalRows} setSortOrder={setSortOrder} />
                            <LocationAddEditModal show={show} handleClose={handleClose} locationDetails={locationDetails} setCountAddEdit={setCountAddEdit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListLocations;