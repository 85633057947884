import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '../../form/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import ImageCellForDataTable from '../../common/ImageCellForDataTable';

const BlogTable: React.FC<any> = () => {
    const [blogData, setBlogData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const[selectedRecordTitle, setSelectedRecordTitle] = useState<string | null>('')
    const [totalRows, setTotalRows] = useState(0)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedBlogRecords = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');
    const navigate = useNavigate();

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Title',
            selector: (row: { title: string; }) => row.title,
            sortable: true,
            keyName: 'title'
        }, {
            name: 'Image',
            cell: (row: { image: string; }) => row.image && <ImageCellForDataTable value={row.image} maxHeight='46px' />,
            center: true,
        }, {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedBlogRecords ? <>
                        <Link to={`/admin/blog-add-edit?id=${row.id}`}>
                            <Button variant="success" size="sm" className="me-3" title="Edit info">
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                        </Link>

                        <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id,row.title)} title="Remove">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>

                    </> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id,row.title)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}

                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        try {
            const getAllBlog = async () => {
                setIsLoading(true)
                const blogRecordStatus = archivedBlogRecords ? 0 : 1
                const params = { currentPage, perPage, sortDetails: JSON.stringify(sortDetails), searchText: currentSearchText, status: blogRecordStatus };
                const response = await HTTP.get(`/api/admin/blog/fetch-all`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setBlogData(data);
           
                setTotalRows(totalRecords)

            }
            getAllBlog()
        }
        catch (error) {
            console.log("Error in blog fetch api")
        }
        finally {
            setIsLoading(false)
        }
    }, [currentPage, perPage, sortDetails, removedIds, archivedBlogRecords, currentSearchText]);

    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };
    const handleRecoverBlog = async ()=>{
        setIsOpenModal(false)
        setOpenActivateModal(false)
        setIsLoading(true);
         try {
        const response = await HTTP.patch(`/api/admin/blog/recover/${selectedRecord}`, {
            id: selectedRecord,
            status:1,
            title: selectedRecordTitle});

        const { data: { status, message, type } } = response;

        if (status === 1) {
              setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message:' Blog Recovered Successfully', variant: 'success' })
         
        } else {
              setToast({ open: true, header: 'Failed', message: 'Blog title already exist', variant: 'danger' })
           
        }
         setIsLoading(false);
    } catch (error) {
        // Handle error if the request fails
        console.error('Error Recovering Blog', error);
        setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' });
    }

    }

  const handleDeleteClick = async (id: number, title: string) => {
    setSelectedRecordTitle(title);
    setSelectedRecord(id);
    setIsOpenModal(true);
}

    const handleActivateClick = (id: number , title: string) => {

        setSelectedRecordTitle(title);
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }
    const handleCancelClick = () => {
        setSelectedRecord(null);
        setSelectedRecordTitle('')
        setIsOpenModal(false)
        setOpenActivateModal(false);
    }

    const handleBlogRecordStatus = async (isDelete = true) => {
        setIsOpenModal(false)
        setOpenActivateModal(false)
        setIsLoading(true);
        const response = await HTTP.put(`/api/admin/blog/update-status`, { id: selectedRecord, status: isDelete ? 0 : 1});
        const { data: { status, error, message } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: isDelete ? 'Blog Removed Successfully' : 'Blog Recovered Successfully', variant: 'success' })
        } else {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        setIsLoading(false);
    }
    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    const addBlog = () => {

        navigate('/admin/blog-add-edit')
    }

    return (<>
        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">All blogs</h2></div>
                <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <Checkbox name="archived" control={control} label="Show removed blogs"
                        classList="" /></div>
                <div className="p-2">
                    <Button onClick={addBlog} variant="success"> Add new blog </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>
        <DataTable
            columns={columns}
            data={blogData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete blog"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleBlogRecordStatus(true)}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover blog"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleRecoverBlog()}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
    </>)
}

export default BlogTable;