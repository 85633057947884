import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';
import FileUpload from '../../form/FileUpload';
import { Image } from 'react-bootstrap';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        msg: string,
        type: string
    };
}
interface IBodyTypeInfo {
    id: number;
    name: string;
    image: string;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    bodyTypeDetails: IBodyTypeInfo;
    setAddEditCount: Function
}

const BodyTypeAddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, bodyTypeDetails, setAddEditCount }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [imageURL, setImageURL] = useState('')

    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm<Record<string, any>>();
    const uploadedImage = watch('uploadedImage')

    useEffect(() => {
        reset(bodyTypeDetails);
        setImageURL(bodyTypeDetails.image ? `${process.env.REACT_APP_CLOUDFRONT_URL}${bodyTypeDetails.image}` : '')
    }, [bodyTypeDetails]);

    useEffect(() => {
        uploadedImage && setImageURL(URL.createObjectURL(uploadedImage) || '')
    }, [uploadedImage])


    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append('id', submitData.id)
            formData.append('name', submitData.name);
            formData.append('image', uploadedImage)
            const { data: { status, msg } }: responseType = await HTTP({
                method: submitData.id ? 'put' : 'post',
                url: `/api/admin/body-type/${submitData.id ? 'edit' : 'add'}`,
                data: formData, headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setToast({ open: true, header: status ? 'Success' : 'Failed', message: (msg) ? msg : 'Something went wrong', variant: status ? 'success' : 'danger' })
            status && setAddEditCount((prev: number) => prev + 1)
        }
        catch (err) {
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
        }
        finally {
            setIsLoading(false)
            handleClose()
            reset()
        }
    }

    const closeModal = () => {
        handleClose()
        reset()
    }


    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        {bodyTypeDetails.id === 0 && <Modal.Title>Add body type</Modal.Title>}
                        {bodyTypeDetails.id !== 0 && <Modal.Title>Update body type</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Name"
                                placeholder="Enter name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <FileUpload name="uploadedImage" label="Body Type Image" accept=".jpeg,.jpg,.png"
                                control={control} rule={{ required: bodyTypeDetails.id == 0 ? fieldRequired : false }} />
                            {/* <Form.Text className="text-black-50">Only Jpeg, png and jpg formats accepted</Form.Text> */}
                            <Form.Text className="text-black-50">Upload image in png format with 60 * 27 pixel and less than 2 MB size</Form.Text>
                        </div>
                        <div>
                            {imageURL && <Image src={imageURL} width={"30%"} />}
                        </div>
                        <InputTextField name="id" label=""
                            placeholder="" type="hidden" clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {bodyTypeDetails.id ? 'Update' : 'Save Changes'}
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
            <SimpleSpinner show={isLoading} />
        </>
    );
}

export default BodyTypeAddEditModal;

