import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { equalCharCheck, equalTo, fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';
import InputTextField from '../../form/InputTextField';
import InputGroupField from '../../form/InputGroupField';
import DropDownField from '../../form/DropDownField';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        colourExist: boolean
    };
}
interface IColourInfo {
    id: number;
    name: string;
    keyName: string;
    hexCode: string;
}

interface AddEditColourProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    colourDetails: IColourInfo;
    setCountAddEdit: Function;
    totalRows: number;
}

const AddEditColour: React.FC<AddEditColourProps> = ({ show, handleClose, colourDetails, setCountAddEdit, totalRows }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [parentColours, setParentColours] = useState([])

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch, setValue } = useForm();

    useEffect(() => {
        const formData = { ...colourDetails, hexCode: colourDetails.hexCode.substring(1)} 
        reset(formData);
    }, [colourDetails]);


    useEffect(() => {
        try {
            const getAllColours = async () => {
                const params = { type: 1 };
                const response = await HTTP.get(`/api/admin/colour/fetch-all`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setParentColours(data);
            }
            getAllColours()
        }
        catch (error) {
            console.log("Error in colour fetch api")
        } 
    }, [ ]);



    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            submitData.hexCode = '#'+submitData.hexCode
            const { data: { status, message, colourExist } }: responseType = await HTTP({
                method: submitData.id ? 'put' : 'post',
                url: `/api/admin/colour/${submitData.id ? 'edit' : 'add'}`,
                data: submitData
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })

            setIsLoading(false)
            if (!status && colourExist) return // Don't close modal and reset form , if lcoation already exist
            setCountAddEdit((prev: number) => prev + 1)
            handleClose()
            reset()

        }
        catch (error) {
            setIsLoading(false)
            setToast({
                open: true,
                header: "Failed",
                message:"Something went wrong",
                variant: "danger",
            });
            console.log("Error on Colour add/ edit api", error)
        }
    }


    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{colourDetails.id ? 'Update' : 'Add'} Colour</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Colour Name"
                                placeholder="Enter Colour name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <DropDownField name="parentId" label="Parent Colour" options={parentColours}
                                placeholder="Select parent colour" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <InputGroupField name="hexCode" label="Hex Code" groupText="#"
                                placeholder="Enter Hex Code" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired, validate: (value:string)=>equalCharCheck(value, 6) }} />                                
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {colourDetails.id ? 'Update' : 'Save Changes'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditColour;