// import "bootstrap/dist/css/bootstrap.min.css";
import CarCheckout from "../../../components/modules/Booking/CarCheckout";

const Checkout = () => {
    return (
        <div className="App">
            <CarCheckout />
        </div>
    );
}

export default Checkout;