import { Button, Col, Row, Table } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { useState, useEffect, Fragment } from "react";
import HTTP from "../../../config/axios";
import SpecificationsModal from "./SpecificationsModal";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { useLocation, useNavigate } from "react-router-dom";

const SpecificationList: React.FC<any> = ({ id, isMobile, isLimited }: { id: string; isMobile?: boolean; isLimited?: boolean; }) => {
  const [specificationList, setSpecificationList] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentLocation = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const getCarSpecifications = async () => {
      const specificationResponse = await HTTP.get(
        `/api/vehicle/specifications/fetch-all/${id}`
      );
      const {
        data: { status, data, msg, error },
      } = specificationResponse;
      if (status == 1) {
        const specificationDataArray: any = [];
        let dataArray: any = [];
        data.map((element: any, index: number) => {
          if (isMobile) {
            specificationDataArray.push(element);
          } else {
            if ((index + 1) % 3 == 1 && index != 0) {
              specificationDataArray.push(dataArray);
              dataArray = [];
              dataArray.push(element);
            } else {
              dataArray.push(element);
            }
            if (index + 1 === data.length) {
              specificationDataArray.push(dataArray);
            }
          }
        });
        setSpecificationList(specificationDataArray);
      } else {
        setSpecificationList([]);
      }
    };
    getCarSpecifications();
  }, [id]);

  const openModal = () => {
    if (!isLoggedIn) {
      encryptLocalStorage.setItem("previousLoggedoutLocation", currentLocation.pathname)
      navigate("/sign-in")
    }
    else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      {!isMobile ? (
        <>
          <h3 className="text-dark is-testimonials mt-3">Car specifications</h3>
          <div className="border rounded px-3">
            {specificationList &&
              specificationList.map((element: any, index: number) => {
                if (isLimited && index >= 3) {
                  return null; // Stop execution beyond the 9th loop if isLimited is true
                }
                return (

                  <Table className="mt-1">
                    <thead>
                      <tr className="custom-tr w-100">
                        {element.map((spec: any, index: number) => (
                          <th className="custom-th align-top" key={index} title={`${spec.name}${spec.unit ? ` (${spec.unit})` : ''}`} style={{ width: '33.33%' }}>
                            <p className="custom-p"> {spec.name}
                              {spec.unit && ` (${spec.unit})`}</p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {element.map((spec: any, index: number) => (
                          <td key={index} className="custom-td align-top" style={{ width: '33.33%' }}>
                            <p className="text-dark is-description custom-p ">{spec.value}</p>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </Table>
                );
              })}
            {specificationList.length != 0 ? (<div className="d-flex justify-content-end">
              <Button variant="primary" onClick={openModal} className="my-2">
                View all specifications
              </Button>
            </div>
            ) : (
              <p>No specifications added</p>
            )}
          </div>
        </>
      ) : (
        <>
          <h3 className="text-dark is-testimonials pt-3 ps-0">Car specifications</h3>
          <div className="border rounded  mt-2 ">
            <Row >
              {specificationList &&
                specificationList.map((spec: any, index: number) => {
                  if (isLimited && index >= 9) {
                    return null; // Stop execution beyond the 9th loop if isLimited is true
                  }
                  return (
                    <Fragment key={index}  >
                      <Col sm={6} xs={6} className="pt-2">
                        <p>
                          {spec.name} {spec.unit && `(${spec.unit})`}
                        </p>
                        <p className="text-dark is-description">{spec.value}</p>
                      </Col>
                      <div className="border-top" />
                    </Fragment>
                  );
                })}
              {specificationList.length != 0 ? (
                <div className="d-flex justify-content">
                  <Button variant="primary" onClick={openModal} className="my-2 w-100">
                    View all specifications
                  </Button>
                </div>

              ) : (
                <p>No specifications added</p>
              )}
            </Row>
          </div>
        </>
      )}
      {isModalOpen && (
        <SpecificationsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} id={id} />
      )}
    </>
  );
};

export default SpecificationList;
