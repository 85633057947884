import React from 'react';
import Image from 'react-bootstrap/Image';

const ImageCellForDataTable = ({ value, maxWidth = '100px', maxHeight = '65px', isS3Path , presignedUrl}: any) => {
    // Assuming `value` is the partial path from the API
    if(presignedUrl){
        return  <Image src={value} alt="Logo" style={{ maxWidth, maxHeight }} thumbnail />
    }
    let fullImageUrl = ""
    if (value != null) {
        if (!isS3Path) {
            const cloudfrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;
            fullImageUrl = `${cloudfrontUrl}${value}`;
        } else {
            const imageS3Url = process.env.REACT_APP_S3_BASE_URL;
            fullImageUrl = `${imageS3Url}/${value}`;
        }
    }

    return fullImageUrl ? <Image src={fullImageUrl} alt="Logo" style={{ maxWidth, maxHeight }} thumbnail /> : null;
};

export default ImageCellForDataTable;