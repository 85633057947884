import { useEffect, useRef, useState } from "react";
import { Col, Row, Container, Image, Button } from "react-bootstrap";
import ImageMarker, { Marker } from 'react-image-marker';
import InputTextField from "../components/form/InputTextField";
import { useForm } from "react-hook-form";
import InputTextAreaField from "../components/form/InputTextAreaField";
import { Form } from "react-bootstrap";
import { faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileUpload from "../components/form/FileUpload";

const DentMap = () => {

    const dentImgInput = useRef<any>()
    const [selectedImage, setSelectedImage] = useState("")
    const [currentMark, setCurrentMark] = useState({})
    const [showForm, setShowForm] = useState(false)
    const [showDentMap, setShowDentMap] = useState(false)
    const [selectedDent, setSelectedDent] = useState<any>("")

    const { control, handleSubmit, watch, reset } = useForm()
    const [markers, setMarkers] = useState< Array <any >>([])
    // useState < Array <any >> ([
    //                                 {
    //                                     top: 10, //10% of the image relative size from top
    //                                     left: 50, //50% of the image relative size from left
    //                                     image: ''
    //                                 },
    //                             ]);

useEffect(() => {

    console.log("markers =============",markers);
    

},[markers])

const currentSelectImg = watch('dent_image')

useEffect(() => {
    currentSelectImg && setSelectedImage(URL.createObjectURL(currentSelectImg) || '')
}, [currentSelectImg])

const handleAddMarker = (marker:any) => {
    // const { offsetX, offsetY } = event.nativeEvent;
      
     //dentImgInput?.current?.click()
      setMarkers([...markers, marker])

    setShowForm(true)
     setCurrentMark(marker)
  };


  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
        const { name, size, type } = file;

        const fileExt = file.name.split('.').pop();
        if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type) ||
            !['jpeg', 'jpg', 'png'].includes(fileExt.toLowerCase())) {
            // setToast({ open: true, header: 'Failed', message: 'Only files with these extensions are allowed: jpg, jpeg, png.', variant: 'danger' })
            return
        }



        const reader = new FileReader();
        reader.onload = (e) => {
            setSelectedImage(e.target?.result as string);
            
            setMarkers([...markers, {...currentMark, image: e.target?.result as string}])
        };
        reader.readAsDataURL(file);
    }
};

    const addMarker = (data:any) => {
       

        data.dent_image = selectedImage

        // add form data to dent object
        setMarkers((prev:any) => {

            const lastIndex = prev.length - 1
            const lastDent = { ...prev[lastIndex],  ...data}

            return [...prev.slice(0, lastIndex), lastDent]
        })
        reset()
        setShowForm(false)
        setSelectedImage("")
    }

    return (
        <div className="App pt-5 pb-5 mt-5"  >
        <Container className=" pb-5"  >
            { !showDentMap ? <>
            <Row><h2 className="text-dark text-center pb-4 mt-5">Create Dent Map</h2></Row>
            <Row>

            <Col>
            <Row>
                <h3 className="text-dark fw-semibold mb-0"> Mark dents on car   </h3>
                <p className="text-secondary fs-7"> <FontAwesomeIcon icon={faExclamationCircle} />
                 &nbsp;Click on image below to mark and add dent details</p>
            </Row>

                <div className="image-container">
                <ImageMarker 
                    src="https://spn-sta.spinny.com/spinny-web/static-images/assets/images/pages/ProductDetail/components/DentMap/assets/dent-map-mobile.png"
                    markers={markers}
                    onAddMarker={handleAddMarker}
                />
                </div>
            </Col>


            {/* <Col>
                <div className="image-container">
                    <img
                    src="https://spn-sta.spinny.com/spinny-web/static-images/assets/images/pages/ProductDetail/components/DentMap/assets/dent-map-mobile.png"
                    alt="Your Image"
                    className="image"
                    />
                    {markers.map((mark, index) => (
                        <div className="pointer" key={index}
                        onClick={() => setSelectedImage(mark.image)}
                        style={{ top: mark.top+'%', left: mark.left+'%', cursor: 'pointer' }}>{index +1}</div>
                    ))}
                    
                </div>
            </Col> */}

            <Col>
            <Row>
                <h3 className="text-dark pb-4 fw-semibold"> Add dent details </h3>
            </Row>
            {
                showForm && <Form onSubmit={handleSubmit(addMarker)}>
                    <Row>
                    <div className="mb-3">
                        <InputTextAreaField label="Description" name="description" control={control} />
                    </div>
                    </Row>
                    <Row>
                        <Col>
                        <div className="mb-3">
                            <FileUpload label="Upload Dent Image" name="dent_image" control={control} accept=".jpeg,.jpg,.png"/>
                        </div>
                        </Col>
                        <Col>
                        { selectedImage && <Image src={selectedImage} alt="Preview" style={{maxWidth: '200px', maxHeight: '200px' }} />}
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                        <div className="mt-5">
                            <Button type="submit" variant="primary"> Add Dent</Button>
                        </div>
                        </Col>
                        <Col >
                        <div className="mt-5">
                            <Button type="button" onClick={()=>setShowDentMap(true)}  variant="outline-primary"> View Dent Map</Button>
                        </div>
                        </Col>
                        <Col>
                        
                        </Col>
                    
                    </Row>
        
                    </Form>
            }
            {
                markers.length == 0 && !showForm && <p className="text-primary"> Click on dent map to add dent details</p>
            }

            {
                markers.length > 0 && !showForm && <>
                <p className="text-success fw-bold pt-4"><FontAwesomeIcon icon={faCheck} className="me-3" />Dent details added successfully </p>
                <Row>
                <div className="mt-5">
                            <Button type="button" onClick={()=>setShowDentMap(true)}  variant="outline-primary"> View Dent Map</Button>
                        </div>
                </Row>
                </>
            }
                {/* { selectedImage && <Image src={selectedImage} alt="Preview" style={{maxWidth: '400px', maxHeight: '400px' }}  />} */}
            
                </Col>
      
        </Row>
            </> :
            <>
            <Row><h2 className="text-dark text-center pb-4">Dent Map</h2></Row>
            <Row>
            <Col>
                <div className="image-container">
                    <img
                    src="https://spn-sta.spinny.com/spinny-web/static-images/assets/images/pages/ProductDetail/components/DentMap/assets/dent-map-mobile.png"
                    alt="Your Image"
                    className="image"
                    />
                    {markers.map((mark, index) => (
                        <div className="pointer" key={index}
                        onClick={() => setSelectedDent(mark)}
                        style={{ top: mark.top+'%', left: mark.left+'%', cursor: 'pointer' }}>{index +1}</div>
                    ))}
                    
                </div>
            </Col>
                <Col>
                { selectedDent ? <>
                    <Row>
                        <div className="mb-2">
                            <p className="text-dark"> 
                            <span className="text-primary"> Description: </span> {selectedDent?.description}
                            </p>
                        </div>
                    </Row>
                    <Row>
                    <Image src={selectedDent?.dent_image} alt="Preview" style={{maxWidth: '400px', maxHeight: '400px' }}  />
                    </Row>
                </>:
                    <Row>
                        <p className="text-primary"> Click a pointer on dent map to view dent details</p>
                    </Row>
                }
                </Col>
            </Row>
            </>

            }
                
            
      </Container>


      <input ref={dentImgInput} onChange={handleImageChange}
            type="file" style={{display: 'none'}} />
      </div>
    );
  };
  
  export default DentMap;