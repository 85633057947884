import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface IRadio {
    name: string;
    control: Control;
    options: { label: string; value: number | string }[];
    classList?: string,
    selectedValue?: number | string
    rule?: any;
    label?:any
    errText?: string;
}

const MultiRadio: React.FC<IRadio> = ({
    name,
    control,
    options,
    classList,
    rule,
    label='',
    errText ='',
    selectedValue=""
    
}) => {
    const [errMessage,setErrMessage] = useState('')
    if (rule?.required && typeof rule.required === "function") {
        rule.required = rule.required(label);
    }

    return (<>

        {options.map((option: { label: string; value: number | string }, index: number) => (
            <div key={index} className={classList}>
                <Controller
                    name={name}
                    control={control}
                    rules={rule}
                    render={({ field: { onChange, onBlur, value, name, ref },fieldState: { error } }) =>
                        
                        <>
                            <Form.Check
                                type='radio'
                                name={name}
                                id={`${option.label}-${option.value}`}
                                label={option.label}
                                value={option.value}
                                onClick={(e) => {
                                    onChange(selectedValue === option.value ? "" : option.value);
                                }}
                                
                                checked={selectedValue==option.value}
                            
                            />  
                            { error && error?.message? setErrMessage( error.type != 'required' && errText ? errText : error.message):setErrMessage('')}
                        </>    
                    }
                />   
            </div>
        ))}
        
        {errMessage&& 
            <>
                <br/>
                <Form.Text className="text-danger ms-2">      
                    {errMessage}           
                </Form.Text> 
            </>
            
        }

    </>)
}


export default MultiRadio