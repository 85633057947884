import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import HTTP from "../../../config/axios";
import { getYearValue } from "../../../config/utils/dateFunctions"
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { encryptLocalStorage } from "../../../config/utils/storage";
import ReactSlider from 'react-slider';
import DropDownField from "../../form/DropDownField";
import MultiSelectDropdown from "../../form/MultiDropdown";


const FindCarForm: React.FC<any> = ({ cmsContent }) => {
  let locationName = encryptLocalStorage.getItem("selectedLocation")
  const navigate = useNavigate();
  interface IMake {
    label: any;
    value: string;
   
  }
    interface IModel {
    id: number;
    name: string;
    keyName: string;
     label: string;
    value: string;
  }
  const [selectedMake, setSelectedMake] = useState<IMake[]>([]);
  const [selectedModel,setSelectedModel] = useState<IModel[]>([]);

  const defaultFormValues = {
    makeList: selectedMake,
    modelList: selectedModel,
    fuelType: '',
    vehicleAge: '',
    transmissionType: '',
    kmDriven: '',
    budgetList: [] as string[]  
  }


  const [makeData, setMakeData] = useState<IMake[]>([]);
  const [modelData, setModelData] = useState<IModel[]>([]);
  const [fuelType, setFuelType] = useState<IModel[]>([]);
  const [transmissionType, setTransmissionType] = useState<IModel[]>([]);
  const { register, handleSubmit, formState: { errors }, setError, clearErrors, control, getValues, setValue, watch } = useForm<Record<string, any>>({defaultValues: defaultFormValues});
  const selectBudgetvalues = getValues("budgetList") && getValues("budgetList").length > 0 ? JSON.parse(getValues("budgetList")[0]) : [0, 1010000];
  const [budgetValue, setBudgetValue] = useState(selectBudgetvalues.length > 1 ? selectBudgetvalues : selectBudgetvalues.length > 0 ? [selectBudgetvalues[0], 1010000] : [0, 1010000]);
  const handleChange = (newValue: any) => {
    setBudgetValue(newValue);
    setValue('budgetList', [`[${newValue}]`])
  };

useEffect(() => {
  const getCarMakeData = async () => {
    try {
      const response = await HTTP.get(`/api/make/all`);
      const { data: { status, data } } = response;

      if (status == 1) {
        const updatedData = data.map((makeDetails: any) => ({
          label: makeDetails.name,
          value: makeDetails.keyName,
          id: makeDetails.id,
        }));
        setMakeData(updatedData);
      } else {
        setMakeData([]);
      }
    } catch (error) {
      console.log("Error in fetching car make data", error);
      setMakeData([]);
    }
  };

  getCarMakeData();
}, []);



 useEffect(() => {
  const handleMakeChange = async () => {
    try {
      const makeList = watch('makeList');
      if (!makeList || makeList.length === 0) {
        setValue('modelList',[]); // setting modelList dropdown to empty if there is no make selected
        setModelData([]);
        return;
      }

      const watchMakeList = makeList.map((option: { value: IMake }) => option.value);
      const mkList = JSON.stringify(watchMakeList)
      const response = await HTTP.get(`/api/model/get-by-make-names/${mkList}`);
      const { data: { status, data } } = response;

      if (status === 1) {
        const updatedData = data.map((modelDetails: any) => ({
          label: modelDetails.name,
          value: modelDetails.keyName,
          id: modelDetails.id,
        }));

     
                  // Get current selected models from form state
          const currentSelectedModels = watch('modelList') 

          const newArray: any[] = [];
          updatedData.forEach((obj1: any) => {
              // Check if obj1.id exists in currentSelectedModels
              if (currentSelectedModels.some((obj2: any) => obj2.id === obj1.id)) {
                  newArray.push(obj1); // Move obj1 to newArray
              }
          });
                

          // Update form state with new selected models
          setValue('modelList',newArray);
        setModelData(updatedData);
      } else {
        setModelData([]);
      }
    } catch (error) {
      console.log("Error in make list fetch API", error);
      setModelData([]);
    }
  };

  const makeList = watch('makeList');
  
  if (makeList) {
    handleMakeChange();
  }
}, [watch('makeList')]);




  useEffect(() => {
    const getFuelTypeData = async () => {
      const response = await HTTP.get(`/api/vehicle/fuel/all`);
      const { data: { status, data, msg, error } } = response
      if (status == 1) {
        setFuelType(data)
      } else {
        setFuelType([])
      }
    }
    getFuelTypeData()
  }, []);

  useEffect(() => {
    const getTransmissionType = async () => {
      const response = await HTTP.get(`/api/vehicle/transmission/all`);
      const { data: { status, data, msg, error } } = response
      if (status == 1) {
        setTransmissionType(data)
      } else {
        setTransmissionType([])
      }
    }
    getTransmissionType()
  }, []);



  const carAgeOptions = [
    { name: 'Less than 1 year', keyValue: JSON.stringify({ value: 1, operator: '>' }), keyName: 'less-than-1-year' },
    { name: 'Less than 3 years', keyValue: JSON.stringify({ value: 3, operator: '>' }), keyName: 'less-than-3-years' },
    { name: 'Less than 5 years', keyValue: JSON.stringify({ value: 5, operator: '>' }), keyName: 'less-than-5-years' },
    { name: 'Less than 7 years', keyValue: JSON.stringify({ value: 7, operator: '>' }), keyName: 'less-than-7-years' },
    { name: 'Less than 10 years', keyValue: JSON.stringify({ value: 10, operator: '>' }), keyName: 'less-than-10-years' },
    { name: 'Above 10 years', keyValue: JSON.stringify({ value: 10, operator: '<' }), keyName: 'above-10-years' }
  ]

   const kmOptions = [
    { name: "<10K", keyName: '10000-kms-or-less', keyValue: JSON.stringify({ value: 10000, operator: '<' }) },
    { name: ">10K-30K", keyName: '30000-kms-or-less', keyValue: JSON.stringify({ value: 30000, operator: '<' }) },
    { name: ">30K-50K", keyName: '50000-kms-or-less', keyValue: JSON.stringify({ value: 50000, operator: '<' }) },
    { name: ">50K-75K", keyName: '75000-kms-or-less', keyValue: JSON.stringify({ value: 75000, operator: '<' }) },
    { name: ">75k-1L", keyName: '100000-kms-or-less', keyValue: JSON.stringify({ value: 100000, operator: '<' }) },
    { name: ">1L", keyName: 'above-1-lakh-kms', keyValue: JSON.stringify({ value: 100000, operator: '>' }) },
  ]


  const onSubmit = async (data: any) => {
    try {

      const searchCriteria: any = watch();

      const filteredCriteria = getFilteredCriteria(searchCriteria)
      navigate(`/buy-used-car?search-criteria=${JSON.stringify(filteredCriteria)}`)
    }
    catch (error: any) {
      console.log(error)
    }
  };

  // Function created to remove field not required for search
  const getFilteredCriteria = (searchCriteria: any) => {    
     const criteria = { ...searchCriteria };
    if (criteria.makeList) {
    criteria.makeList = criteria.makeList.map((option: { value: string }) => option.value);
  }
  if (criteria.modelList) {
    criteria.modelList = criteria.modelList.map((option: { value: string }) => option.value);
  }
    
    Object.keys(criteria).forEach(field => {
      if (!criteria[field] || 
        (Array.isArray(criteria[field]) && criteria[field]?.length == 0)) {
        delete criteria[field];
      } else if (!Array.isArray(criteria[field]) && 
              !['vehicleAge','kmDriven'].includes(field) ) { // push the field value into an array, so that it match the search filtering in Buy Car page
        criteria[field] = [criteria[field]]
      }
    });
    return criteria
  }

  return (
    <div className="App">
      <Container>
        <Row className="pt-5">
          <Col md={12}>
            <div className="text-center pb-4">
              <h2 className="text-primary head-bold">FIND YOUR CAR NOW</h2>
            </div>
          </Col>
          <Col  md={12} lg={6}>
            <div dangerouslySetInnerHTML={{ __html: cmsContent && cmsContent.content }}></div>
            <div className="text-center py-4">
              <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/find_car.svg`} width={"70%"} />
            </div>
          </Col>
          <Col md={12} lg={6}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6} sm={6} xs={6}>
          
              
              
           <div className="mb-2">
          <MultiSelectDropdown name="makeList" control={control} label="Make" options={makeData}
          placeholder="Choose make" isValueKeyname={true}  selectedValues={watch('makeList') || []}  setValues={setSelectedMake} classList="text-dark"/>
                  
                  </div>  
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <div className="mb-2">
                  <MultiSelectDropdown name="modelList" label="Model" options={modelData}
                      control={control} placeholder="Choose Model"  isValueKeyname={true}
                   selectedValues={(watch('modelList') || [])}  setValues={setSelectedModel}
                      
                      />
                  </div>
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <div className="mb-3 mt-1">
                    <Form.Label htmlFor="basic-url" className="text-dark fw-semibold fs-6">Choose Budget</Form.Label>
                    <ReactSlider className="horizontal-slider"
                      thumbClassName="custom-thumb "
                      trackClassName="custom-track "
                      min={0}
                      max={1010000}
                      step={10000}
                      value={budgetValue}
                      onChange={handleChange}
                      renderTrack={(props, state) => (
                        <div
                          {...props}
                          className={`custom-track ${state.index == 0 ? 'lower-track' : `${state.index == 2 ? 'upper-track' : 'mid-track'}`}`}
                        ></div>
                      )}
                    />
                    <br />
                    <div className="d-flex mt-2">
                      <div className="me-auto text-primary">₹ {budgetValue[0].toLocaleString('en-IN')}</div>
                      <div className="text-primary">₹ {`${budgetValue[1] > 1000000 ? '1000000+' : budgetValue[1].toLocaleString('en-IN')}`}</div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <DropDownField name="fuelType" label="Fuel Type" options={fuelType}
                        control={control} placeholder="Choose fuel type"  isValueKeyname={true}/>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <DropDownField name="vehicleAge" label="Age of car" options={carAgeOptions}
                        control={control} placeholder="Choose year" isValueKeyname={true}/>
                  </div>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <div className="mb-2">
                  <DropDownField name="transmissionType" label="Choose type" options={transmissionType}
                        control={control} placeholder="Choose type" isValueKeyname={true}/>
                  </div>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <div className="mb-2">
                    <DropDownField name="kmDriven" label="KM Driven" options={kmOptions}
                        control={control} placeholder="Choose KM's" isValueKeyname={true}/>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mt-3">
                    <Button variant="primary" type="submit">
                      Next <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container >
    </div >
  );
};

export default FindCarForm;
