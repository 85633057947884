import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import HTTP from "../../config/axios";
import Breadcrumbs from "../../components/common/Breadcrumbs";

const AboutUs = () => {

    interface IAboutUs {
        id: number;
        title: string;
        name: string;
        content: string;
    }

    const [cmsArray, setCmsArray] = useState<IAboutUs[]>([]);
    useEffect(() => {
        const getAboutUs = async () => {
            const aboutUsData = await HTTP.post(`/api/cms/fetch-all`,
                {
                    nameList: ["about-us"]
                })
            const { data: { status, data, msg, error } } = aboutUsData
            if (status == 1) {
                setCmsArray(data)
            } else {
                setCmsArray([])
            }
        }
        getAboutUs()
    }, []);
    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "About Us", path: "/about-us", active: true, linkClass: "text-primary fw-semibold" }
    ]
    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/about_us_banner.jpg`;
    return (
        <>
            <Container fluid className="top-banner-small-fit" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="mt-120">
                    <Col md={12} className="text-center my-5 py-4">
                        <h1 className="text-light mb-0">About Us</h1>
                    </Col>
                </Container>
            </Container>
            <Container>
                <Breadcrumbs breadcrumbData={breadCrumbList} />
                {cmsArray.map((aboutUsData) => (

                    <Row className="text-dark pb-4 label" key={aboutUsData.id}>
                        <div dangerouslySetInnerHTML={{ __html: aboutUsData.content }}></div>
                    </Row>
                ))}
            </Container>
        </>
    );
}

export default AboutUs;
