import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Faq from "../../components/common/Faq";
import ContactUs from "../../components/common/ContactUs";
import { encryptLocalStorage } from "../../config/utils/storage";
import { useNavigate } from "react-router-dom";

const HassleFreeReturn = () => {
    let locationName = encryptLocalStorage.getItem("selectedLocation");
    const navigate = useNavigate();

    const handleAssuredCars = async () => {
        const searchCriteria = { assuredList: 1 }
        navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}`)
    }

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/hassle_free_return_banner.jpg`;

    return (
        <>
            <Container fluid className="top-banner-small-fit d-flex mt-5" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col md={12}>
                        <h3 className="text-primary mb-1 mt-5">Return</h3>
                        <h2 className="text-light">Hassle free Return</h2>
                        <p className="text-light">If you are not completely satisfied with your pre owned vehicle, simply return it within 3 days of purchase</p>
                    </Col>
                </Container>
            </Container>
            <Container>
                <Row className="mt-4 ">
                    <Col md={12}>
                        <div className="text-center pb-4">
                            <h2 className="text-dark ">How it works</h2>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/choose.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Car should be returned within 3 days or
                                before running 250 kms after purchase <br />
                                ( which ever occurs earlier )</h3>

                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/01.svg`} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/02.svg`} />
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-end text-sm-end">
                            <h3 className="text-dark fw-normal fs-5">The vehicle returned shall be in the same
                                condition as at time of purchase</h3>
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/search-detail.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/test-drive.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Car will be taken back only from the actual
                                purchaser</h3>
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/03.svg`} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block mt-3">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/04.svg`} />
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-5">
                            <hr />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-0 pt-sm-0 pt-xs-0 align-self-center">
                        <div className="text-lg-center text-md-end text-sm-end">
                            <h3 className="text-dark fw-normal fs-5">All other conditions mentioned in the letter
                                of the undertaking executed by the
                                customer at the time of purchase shall be
                                complied with.</h3>

                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/paperwork.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
            </Container >
            <Faq />
            <ContactUs />
        </>
    );
}

export default HassleFreeReturn;