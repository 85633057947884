import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Location from "../Location/Location"
import { Link } from "react-router-dom";

const HomeLocation = () => {
    return (
        <div className="App mt-5">
            <Container className="mb-5">
                <Row className="text-center location-wrapper">
                    {/* <h3 className="text-primary pt-3">Search cars by location</h3> */}
                    <h2 className="text-primary mb-4 head-bold">CARS ACROSS LOCATIONS</h2>
                    <Location showAll={false} />
                    <div className="text-center pt-3">
                        <Link to="/all-locations" className="btn btn-primary">
                            View all locations <FontAwesomeIcon icon={faAngleRight} />
                        </Link>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default HomeLocation;
