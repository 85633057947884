import { useEffect } from "react";
import { useLocation,useSearchParams } from "react-router-dom";

const ScrollToTop = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        // if(!searchParams.get('search-criteria')){
            window.history.scrollRestoration = 'manual';
            window.scrollTo({ top: 0, behavior: 'auto' });
        // }
    }, [location]);

    return null;
}
export default ScrollToTop