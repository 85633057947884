import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row, Image } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft,faCircleChevronRight,faArrowTurnUp,faMedal,faSquareCheck ,faShieldHeart} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PopularBenefits = () => {

 const carouselRef = React.useRef<any>(null);
 const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate();

    const PopularBenefits = [
        {
            id: 1,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/inspected.svg',
            Content: "376 Points Vehicle Evaluation"
        }, {
            id: 2,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/warranty.svg',
            Content: "Certified and Quality Assured Cars"
        }, {
            id: 3,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/guarantee.svg',
            Content: "Up to 1 Year warranty & 3 free services for selected models"
        }, {
            id: 4,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/assured.svg',
            Content: "Only 1 out of 25 cars we inspect qualifies as popular assured"
        },{
            id: 5,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/inspected.svg',
            Content: "376 Points Vehicle Evaluation"
        }, {
            id: 6,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/warranty.svg',
            Content: "Certified and Quality Assured Cars"
        }, {
            id: 7,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/guarantee.svg',
            Content: "Up to 1 Year warranty & 3 free services for selected models"
        }, {
            id: 8,
            icon: process.env.REACT_APP_CLOUDFRONT_URL + '/public/icons/assured.svg',
            Content: "Only 1 out of 25 cars we inspect qualifies as popular assured"
        },
];
   const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 2,
            
        }
    };
 useEffect(() => {
         
        setIsMobile(window.innerWidth <= 565);
         
      }, []);

    const CustomRightArrow = () => (
    <button className="bg-light border-0" onClick={handleNextClick}>
        <FontAwesomeIcon icon={faCircleChevronRight}   size={isMobile ? '2x' : '3x'} className="bg-light" style={{color:"#F05336"}} />
    </button>);

    const handleNextClick = () => {
        navigate('/benefits')
    };

    return (
      <>
        <Container className={`${isMobile ? 'p-1' : ''}`}>
            <hr/>
            <Row className={`${isMobile ? '' : 'my-4'}`}>
                <div>
                    <h2 className={isMobile?"text-dark fs-2 mb-3":"text-dark mb-4"}>Popular car benefits</h2>
                </div>
                <Col xs={9} sm={10} md={10} className="text-center"> 
                    <Carousel
                        centerMode={isMobile}
                        className="home-type-carousel"
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        ssr={true} 
                        infinite={true}
                        ref={carouselRef}
                        arrows={false}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        itemClass={isMobile?"carousel-item-padding-40-px width-c ms-2":"carousel-item-padding-40-px ms-3 width-d d-flex"}
                        showDots={false}
                        rewind={false}
                  
                    >

                        {PopularBenefits.map((data) => (
                            <div key={data.id} className={ isMobile ? "px-2 py-4 benefits-carousel me-2 mb-4" : "benefits-carousel py-5 px-2 me-3 mb-5"}>
                                <Image src={data.icon} alt="benefits"/>
                                <p className="text-dark mb-0">{data.Content}</p>
                            </div>
                        ))}
                    </Carousel>
                </Col>
                <Col xs={3} sm={2} md={2} className={`d-flex align-items-center`}> 
                    <Row className="text-center"> 
                        <CustomRightArrow />
                        <p className="fs-7 mb-0">Learn&nbsp;More</p>
                    </Row>
                </Col>
                
               
                
            </Row>
            <hr />
        </Container>
    </>
    );
}

export default PopularBenefits;
