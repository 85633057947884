import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { dateIsToday, dateTimeDistance, getLongFormatOfDateAndTime, getParsedDate } from '../../../config/utils/dateFunctions';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from "../../common/Spinner";
import MultiRadio from '../../form/MultiRadio';
import InputGroupField from '../../form/InputGroupField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
import ImageCellForDataTable from '../../common/ImageCellForDataTable';
import { downloadFileFromApi, priceFormat } from '../../../config/utils/helper';


const  TransactionDataTable : React.FC<any> = () => {

    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const { control, watch, getValues } = useForm<Record<string, any>>({ defaultValues: { search: "", contactStatus: "All" } });
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [isChecked, setIschecked] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const searchText = watch('search');
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };
    


    const column: any = [
        {
            name: 'Order Id',
            selector: (row: { orderNo: string; }) => `${row.orderNo}`,
            keyName: '',
        },
        {
            name: 'Name',
            selector: (row: { firstName: string; lastName:string }) => `${row.firstName} ${row.lastName}`,
            sortable: true,
            keyName: 'firstName',
        }, 
        {
            name: 'Contact no',
            selector: (row: { phone: string; }) => row.phone,
            keyName: 'mobileNo',
        }, {
            name: 'Vehicle',
            selector: (row: { makeName:string, modelName:string, variantName:string }) => {return(`${row.makeName} ${row.modelName} ${row.variantName}`)},
            keyName: 'makeName',
            wrap: true,
        },{
            name: 'Registration',
            selector: (row: {registration:string}) => row.registration
        },
        {
            name: <div>Transaction Message</div>,
            selector: (row: {transactionMessage:string}) => row.transactionMessage
        },
        {
            name: <div>Bank Transaction Id</div>,
            selector: (row: {BankTransactionID:string}) => row.BankTransactionID
        },
        {
            name: <div>Bank Transaction Time</div>,
            wrap: true,
            selector: (row: { bankTransactionTime: string }) => row.bankTransactionTime ? getLongFormatOfDateAndTime(getParsedDate(row.bankTransactionTime).toString()):''
        },
        {
            name: <div>Transaction Amount</div>,
            selector: ({paymentAmount}: { paymentAmount:string;}) => `${priceFormat(paymentAmount,'en-IN',{style: 'currency',currency: 'INR',maximumFractionDigits: 2})}`,
        }
        ,{
            name: 'Transaction At',
            sortable: true,
            wrap: true,
            selector: (row: { createdAt: string; }) => dateIsToday(row.createdAt) ? dateTimeDistance(row.createdAt)
                : getLongFormatOfDateAndTime(row.createdAt),
        },
    ]

    useEffect(() => {
        try {
            const getAllTransactionDataData = async () => {
                setIsLoading(true);
                const params = {
                    searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                };
                const response = await HTTP.get(`/api/admin/transaction/fetch-all/${currentPage}/${perPage}`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setTransactionData(data.rows)
                setTotalRows(data.count)
            }
            getAllTransactionDataData()
        }
        catch (e) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsLoading(false)
        }

    }, [currentPage, perPage, searchText, sortDetails]);




    //Handling the page changes
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };


    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    //Function to download CSV
    const getAllTransactionExcel = async () => {
        try {
            setIsLoading(true);
            const params = {
                searchText: searchText, sortDetails: JSON.stringify(sortDetails)
            };
            const response = await HTTP.get(`/api/admin/transaction/fetch-all/excel`, { params, responseType: 'blob', });
            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            downloadFileFromApi(response.data, filename)
            setIsLoading(false);
        }
        catch (error) {
            console.log("Error in enquery download api", error);
            setIsLoading(false);
        }

    }

    return (<>
        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">All Transactions</h2></div>
                <div className="p-2 mt-2 text-success" title="Download Excel Report">
                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={getAllTransactionExcel} style={{ cursor: 'pointer' }} />
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={column}
            data={transactionData}
            striped={true}
            //progressPending={isLoading}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
        <SimpleSpinner show={isLoading} />

    </>)
}

export default TransactionDataTable