import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faPinterest,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { encryptLocalStorage } from "../config/utils/storage";
import { faCommentDots, faPhone } from "@fortawesome/free-solid-svg-icons";
import {useState,useEffect} from "react";
import Chat360Widget from "../components/common/Chat360Widget";

const Footer = () => {
  const location = encryptLocalStorage.getItem("selectedLocation") || '';
  const currrentTime = new Date();
  const currentYear = currrentTime.getFullYear()
  const [isMobile,setIsMobile] = useState(false)

useEffect(() => {
  const handleOrientationChange = () =>setIsMobile(window.innerWidth <= 990);
  const mediaQuery = window.matchMedia("(max-width:990px)");//  Create a MediaQueryList object it checks the device is less or qual to the given value

  // Initial check
  setIsMobile(mediaQuery.matches);

  mediaQuery.addEventListener('change', handleOrientationChange);

  return () => {
    mediaQuery.removeEventListener('change', handleOrientationChange);
  };
}, []);


  return (
    <div className="App">
      <footer className=" footer">
        <Container>
          <Row className="pb-5">
            <Col lg={7} md={12} className="border-end border-end-sm-0 pe-5">
              <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/kuttukaran logo.svg`} width={"40%"} />
              <hr />
              <p className="text-dark py-2">
                {/* Popular is the most trusted way of buying and selling used
                cars. Choose from over 5000 fully inspected second hand car
                models. Select online and book a test drive at your home or at a
                Popular Car Hub near you. Get a no-questions asked 5-day money
                back guarantee and a free one year comprehensive service
                warranty with Assured Resale Value on every Popular Assured®
                car. */}
                Popular Maruti Suzuki True Value, a top-ranked Pre-Owned Car Dealer in the Country, manned by a well-trained team of 300 professionals, spread across Kerala and Chennai with 29 showrooms, is your choicest destination for buying or selling a Pre-owned Car at the most attractive and affordable price. Coming under Popular Vehicles & Services Ltd of the Kuttukaran Group, leaders in car retailing for over four decades, we are well-versed in both the product and the market.
                <br />
                At Popular True Value, our mission is to redefine your Pre-owned Car buying experience by offering unparalleled quality, transparency, and customer satisfaction. We distinguish ourselves with a 376-point digital evaluation process in sourcing, ensuring that every car meets our rigorous standards for quality and safety. Each car in our inventory is meticulously refurbished to deliver a driving experience as good as a new car. Enjoy added assurance with our warranty coverage of up to 1 year, three complimentary services, a 100% refund if you're not satisfied with your purchase, exchange facility, attractive financing solutions, diverse selection of high-quality vehicles and a convenient park-and-sell facility. With decades of expertise in the automotive industry, our team is 100% dedicated to provide you expert guidance and support every step of the way.
              </p>
              <hr />
              <h5 className="text-primary">Follow</h5>
              <div className="social-media-icons">
                <a
                  className="btn btn-link btn-floating btn-lg ps-0 social_icons "
                  href="https://www.facebook.com/PopularMarutiOfficial/"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  className="btn btn-link btn-floating btn-lg m-1 social_icons"
                  href="https://twitter.com/PopularMaruti"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  className="btn btn-link btn-floating btn-lg m-1 social_icons"
                  href="https://www.youtube.com/user/PopularMaruti"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a
                  className="btn btn-link btn-floating btn-lg m-1 social_icons"
                  href="https://www.linkedin.com/company/pvsl/"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a
                  className="btn btn-link btn-floating btn-lg m-1 social_icons"
                  href="https://in.pinterest.com/popularmaruti/"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <FontAwesomeIcon icon={faPinterest} />
                </a>
              </div>
            </Col>
            <Col lg={5} md={12} className="px-3 px-lg-5 pt-5 ">
              <Row>
                <h5 className="text-primary">QUICK LINKS</h5>
                <Col md={6} sm={6} xs={6} className="quick-links">
                  <ul className="list-unstyled ">
                    <li className="pb-3">
                      <Link to="/" className="text-dark pb-4"> Home </Link>
                    </li>
                    <li className="pb-3">
                      <Link to="/about-us" className="text-dark">
                        About us
                      </Link>
                    </li>
                    <li className="pb-3">
                      <a href="/enquiry" className="text-dark">
                        Enquiry
                      </a>
                    </li>
                    <li className="pb-3">
                    <Link to="https://www.popularmaruti.com/contact-us" className="text-dark"  target="blank"> Contact us</Link>
                   </li>
                    <li className="pb-3">
                      <Link to="/blogs" className="text-dark" > Blog</Link>
                    </li>
                    <li className="pb-3">
                      <Link to="/testimonials" className="text-dark" > Testimonials</Link>
                    </li>
                  </ul>
                </Col>
                <Col md={6} sm={6} xs={6} className="quick-links">
                  <ul className="list-unstyled ">
                    <li className="pb-3">
                    <Link to="https://www.popularmaruti.com/location-map" target="_blank" className="text-dark pb-4" > Location map</Link>
                    </li>
                    {/* <li className="pb-3">
                    <Link to="/brochure" className="text-dark" target="blank"> Brochure</Link>
                    </li> */}
                    <li className="pb-3">
                      <Link to="/terms-and-conditions" className="text-dark" target="blank"> Terms&nbsp;&&nbsp;Conditions</Link>
                    </li>
                    <li className="pb-3">
                    <Link to="https://www.popularmaruti.com/refund-policy" className="text-dark" target="blank"> Refund Policy</Link>
                    </li>
                    <li className="pb-3">
                      <Link to="/privacy-policy" className="text-dark" target="blank"> Privacy Policy</Link>
                    </li>
                    <li className="pb-3">
                      <Link to="/all-locations" className="text-dark"> View Locations </Link>
                    </li>
                  </ul>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={4} md={4} sm={4} xs={4} className=" mb-3">
                  <Link to={`/buy-used-car`} className="btn btn-primary">
                    Buy&nbsp;car
                  </Link>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className=" mb-3">
                  <Link to={`/sell-your-car`} className="btn btn-outline-primary">
                    Sell&nbsp;car
                  </Link>
                </Col>
                <Col lg={4} md={4} sm={4} xs={4} className="">
                  <Link to={`/finance`} className="btn btn-outline-primary">
                    Finance
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid className="bg-primary text-center">
          <Container>
            <Row>
              <p className="text-light align-self-center my-2">
                {" "}
                &copy; {currentYear} Popular Maruti. Kerala | Chennai | All rights
                reserved
              </p>
            </Row>
          </Container>
        </Container>
      </footer >
      <Chat360Widget botId="faf6f9f1-4c7f-4aa9-9017-c3a28267f0b0" />
      {/* <div className="chatbot">
        <FontAwesomeIcon className="text-primary fs-5" icon={faCommentDots} />
      </div> */}
{isMobile && (
  <div>
    <div className="mob-whatsapp-icon">
      <Link to="whatsapp://send?phone=+917306112802">
        <FontAwesomeIcon className="text-light fs-5" icon={faWhatsapp} />
      </Link>
    </div>
    <div className="mob-phone-icon">
      <Link to='tel:18001238090'>
        <FontAwesomeIcon className="text-light fs-5" icon={faPhone} />
      </Link>
    </div>
  </div>

)}
    </div >
  );
}

export default Footer;