import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        msg: string,
        type: string
    };
}
interface ITransmissionInfo {
    id: number;
    name: string;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    transmissionDetails: ITransmissionInfo;
    setAddEditCount:Function
}

const TransmissionAddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, transmissionDetails,setAddEditCount }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        reset(transmissionDetails);
    }, [transmissionDetails]);



    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm<Record<string, any>>();

    const onSubmit = async (formData: any) => {

        try{
            setIsLoading(true)
            const { data: { status, msg } }: responseType = await HTTP({
                method: transmissionDetails.id ? 'put' : 'post',
                url: `/api/admin/transmission/${transmissionDetails.id ? 'edit' : 'add'}`,
                data: formData,
            })
            setToast({ open: true, header: status?'Success':'Failed', message: (msg) ? msg : 'Something went wrong', variant: status?'success':'danger' })
            status&&setAddEditCount((prev: number) => prev + 1)
        }
        catch(err){
            setToast({
                open: true,
                header: "Failed",
                message:"Something went wrong",
                variant: "danger",
            });
        }
        finally{
            setIsLoading(false)
            handleClose()
            reset()
        }
        
    }

    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        {transmissionDetails.id === 0 && <Modal.Title>Add transmission type</Modal.Title>}
                        {transmissionDetails.id !== 0 && <Modal.Title>Update transmission type</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Name"
                                placeholder="Enter name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        {/* <div className='mb-1'>
                            <InputTextField name="keyName" label="Key name"
                                placeholder="Enter key name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div> */}
                        <InputTextField name="id" label=""
                            placeholder="" type="hidden" clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        {transmissionDetails.id !== 0 &&
                            <Button variant="primary" type="submit">
                                Update
                            </Button>}
                        {transmissionDetails.id == 0 &&
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>}

                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
            <SimpleSpinner show={isLoading} />
        </>
    );
}

export default TransmissionAddEditModal;

