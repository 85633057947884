import React, { ReactElement, ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger, { OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Placement } from 'react-bootstrap/esm/types';

interface CustomPopoverProps {
    header?: string;
    body: string;
    component: ReactElement,
    placement?: Placement,
    trigger?: OverlayTriggerType | OverlayTriggerType[]
}


const CustomPopover: React.FC<CustomPopoverProps> = ({header, body, component,placement='right', trigger=['hover','focus','click']}) => {

    const popoverlay = (
        <Popover id="popover-basic">
        <Popover.Header as="h3">{header}</Popover.Header>
          <Popover.Body> 
          <div dangerouslySetInnerHTML={{ __html: body }} />  
          </Popover.Body>
        </Popover>
      );
      
      return (
        <OverlayTrigger trigger={trigger} placement={placement} overlay={popoverlay}>
          {component}
        </OverlayTrigger>
      );
}


export default CustomPopover

 