import React, { useContext, useEffect, useRef, useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Row, Col, Image, Button, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from 'react-bootstrap';
import {
  faHeart,
  faLocationDot,
  faUserCircle,
  faSearch,
  faBars,
  faCar,
  faHistory,
  faCircleQuestion,
  faGift,
  faCircleDollarToSlot,
  faCircleUser,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import psvLogo from "../pvsLogo.svg";
import "../css/main.css";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import LogoutConfirmationModal from "../components/common/LogoutConfirmationModal";
import LocationModal from "../components/modules/Location/LocationModal";
import { encryptLocalStorage } from "../config/utils/storage";
import FavouritesModal from "../components/common/FavouritesModal";
import SearchBox from "../components/common/SearchBox";
import { ClientContext } from '../config/context/clientContext';
import { directToComparePage } from "../config/utils/helper";
import SimpleToast from "../components/common/Toast";
import { Overlay } from "react-bootstrap";
import CustomModal from "../components/common/CustomModal";
import DesktopHeader from "./Headers/DesktopHeader";
import MobileHeader from "./Headers/MobileHeader";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";



const Header: React.FC = () => {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isOpenLocationModal, setIsOpenLocationModal] = useState(false);
  const [isOpenFavouritesModal, setIsOpenFavouritesModal] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { locationContext, setLocationContext, toast, setToast, isMobile, setIsMobile }: any = useContext(ClientContext);
  const [isTablet,setIsTablet]=useState(false)

  const location = useLocation();
  const navigate = useNavigate()




  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (token) {
      setIsLoggedIn(true);
    }
    // on ininital page load check selected location in localstoarge and set in location context
    if (locationContext && locationContext.length == 0 && encryptLocalStorage.getItem('selectedLocation')?.length > 0) {
      setLocationContext(encryptLocalStorage.getItem('selectedLocation'))
    }
  }, [location]);

  useEffect(() => {
    // const handleResize = () => setIsMobile(window.innerWidth <= 565)
    // window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 565);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, []);
  useEffect(() => {
  const handleOrientationChange = () => setIsTablet(window.innerWidth <= 820);
  
  // Initial check
  setIsTablet(window.innerWidth <= 820);

  window.addEventListener('orientationchange', handleOrientationChange);

  return () => {
    window.removeEventListener('orientationchange', handleOrientationChange);
  };
}, []);

  // useEffect(() => {
  //   let prevScrollPos = window.scrollY;
  //   let ticking = false;

  //   const handleScroll = () => {
  //     if (!ticking) {
  //       window.requestAnimationFrame(() => {
  //         const currentScrollPos = window.scrollY as number; // Type assertion
  //         const isScrolledUp = currentScrollPos < prevScrollPos;
  //         setIsHeaderVisible(!isScrolledUp);
  //         prevScrollPos = currentScrollPos;
  //         ticking = false;
  //       });
  //       ticking = true;
  //     } else {
  //       const currentScrollPos = window.scrollY as number;
  //       const isScrolledDown = currentScrollPos > prevScrollPos;
  //       setIsHeaderVisible(isScrolledDown)
  //       ticking = false
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
 
  // }, [prevScrollPos]);

  useEffect(() => {
    // throttleScroll function ensures that the handleScroll function  is not executed too frequently. 
    const throttleScroll = () => {
      let ticking = false;
      const handleScroll = () => {
        if (!ticking) {
          const currentScrollPos = window.scrollY;
          const isScrolledUp = prevScrollPos > currentScrollPos;
          setIsHeaderVisible(isScrolledUp || currentScrollPos < 10);
          setPrevScrollPos(currentScrollPos);
          ticking = true;
          setTimeout(() => {
            ticking = false;
          }, 200); // Adjust throttle time as needed
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    };

    throttleScroll();

  }, [prevScrollPos]);

  const handleLogout = () => {
    setShowLogoutConfirmation(true);
  };

  const confirmLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    setShowLogoutConfirmation(false);
    // window.location.replace("/sign-in/");
    navigate("/sign-in/")
  };

  const openSignInPage = () => {
    navigate("/sign-in/")
  }

  const cancelLogout = () => {
    setShowLogoutConfirmation(false);
  };

  const openLocationModal = () => {
    setIsOpenLocationModal(true);
  };


  // const openFavouritesModal = () => {
  //   if (isLoggedIn) {
  //     setIsOpenFavouritesModal(true);
  //   }

  // }

  const locationName = encryptLocalStorage.getItem("selectedLocation") || ''
  let classList = "pb-2 header-visible"

  // if (isTablet) {
  //   classList = ` ${isHeaderVisible ? "" : ""} `
  // }

  const toggleSearch = () => {
    setShowSearchModal(!showSearchModal);
  }

  const directToCompare = () => {
    directToComparePage(navigate)
  }

  const SearchModal = () => {

    return (<CustomModal isOpen={showSearchModal} title="Search your car"
      component={<SearchBox showSearchModal={setShowSearchModal} />}
      closeModal={() => setShowSearchModal(!showSearchModal)} />)
  }
 


  return (
    <>
  {isTablet ? (
    <Collapse in={isHeaderVisible} timeout={300}>
      <header className="">
        <Navbar
          collapseOnSelect={true}
          expand="lg"
          bg="light"
          variant="light"
          fixed="top"
        >
          <MobileHeader
            isLoggedIn={isLoggedIn}
            openLocationModal={openLocationModal}
            directToCompare={directToCompare}
            handleLogout={handleLogout}
            openSignInPage={openSignInPage}
            showSearchModal={showSearchModal}
            toggleSearch={toggleSearch}
          />
        </Navbar>
        <LocationModal isOpen={isOpenLocationModal} closeModal={setIsOpenLocationModal} />
        <LogoutConfirmationModal
          show={showLogoutConfirmation}
          onClose={cancelLogout}
          onConfirm={confirmLogout}
        />
        <SimpleToast
          show={toast.open}
          header={toast.header}
          message={toast.message}
          variant={toast.variant}
          onClose={() => setToast({ ...toast, open: false })}
        />
        <SearchModal />
      </header>
    </Collapse>
  ) : (
    <header className="">
    
      <Navbar
        collapseOnSelect={true}
        expand="lg"
        bg="light"
        variant="light"
        fixed="top"
      >
          <div className="toll-free-container">
               <span className="toll-free-number mt-1 me-1"> <Link className="text-light" to='tel:18001238090'><FontAwesomeIcon className=" fs-5 me-2" icon={faPhone} />1800 123 8090</Link></span>
                <span className="toll-free-number mt-1"> <Link className="text-light" to="whatsapp://send?phone=+917306112802"><FontAwesomeIcon className="text-light fs-5 me-2" icon={faWhatsapp} />+91 73061 12802</Link></span>
                
            </div>
        <DesktopHeader
          isLoggedIn={isLoggedIn}
          openLocationModal={openLocationModal}
          directToCompare={directToCompare}
          handleLogout={handleLogout}
          openSignInPage={openSignInPage}
          isHeaderVisible={isHeaderVisible}
          isTablet={isMobile}
        />
      </Navbar>
      <LocationModal isOpen={isOpenLocationModal} closeModal={setIsOpenLocationModal} />
      <LogoutConfirmationModal
        show={showLogoutConfirmation}
        onClose={cancelLogout}
        onConfirm={confirmLogout}
      />
      <SimpleToast
        show={toast.open}
        header={toast.header}
        message={toast.message}
        variant={toast.variant}
        onClose={() => setToast({ ...toast, open: false })}
      />
      <SearchModal />
    </header>
  )}
</>
  );
};

export default Header;
