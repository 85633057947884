import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropDownField from '../../form/DropDownField';
import InputGroupField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import InputTextField from '../../form/InputTextField';
import { InspectionTypeOptions } from '../../../config/utils/helper';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        msg: string,
        type: string
    };
}
interface IInspectionDetails {
    id: number;
    name: string;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    inspectionDetails: IInspectionDetails;
    setAddEditCount: Function
}

const AddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, inspectionDetails, setAddEditCount }) => {
    
    useEffect(() => {
        reset(inspectionDetails);
    }, [inspectionDetails]);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset } = useForm();

    const onSubmit = async (formData: any) => {
        try {
            formData.status = 1;
            const { data: { status, msg } }: responseType = await HTTP({
                method: inspectionDetails.id ? 'put' : 'post',
                url: `/api/admin/inspections/${inspectionDetails.id ? 'edit' : 'add'}`,
                data: formData,
            })
            setToast({ open: true, header: status ? 'Success' : 'Failed', message: (msg) ? msg : 'Something went wrong', variant: status ? 'success' : 'danger' })
            status && setAddEditCount((prev: number) => prev + 1)
            status && handleClose()
            status && reset()
        }
        catch (err) {
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
        }
    }

    const closeModal = () => {
        handleClose()
        reset()
    }


    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{inspectionDetails.id ? "Update Inspection" : "Add Inspection"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputGroupField name="name" label="Inspection Name"
                                placeholder="Enter Inspection Name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <DropDownField name="type" label="Type" options={InspectionTypeOptions.slice(2)}
                                placeholder="Select type" clearErrors={clearErrors} rule={{ required: fieldRequired }}
                                control={control} />
                        </div>
                        <div className='mb-1'>
                            <InputGroupField name="id" label=""
                                placeholder="" type="hidden" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button type="submit">{inspectionDetails.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditModal;