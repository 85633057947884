import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faKey, faEnvelope, faPhone, faLock } from '@fortawesome/free-solid-svg-icons';
import HTTP from '../../../config/axios';
import SimpleSpinner from '../../common/Spinner';
import Alert from 'react-bootstrap/Alert';
import InputGroupField from "../../form/InputGroupField"
import CheckBox from '../../form/Checkbox';
import { fieldRequired, lengthBetween, emailPattern, checkRequired, checkPassword, phoneNumberPattern } from '../../../config/validations/rules';
import SimpleToast from "../../common/Toast";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { encryptLocalStorage } from '../../../config/utils/storage';
import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [phoneVerified, setPhoneVerified] = useState(false)
    interface responseType {
        success: boolean;
        error: any;
        data: {
            recaptchaStatus?: number;
            otpResult:string;
            err?: string;
            status: number,
            error: string,
            message: string,
            type: string
        };
    }
    const { register, handleSubmit, formState: { errors }, setError, clearErrors, control, watch, reset, getValues } = useForm();
    const password = useRef({});
    const navigate = useNavigate();
    // password.current = watch("password", "");
    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            data.siteToken = executeRecaptcha ? await executeRecaptcha('signIn') : '';

            const response: responseType = await HTTP.post(`/api/sign-up`, data)
            if (response.data.status === 1) {
                setSignupSuccess(true);
                reset();
                const previousLocation = encryptLocalStorage.getItem("previousLoggedoutLocation")
                encryptLocalStorage.removeItem("previousLoggedoutLocation")
                navigate(previousLocation ? previousLocation : "/");
            } else {
                setError(response.data.type ? response.data.type : "serverError", {
                    type: "manual", message: response.data.message,
                });
                setToast({ open: true, header: 'Failed', message: response.data.message || '', variant: 'danger' })
            }
            setIsLoading(false);
        }
        catch (error: any) {
            const { response: { status, data: { recaptchaStatus, err } } } = error
            if (status === 401 && recaptchaStatus === 0) {
                setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
            } else {
                setError("serverError", {
                    type: "manual", message: "Registration failed",
                });
            }

            setIsLoading(false);
        }
    };

    const sendOtp = async () => {
        try {
            setIsLoading(true);
            const phone = getValues('phone')
            if (!phoneNumberPattern.value.test(phone)) {
                setError( "phoneError", {
                    type: "manual", message: phoneNumberPattern.message,
                });
                setIsLoading(false);
                return; // Return early if validation fails
            }

            const response: responseType = await HTTP.post(`/api/signup-otp`, {phone:phone})
            if (response.data.status === 1) {
                setToast({ open: true, header: 'Success', message: response.data.message, variant: 'success' })
                setPhoneVerified(true);
            } else {
                setToast({ open: true, header: 'Failed', message: response.data.message, variant: 'danger' })
            }
            setIsLoading(false);
        }
        catch (error: any) {
            const { response: { status, data: { recaptchaStatus, err } } } = error
            if (status === 401 && recaptchaStatus === 0) {
                setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
            } else {
                 setToast({ open: true, header: 'Error', message: 'Mobile number is not registered', variant: 'danger' })
            }
            setIsLoading(false);
        }
    };

    return (<>
        <div>{!signupSuccess ? (
            <Form onSubmit={handleSubmit(onSubmit)}>
                {errors.serverError && (
                    <Form.Text className="text-danger">
                        {errors.serverError.message?.toString()}
                    </Form.Text>
                )}
                <div className='mb-3'>
                    <InputGroupField name="firstName" label="First name"
                        placeholder="Enter First name" addOn={faCircleUser} clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired }} />
                </div>
                <div className='mb-3'>
                    <InputGroupField name="lastName" label="Last name"
                        placeholder="Enter Last name" addOn={faCircleUser} clearErrors={clearErrors}
                        control={control} />
                </div>
                <div className='mb-3'>
                    <InputGroupField name="email" label="Email"
                        placeholder="Enter Email" addOn={faEnvelope} clearErrors={clearErrors}
                        control={control} rule={{ pattern: emailPattern }} />
                </div>
                <div className='mb-3'>
                    <InputGroupField name="phone" label="Mobile"
                        placeholder="Enter Mobile Number" addOn={faPhone} clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired, pattern: phoneNumberPattern }} />
                        {errors.phoneError && (
                            <Form.Text className="text-danger">
                                {errors.phoneError.message?.toString()}
                            </Form.Text>
                        )}
                </div>
                {/* <div className='mb-3'>
                    <InputGroupField name="password" label="Password" type="password"
                        placeholder="Enter Password" addOn={faKey} clearErrors={clearErrors} control={control}
                        rule={{ required: fieldRequired, validate: (value: string) => lengthBetween(value, 6, 12) }} />
                </div>
                <div className='mb-3'>
                    <InputGroupField name="passwordConfirm" label="Confirm Password" type="password"
                        placeholder="Re-enter Password" addOn={faKey} clearErrors={clearErrors} control={control}
                        rule={{ required: fieldRequired, validate: (value: string) => checkPassword(value, getValues('password')) }} />
                </div> */}
                {phoneVerified ? ( <>
                <div className="mb-3">
                        <InputGroupField name="otp" label="OTP"
                            placeholder="Enter OTP" addOn={faLock} clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired}} maxLength={6}/>
                </div>
                <CheckBox name="termsAndConditions"
                    label={<span>I have read and agree to the <br></br> <span className='text-primary'> <Link to="/terms-and-conditions" target="blank"> terms & conditions </Link></span> and <span className='text-primary'> <Link to="/privacy-policy" target="blank"> Privacy policy </Link></span></span>}
                    rule={{ required: fieldRequired, validate: (value: number) => checkRequired(value) }} control={control}
                    errText="Check to accept terms & conditions and Privacy policy" />
                <Button variant="primary" type="submit" className="mt-3">
                    Sign up
                </Button> </>) : (<Button variant="primary" className="mt-3" onClick={sendOtp}>
                    Verify Phone Number
                </Button>)}
                <SimpleSpinner show={isLoading} />
            </Form >
        ) : (
            <div className="div-success">
                <Alert key={"info"} variant={"info"}>
                    You have successfully signed up. Please sign in.
                </Alert>
            </div>
        )}
        </div>
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
    </>);
}

export default SignUp;