import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import FeaturesAddEditModal from '../../../components/admin/features/FeaturesAddEditModal'
import FeaturesDataTable from '../../../components/admin/features/FeaturesDataTable'

const ListFeatures: React.FC = () => {
    const [show, setShow] = useState(false);
    const [addEditCount, setAddEditCount] = useState(0)
    const [featuresDetails, setFeaturesDetails] = useState({ id: 0, name: '', keyName: '', type: '',fieldType:'',options:'',sort:'' });

    const handleClose = () => setShow(false);

    const editModal = (features: any) => {
        setFeaturesDetails(features)
        setShow(true)
    }

    const newFeatures = () => {
        setShow(true);
        setFeaturesDetails({ id: 0, name: '', keyName: '', type: '',fieldType:'',options:'',sort:'' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <FeaturesDataTable newFeatures={newFeatures} editFeatureDeatils={editModal} addEditCount={addEditCount} />
                            <FeaturesAddEditModal show={show} handleClose={handleClose} fetaureDetails={featuresDetails} setAddEditCount={setAddEditCount} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListFeatures;