import { Container,Row,Col,Form,Button } from "react-bootstrap";
import { useRef, useState,useEffect } from 'react';
import { useForm } from "react-hook-form";
import InputTextField from "../../../components/form/InputTextField";
import { fieldRequired,checkPassword,lengthBetween } from "../../../config/validations/rules";
import HTTP from "../../../config/axios";
import SimpleSpinner from "../../../components/common/Spinner";
import SimpleToast from "../../../components/common/Toast";
import { useSearchParams,useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

const ResetPassword = ()=>{
    const {  handleSubmit, formState: { errors }, setError, clearErrors, control, watch, reset, getValues } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const  {accessToken}  = useParams()
    const [linkExpired,setLinkExpired] = useState(false)
    const [passwordUpdated,setPasswordUpdated] = useState(false)
    const [countdown,setCountdown] = useState(5)

    

    useEffect( ()=>{
        const checkUrlvalid = async()=>{
            try{
                
                const response = await HTTP({
                    method: 'get',
                    url: '/api/authenticate',
                    data: {},
                    headers: {
                        'Authorization':`Bearer ${accessToken}`
                    }
                })
                if(response.status != 200){
                    setLinkExpired(true)
                }
            }
            catch(err){

            }
        }
        checkUrlvalid()
    },[])

    useEffect(()=>{
        if(countdown == 0){
            window.location.href ='/sign-in'  
        }
    },[countdown])

    const onSubmit = async (data: any) => {
        try{
            setIsLoading(true)
            const response = await HTTP({
                method: 'post',
                url: '/api/set-password',
                data: data,
                headers: {
                    'Authorization':`Bearer ${accessToken}`
                } 
            })
            if(response.data.status){
                setPasswordUpdated(true)
                setInterval(()=>{
                    setCountdown((prev: number) => prev - 1)
                },1000)
            }
            setToast({open: true, header: response.data.status?'Success':"Failed", message: response.data.status?'Your password is updated ':'Something went wrong', variant: response.data.status?'success':'danger' })
        }   
        catch(err){

        } 
        finally{
            setIsLoading(false)
        }
    }

    return(<>
        {!linkExpired && !passwordUpdated?
            <div className="App">
                <Container className="pt-5">
                    <Row className="my-5">
                        <h2 className="text-center text-primary">Reset Password</h2>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mb-3'>
                            <InputTextField name="password" label="Password" type="password"
                            placeholder="Enter Password"  clearErrors={clearErrors} control={control}
                            rule={{ required: fieldRequired, validate: (value: string) => lengthBetween(value, 6, 12) }} />
                        </div>
                        <div className='mb-3'>
                            <InputTextField name="passwordConfirm" label="Confirm Password" type="password"
                                placeholder="Re-enter Password"  clearErrors={clearErrors} control={control}
                                rule={{ required: fieldRequired, validate: (value: string) => checkPassword(value, getValues('password')) }} />
                        </div>
                            < div className="mb-3">
                                <Button type="submit">Reset Password</Button>
                            </div>
                        </Form>
                    </Row>
                </Container>
                
            </div>
            :
            !passwordUpdated && <div className="App">
                <Container className="pt-5">
                    <Row className="my-5">
                        <h2 className="text-center text-primary">Link Expired</h2>
                    </Row>
                </Container>
            </div>
        
        }

        {passwordUpdated && 
            <div className="App">
                <Container className="pt-5">
                    <Row className="my-5">
                        <h2 className="text-center text-primary">You 'll be redirected to login page in {countdown} or <Link to="/sign-in">click here </Link> </h2>
                    </Row>
                </Container>
            </div> }
        <SimpleSpinner show={isLoading} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
        
    </>)
}

export default ResetPassword