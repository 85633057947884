import { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { Col, Container, Row, Form, Image, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const EmiCalculator = () => {

    const [sliderLoanAmount, setSliderLoanAmount] = useState(500000);
    const [sliderLoanDuration, setSliderLoanDuration] = useState(3);
    const [sliderInterestRate, setSliderInterestRate] = useState(12.5);
    const [calculateEmi, setCalculateEmi] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalInterest, setTotalInterest] = useState(0)
    const [showLoanBreakupModal, setShowLoanBreakupModal] = useState(false);


    const handleLoanAmountChange = (event: any) => {
        setSliderLoanAmount(parseInt(event.target.value));
    };

    const handleLoanDurationChange = (event: any) => {
        setSliderLoanDuration(event.target.value);
    };

    const handleInterestChange = (event: any) => {
        setSliderInterestRate(event.target.value);
    };

    const handleCalculateEmi = () => {
        const amount = sliderLoanAmount;
        const tenure = sliderLoanDuration * 12;
        const interest = sliderInterestRate / 12 / 100;
        const emi = ((amount * interest * (1 + interest) ** tenure) / (((1 + interest) ** tenure) - 1));
        const total = emi * tenure;
        setTotalAmount(total);
        setTotalInterest(total - amount);
        return Math.round(emi);
    };

    useEffect(() => {
        const emi = handleCalculateEmi();
        setCalculateEmi(emi)
    }, []);

    useEffect(() => {
        const emi = handleCalculateEmi();
        setCalculateEmi(emi)
    }, [sliderLoanAmount, sliderLoanDuration, sliderInterestRate]);

    const openLoanBreakupModal = () => {
        setShowLoanBreakupModal(true);
    };

    const closeLoanBreakupModal = () => {
        setShowLoanBreakupModal(false);
    };


    return (
        <Container>
            <Row className="my-4">
                <div className="text-center">
                    <h3 className="text-primary is-testimonials head-bold">CALCULATE EMI</h3>
                </div>
                {/* Loan amount range selector */}
                <Col md={4} className="border-end">
                    <div className="d-flex mb-3">
                        <div className="me-auto text-dark">Loan Amount</div>
                        <div className="text-primary">₹ {sliderLoanAmount.toLocaleString('en-IN')}</div>
                    </div>
                    <Form.Range
                        min={50000}
                        step={100}
                        max={2000000}
                        bsPrefix={'range-color'}
                        value={sliderLoanAmount}
                        onChange={handleLoanAmountChange}
                        style={{ cursor: "pointer" }}
                    />
                    <div className="d-flex mb-3">
                        <div className="me-auto">₹ 50,000</div>
                        <div>₹ 20,00,000</div>
                    </div>
                    {/* Duration range selector */}
                    <div className="d-flex mb-3">
                        <div className="me-auto text-dark">Loan Duration</div>
                        <div className="text-primary">{sliderLoanDuration} year</div>
                    </div>
                    <Form.Range
                        min={1}
                        step={1}
                        max={7}
                        bsPrefix={'range-color'}
                        value={sliderLoanDuration}
                        onChange={handleLoanDurationChange}
                        style={{ cursor: "pointer" }}
                    />
                    <div className="d-flex mb-3">
                        <div className="me-auto">1 year</div>
                        <div>7 years</div>
                    </div>
                    {/* Interest range selector */}
                    <div className="d-flex mb-3">
                        <div className="me-auto text-dark">Interest rate</div>
                        <div className="text-primary">{sliderInterestRate} %</div>
                    </div>
                    <Form.Range
                        min={1}
                        max={30}
                        step={0.1}
                        value={sliderInterestRate}
                        onChange={handleInterestChange}
                        bsPrefix={'range-color'}
                        style={{ cursor: "pointer" }}
                    />
                    <div className="d-flex mb-3">
                        <div className="me-auto">1 %</div>
                        <div>30 %</div>
                    </div>

                </Col>
                <Col md={8}>
                    <h3 className="text-primary is-testimonials">₹ {calculateEmi.toLocaleString('en-IN')}/- <span className="fs-7">Emi for {sliderLoanDuration} years</span></h3>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                        <Col md={4}>
                            <p className="text-dark mb-0">Interest rate*</p>
                        </Col>
                        <Col md={8} style={{ cursor: "pointer" }}>
                            <div className="d-flex">
                                <div><Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/view_loan_breakup.svg`} className="me-2" width={'60%'} /></div>
                                <div>
                                    <p className="text-primary mb-0" onClick={openLoanBreakupModal} > View loan breakup</p>
                                </div>
                            </div>

                        </Col>
                    </div>
                    <p className="text-dark pt-2">{sliderInterestRate}%</p>
                    <p >*Rate of interest can vary subject to credit profile. <br /> Loan approval is at the sole
                        discretion of the finance partner.</p>
                </Col>
            </Row>
            <Modal
                show={showLoanBreakupModal}
                onHide={closeLoanBreakupModal}
                centered
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Loan Breakup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Monthly EMI: ₹{calculateEmi.toLocaleString('en-IN')}</p>
                    <p>Total amount: ₹ {Math.round(totalAmount).toLocaleString('en-IN')}</p>
                    <p>Total interest: ₹ {Math.round(totalInterest).toLocaleString('en-IN')}</p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default EmiCalculator;
