import Breadcrumb from 'react-bootstrap/Breadcrumb';

interface IBreadcrumb {
    title: string,
    path: string,
    active?: boolean,
    linkClass?: string,
}

const Breadcrumbs: React.FC<any> = ({ breadcrumbData }) => {
    return (
        <div className="container pt-2">
            <Breadcrumb>
                {breadcrumbData.map((breadcrumb: IBreadcrumb, index: number) => (
                    <Breadcrumb.Item href={breadcrumb.path} key={index} active={breadcrumb.active} className={breadcrumb.linkClass}>{breadcrumb.title}</Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </div>
    );
}

export default Breadcrumbs;