import React from "react";
import RoutesComponent from "./components/common/RoutesComponent"


const App: React.FC = () => {
  return (
    <div className="App">
      <RoutesComponent />
    </div>
  );
};

export default App;
