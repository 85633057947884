import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { faLock, faPhone } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import HTTP from "../../../config/axios";
import SimpleSpinner from "../../common/Spinner";
import { useNavigate } from "react-router-dom";
import InputGroupField from "../../form/InputGroupField"
import { fieldRequired, phoneNumberPattern } from '../../../config/validations/rules';
import SimpleToast from "../../common/Toast";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { encryptLocalStorage } from "../../../config/utils/storage";
import { Col, Row } from "react-bootstrap";
import InputTextField from "../../form/InputTextField";

const SignIn = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [mobileNumber, setMobileNumber] = useState(0)
    const [resendDisabled, setResendDisabled] = useState(false);
    const [resendTimer, setResendTimer] = useState(60);
    const [resendAttempts, setResendAttempts] = useState(0);
    interface responseType {
        success: number;
        message: string;
        data: {
            recaptchaStatus?: number;
            err?: string;
            otpResult:string;
            status: number,
            message: string,
            token: string,
            refreshToken:string,
            userInfo: {
                email: string,
                firstName: string,
                lastName: string,
                role: number,
                status: number
            }
        };
    }
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, resetField, watch } = useForm();

    useEffect(() => {
        const interval = setInterval(() => {
            if (resendTimer > 0) {
                setResendTimer(prevTimer => prevTimer - 1);
            } else {
                setResendDisabled(false);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [resendTimer]);
    
    // const onSubmit = async (data: any) => {
    //     try {
    //         setIsLoading(true);
    //         data.siteToken = executeRecaptcha ? await executeRecaptcha('signIn') : '';

    //         const response: responseType = await HTTP.post(`/api/sign-in`, data)
    //         if (response.data.status === 1) {
    //             console.log(response.data)
    //             encryptLocalStorage.setItem("jwtToken", response.data.token);
    //             encryptLocalStorage.setItem("userInfo", JSON.stringify(response.data.userInfo));
    //             encryptLocalStorage.setItem("refreshToken", response.data.refreshToken);
                
    //             navigate("/");
    //         } else {
    //             setError("password", {
    //                 type: "manual",
    //                 message: response.data.message,
    //             });
    //         }
    //         setIsLoading(false);
    //     }
    //     catch (error: any) {
    //         const { response: { status, data: { recaptchaStatus, err } } } = error
    //         if (status === 401 && recaptchaStatus === 0) {
    //             setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
    //         } else {
    //             setError("password", {
    //                 type: "manual", message: "Incorrect username or password",
    //             });
    //         }
    //         setIsLoading(false);
    //     }
    // };

    const sendOtp = async (data: any) => {
        try {
            setIsLoading(true);
            const response: responseType = await HTTP.post(`/api/send-otp`, data)
            
            if (response.data.status === 1) {
                if(!otpBox){
                    setMobileNumber(data.phone)
                    setOtpBox(true);
                }
                setResendDisabled(true);
                setResendTimer(60);
                resetField('otpNumber', {
                    keepDirty: false,
                    keepError: false,
                    keepTouched: false,
                    defaultValue: 0
                })
                setToast({ open: true, header: 'Success', message: response.data.message, variant: 'success' })
                
            } else {
                setToast({ open: true, header: 'Failed', message: response.data.message, variant: 'danger' })
            }
            setIsLoading(false);
        }
        catch (error: any) {
            const { response: { status, data: { recaptchaStatus, err } } } = error
            if (status === 401 && recaptchaStatus === 0) {
                setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
            } else {
                 setToast({ open: true, header: 'Error', message: 'Phone number is not registered', variant: 'danger' })
            }
            setIsLoading(false);
        }
    };

    const verifyOtp = async (data: any) => {
        try {
            setIsLoading(true);
            data.phone = mobileNumber;
            const response: responseType = await HTTP.post(`/api/verify-otp`, data)
            if (response.data.status === 1) {
                encryptLocalStorage.setItem("jwtToken", response.data.token);
                encryptLocalStorage.setItem("userInfo", JSON.stringify(response.data.userInfo));
                encryptLocalStorage.setItem("refreshToken", response.data.refreshToken);
                const previousLocation = encryptLocalStorage.getItem("previousLoggedoutLocation")
                encryptLocalStorage.removeItem("previousLoggedoutLocation")
                navigate(previousLocation ? previousLocation : "/");
                
            } else {
                setToast({ open: true, header: 'Failed', message: "Invalid OTP", variant: 'danger' })
            }
            setIsLoading(false);
        }
        catch (error: any) {
            const { response: { status, data: { recaptchaStatus, err } } } = error
            if (status === 401 && recaptchaStatus === 0) {
                setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
            } else {
                setError("password", {
                    type: "manual", message: "OTP is not valid",
                });
            }
            setIsLoading(false);
        }
    };

    const resendOtp = async () => {
        try {
            if (resendAttempts < 2) {
                let data = {
                    phone:mobileNumber
                }
                await sendOtp(data);
                setResendDisabled(true);
                setResendTimer(60);
                setResendAttempts(prevAttempts => prevAttempts + 1);
            } else {
                setToast({ open: true, header: 'Error', message: 'Maximum resend attempts reached', variant: 'danger' });
            }
        }
        catch (error: any) {
            const { response: { status, data: { recaptchaStatus, err } } } = error
            if (status === 401 && recaptchaStatus === 0) {
                setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
            } else {
                setError("password", {
                    type: "manual", message: "Something went wrong",
                });
            }
            setIsLoading(false);
        }
    };


    return (
        <div>
            { !otpBox ? (
            <Form onSubmit={handleSubmit(sendOtp)}>
                {/* <div className="mb-3">
                    <InputGroupField name="email" label="Email ID"
                        placeholder="Enter Email ID" addOn={faEnvelope} clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired, pattern: emailPattern }} />
                </div>
                <div className="mb-3">
                    <InputGroupField name="password" label="Password" type="password"
                        placeholder="Enter Password" addOn={faKey} clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired }} />
                </div> */}
                
                    <div className="mb-3">
                        <InputGroupField name="phone" label="Mobile Number"
                            placeholder="Enter Mobile Number" addOn={faPhone} clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired, pattern: phoneNumberPattern }} />
                    </div>
                    <div className="d-flex align-items-center">
                        <Button variant="primary" type="submit" >
                            Get OTP
                        </Button>
                    </div>
                </Form>
                ):(
                    <Form onSubmit={handleSubmit(verifyOtp)}>
                        <div className="mb-3">
                            <InputTextField name="otpNumber" label="OTP"
                                placeholder="Enter OTP"  clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired}} maxLength={6} min={0}/>
                        </div>
                        <Row >
                            <Col lg={5} md={5} sm={12}>
                                <Button variant="primary" type="submit" className="mb-3 w-100 d-flex justify-content-center ">
                                Verify&nbsp;OTP
                            </Button></Col>
                            <Col lg={7} md={7} sm={12} className="otp-resend-btn"> 
                            <Button variant="primary" className="btn btn-outline-primary w-100  d-flex justify-content-center" onClick={resendOtp} disabled={resendDisabled}>
                                Resend&nbsp;OTP&nbsp;{resendDisabled && `(${resendTimer}s)`}
                  </Button></Col>
                        </Row>
                    </Form>
                )}
                
            
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </div>
    );
}

export default SignIn;