import React, { useEffect, useState } from 'react';
import FuelDataTable from '../../../components/admin/fuel/FuelDataTable';
import FuelAddEditModal from '../../../components/admin/fuel/FuelAddEditModal';
import ReportCriteria from '../../../components/admin/report/ReportFilterCriteria';
import ReportTable from '../../../components/admin/report/ReportDataTable';
import { Button, Row } from 'react-bootstrap';

const Report: React.FC = () => {

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <Row className='mb-1'>
                                <div className="d-flex">
                                    <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Vehicle Report</h2></div>
                                </div>
                            </Row>
                            <ReportCriteria/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Report;