import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import HTTP from "../../../config/axios";
import MultiCarousel from "../../../components/common/MultiCarousal";
import { convertStringToSlug, getOriginalUrlFromThumb, handleRemoveFromCompare, ordinalSuffixForOwner } from "../../../config/utils/helper";
import { getFormatMMMYYYY } from "../../../config/utils/dateFunctions"
import DamageReport from "./DamageReport";
import SpecificationList from "./SpecificationList";
import FeaturesList from "./FeaturesList";
import { encryptLocalStorage } from "../../../config/utils/storage";

const CompareCard = ({ id, carPosition }: { id: string, carPosition: string }) => {
    const location = encryptLocalStorage.getItem("selectedLocation")
    const [vehicleDetails, setVehicleDetails] = useState<any>([]);
    const [mainImage, setMainImage] = useState("");
    const navigate = useNavigate();
    const fullCarName = `${vehicleDetails.year} ${vehicleDetails.make} ${vehicleDetails.model}`;
    const carSlugName = `${vehicleDetails.year} ${vehicleDetails.make} ${vehicleDetails.model} ${vehicleDetails.variant}`;
    const slugCarName = convertStringToSlug(carSlugName)
    const selectedLocation = encryptLocalStorage.getItem("selectedLocation");
    const [specificationList, setSpecificationList] = useState<any>([])
    const [featuresList, setFeaturesList] = useState<any>([])
    const [damageList, setDamageList] = useState<any>([])

    const handleAddCar = () => {
        navigate(`/buy-used-car`)
    };
    useEffect(() => {
        const getCarDetails = async () => {
            const response = await HTTP.get(`/api/vehicle/fetch-one/${id}`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setVehicleDetails(data)
                const carMainImage = getOriginalUrlFromThumb(`${process.env.REACT_APP_CLOUDFRONT_URL}${data.image}`)
                setMainImage(carMainImage)
            } else {
                setVehicleDetails([])
                setMainImage("")
            }
        }
        id && getCarDetails()
    }, []);

    useEffect(() => {
        const getCarSpecifications = async () => {
            const specificationResponse = await HTTP.get(`/api/vehicle/specifications/fetch-all/${id}`);
            const { data: { status, data, msg, error } } = specificationResponse;
            if (status == 1) {
                setSpecificationList(data)
            } else {
                setSpecificationList([])
            }
        }
        const getCarFeatures = async () => {
            const featuresResponse = await HTTP.get(`/api/vehicle/features/fetch-all/${id}`);
            const { data: { status, data, msg, error } } = featuresResponse;
            if (status == 1) {
                setFeaturesList(data)
            } else {
                setFeaturesList([])
            }
        }
        const getCarDamage = async () => {
            const damageResponse = await HTTP.get(`/api/vehicle/damage-report/fetch-all/${id}`);
            const { data: { status, data, msg, error } } = damageResponse;
            if (status == 1) {
                setDamageList(data)
            } else {
                setDamageList([])
            }
        }
        id && getCarDamage()
        id && getCarSpecifications()
        id && getCarFeatures()
    }, []);

    const handleCarouselImageClick = (imageUrl: string) => {
        const image = getOriginalUrlFromThumb(`${imageUrl}`)
        setMainImage(image);
    };

    const removeCar = () => {
        const compareCar: { id: string, name: string }[] = encryptLocalStorage.getItem("compareCar") || [];
        if (carPosition == 'first') {
            delete compareCar[0]
        } else {
            delete compareCar[1]
        }
        encryptLocalStorage.setItem("compareCar", compareCar);
        handleRemoveFromCompare(compareCar, navigate)
    }

    const changeCompare = () => {
        const compareString = encryptLocalStorage.getItem("compareCar");
        const compareCar: { id: string, name: string }[] = compareString ? compareString : [];

        if (carPosition == 'first') {
            delete compareCar[0]
        } else {
            delete compareCar[1]
        }
        encryptLocalStorage.setItem("compareCar", compareCar);
        navigate(`/buy-used-car`)
    }
    return (
        <Col md={6} className="border-end border-end-sm-0">
            {id == '' ? (
                <Row>
                    <Col lg={12} className="mb-3">
                        <Button variant="primary" onClick={handleAddCar}>
                            Add a car
                        </Button>
                    </Col>
                </Row>
            ) : (
                <>
                    <h3 className="text-dark fs-5">
                        {carSlugName}
                    </h3>
                    <Image src={`${mainImage}`} width={"100%"} className="mb-3" />
                    <MultiCarousel id={id} onCarouselImageClick={handleCarouselImageClick} />
                    <h2 className="text-primary pt-3">Overview</h2>
                    <h3 className="text-dark is-testimonials">Car overview</h3>
                    <Row>
                        <Col md="4">
                            <p>Make year</p>
                            <p className="text-dark is-description"> {getFormatMMMYYYY(vehicleDetails.yearMonthManufacture)}</p>
                        </Col>
                        <Col md="4">
                            <p>Transmission</p>
                            <p className="text-dark is-description">{vehicleDetails.transmission}</p>
                        </Col>
                        <Col md="4">
                            <p>Fuel type</p>
                            <p className="text-dark is-description">{vehicleDetails.fuel}</p>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md="6">
                            <p>KM Driven</p>
                            <p className="text-dark is-description">{vehicleDetails.km} Kms</p>
                        </Col>
                        <Col md="6">
                            <p>No.of owners</p>
                            <p className="text-dark is-description">{ordinalSuffixForOwner(vehicleDetails.numberOfOwners)} owner</p>
                        </Col>
                    </Row>
                    <DamageReport id={id} />

                    <SpecificationList id={id} />


                    <FeaturesList id={id} />

                    <hr />
                    <Row>
                        <Col lg={4} md={6} sm={4} className=" mb-3">
                            <Link to={`/buy-used-car/${slugCarName}/${id}`} className="btn btn-primary w-100">
                                View&nbsp;details
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={4} className="mb-3">
                            <Button variant="outline-primary w-100" type="submit" onClick={changeCompare}>
                                Change&nbsp;car
                            </Button>
                        </Col>
                        <Col lg={4} md={6} sm={4} className="mb-3">
                            <Button variant="outline-primary w-100" type="submit" onClick={removeCar}>
                                Remove&nbsp;car
                            </Button>
                        </Col>
                    </Row>
                </>
            )
            }
        </Col >
    );
};

export default CompareCard 