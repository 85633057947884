import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { equalCharCheck, equalTo, fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';
import InputTextField from '../../form/InputTextField';
import InputGroupField from '../../form/InputGroupField';
import DropDownField from '../../form/DropDownField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faList } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Nav } from 'react-bootstrap'

interface responseType {
    success: boolean;
    error: any;
    data: {
        colourId: number;
        status: number,
        message: string,
        alreadyLinked: boolean
    };
}
 

interface VariantAddColourProps {
    show: boolean;
    modelId: number;
    colours: any;
    setColours: any;
    appendColour: any;   // To  add newly saved colours to colour dropdown in Add/ Edit VAriant form
    handleClose: () => void; // Specify the function signature
}

const VariantAddColour: React.FC<VariantAddColourProps> = ({ show, modelId, handleClose, colours, setColours, appendColour }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [parentColours, setParentColours] = useState([])
    const [childColours, setChildColours] = useState([])
    const [isNewColour, setNewColour] = useState(false)
    const [colourName, setColourName] = useState('')
    const [savedColourId, setSavedColourId] = useState(0)
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch, setValue } = useForm();

 


    useEffect(() => {
        try {
            const getAllParentColours = async () => {
                const params = { type: 1 };
                const response = await HTTP.get(`/api/admin/colour/fetch-all`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setParentColours(data);
            }
            getAllParentColours()
        }
        catch (error) {
            console.log("Error in parent colour fetch api")
        } 
    }, [ ]);

    useEffect(() => {
        try {
            const getAllChildColours = async () => {
                const parentId = watch('parentId')
                const response = await HTTP.get(`/api/admin/colour/fetch-children/${parentId}`);
                const { data: { status, colours, msg, error, totalRecords } } = response;
                setChildColours(status ? colours : []);
            }
            watch('parentId') && getAllChildColours()
        }
        catch (error) {
            console.log("Error in child colour fetch api")
        } 
    }, [ watch('parentId') ]);

    useEffect(() => {
        const selectedChildColour = watch('childId')
        if(selectedChildColour){
            childColours.forEach((colour:any) =>{
                if(selectedChildColour == colour.id){
                    setValue('hexCode', colour.hexCode.replace("#",""))
                    setColourName(colour.name)
                }
            })
        }
    },[ watch('childId')])

    useEffect(() => {
        if(isNewColour){
            setValue('hexCode', '')
            setValue('childId', '')
        }
    },[ isNewColour])

    useEffect(() => {
        const newColour = colours[colours.length - 1]

        if(newColour?.value == savedColourId){
            appendColour(newColour)
            handleClose()   // clode modal after appending newly added colour as selected color in dropdown
            reset()
        }
    },[savedColourId, colours])



    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            submitData.modelId = modelId
            submitData.name = submitData.name || ''
            submitData.hexCode = '#'+submitData.hexCode
            
            const { data: { status, message, colourId, alreadyLinked } }: responseType = await HTTP({
                method:  'post',
                url: `/api/admin/colour/model-colour/add`,
                data: submitData
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })

            if(status){
                
                !alreadyLinked && setColours((prev:any) => {
                    return [ ...prev, { value: colourId, label: colourName || submitData.name}]
                })
                
                setSavedColourId(colourId)
            }

            setIsLoading(false)
           

        }
        catch (error) {
            setIsLoading(false)
            setToast({
                open: true,
                header: "Failed",
                message:"Something went wrong",
                variant: "danger",
            });
            console.log("Error on Colour add/ edit api", error)
        }
    }


    const closeModal = () => {
        handleClose()
        reset()
    }


    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Colour To Variant</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       
                        <div className='mb-1'>
                            <DropDownField name="parentId" label="Parent Colour" options={parentColours}
                                placeholder="Select parent colour" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        {
                            isNewColour ?
                                <div className='mb-1'>
                                    <Form.Label htmlFor={`childId-id`} className="text-dark fw-semibold fs-6">
                                        Add New Colour Name
                                    </Form.Label>
                                    <Row>
                                        <Col md={10}>
                                            <InputTextField name="name"
                                                placeholder="Enter colour name" clearErrors={clearErrors}
                                                control={control} rule={{ required: fieldRequired }} />
                                        </Col>
                                        <Col>
                                            <Button variant="success" size="sm" style={{ padding: '10px 14px' }} title="Select existing colour" >
                                                <FontAwesomeIcon icon={faList} onClick={() => setNewColour(!isNewColour)} />
                                            </Button>
                                        </Col>
                                    </Row>
                                </div> :
                                <div className='mb-1'>
                                    <Form.Label htmlFor={`childId-id`} className="text-dark fw-semibold fs-6">
                                        Select Colour<span className="text-danger fw-bold">*</span>
                                    </Form.Label>
                                    <Row>
                                        <Col md={10}><DropDownField name="childId" options={childColours}
                                            placeholder="Select colour" clearErrors={clearErrors}
                                            control={control} rule={{ required: fieldRequired }} /></Col>
                                        <Col md={2} className="text-end">
                                            <Button variant="success" size="sm" style={{ padding: '10px 14px' }} title="Add new colour" >
                                                <FontAwesomeIcon icon={faEdit} onClick={() => setNewColour(!isNewColour)} />
                                            </Button></Col>


                                    </Row>
                                </div>
                        }
                        <div className='mb-1'>
                            <InputGroupField name="hexCode" label="Hex Code" groupText="#"
                                placeholder="Enter Hex Code" clearErrors={clearErrors} disabled={!isNewColour}
                                control={control} rule={{ required: fieldRequired, validate: (value: string) => equalCharCheck(value, 6) }} />
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default VariantAddColour;