
const Benefits :React.FC<any> = ({cmsContent}) => {

  return (
    <div className="App">
      <div dangerouslySetInnerHTML={{ __html: cmsContent && cmsContent.content }}></div>
    </div>
  );
};

export default Benefits;
