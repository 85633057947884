import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropDownField from '../../form/DropDownField';
import InputGroupField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import InputTextField from '../../form/InputTextField';
import {FeatureTypeOptions, InputType} from '../../../config/utils/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CustomModal from '../../common/CustomModal';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        msg: string,
        type: string
    };
}
interface IFetaureDetails {
    id: number;
    name: string;
    keyName: string;
    fieldType:string;
    options:string;
    sort:string|number
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    fetaureDetails: IFetaureDetails;
    setAddEditCount: Function
}

const AddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, fetaureDetails, setAddEditCount }) => {

    useEffect(() => {
        reset(fetaureDetails);
    }, [fetaureDetails]);

    const [dropdownOptions, setDropdownOptions] = useState<any[]>([])
    const [dropdownOptionsHtml ,setDropdownOptionsHtml ] = useState<JSX.Element[]>([]);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch,setValue } = useForm();
    const [modalContent,setModalContent] = useState<JSX.Element>(<></>)
    const currentOptions:Array<[]> = (fetaureDetails.options !== null || fetaureDetails.options !==  undefined ) && fetaureDetails.options !== '' ?JSON.parse(fetaureDetails?.options):[]
    const [formDatas,setFormDatas] = useState<any>() 
    const [showModal, setShowModal] = useState(false); 
    

    const onSubmit = async (formData: any) => {
        try {

            //Adding DropDown option to form as an array of objects
            if(dropdownOptions.length > 0){
                const options:any = [...currentOptions] //Append old options to the new  Array
                dropdownOptions.forEach((element:any,index:number) => { //Appending the new options to the newArray
                    const option = {
                        id:index+1+currentOptions.length ,
                        keyName:formData[element.label].toLowerCase().replace(/ /g, '_'), //Converting space into '_'
                        name:formData[element.label]
                    }
                    options.push(option)
                });
                formData['options'] = JSON.stringify(options)
            }

            formData.status = 1;
            if (formData.id != 0) {
                addEditFeature(formData)
                setDropdownOptions([])
                setDropdownOptionsHtml([])
            } else {
                setFormDatas(formData)
                setShowModal(true);
                setModalContent(<p className='text-primary'>Please note: Option Type cannot be modified later once saved.</p>)
            }
        }
        catch (err) {
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
        }
        finally {
            
        }

    }

    //Create a dynamic option
    useEffect(()=> {
        const inputType = watch('fieldType')
        if(inputType === 'dropdown' && fetaureDetails.id == 0){
            addOption()  
        } else{
            setDropdownOptionsHtml([])   
            setDropdownOptions([])
        }
    },[watch('fieldType')])


    //Create a new option for dropdown
    const addOption = () => {
        let currentDiv: JSX.Element;
        let currentIndex: number = currentOptions && currentOptions.length > 0 ? currentOptions.length : 0; //To get the current length of options if there is already options are saved

        const hasEmptyInput = dropdownOptionsHtml.some(option => {
            const inputElement = document.getElementById(option.props.id)?.querySelector('input');
            return inputElement ? inputElement.value.trim() === '' : false;
        });


        if (hasEmptyInput) {
            setToast({
                open: true, header: 'failed', message: 'Please fill in all existing options before adding a new one.',
                variant: 'danger'
            })
            return
        }

        if (dropdownOptionsHtml.length > 0) {
            currentDiv = dropdownOptionsHtml[dropdownOptionsHtml.length - 1]
            currentIndex = parseInt(currentDiv.props.id.split('-')[1]); // Splits the string by '-' and takes the second part
        }

        const newOption = (

            <div className='d-flex' key={`option-${currentIndex + 1}`} id={`option-${currentIndex + 1}`}>
                <InputTextField
                    key={dropdownOptionsHtml.length + 1} // Ensure unique key for each element
                    name={`option-${currentIndex + 1}`}
                    label={`Option ${currentIndex + 1}`}
                    placeholder=""
                    classList='flex-grow-1 options'
                    clearErrors={clearErrors}
                    rule={{ required: fieldRequired }}
                    control={control}
                />
                {(dropdownOptionsHtml.length > 0 || (currentOptions && currentOptions.length > 0)) &&
                    <FontAwesomeIcon className='mt-5 ms-2 text-danger' icon={faClose} onClick={() => removeOption(currentIndex + 1)} />
                }
            </div>

        );

        setDropdownOptionsHtml(prevOptions => [...prevOptions, newOption]);
        const option = {label:`option-${currentIndex + 1}`}
        setDropdownOptions(prev => [...prev, option]);

    };

    //Function to remove option
    const removeOption = (id: number) => {
        setDropdownOptionsHtml(prevOptions => prevOptions.filter((item, i) => item.props.id   !== `option-${id}`));
        setDropdownOptions(prevOptions => prevOptions.filter((item, i) => item.label !== `option-${id}`));
    };

    const closeModal = () => {
        handleClose()
        reset()
    }

    const closeCustomModal = () => {
        setShowModal(false);
    }

    const handleConfirmClick = () =>{
        try{
            addEditFeature(formDatas)    
        } catch(err){
            setToast({ open: true, header: 'failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally{
            closeCustomModal()
        }
        
    }

    //Function to submit the form
    const addEditFeature = async(formData:any)=>{
        try{
            const { data: { status, msg } }: responseType = await HTTP({
                method: fetaureDetails.id ? 'put' : 'post',
                url: `/api/admin/features/${fetaureDetails.id ? 'edit' : 'add'}`,
                data: formData,
            })
            setToast({ open: true, header: status ? 'Success' : 'Failed', message: (msg) ? msg : 'Something went wrong', variant: status ? 'success' : 'danger' })
            status && setAddEditCount((prev: number) => prev + 1)
            status && handleClose()
            status && reset({ sort: '' })
        } catch (err) {
            throw err
        }
        finally {
            setFormDatas([])
        }
    }


    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{fetaureDetails.id ? "Update Feature" : "Add Feature"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputGroupField name="name" label="Feature Name"
                                placeholder="Enter Feature Name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <DropDownField name="type" label="Type" options={FeatureTypeOptions}
                                placeholder="Select type" clearErrors={clearErrors} rule={{ required: fieldRequired }}
                                control={control} />
                        </div>
                        
                        <div className='mb-1'>
                            <DropDownField name="fieldType" label="Option Type" options={InputType}
                                placeholder="Select an option type" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }}  isValueKeyname={true} isDisabled = {fetaureDetails.id>0}
                                />
                        </div>

                        <div className='text-end mt-3'>
                            {(dropdownOptionsHtml.length > 0 || (currentOptions && currentOptions.length > 0))  && 
                            <Button variant='primary' className="text-end" onClick={addOption}>Add Option</Button>}
                        </div>

                        {/* Current Option */}
                        {currentOptions && currentOptions.length > 0 && currentOptions.map((option:any, index:number) => (
                            <>
                                <div key={index+1}>
                                    <InputTextField name={`option-${index+1}`} label={`Option ${index+1}`}
                                    placeholder="" clearErrors={clearErrors} disabled = {true} 
                                    control={control} />
                                </div>
                                {setValue(`option-${index+1}`,option.name )}
                            </>
                        ))}

                        {/* Newly added Option */}
                        {dropdownOptionsHtml.length > 0 && dropdownOptionsHtml.map((option, index) => (
                            <div key={index}>{option}</div>
                        ))}
                        <div className='mb-1'>
                            <InputTextField name="sort" label="Sort Order" type="number" min={1}
                                placeholder="Enter sort order" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <InputGroupField name="id" label=""
                                placeholder="" type="hidden" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button type="submit">{fetaureDetails.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
            
            <CustomModal isOpen={showModal}  title={`Warning`}
                component={modalContent} closeModal={() => setShowModal(false)}
                buttonList={<>
                    <Button variant="secondary" onClick={closeCustomModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleConfirmClick}>
                        Confirm
                    </Button>
                </>} />

        </>
    );
}

export default AddEditModal;