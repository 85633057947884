import React, { useState, useEffect } from 'react';
import HTTP from '../../../config/axios';
import InputTextAreaField from '../../form/InputTextAreaField';
import InputTextField from '../../form/InputTextField';
import DropDownField from '../../form/DropDownField';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FeatureTypeOptions } from '../../../config/utils/helper';
import SimpleSpinner from '../../common/Spinner';
import SimpleToast from '../../common/Toast';
import ResetPassword from '../../../pages/module/authentication/resetPassword';
import { validateOnlySpaces } from '../../../config/validations/rules';


interface IVariantInfo {
  id: number;
  name: string;
  // keyName: string;
  modelId: number | null;
  makeId: number | null;
  modelName: string;
  [key: string]: number | string | null;
}

interface IfeatureDetail {
  [key: string]: number | string | null;
}
interface IvariantFeatures {
  setActiveTab:Function
  vehicleVariantId: Number
  variantDetails: IVariantInfo;
  featureDetails: IfeatureDetail;
  setFeatureForm:Function;
  setSpecificationForm:Function  
  assignedVehicleIds:number[]                             
}


const VariantFeaturesModal: React.FC<IvariantFeatures> = ({ setActiveTab, vehicleVariantId, variantDetails, featureDetails, setFeatureForm, setSpecificationForm, assignedVehicleIds })=> {
  const [featuresArray, setFeaturesArray] = useState([]);
  const { handleSubmit, control, clearErrors, reset } = useForm();
  const [isLoading, setIsLoading] = useState(true)
  const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
  const [featureDetail, setFeatureDetail] = useState({})

  useEffect(() => {
    reset(featureDetails)

  }, [featureDetails])



  useEffect(() => {

    const getFeaturesData = async () => {

      try {
        setFeatureForm(true)
        setSpecificationForm(true)
        const response = await HTTP.get(`/api/admin/features/all`);
        const { data: { status, data, msg, error } } = response
        if (status == 1) {

          setFeaturesArray(data)
        }
      }
      catch (error) {
        console.log('error in feature API ')
      }
      finally {
        setIsLoading(false); 
      }

    }

    
    getFeaturesData()

  }, []);







  const onSubmit = async (formData: any) => {

    try {

      const features: any = []
      const removedfeatures: any = []
      featuresArray.map((item: any) => {
        formData[item.keyName] && features.push({ id: item.id, value: formData[item.keyName],Keyname: item.keyName })
      })
      formData['variantFeatures'] = features

      Object.keys(featuresArray).forEach((index: any) => {
        const features = featuresArray[index];
        if (!formData[features['keyName']]) {
          removedfeatures.push(features['keyName']);
        }
      });

      formData['removedfeatures'] = removedfeatures

      if (variantDetails.id) {
        formData['variantId'] = variantDetails.id
        onEdit(formData)
      }
      else if (vehicleVariantId) {
        formData['variantId'] = vehicleVariantId
        onAdd(formData)
      }
    }
    catch (err) {
      console.log('Error', err)
    }
  };
  

  const onAdd = async(data:any) =>{
    try{
      const response = await HTTP({
        method: 'post',
        url: `/api/admin/variant/addFeatures`,
        data: data,
      });
      console.log("resposne", response)
      const { status, msg } = response.data;

      if (status == 1) {
        setActiveTab('basic')
        getVariantFeatureDetails(vehicleVariantId)
      }

      setToast({
        open: true,
        header: status ? 'Success' : 'Failed',
        message: msg ? 'Features Added successfully' : 'Something went wrong',
        variant: status ? 'success' : 'danger',
      });
      

    }
    catch(err){
      console.log('Error', err)
    }
  }
  const onEdit = async (data: any) => {
    try {
      if (assignedVehicleIds.length > 0) {
        data.vehicleIds = assignedVehicleIds;
      }
      const response = await HTTP({
        method: 'post',
        url: `/api/admin/variant/addFeatures`,
        data: data,
      });

      const { status, msg } = response.data;
      if(status == 1){
        setActiveTab('basic')
        getVariantFeatureDetails(variantDetails.id)
      }

      setToast({
        open: true,
        header: status ? 'Success' : 'Failed',
        message: msg ? 'Features Updated successfully' : 'Something went wrong',
        variant: status ? 'success' : 'danger',
      });

    }
    catch (err) {
      console.log('Error', err)
    }
  }

  useEffect(() => {
    if (Object.keys(featureDetail).length == 0) {
      getVariantFeatureDetails(vehicleVariantId)
    }
    reset(featureDetail)

  }, [featureDetail])

  const getVariantFeatureDetails = async (id: any) => {

    const response = await HTTP.get(`/api/admin/variant/getVariantFeatureDetails/${id}`);
    const { data: { status, data, msg, error } } = response
    if (Object.keys(data.transformedDetails).length === 0) {
      setFeatureDetail(id)
    } else {
      setFeatureDetail({
        ...data.transformedDetails
      });
    }

  }


  const renderInputField = (data: any) => {
    switch (data.fieldType) {
      case 'number':
        return (
          <InputTextField
            label={data.name}
            name={data.keyName}
            type="number"
            control={control}
            min={0}
            clearErrors={clearErrors}
            placeholder={`Enter ${data.name}`}
            unit={data.unit}
          />
        );
      case 'text':
        return (
          <InputTextField
            label={data.label}
            name={data.keyName}
            type="text"
            control={control}
            clearErrors={clearErrors}
            placeholder={`Enter ${data.label}`}
            unit={data.unit}
            rule={{ validate: validateOnlySpaces }}
          />
        );
      case 'decimal':
        return (
          <InputTextField
            label={data.label}
            name={data.keyName}
            type="number"
            control={control}
            step="any"
            min={0}
            placeholder={`Enter ${data.label}`}
            unit={data.unit}
          />
        );
      case 'dropdown':
        const options = JSON.parse(data.options || '[]');
        return (
          <DropDownField
            label={data.label}
            name={data.keyName}
            options={options}
            control={control}
            placeholder={`Select ${data.label}`}
          />
        );
      default:
        return (
          <InputTextField
            label={data.label}
            name={data.keyName}
            type="number"
            control={control}
            min={0}
            clearErrors={clearErrors}
            placeholder={`Enter ${data.label}`}
            unit={data.unit}
          />
        );
    }
  };

  const groupedFeatures = FeatureTypeOptions.reduce((acc:any, typeOption:any) => {
    acc[typeOption.id] = featuresArray.filter((spec:any) => spec.type === typeOption.id);
    return acc;
  }, {});




  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>

        {/* <Row>
          <Col md={10}></Col>
          <Col md={2}>
            {!isLoading ? <Button className="mt-3" variant="primary" type="submit">Save Changes</Button> : <></>}
          </Col>
        </Row> */}
        {Object.keys(groupedFeatures).map((typeId:any) => {
          const features = groupedFeatures[typeId];
          if (features.length === 0) return null;

          const typeName = FeatureTypeOptions.find((option:any) => option.id === parseInt(typeId))?.name;

          return (
            <div key={typeId}>
              <h4 className='text-primary fw-semibold fs-18 mt-3'>{typeName}</h4>
              <Row>
                {features.map((feature:any) => (
                  <Col md={4} key={feature.id}>
                    {renderInputField(feature)}
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
        <Row>
          <Col md={10}></Col>
          <Col md={2}>
            {!isLoading ? <Button className="mt-3" variant="primary" type="submit">Save Changes</Button>:<></>}  
          </Col>
        </Row>
      </Form>
      <SimpleSpinner show={isLoading} />
      <SimpleToast
        show={toast.open}
        header={toast.header}
        message={toast.message}
        variant={toast.variant}
        onClose={() => setToast({ ...toast, open: false })}
      />
    </div>
  )
}

export default VariantFeaturesModal