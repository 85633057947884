import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import CarCard from "../../common/CarCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"
import HTTP from "../../../config/axios";
import { encryptLocalStorage } from "../../../config/utils/storage";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";

const SimilarCars: React.FC<any> = ({ budget, vehicleId, bodyType }) => {
  interface ISimilar {
    id: number;
    km: number;
    image: string;
    fuel: string;
    transmission: string;
    price: any;
    make: string;
    model: string
    variant: string;
    year: number;
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 1400, min: 767 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1100, min: 767 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tabletSmall: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const [similarCars, setSimilarCars] = useState<ISimilar[]>([]);
  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate();
  let locationName = encryptLocalStorage.getItem("selectedLocation");
  const carouselRef = React.useRef<any>(null);

  useEffect(() => {
    const getSimilarData = async () => {
      const percent = (budget / 100) * 20;
      const startBudget = budget - percent;
      const endBudget = budget + percent;
      const response = await HTTP.post(`/api/vehicle/similar`,
        {
          startPrice: startBudget, endPrice: endBudget, vehicleId: vehicleId, selectedLocation: locationName, bodyType: bodyType
        })
      const { data: { status, data, totalCount, msg, error } } = response
      if (status == 1) {
        setSimilarCars(data)
      } else {
        setSimilarCars([])
      }
    }
    getSimilarData()
  }, [vehicleId]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 565)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 565);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleNextClick = () => {

    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const CustomLeftArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
      <FontAwesomeIcon icon={faChevronLeft} size={'2x'} />
    </button>
  );

  const CustomRightArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
      <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
    </button>
  );

  const handleSearchCar = () => {
    navigate(`/buy-used-car`)
  }

  return (
    <>
      {similarCars.length > 0 &&
        <Container fluid>
          <div className="text-center">
            <h2 className="text-primary head-bold">
              SIMILAR CARS
             </h2>
          </div>

          {!isMobile ? (
            <Row>
              <Col md={2} className=" d-none flex-row-reverse align-items-center  d-md-flex ">
                <CustomLeftArrow />
              </Col>
              <Col md={8}>
                <Carousel
                  className="home-type-carousel"
                  swipeable={true}
                  draggable={true}
                  responsive={responsive}
                  ref={carouselRef}
                  ssr={true} // render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={4000}
                  keyBoardControl={true}
                  arrows={false}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "tabletSmall"]}
                  itemClass="carousel-item-padding-40-px"
                >
                  {similarCars.map((data) => (
                    <div key={data.id} className="px-3" >
                      <CarCard detail={data} isCarousel={true} />
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col md={2} className="d-none  align-items-center  d-md-flex">
                <CustomRightArrow />
              </Col>
            </Row>) : (
            <React.Fragment>
              {similarCars.slice(0, 3).map((data) => (
                <div key={data.id} className="pb-3">
                  <CarCard detail={data} />
                </div>
              ))}
              <div className="text-center">
                <Button variant="link" onClick={() => handleSearchCar()}>
                  View More
                </Button>
              </div>
            </React.Fragment>
          )}
        </Container>}
    </>
  );
};

export default SimilarCars;
