import React, { useEffect, useState } from "react";
import InputTextField from "../../form/InputTextField";
import { useForm } from "react-hook-form";
import { fieldRequired } from "../../../config/validations/rules";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import HTTP from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { faAngleLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import InputGroupField from "../../form/InputGroupField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SidebarModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const SpecificationsModal: React.FC<SidebarModalProps> = ({ isOpen, onClose, id }) => {

  const { formState: { errors }, clearErrors, control, watch } = useForm();
  const navigate = useNavigate();

  const [specificationList, setSpecificationList] = useState<any>([]);
  const [fullCarName, setFullCarName] = useState('');
  const [isMobile, setIsMobile] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState<string>('first');


  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (!token) {
      navigate('/sign-in')
    }
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 575);
  }, []);

  useEffect(() => {
    const getCarSpecifications = async () => {
      const specificationResponse = await HTTP.get(
        `/api/vehicle/specifications/fetch-all/${id}`
      );
      const {
        data: { status, data, msg, error },
      } = specificationResponse;

      if (status == 1) {
        setSpecificationList(data);
      } else {
        setSpecificationList([]);
      }
    };
    getCarSpecifications();
  }, []);

  useEffect(() => {
    const getCarDetails = async () => {
        const response = await HTTP.get(`/api/vehicle/fetch-one/${id}`);
        const { data: { status, data, msg, error } } = response
        if (status == 1) {
          setFullCarName(`${data.year || ''} ${data.make} ${data.model} ${data.variant}`)
        }

    }
    getCarDetails()
  }, []);

  // const renderSpecifications = (typeRange: number[]) => {
  //   const filteredSpecifications = specificationList
  //   .filter((spec:any) => typeRange.includes(spec.type))
  //   if (filteredSpecifications.length === 0) {
  //     return <div className="w-100 text-start border-bottom px-3">
  //               <p className="text-start text-dark is-description mt-2 mb-0">Not available</p>
  //            </div>;
  //   }
  //   return filteredSpecifications.map((spec:any, index:number) => (
  //         <div  key={index} className="w-100 text-start border-bottom px-3">
  //           <p className="text-start text-dark is-description mt-2 mb-0">{spec.name}</p>
  //           <p className="text-start is-description">{spec.value} {spec.unit}</p>
  //         </div>
  //     ));
  // };

  const renderSpecifications = (typeRange: number[]) => {
    const filteredSpecifications = specificationList
      .filter((spec: any) => {
        return spec.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          spec.value.toLowerCase().includes(searchQuery.toLowerCase());
      })
      .filter((spec:any) => typeRange.includes(spec.type));

    if (filteredSpecifications.length === 0) {
      return (
        <div className="w-100 text-start border-bottom px-3">
          <p className="text-start text-dark is-description mt-2 mb-0">Not available</p>
        </div>
      );
    }

    return filteredSpecifications.map((spec: any, index: number) => {
      // Initialize displayed value with the original value
      let displayedValue = spec.value;

      // Check if the keyName is "mileage" and round the value if needed
      if (spec.keyName === 'mileage') {
        displayedValue = Math.round(spec.value * 10) / 10; // Round to one decimal place
      }

      return (
        <div key={index} className="w-100 text-start border-bottom px-3">
          <p className="text-start text-dark is-description mt-2 mb-0">{spec.name}</p>
          <p className="text-start is-description">{displayedValue} {spec.unit}</p>
        </div>
      );
    });
  };

  const searchedData = watch('searchSpec') || '';

  useEffect(() => {
    const renderFilteredSpecifications = () => {

      setSearchQuery(searchedData);
      handleSearch(searchedData)
    };
    renderFilteredSpecifications();
  }, [searchedData]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);

    let newActiveTabKey = 'first'; // Default tab
    if (specificationList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [1, 9].includes(spec.type))) {
      newActiveTabKey = 'first';
    } else if (specificationList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [2, 3].includes(spec.type))) {
      newActiveTabKey = 'second';
    } else if (specificationList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [7, 8].includes(spec.type))) {
      newActiveTabKey = 'third';
    } else if (specificationList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [4, 5, 6].includes(spec.type))) {
      newActiveTabKey = 'fourth';
    }

    setActiveTab(newActiveTabKey);

  };

  const handleTabSelect = (selectedTab: any) => {
    setActiveTab(selectedTab);
  };


  return (
    <Modal
      show={isOpen}
      size={"lg"}
      onHide={() => onClose}
      fullscreen="fullscreen"
      dialogClassName={isMobile ? `modal-90w` : `modal-90w h-100`}
      aria-labelledby="example-custom-modal-styling-title"
      className="featuer-modal "
    >
      
      <Modal.Body className={isMobile ? `bg-white p-0` :`bg-white`}>
        <div className="mt-3"> 
       <div className="d-flex align-items-center ms-3">
         <div>
          <FontAwesomeIcon icon={faAngleLeft} className={isMobile ? `fs-3 mb-2` : `fs-4 mb-2`} onClick={onClose} style={{ cursor: "pointer" }}/>
         
        </div>
        <div className="ms-3"> <h2 className={isMobile ? `ps-2 fs-5` : `fs-4 mb-1`}>SPECIFICATIONS</h2>
          <h3 className={isMobile ? `fs-6 ps-2` :`fs-6`} >{fullCarName}</h3>
          </div>
       </div>
          
          <InputGroupField name="searchSpec" label="" defaultValue={""}
            placeholder="Search any specification" addOn={faSearch} clearErrors={clearErrors}
            control={control} classList={isMobile ? `form-control-sm m-2` : `form-control-sm`} />
          <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={handleTabSelect}>
            <div className={isMobile ? `mt-3 d-flex` : `mt-3 row`}>
              <Col lg={3} md={4} sm={5} xs={5} className="pe-0 ps-0 border-end border-bottom border-top border-start-0">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first" className="border-bottom"> <div className=" text-start d-flex align-items-center ">
                      <p className="text-start my-2">Dimensions & capacity</p>
                    </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="second" className="border-bottom">
                      <div className=" text-start d-flex align-items-center ">
                        <p className="text-start my-2">Engine & transmission</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third" className="border-bottom">
                      <div className=" text-start d-flex align-items-center ">
                        <p className="text-start my-2">Fuel & performance</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth" className="border-bottom">
                      <div className=" text-start d-flex align-items-center ">
                        <p className="text-start my-2">Suspension, steering & brakes</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9} md={8} sm={7} xs={7} className="ps-0 pe-0 border-end border-bottom border-top-0 " >
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="border-end border-bottom border-top scrol-cnt ">
                      <div className="w-100 text-start ">
                        <p className="text-start head-text fw-bold faq-answer px-3 pt-3">
                          DIMENSIONS & CAPACITY
                        </p>
                        {renderSpecifications([1, 9])}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="border-end border-bottom border-top scrol-cnt ">
                      <div className="w-100 text-start ">
                        <p className="text-start head-text fw-bold faq-answer px-3 pt-3">
                          ENGINE & TRANSMISSION
                        </p>
                        {renderSpecifications([2, 3])}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="border-end border-bottom border-top scrol-cnt ">
                      <div className="w-100 text-start ">
                        <p className="text-start head-text fw-bold faq-answer px-3 pt-3">
                          FUEL & PERFORMANCE
                        </p>
                        {renderSpecifications([7, 8])}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <div className="border-end border-bottom border-top scrol-cnt ">
                      <div className="w-100 text-start ">
                        <p className="text-start head-text fw-bold faq-answer px-3 pt-3">
                          SUSPENSION, STEERING & BRAKES
                        </p>
                        {renderSpecifications([4, 5, 6])}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </div>
          </Tab.Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SpecificationsModal;
