import { ClientContextProvider } from '../config/context/clientContext';
import Header from './Header';
import { Outlet } from 'react-router-dom';

const PublicLayout = () => {
    return (
        <>
            <ClientContextProvider>
                <Header />
                <Outlet />
            </ClientContextProvider>
        </>
    );
};

export default PublicLayout;