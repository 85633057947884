import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from 'react';
import DropDownField from "../../form/DropDownField";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { ClientContext } from "../../../config/context/clientContext";
import { useNavigate } from "react-router";
import { Button } from "react-bootstrap";
import MultiSelectDropdown from "../../form/MultiDropdown";
import { setSelectedLocation } from "../../../config/utils/helper";

const LocationSearch = ({ setSearchName, locationOptions, close }: { setSearchName: Function, locationOptions: any, close?: (param: boolean) => void; }) => {

    const { locationContext, setLocationContext, locationOptContext, setLocationOptContext }: any = useContext(ClientContext);
    const navigate = useNavigate();
    const hasPageBeenRendered = useRef(false)
    const { watch, control, reset, setValue } = useForm<Record<string, any>>({ defaultValues: { selectedLocation: locationOptContext } });
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(window.innerWidth <= 565);
    }, [])

    useEffect(() => {
        const addLocations = () => {
            const selectedOptions: string[] = []
            currentSelectedOption.forEach((option: any) => {
                if (option?.value ==="" && currentSelectedOption[currentSelectedOption.length-1].value === '' ){ //Appending Option 'All' if it was lastly selected.
                    selectedOptions.push(option?.value)    
                } 

                if(option?.value !=""){
                    selectedOptions.push(option?.value)
                }
            })
            
            setSelectedLocation(selectedOptions)
            setLocationContext(selectedOptions)
            const selectedLocationOptions = currentSelectedOption.filter((location: any) => {
                if(location.value !=""){
                    return 'value' in location    
                }
                
            })
            setLocationOptContext(selectedLocationOptions)
        }

        const removeAllLocations = () => {
            setSelectedLocation([])
            setLocationContext([])
            setLocationOptContext([])
        }

        const selectAllLocation = () => {
            const locationValues = locationOptions.map((options: any) => options.value)
            setSelectedLocation(locationValues)
            setLocationContext(locationValues)
            setLocationOptContext(locationOptions)
        }

        const currentSelectedOption = watch('selectedLocation')
        const isAllOptionSelected = currentSelectedOption && currentSelectedOption.some((option: any) => option.value == '')

        if (isAllOptionSelected) {
            if(currentSelectedOption.length < locationOptions.length && currentSelectedOption[currentSelectedOption.length-1].value != ''){//Checking the All option selected recently
                addLocations();  
            } else{
                selectAllLocation();
            }
            
        } else {
            const isAllOptionRemoved = locationOptContext && locationOptContext.some((option: any) => option.value == '') //Checking all option were exist when user selected last time 
            if(isAllOptionRemoved){ //Removing the all option when unselecting the all option
                removeAllLocations()    
            } else{
                watch('selectedLocation').length && addLocations()
                watch('selectedLocation').length == 0 && removeAllLocations()
            }
            
        }

    }, [watch('selectedLocation')])


    // Remove all selected  locations
    const removeAllLocations = (locations: []) => {
        setSelectedLocation(locations);
        setLocationContext(locations)
        setLocationOptContext(locations)
    }

    const handleViewCar = () => {
        const selectedLocation = encryptLocalStorage.getItem('selectedLocation');
        let searchCriteria = encryptLocalStorage.getItem('searchCriteria');
        searchCriteria = searchCriteria ? searchCriteria : {};
        searchCriteria.selectedLocation = selectedLocation
        encryptLocalStorage.setItem('searchCriteria', searchCriteria)
        navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}&from=location-modal`)
        if (close) {
            close(false);
        }
    }

    return (
        <>
            <Row >
                <div className="text-center mt-120">
                    <h2 className="text-primary mb-4 head-bold mt-2">CHOOSE YOUR LOCATION</h2>
                </div>
                {
                    isMobile ? <>
                        <div className="d-flex justify-content-center location-search mb-3">
                            <div style={{ width: "60%" }}>
                                <MultiSelectDropdown label="" name="selectedLocation"
                                    selectedValues={locationOptContext} setValues={removeAllLocations}
                                    options={locationOptions} control={control} placeholder="Select locations" />
                            </div>

                            <div className="ms-4">
                                <Button variant="primary" onClick={() => handleViewCar()} size='sm'>
                                    View cars
                                </Button>
                            </div>
                        </div> </> :

                        <div className="d-flex justify-content-center location-search mb-3">
                            <div style={{ width: "45%" }}>
                                <MultiSelectDropdown label="" name="selectedLocation"
                                    selectedValues={locationOptContext} setValues={removeAllLocations}
                                    options={locationOptions} control={control} placeholder="Select locations" />
                            </div>
                            <div className="ms-4">
                                <Button variant="primary" onClick={() => handleViewCar()}>
                                    View cars
                                </Button>
                            </div>
                        </div>
                }
            </Row>
        </>
    )
}

export default LocationSearch;

