import React, { useEffect, useState } from 'react';
import OutletTable from '../../../components/admin/location/OutletTable';
import OutletAddEditModal from '../../../components/admin/location/OutletAddEditModal';

const ListOutlets: React.FC = () => {
    const [show, setShow] = useState(false);
    const [countAddEdit, setCountAddEdit] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [sortOrder, setSortOrder] = useState(0)
    const [outletDetails, setOutletDetails] = useState({ id: 0, name: '', keyName: '', dealershipId: '', locationId: '' });
    const newModal = () => {
        setShow(true);
        setOutletDetails({ id: 0, name: '', keyName: '', dealershipId: '', locationId: '' })
    }
    const handleClose = () => setShow(false);

    const editModal = (outlet: any) => {

        setOutletDetails(outlet)
        setShow(true)
    }

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <OutletTable newModal={newModal} showModal={editModal} handleClose={handleClose} countAddEdit={countAddEdit} totalRows={totalRows} setTotalRows={setTotalRows} setSortOrder={setSortOrder} />
                            <OutletAddEditModal show={show} handleClose={handleClose} outletDetails={outletDetails} setCountAddEdit={setCountAddEdit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListOutlets;