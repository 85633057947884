import { Button, Col, Nav, Row, Tab, Image, Form, Tabs, Container, Pagination, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useState, useEffect, useRef } from "react";
import HTTP from "../../../config/axios";

const ReferAndEarn = ({ isMobile }: { isMobile?: boolean }) => {

  const [isTab, setIsTab] = useState(false)
  const [referData, setReferData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const [showReferData, setShowReferData] = useState(false);
  const tableRef = useRef<HTMLDivElement>(null);

  const handleShowReferData = () => {
    setShowReferData(prevShowDiv => {
   
      if (!prevShowDiv && tableRef.current) {
        tableRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      setTimeout(() => {
        tableRef.current?.focus();
      }, 300)
      return !prevShowDiv;
    });
  };
  useEffect(() => {
    setIsTab(window.innerWidth <= 820);
  }, []);

  useEffect(() => {
    const hanldeData = async () => {
      try {
        const response = await HTTP.get(`/api/leads/refer-and-earn/fetch-all/${currentPage}/${perPage}`);
        const { data: { status, data, totalRecords } } = response;
        console.log(response.data)
        setReferData(data);
        setTotalRows(totalRecords);
      } catch (error) {
        console.log("Error in blog fetch api", error);
      }
    }
    hanldeData()
  }, [perPage, currentPage])

  const totalPages = Math.ceil(totalRows / perPage)

  const PrevBtn = () => {
    return (
      <Pagination.Prev
        disabled={currentPage === 1}
        onClick={() =>
          setCurrentPage(prevPage => prevPage === 0 ? 0 : prevPage - 1
          )}>
        «
      </Pagination.Prev>
    )
  }

  const NextBtn = () => {
    return (
      <Pagination.Next
        disabled={currentPage === totalPages}
        onClick={() =>
          setCurrentPage(prevPage => prevPage === totalRows ? totalRows : prevPage + 1)}>
        »
      </Pagination.Next>
    )
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };



  return (

    <Tab.Pane eventKey="sideTab_6">
      <Row className="mx-1">
        <h2 className="text-dark is-testimonials pb-3">
          Refer and earn
        </h2>
        <h3 className="text-dark">How it works</h3>
        {!isTab ? (
          <Fragment>
            <Col md={4}>
              <div className="text-center pt-4 shadow border rounded-15 mb-3 h-100 ">
                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/refer-friend.svg`} height={"180px"} className="w-100" />
                <p className="pt-4 fs-7">Refer a friend</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center pt-4 shadow border rounded-15 mb-3 h-100">
                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/transaction.svg`} height={"180px"} className="w-100" />
                <p className="pt-4 fs-7">Your friend completes a transaction</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center pt-4 shadow border rounded-15 mb-3 h-100">
                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/gift.svg`} height={"180px"} className="w-100" />
                <p className="pt-4 fs-7">Both of you earn rewards</p>
              </div>
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            <div className="d-flex align-items-center shadow border rounded-15 mb-3 h-100">
              <Col sm={4} xs={4} col={4}>
                <div className="text-center pt-4">
                  <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/refer-friend.svg`} height={"180px"} className="w-100" />
                </div>
              </Col>
              <Col sm={8} xs={8} col={8}>
                <div className="text-center pt-4">
                  <p className="pt-4 fs-7">Refer a friend</p>
                </div>
              </Col>
            </div>
            <div className="d-flex align-items-center shadow border rounded-15 mb-3 h-100">
              <Col sm={8} xs={8} col={8}>
                <div className="text-center pt-4">
                  <p className="pt-4 fs-7">Your friend completes a transaction</p>
                </div>
              </Col>
              <Col sm={4} xs={4} col={4}>
                <div className="text-center pt-4">
                  <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/transaction.svg`} height={"180px"} className="w-100" />
                </div>
              </Col>
            </div>
            <div className="d-flex align-items-center shadow border rounded-15 mb-3 h-100">
              <Col sm={4} xs={4} col={4}>
                <div className="text-center pt-4">
                  <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/gift.svg`} height={"180px"} className="w-100" />
                </div>
              </Col>
              <Col sm={8} xs={8} col={8}>
                <div className="text-center pt-4">
                  <p className="pt-4 fs-7">Both of you earn rewards</p>
                </div>
              </Col>
            </div>
          </Fragment>
        )}
      </Row>
      < hr className="my-4" />
      <Container>
        <Row>
          <Col lg={4} md={12} className="pe-2 d-flex flex-column">
            <div className="content">
              <p className="text-dark fs-7 fw-semibold ">Track&nbsp;&&nbsp;redeem&nbsp;rewards</p>
              <p className="text-primary ">Total rewards earned</p>
              <p className="text-primary is-testimonials mb-1">₹0</p>
              <p className="mb-3">0 successful referrals</p>
            </div>
            <div className="mt-auto">
              <Button variant="primary" type="submit" className="w-100" onClick={handleShowReferData}>
                View all <FontAwesomeIcon icon={faAngleRight} />
              </Button>
            </div>
          </Col>
          <Col lg={4} md={12} className="pe-2  d-flex flex-column">

            <div className="content">
              <p className="text-dark fs-7 fw-semibold  mt-2 mt-md-1 mt-lg-0">Refer&nbsp;your&nbsp;friends</p>
              <p className="">Once your friend buy a car from us through your referral, you get a gift worth Rs.2000.</p>
            </div>
            <div className='mt-auto'><Link to="/refer-and-earn"><Button variant="primary" type="submit" className="w-100 ">
              View all <FontAwesomeIcon icon={faAngleRight} />
            </Button></Link></div>

          </Col>
          <Col lg={4} md={12}>
            <div className="align-self-center ps-lg-4 ps-sm-0 mt-3 mb-3">
              <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/mini.png`} height={"225px"} />
            </div>
          </Col>
        </Row>
        {showReferData && 

          <div style={{ overflowX: 'auto' }} tabIndex={-1} ref={tableRef}>
            <hr />
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>No.</th>
                  <th >Name</th>
                  <th>Phone&nbsp;Number</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {referData.map((item: any, index: number) => (

                  <tr key={index}>
                    <td>{(currentPage - 1) * 5 + index + 1}</td>
                    <td className="text-break">{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.refer_type === 1 ? "Buy" : "Sell"}</td>
                    <td>{item.status === 1 ? "Pending" : "Success"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination className="justify-content-center">
              <Pagination.Prev onClick={handlePrevPage} />
              {Array.from({ length: totalPages }).map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={currentPage === index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={handleNextPage} />
            </Pagination>
          </div>
        }
      </Container>

    </Tab.Pane>
  )
}

export default ReferAndEarn