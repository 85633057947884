import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import {  fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';
import InputTextField from '../../form/InputTextField';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col, Image } from 'react-bootstrap';
import Ckeditor5 from '../../../config/utils/ckeditor5';
import FileUpload from '../../form/FileUpload';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import InputTextAreaField from '../../form/InputTextAreaField';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        blogExist: boolean
    };
}

const BlogAddEdit: React.FC = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [editorData, setEditorData] =  useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id')
    const navigate = useNavigate()
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch , getValues} = useForm();
    const uploadedImage = watch('blogImage')
    const [imageURL, setImageURL] = useState('')
    const [editImage, setEditImage] = useState(false)
    const [croppedBlogImage, setCroppedBlogImage] = useState<string | Blob>('')
    const [imageData, setImageData] = useState('')
    const cropperRef = useRef<ReactCropperElement>(null);

    const nameValue = watch('name') || getValues('name')
    const uploadPath = `blog/images/${nameValue || 'title-no'}`
    const uploadUrl = `/admin/blog/image-upload`

    useEffect(() => {
         try{
            const getBlog = async () => {
                const response = await HTTP.get(`/api/admin/blog/fetch-one/${id}`)
                const { data: { status, data, error } } = response;
                reset(data)
                setEditorData(data.content)
                setImageData(data.image)
                setImageURL(process.env.REACT_APP_CLOUDFRONT_URL+data.image)
            }
            id && getBlog()
         }
         catch(err){
            console.log("Error in blog fetch one api")
         }

    }, []);

    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            submitData.content = editorData
            const formData = new FormData()
            formData.append('id', id ? id : '')
            formData.append('title', submitData.title);
            formData.append('content', editorData);
            formData.append('summary',submitData.summary);
            formData.append('image', croppedBlogImage);
            const { data: { status, message } }: responseType = await HTTP({
                method: id ? 'put' : 'post',
                url: `/api/admin/blog/${ id ? 'edit' : 'add'}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message: message, 
                variant: status ? 'success' : 'danger'
            })
            status && setTimeout(() => navigate(`/admin/blog`), 2000) 
        }
        catch (error) {
            setToast({
                open: true, header: "Failed",
                message:"Something went wrong", variant: "danger",
            });
            console.log("Error on blog add/ edit api", error)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (uploadedImage) {
            setImageURL(URL.createObjectURL(uploadedImage) || '')
            setEditImage(true)
        } else {
            setEditImage(false)
            setImageURL(id ? `${process.env.REACT_APP_CLOUDFRONT_URL}${imageData}` : '')
        }
    }, [uploadedImage])

    const cropImage = async () => {
        const cropper: any = cropperRef.current?.cropper;
        if (cropper) {
            const originalImage = urlToBlob(cropper.getCroppedCanvas({ width: 1180, height: 750 })?.toDataURL())
            setCroppedBlogImage(originalImage)
        }
    };

    const urlToBlob = (dataURL: string) => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeType = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([arrayBuffer], { type: mimeType });
    }

    return (
        <>
        <Container  fluid>
            <h1> { id ? 'Update' : 'Add'} blog</h1>
            <Form onSubmit={handleSubmit(onSubmit)}>
        
                <Row>
                     <Col md={6}> 
                    <InputTextField name="title" label="Title"
                        placeholder="Enter Title" clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired }} maxLength={120} />
                </Col>
                <Col md={{ span: 6, offset: 6 }}> </Col>
                </Row>
                <Row> 
                     <Col md={6}>
                    <InputTextAreaField name="summary" label='Summary'
                    placeholder='Enter Summary' clearErrors={clearErrors}
                    control={control} rule={{ required: fieldRequired,}} maxLength={200} 
                    rows={4}/>
                    </Col>
                    <Col md={{ span: 6, offset: 6 }}> </Col>
                </Row>
                <Row>
                           <Col className='mt-1'> 
                    <Ckeditor5 data={editorData} 
                        onChange={setEditorData} label="Content"
                        uploadPath={uploadPath} uploadUrl={uploadUrl} /> 
                </Col>
                <Col md={{ span: 6, offset: 6 }}> </Col>
                </Row>
                <Col md={6}>
                        <div className='mb-1'>
                            <FileUpload name="blogImage" label="Blog Image" accept=".jpeg,.jpg,.png"
                                control={control} rule={id ? '' : { required: fieldRequired }} />
                            <Form.Text className="text-black-50">Upload image in png/jpg/jpeg format and less than 2 MB size</Form.Text>
                        </div>
                        <div>
                            {imageURL && !editImage && <Image src={imageURL} width={"30%"} />}
                        </div>
                        {editImage && <Cropper
                            ref={cropperRef}
                            crop={cropImage}
                            src={imageURL}// Replace with your image URL
                            style={{ width: 460, height: 300 }}
                            aspectRatio={1180 / 750}
                            guides={true} // Show cropping guidelines
                        />}
                </Col>

        
            <Row>
                <Col md={6}>
                    <Button variant="primary" type="submit" className='mt-3' >
                        {id ? 'Update' : 'Save Changes'}
                    </Button>
                </Col>
                <Col md={{ span: 6, offset: 6 }}> </Col>
            </Row>
            </Form>
        </Container>
            
               
             
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default BlogAddEdit;