import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <>
            <Container fluid >
                <Row className="py-5">
                    <div className="px-4 py-4 text-center">
                        <div className="text-center ">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/404-image.png`} height={"340px"} />
                        </div>
                        <h1 className="display-5 fw-bold text-dark">Page Not Found</h1>
                        <div className="col-lg-6 mx-auto">
                            <p className="lead mb-4"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>
                            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                <Link to="/" className="btn btn-primary btn-lg px-4">
                                    Go to Home
                                </Link>
                            </div>
                        </div>
                    </div>
                </Row>

            </Container>
        </>
    );
}

export default NotFound;