import React from "react";
import { Alert, Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface IdateTimePicker {
    name: string;
    label?: string;
    placeholder?: string;
    control?: Control;
    classList?: string;
    type?: string;
    rule?: any;
    min?: string;
    max?: string;
    defaultValue?: string;
    clearErrors?: Function;
    disabled?: boolean;
    errText?: string;
}

const DateTimePicker: React.FC<IdateTimePicker> = ({
    name,
    label,
    placeholder,
    control,
    classList,
    type = "datetime-local",
    rule,
    min,
    max,
    clearErrors,
    defaultValue,
    disabled = false,
    errText = ''
}) => {

    const handleError = () => {
        if (clearErrors) clearErrors();
    }


    if (rule?.required && typeof rule.required == 'function') {
        rule.required = rule.required(label)
    }

    return (<>

        <div className={classList}>
            <Controller
                name={name}
                control={control}
                rules={rule}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <>
                        {label &&
                            <Form.Label htmlFor={`${name}-id`} className="text-dark fw-semibold fs-6">
                                {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                            </Form.Label>}
                        <Form.Control type={type} id={`${name}-id`}
                            value={value || ''}
                            placeholder={placeholder}
                            disabled={disabled}
                            {...(min ? { min } : {})} // Add 'min' attribute if min is valid { ...(min ? {min:min} : {}) }
                            {...(max ? { max } : { max: '9999-12-31' })}
                            onChange={(e) => { handleError(); onChange(e) }} />
                        {error?.message &&
                            <Form.Text className="text-danger">
                                {/* Show custom error if present and required message is not triggeres */}
                                {error.type != 'required' && errText ? errText : error.message}
                            </Form.Text>}
                    </>)
                }
            />

        </div>


    </>)
}


export default DateTimePicker