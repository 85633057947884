import { Button, Col, Nav, Row, Tab, Image, Form, Tabs } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import HTTP from "../../../config/axios";
import InputTextField from "../../../components/form/InputTextField";
import { useForm } from "react-hook-form";
import { fieldRequired, equalTo, lengthBetween, phoneNumberPattern, checkPassword, min } from "../../../config/validations/rules";
import SimpleToast from "../../../components/common/Toast";
import { encryptLocalStorage } from "../../../config/utils/storage";
import SimpleSpinner from "../../common/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";


interface IUserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  currentImagePath: string;
}
const PersonalInfo = ({ userInfo, getUserData }: { userInfo: IUserInfo, getUserData: Function }) => {

  const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
  const { register, handleSubmit, formState: { errors }, setError, setValue, getValues, clearErrors, control, reset, watch } = useForm<Record<string, any>>({ defaultValues: userInfo });
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [imageURL, setImageURL] = useState(userInfo.currentImagePath ? `${process.env.REACT_APP_S3_BASE_URL}/${userInfo.currentImagePath}` : `${process.env.REACT_APP_CLOUDFRONT_URL}/public/pro_pic.png`)
  const uploadedImage = watch('uploadProfile')


  const token = encryptLocalStorage.getItem("jwtToken");
  if (!token) {
    window.location.replace("/");
  }

  const handleChangeProfile = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }

  };

  useEffect(() => {
    if (uploadedImage) {
      setImageURL(URL.createObjectURL(uploadedImage) || '')
    }
  }, [uploadedImage])

  const profileSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const formData = new FormData()
      formData.append('firstName', data.firstName)
      formData.append('lastName', data.lastName)
      // formData.append('phone', data.phone)
      formData.append('profileImagePath', userInfo.currentImagePath)
      formData.append('profilePicture', data.uploadProfile)
      formData.append('email',data.email)
      delete data.email;
      const response = await HTTP.put(`/api/update-user`, formData)
      if (response.data.status === 1) {
        setToast({ open: true, header: 'success', message: 'Profile updated successfully', variant: 'success' })
        setIsLoading(false);
      } else {
        setToast({ open: true, header: 'Failed', message: 'Something went wrong. Please try again', variant: 'danger' })
        setIsLoading(false);
      }
      getUserData() // Call user profile again to get updated profile data
      setIsLoading(false);
    }
    catch (error: any) {
      const { response: { status, data: { recaptchaStatus, err } } } = error
      setToast({ open: true, header: 'Failed', message: 'Something went wrong. Please try again', variant: 'danger' })
      setIsLoading(false);
    }
  };

  const handleResetPassword = () => {
    setShowPasswordResetForm(true);
  }

  const handlePasswordResetSubmit = async (passwordData: any) => { 
    setIsLoading(true);
    const { currentPassword, newPassword, confirmPassword } = passwordData;
    if (currentPassword == newPassword) {
      setNewPasswordError("Current password and new password are same")
      setIsLoading(false); return
    }
    if (confirmPassword != "" && newPassword != confirmPassword) {
      setConfirmPasswordError("New password and confirm password are not matching")
      setIsLoading(false); return
    }
    const response = await HTTP.put(`/api/reset-password`, { currentPassword, newPassword })
    if (response?.data?.status === 1) {
      setToast({ open: true, header: 'success', message: 'Password updated successfully', variant: 'success' })
      setShowPasswordResetForm(false)
      setIsLoading(false);
    } else {
      setToast({ open: true, header: 'Failed', message: 'Something went wrong. Please try again', variant: 'danger' })
      setIsLoading(false);
    }
    reset();


  }

  const confirmPassword = (value:string) => {
    const isLengthValid = lengthBetween(value, 6, 12)
    if(isLengthValid !== true) return isLengthValid

    const isPasswordMatch = checkPassword(value, getValues('newPassword'))
    if(isPasswordMatch !== true) return isPasswordMatch

    return true
  }

  return (<>
    <Tab.Pane eventKey="sideTab_0">
      {!showPasswordResetForm && (<Form onSubmit={handleSubmit(profileSubmit)}>
        <Row>
          <h2 className="text-dark is-testimonials pb-3">
            Personal Information
          </h2>
          {/* {updateProfileSuccess && <Form.Text className="text-success fw-bold pb-4"><FontAwesomeIcon icon={faCheck} className="me-3" />Request submitted successfully </Form.Text>} */}
          <div className="d-flex mb-3">
            <Image
              src={imageURL}
              className="rounded-circle"
              height={"80px"}
              width={"80px"}
            />
            <Button className="bg-white border-0" onClick={handleChangeProfile}>
              <FontAwesomeIcon
                className="align-self-center text-primary"
                icon={faPen}
                title="Update image"
              />
            </Button>
            <input
              type="file"
              accept=".jpeg,.jpg,.png"
              id="uploadProfile"
              {...register("uploadProfile", {
                // Add any other validation rules here
              })}
              onChange={(e: any) => {
                if (e.target.files && e.target.files.length > 0) {
                  const selectedFile = e.target.files[0];

                  const allowedFormats = ['image/png', 'image/jpeg'];
                  if (!allowedFormats.includes(selectedFile.type)) {
                    setError("uploadProfile", {
                      type: "manual",
                      message: "Please upload a PNG or JPG image."
                    });
                    e.target.value = null;
                    return;
                  }

                  const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
                  if (selectedFile.size > maxSizeInBytes) {
                    setError("uploadProfile", {
                      type: "manual",
                      message: "File size must be less than 2MB."
                    });
                    e.target.value = null;
                    return;
                  }
                  setValue('uploadProfile', selectedFile);
                  clearErrors("uploadProfile");
                }
              }}
              ref={hiddenFileInput}
              style={{ display: 'none' }}
            />

            {/* Display the error message if it exists */}



          </div>
          {errors.uploadProfile && (
            <span className="text-danger">{String(errors.uploadProfile.message)}</span>
          )}
          <Col lg={6} md={12}>
            <div className="mb-3">
              <InputTextField name="firstName" label="First name"
                placeholder="First name" clearErrors={clearErrors}
                control={control} rule={{ required: fieldRequired }} />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="mb-3">
              <InputTextField name="lastName" label="Last name"
                placeholder="Last name" clearErrors={clearErrors} rule={{ required: fieldRequired }}
                control={control} />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="mb-3">
              <InputTextField name="phone"
                label="Phone number"
                placeholder="Phone number"
                clearErrors={clearErrors}
                type="number" 
                control={control}
                disabled={true}
                rule={{ required: fieldRequired, pattern: phoneNumberPattern, min: min(0) }} />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="mb-3">
              <InputTextField name="email" label="Email address"
                placeholder="Enter your email" clearErrors={clearErrors}
                control={control} />
            </div>
          </Col>
          <Col lg={6} md={12}>
            <Row>
              <Col md={6} className=" mb-3">
                <Button
                  className="w-100"
                  variant="outline-primary"
                  type="submit"
                >
                  Edit info
                </Button>
              </Col>
              {/* <Col md={6} className=" mb-3">
                <Button
                  className="w-100 px-0"
                  variant="primary"
                  onClick={handleResetPassword}
                >
                  Reset Password
                </Button>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Form>)}
      {showPasswordResetForm && (
        <Form onSubmit={handleSubmit(handlePasswordResetSubmit)}>
          <Row>
            <h2 className="text-dark is-testimonials pb-3">Reset Password</h2>
            <Row>
              <Col lg={6} md={12}>
                <div className="mb-3">
                  <InputTextField name="currentPassword" label="Current password"
                    placeholder="Enter current password" clearErrors={clearErrors}
                    type="password"
                    control={control} />
                </div>
              </Col>
            </Row>

            <Col lg={6} md={12}>
              <div className="mb-3">
                <InputTextField name="newPassword" label="New password"
                  placeholder="Enter new password" clearErrors={clearErrors}
                  rule={{ required: fieldRequired, validate: (value: string) => lengthBetween(value, 6, 12) }}
                  type="password"
                  control={control} />
                {newPasswordError && <small className="text-danger form-text"> {newPasswordError}</small>}
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="mb-3">
                <InputTextField name="confirmPassword" label="Confirm new password"
                  placeholder="Confirm new password" clearErrors={clearErrors}
                  rule={{ required: fieldRequired, validate: (value: string) => confirmPassword(value) }}
                  type="password"
                  control={control} />
                {confirmPasswordError && <small className="text-danger form-text"> {confirmPasswordError}</small>}
              </div>
            </Col>
            <Col lg={6} md={12}>
              <Row>
                <Col md={6} className="mb-3">
                  <Button
                    className="w-100"
                    variant="outline-primary"
                    type="submit"
                  >
                    Reset Password
                  </Button>
                </Col>
                <Col md={6} className="mb-3">
                  <Button
                    className="w-100 px-0"
                    variant="primary"
                    onClick={() => setShowPasswordResetForm(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Tab.Pane>
    <SimpleSpinner show={isLoading} />
    <SimpleToast show={toast.open} header={toast.header}
      message={toast.message} variant={toast.variant}
      onClose={() => setToast({ ...toast, open: false })} />
  </>)
}


export default PersonalInfo