import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { emailPattern, fieldRequired, phoneNumberPattern } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        msg: string,
        message:string,
        type: string
    };
}
interface IClientInfo {
    id: number;
    firstName: string;
    lastName: string;
    phone: number;
    email: string
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    clientDetails: IClientInfo;
}

const ClientAddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, clientDetails }) => {

    const [disableEmail, setDisableEmail] = useState(false)

    useEffect(() => {
        if (clientDetails.id !== 0) {
            setDisableEmail(true)
        }
    }, [clientDetails]);

    useEffect(() => {
        reset(clientDetails);
    }, [clientDetails]);



    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm<Record<string, any>>();

    const onSubmit = async (data: any) => {
        if (data.id !== 0) {
            // delete data.email;
            const clientData: responseType = await HTTP.post(`/api/admin/client/edit`, data);
            if (clientData.data.status === 1) {
                setToast({ open: true, header: 'Success', message: 'Client info updated successfully', variant: 'success' })
            } else {
                setToast({ open: true, header: 'failed', message: clientData.data.message || 'Something went wrong', variant: 'danger' })
            }
        } else {
            delete data.id;
            const clientData: responseType = await HTTP.post(`/api/admin/client/add`, data);
            if (clientData.data.status === 1) {
                setToast({ open: true, header: 'Success', message: 'Client created successfully', variant: 'success' })
            } else {
                setToast({ open: true, header: 'failed', message: clientData.data.message || 'Something went wrong', variant: 'danger' })
            }
        }
        handleClose()
        reset()
    }

    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{clientDetails.id === 0 ? 'Add client' : 'Upadate Client'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="firstName" label="First name"
                                placeholder="Enter first name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <InputTextField name="lastName" label="Last name"
                                placeholder="Enter last name" clearErrors={clearErrors} rule={{ required: fieldRequired }}
                                control={control} />
                        </div>
                        <InputTextField name="id" label=""
                            placeholder="" type="hidden" clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />
                        <div className='mb-1'>
                            <InputTextField name="phone" label="Phone number"
                                placeholder="Enter phone number" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired, pattern: phoneNumberPattern }} />
                        </div>
                        <div className='mb-1'>
                            <InputTextField name="email" label="Email"
                                
                                placeholder="Enter email" clearErrors={clearErrors}
                                control={control} 
                                rule = {{pattern:emailPattern}}
                                />
                              
                        </div>
                        {/* {clientDetails.id === 0 && <div className='mb-1'>
                            <InputTextField name="password" label="Password"
                                type="password" placeholder="Enter password"
                                clearErrors={clearErrors} control={control}
                                rule={{ required: fieldRequired }} />
                        </div>} */}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        {clientDetails.id !== 0 &&
                            <Button variant="primary" type="submit">
                                Update
                            </Button>}
                        {clientDetails.id == 0 &&
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>}

                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default ClientAddEditModal;

