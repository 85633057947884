import { useState, useEffect, ReactNode, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropDownField from '../../form/DropDownField';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired, validateOnlySpaces } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import { Row, Col, Nav } from 'react-bootstrap'
import CustomModal from '../../common/CustomModal';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import VariantFeaturesModal from './VariantFeaturesModal';
import VariantSpecificationModal from './VariantSpecificationModal';
import MultiSelectDropdown from '../../form/MultiDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import VariantAddColour from './VariantAddColour';
import VariantBAsicDetails from './VariantBAsicDetails';
interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        msg: string,
        type: string
    };
}
interface IVariantInfo {
    id: number;
    name: string;
    // keyName: string;
    modelId: number | null;
    makeId: number | null;
    modelName: string;
    [key: string]: number | string | null;
}
interface IMake {
    id: number;
    name: string;
    keyName: string;
}
interface IfeatureDetail {
    [key: string]: number | string | null;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    variantDetails: IVariantInfo;
    setAddEditCount: Function
    featureDetails: IfeatureDetail
    setModelDetails: Function;

}

const AddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, variantDetails, setAddEditCount, featureDetails, setModelDetails }) => {

    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch, resetField, setValue } = useForm();
    const [activeTab, setActiveTab] = useState('basic')
    const [showModal, setShowModal] = useState(false);
    const [assignedVehicleIds, setAssignedVehicleIds] = useState<number[]>([]);
    const [featureForm, setFeatureForm] = useState(false);
    const [specificationForm, setSpecificationForm] = useState(false)
    const [vehicleVariantId, setVehicleVariantId] = useState(0)

    useEffect(() => {
        setVehicleVariantId(variantDetails.id) // set variant id and pass to variant specification component and features component
        setFeatureForm(variantDetails.id != 0 ? true : false) // to make the tab disable 
        setSpecificationForm(variantDetails.id != 0 ? true : false)

    }, [variantDetails, activeTab]);


    const switchTabs = (tabKey: string | null) => {
        if (tabKey === 'specification' && specificationForm) {
            setActiveTab('specification')
        } else if (tabKey === 'feature' && featureForm) {
            setActiveTab('feature')
        } else {
            setActiveTab('basic')
        }
    }


    const closeModal = () => {
        reset()
        setValue('colours', [])
        handleClose()
        setActiveTab('basic')
        setShowModal(false)
    }



    return (
        <>
            <Modal show={show} onHide={() => closeModal()} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>{variantDetails.id === 0 ? 'Add Variant' : 'Update Variant'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={activeTab}
                        onSelect={(key) => switchTabs(key)}
                        transition={false}
                        id="noanim-tab-example"
                        className="mt-1 mb-3"
                    >
                        <Tab eventKey="basic" title="Basic Details">
                            {activeTab === "basic" && (
                                <VariantBAsicDetails setActiveTab={setActiveTab} show={show} handleClose={handleClose} variantDetails={variantDetails} setModelDetails={setModelDetails} setAddEditCount={setAddEditCount} activeTab={activeTab} setAssignedVehicleIds ={setAssignedVehicleIds} assignedVehicleIds= {assignedVehicleIds}/>)}
                        </Tab>
                        <Tab eventKey="specification" title="Specification" disabled={!specificationForm}>
                            {activeTab === "specification" && <VariantSpecificationModal setActiveTab={setActiveTab} vehicleVariantId={vehicleVariantId} variantDetails={variantDetails} setSpecificationForm={setSpecificationForm} setFeatureForm={setFeatureForm} assignedVehicleIds={assignedVehicleIds} />}
                        </Tab>
                        <Tab eventKey="feature" title="Features" disabled={!featureForm} >
                            {activeTab === "feature" && <VariantFeaturesModal setActiveTab={setActiveTab} vehicleVariantId={vehicleVariantId} variantDetails={variantDetails} featureDetails={featureDetails} setFeatureForm={setFeatureForm} setSpecificationForm={setSpecificationForm} assignedVehicleIds={assignedVehicleIds} />}
                        </Tab>
                    </Tabs>
                </Modal.Body>

             

            </Modal>
   
        </>
    );
}

export default AddEditModal;