import React, { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

interface CustomToastProps {
    show: boolean;
    header: string;
    message: string;
    onClose: () => void;
    variant: string;
    delay?: number
}

const SimpleToast: React.FC<CustomToastProps> = ({
    show=false,
    header,
    message,
    onClose,
    variant = 'success',
    delay = 3000
}) => {
    const isDanger = variant === 'danger';
    const isSuccess = variant === 'success';

    return (
        <ToastContainer position="top-end" className="mt-5 p-3 position-fixed" style={{zIndex:1500}}>
            <Toast show={show} onClose={onClose} bg={variant} delay={delay} autohide>
                <Toast.Header>
                    {/* <img src="" className="rounded me-2" alt="" /> */}
                    <strong className="me-auto">{header}</strong>
                </Toast.Header>
                <Toast.Body
                    className={`fs-6 ${isDanger || isSuccess ? 'text-white' : 'text-dark'}`}
                >
                    {message}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default SimpleToast;
