import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.scss';
import './Admin.scss';

const loadApp = async () => {

  if (process.env.REACT_APP_ENVIRONMENT == "production") {
    await import('./bg-style-image-production.scss')
  } else if (process.env.REACT_APP_ENVIRONMENT == "staging") {
    await import('./bg-style-image-staging.scss')
  } else if (process.env.REACT_APP_ENVIRONMENT == "local") {
    await import('./bg-style-image-local.scss')
  }
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

loadApp()



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
