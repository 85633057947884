import "bootstrap/dist/css/bootstrap.min.css";
import HowitWorks from "../../../components/modules/Vehicle/SellCarWorkFlow"
import SellCarForm from "../../../components/modules/Vehicle/SellCarForm";
import DocumentsRequired from "../../../components/modules/Vehicle/SellCarDocuments";
import Benefits from "../../../components/modules/Vehicle/SellCarBenefits";
import Testimonials from "../../../components/common/Testimonial";
import ContactUs from "../../../components/common/ContactUs";
import ParkAndSellBanner from "../../../components/common/ParkAndSellBanner";
import BuyCarBanner from "../../../components/common/BuyCarBanner";
import Faq from "../../../components/common/Faq";
import HTTP from "../../../config/axios"
import { useState, useEffect, useRef } from "react";
import { Carousel } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const SellCar = () => {

  const [faqContent, setFaqcontent] = useState('')
  const [isMobile, setIsMobile] = useState(false)   

  useEffect(() => {

    const getCmsContent = async () => {
      let sellCarFaq = "sellcar-FAQ"
      const response = await HTTP.get(`/api/cms/fetch-one/${sellCarFaq}`);
      const { data: { status, data, msg, error } } = response
      setFaqcontent(data)
    }
    getCmsContent()

  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 500)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 500);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Carousel className="home-carousel" controls={false}>
        <Carousel.Item>
          {!isMobile ? (<img
            className="d-block  "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/banner1-sell-web.png`}
            alt="First slide"
          />) : (<img
            className="d-block  "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/banner1-sell-mobile.png`}
            alt="First slide"
          />)}
        </Carousel.Item>
        <Carousel.Item>
          {!isMobile ? (<img
            className="d-block "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/banner2-sell-web.png`}
            alt="Second slide"
          />) : (<img
            className="d-block  "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/banner2-sell-mobile.png`}
            alt="First slide"
          />)}
        </Carousel.Item>
      </Carousel>
      <SellCarForm />
      <HowitWorks />
      <Benefits />
      <DocumentsRequired />
      <ParkAndSellBanner />
      <Testimonials />
      <BuyCarBanner />
      {faqContent && <Faq cmsContent={faqContent} cmsType="all-sell-faq" />}
      <ContactUs />
    </div>
  );
}

export default SellCar;