import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import { useForm } from 'react-hook-form';
import Checkbox from '../../form/Checkbox';
import ImageCellForDataTable from '../../common/ImageCellForDataTable';


const ImageTable: React.FC<any> = ({ newImage, editModalDetails, addEditCount, vehicleId, vehicleUuid, totalRows, setTotalRows }) => {
    const [vehicleImageData, setVehicleImageData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const removedImage = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)


    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Sort order',
            selector: (row: { sortOrder: number; }) => row.sortOrder,
            keyName: 'name'
        },
        {
            name: 'Image',
            cell: (row: { thumbnailUrl: any; }) =>
                <ImageCellForDataTable value={row.thumbnailUrl.replace('/original/', '/thumbnail/')} />
            ,
            keyName: 'modelName'
        },
        {
            name: 'Action',
            cell: (row: any, index: number) => (
                <div>{!removedImage ?
                    <>
                        <Button variant="success" size="sm" className="me-3" onClick={() => editImage(row)} title="Edit info" >
                            <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <span title={row.sortOrder == 1 ? "There should be at least one image against a car" : "Remove image"}>
                            <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.imageId)}
                                disabled={row.sortOrder == 1} >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </span></>
                    :
                    // <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.imageId)} title="Image active" >
                    //     <FontAwesomeIcon icon={faUndo} />
                    // </Button>
                    <Button variant="success" size="sm" className="me-3" onClick={() => editImage(row)} title="Image active" >
                        <FontAwesomeIcon icon={faUndo} />
                    </Button>

                }

                </div>
            ),
            center: true,
        }
    ];


    //Fetching the intial data for the data table
    useEffect(() => {
        const getAllImages = async () => {
            try {
                setIsLoading(true);
                const response = await HTTP.get(`/api/admin/fetch-all-images/vehicle/${vehicleUuid}/${removedImage ? 0 : 1}/${currentPage}/${perPage}`);
                const { data: { status, data, msg, error, totalRecords } } = response;
                setVehicleImageData(data.rows);
                setTotalRows(data.count);

            }
            catch (err) {
                console.log(err)
            }
            finally {
                setIsLoading(false);
            }


        }
        getAllImages()
    }, [addEditCount, currentPage, perPage, removedIds, removedImage]);

    //Invokng the edit modal for editing the model
    const editImage = (imageDetails: any) => {
        editModalDetails(imageDetails, totalRows)
    }

    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
        setIsLoading(true);
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
        setIsLoading(true);
    };

    //handling Delete of models
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }

    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false)
    }

    const handleDelete = async () => {
        try {
            setIsOpenModal(false)
            const response = await HTTP.patch(`/api/admin/vehicle/image/remove/${selectedRecord}`);
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                //getAllModels({page:currentPage,limit:limit,makeId:'makeAll'})
                setRemovedIds([...removedIds, selectedRecord])
                setToast({ open: true, header: 'Success', message: 'Image deleted successfully', variant: 'success' })
            } else {
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleRecover = async () => {
        try {
            setOpenActivateModal(false)
            setIsLoading(true);
            const response = await HTTP.patch(`/api/admin/vehicle/image/recover/${selectedRecord}`);
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setRemovedIds([...removedIds, selectedRecord])
                setIsLoading(false);
                setToast({ open: true, header: 'Success', message: 'Image recovered successfully', variant: 'success' })
            } else {
                setIsLoading(false);
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

            }
        }
        catch (err) {
            console.log('Something went wrong')
        }
        finally {
            setIsLoading(false);
        }
    }

    //Handling the sort
    // const handleSort = (column: any, sortDirection: any) => {
    //     const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
    //     setSortDetails(columnDetails)
    //     //getAllModels({page:1,limit,'makeId',columnDetails})
    // }

    return (<>


        <Row className='mb-1'>
            <div className="d-flex">

                <div className="p-2">
                    <Button variant="success" onClick={newImage}>
                        Add Image
                    </Button>
                </div>
                <div className="p-2 mt-2"><Checkbox name="archived" control={control} label="Show removed image"
                    classList="" /></div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={vehicleImageData}
            // progressPending={isLoading}
            striped={true}
            // onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete  Image"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover Image"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />


    </>)
}

export default ImageTable;