import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ContactUs from "../../../components/common/ContactUs";
import FinancePartners from "../../../components/modules/Finance/FinancePartners";
import EmiCalculator from "../../../components/modules/Finance/EmiCalculator";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import { useEffect, useRef } from "react";

const Finance = () => {

  
  const breadCrumbList = [
    { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
    { title: "Finance", path: "/finance", active: true, linkClass: "text-primary fw-semibold" }
  ]


  const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/finance_banner.jpg`;

  return (
    <div className="App">
      <Container fluid className="top-banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
        <Container className="my-auto">
          <Col md={8}>
            <h5 className="text-primary is-sub-heading mb-1 head-bold">
              FINANCE
            </h5>
            <h1 className="text-light">
              Financing at Popular
            </h1>
            <p className="text-light is-description">
              At Popular, we believe in offering customer-friendly financing to help you purchase your dream car. That's why many of our customers choose to stay with us throughout their car-ownership journey. Our Finance channel partners offers a range of loan options tailored to your specific requirements, ensuring that you can own the car you love on your own terms.
            </p>
            <Link to="/finance/loan-application" className="btn btn-primary">
              Check eligibility
            </Link>
          </Col>
        </Container>
      </Container>
      {/* Emi calculator component */}
      <Breadcrumbs breadcrumbData={breadCrumbList} />
      <EmiCalculator />
      <hr />
      <Container>
        <Row className="mt-4 ">
          <Col md={12}>
            <div className="text-center pb-4">
              <h2 className="text-primary head-bold">HOW IT WORKS</h2>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 shadow border rounded-15 p-3 h-100">
          <Col lg={4} md={6} sm={5} xs={5}>
            <div className="text-center">
              <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/upload_documents.svg`} width={"80%"} />
            </div>
          </Col>
          <Col lg={4} md={6} sm={7} xs={7} className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center">
            <div className="text-lg-center text-md-start text-sm-start mt-3">
              <h3 className="text-dark fw-normal fs-5">Upload Documents</h3>
              <div className="d-none d-d-md-block d-lg-block pt-5">
                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
              </div>
            </div>
          </Col>
          <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
            <div className="pt-4">
              <hr />
            </div>
          </Col>
          <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/01.svg`} />
          </Col>
        </Row>
        <Row className="mb-5 shadow border rounded-15 p-3 h-100">
          <Col md={2} className="align-self-center d-none d-d-md-block d-lg-block">
            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/02.svg`} />
          </Col>
          <Col md={2} className="align-self-center d-none d-d-md-block d-lg-block">
            <hr />
          </Col>
          <Col lg={4} md={6} sm={7} xs={7} className="align-self-center">
            <div className="text-lg-center text-md-start text-sm-start mt-2">
              <h3 className="text-dark fw-normal fs-5">Same day approval</h3>
            </div>
          </Col>

          <Col lg={4} md={6} sm={5} xs={5}>
            <div className="text-center">
              <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/ok.svg`} width={"80%"} />
            </div>
          </Col>

        </Row>
      </Container>
      <FinancePartners />
      <ContactUs />
    </div >
  );
}

export default Finance;
