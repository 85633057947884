import React, { useState, useEffect, useContext } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import HTTP from '../../../config/axios';
import { useNavigate, useLocation } from "react-router-dom";
import { encryptLocalStorage } from '../../../config/utils/storage';
import { ClientContext } from "../../../config/context/clientContext"
import { setSelectedLocation } from '../../../config/utils/helper';

interface LocationInterface {
    id: number;
    name: string;
    keyName: string;
    image: string;
}
interface LocationProps {
    showAll: boolean;
    close?: (param: boolean) => void;
    searchName?: string | null;
    locationList?: any;
    fromModal?: boolean
}


const Location: React.FC<LocationProps> = ({ showAll, close, searchName = null, locationList = [], fromModal = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentRoute = location.pathname.split('/')[1] // first part of current route
    const [locationArray, setLocationArray] = useState(locationList)
    const [singleSelect, setSingleSelect] = useState(false)
    const { locationContext, setLocationContext, locationOptContext, setLocationOptContext }: any = useContext(ClientContext);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [locationLength, setLocationLength] = useState(0)

    useEffect(() => {
        const getAllLocation = async () => {
            const locationData = await HTTP.get(`/api/location/fetch-all`, { params: { status: 1 } });
            const { data: { status, data, msg, error } } = locationData

            if (status == 1) {
                setLocationArray(data)
                setLocationLength(data.length)
            } else {
                setLocationArray([])
            }
        }
        locationList.length == 0 && getAllLocation() // If location list is not provided as params , fetch from API
    }, []);




    const filteredLocations = showAll ? locationArray : locationArray.slice(0, 10);


    useEffect(() => {  // to redirect to BuyCar from home page when a location is selected and after locationContext is updated
        if (singleSelect) {
            navigate(`/buy-used-car`)
        }
        
    },[locationContext])

    const handleLocationClick = ({ id, name, keyName }: { id: number, name: string, keyName: string }) => {

        if (window.location.pathname == '/' && !fromModal) {
            setSelectedLocation([keyName]);
            setLocationContext([keyName])
            setLocationOptContext([{ value: keyName, label: name }])
            setSingleSelect(true)
            return
        }

        const currentSelectedLocation = locationContext.length > 0 ? locationContext : encryptLocalStorage.getItem("selectedLocation")
        let updatedLocations; let updatedLocationOptions;

        if (currentSelectedLocation && currentSelectedLocation.includes(keyName)) { // handling the multiselect while clicking the image
            updatedLocations = currentSelectedLocation.filter((location: any) => location !== keyName);
            updatedLocationOptions = locationOptContext.filter((option: any) => option.value !== keyName);

            //  // removing all options when the delecting an option 
            if (updatedLocationOptions.length == locationLength && updatedLocationOptions.some((option: any) => option.value === '')) {
                updatedLocations = updatedLocations.filter((location: any) => location !== "");
                updatedLocationOptions = updatedLocationOptions.filter((option: any) => option.value !== "");
            }

        } else {
            updatedLocations = currentSelectedLocation ? [...currentSelectedLocation, keyName] : [keyName]
            updatedLocationOptions = [...locationOptContext, { value: keyName, label: name }]
        }
        setSelectedLocation(updatedLocations);
        setLocationContext(updatedLocations)
        setLocationOptContext(updatedLocationOptions)
        
    }

    return (
        <div className="App">
            <Container className="mb-md-4 mb-sm-2 ">
                <Row className=" location-wrapper justify-content-center">
                    {filteredLocations.map((location: any) => (
                        <Col key={location.id} md={2} sm={6} xs={6} className='outline-box '
                            onClick={() => handleLocationClick({ id: location.id, name: location.name, keyName: location.keyName })} >
                            <div className="location-tile is_zoom_in py-1">
                                <Image
                                    src={`${process.env.REACT_APP_CLOUDFRONT_URL}${location.image}`}
                                    className="col-md-12 col-sm-12 col-lg-6 w-sm-80 p-2"
                                    alt="..."
                                />
                                <h4 className="is-description text-dark">{location.name}</h4>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default Location;