import { Navigate } from 'react-router-dom'
import { encryptLocalStorage } from "../../config/utils/storage"
import { useEffect, useState } from 'react'
import HTTP from '../../config/axios'

const AuthenticatedRoute = ({ allowedRole, children }: any) => {
    const userInfo = encryptLocalStorage.getItem("userInfo")
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const verifyAuthentication = async () => {
            try {
                const { status } = await HTTP.get("/api/authenticate")
                if (status >= 200 && status <= 299 && userInfo && allowedRole.includes(userInfo.roleUUID)) {
                    setIsAuthenticated(true)
                }
            } catch (error) {
                // Handle authentication error
            } finally {
                setIsLoading(false)
            }
        }
        verifyAuthentication()
    }, [userInfo, allowedRole])

    if (isLoading) {
        // Return a loading indicator or null to prevent rendering children prematurely.
        return null
    }

    if (isAuthenticated) {
        return children
    } else {
        return <Navigate to="/sign-in" />
    }
}

export default AuthenticatedRoute
