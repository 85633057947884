import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container } from "react-bootstrap";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EnquiryForm from "../../components/common/EnquiryForm";

const Enquiry = () => {

    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "Enquiry", path: "/enquiry", active: true, linkClass: "text-primary fw-semibold" }
    ]
    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/about_us_banner.jpg`;
    return (
        <>
            <Container fluid className="top-banner-small-fit mt-60" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col md={12} className="text-center my-5 py-4">
                        <h1 className="text-light mb-0">Enquiry</h1>
                    </Col>
                </Container>
            </Container>
            <Breadcrumbs breadcrumbData={breadCrumbList} />

            <EnquiryForm/>
        </>
    );
}

export default Enquiry;
