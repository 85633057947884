import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Card, Row, Form, Button } from "react-bootstrap";
// import InputGroupField from "../../../components/form/"

const CmsForm = () => {
    return (
        <div className="App mt-5">
            <Container className="pt-5">
                <Row className="my-5">
                    <div className="text-primary text-center pb-4">
                        <h2>CMS</h2>
                    </div>
                    <div className="mb-3">
                        <Form.Label htmlFor="basic-url">Title</Form.Label>
                        <Form.Control
                            placeholder="Enter title"
                            name="title"
                            aria-label="Title"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    <div className="mb-3">
                        <Form.Label htmlFor="basic-url">Name</Form.Label>
                        <Form.Control
                            placeholder="Enter name"
                            aria-label="Name"
                            name="name"
                            aria-describedby="basic-addon1"
                        />
                    </div>
                    <div className="mb-3">
                        <Form.Label htmlFor="basic-url">Content</Form.Label>
                        <Form.Control
                            placeholder="Enter content"
                            aria-label="Content"
                            name="content"
                            aria-describedby="basic-addon1"
                            as="textarea"
                            rows={3}
                        />
                    </div>
                    <Button variant="primary" type="submit" >
                        Submit <FontAwesomeIcon icon={faAngleRight} />
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

export default CmsForm;