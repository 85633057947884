import Modal from 'react-bootstrap/Modal';
import Location from './Location';
import LocationSearch from './LocationSearch';
import React, { useState, useEffect, useContext } from 'react';
import { encryptLocalStorage } from '../../../config/utils/storage';
import HTTP from '../../../config/axios';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { ClientContext } from '../../../config/context/clientContext';
import { setSelectedLocation } from '../../../config/utils/helper';
import LocationSearchModal from './LocationSearchModal';

const LocationModal = ({ isOpen, closeModal }: { isOpen: any, closeModal: any }) => {

    const [searchName, setSearchName] = useState(null)
    const [locationArray, setLocationArray] = useState([])
    const [locationOptions, setLocationOptions] = useState([])
    const [locationLabel, setLocationLabel] = useState<{[key:string]: string}>({})
    const { locationContext, setLocationContext, locationOptContext, setLocationOptContext }: any = useContext(ClientContext);


    useEffect(() => {
        if (!isOpen) setSearchName(null) // Remove search value when modal closes
    }, [isOpen])


    useEffect(() => {

        // used to set location dropdown options when location context is empty 
        const getAllLocation = async () => {
            const locationData = await HTTP.get(`/api/location/fetch-all`, { params: { status: 1 } });
            const { data: { status, data, msg, error } } = locationData

            if (status == 1) {
                const selectedOptions:any = [] ; // to fill multi-select with already selected locations
                const storedLocations:any = encryptLocalStorage.getItem('selectedLocation')

                setLocationArray(data)
                const options = data.map((option:any, index: number) => {

                    if(storedLocations?.length > 0 && locationOptContext.length == 0){
          
                      if(storedLocations.includes(option.keyName)){  
                        selectedOptions.push({value: option.keyName, label: option.name})
                      }
                      if(data.length == index+1){ // add to location option context in last iteration
                        setLocationOptContext(selectedOptions)
                      }
                    }
                    return {value: option.keyName, label: option.name}
                  })
                options.unshift({value: '', label: 'All'})
                setLocationOptions(options)
            } else {
                setLocationArray([])
            }

            const labels: {[key:string]: string} = {}

            data.forEach((location: any) => {
                labels[location.keyName] = location.name
            })

            setLocationLabel(labels)
        }
        getAllLocation()
    }, []);

    const hasSelectedLocation = encryptLocalStorage.getItem("selectedLocation") ? true : false
    // console.log(encryptLocalStorage.getItem("selectedLocation"), "-------location in localstorage")
    const LocationList = () => {

        const removeLocation = (locationToRemove: any) => {
            if(locationToRemove !=""){ //Checking the remove option is 'All'
                const updatedLocations: string[] = locationContext.filter((location: any) => {
                    if(location !== locationToRemove && location.value !=='') {
                        return location
                    }
                });
                setLocationContext(updatedLocations)
                setLocationOptContext(locationOptContext.filter((location: any) => {
                    if(location.value !== locationToRemove && location.value!=='') {
                        return location
                    }
                }))
                setSelectedLocation(updatedLocations);
            } else{
                setLocationContext([])
                setLocationOptContext([])
                setSelectedLocation([]);
            }

        };
        return <Row className="flex-nowrap overflow-auto">
            {
                locationContext && locationContext.map((option: any, index: number) => {
                    return <div className="p-2 w-auto m-2 shadow-sm" key={index}>
                        <span className="me-auto">{locationLabel[option] || 'All'}</span>
                        <FontAwesomeIcon icon={faX} className="text-gray fs-7 ms-2 link-pointer" onClick={() => removeLocation(option)} />
                    </div>
                })
            }
        </Row>

    }




    return (
        <>
            <Modal
                show={isOpen}
                onHide={() => closeModal(false)}
                backdrop={true} //  modal will close when clicking outside it
                dialogClassName="modal-95w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton={true} className='pb-0'>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <LocationSearchModal setSearchName={setSearchName} locationOptions={locationOptions} close={closeModal} />

                    <div className="d-flex justify-content-between horizontal-scrollable" >
                        {locationContext.length > 0 && <LocationList />}
                    </div>
                    <Location searchName={searchName} showAll={true} close={closeModal} fromModal={true}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LocationModal;