
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import { ComparisonContext } from "../../../../config/context/comparisonContext";
import { useContext } from "react";




const CompareFeaturesMob = () => {

    const { compareDetails, setCompareDetails } = useContext(ComparisonContext);

    const carData = compareDetails.first || compareDetails.second || compareDetails.third || compareDetails.fourth
    const featureList = carData?.features
    const firstCarFeatures = compareDetails?.first?.features
    const secondCarFeatures = compareDetails?.second?.features


    return (
        <div className="App">
             
                {featureList && Object.keys(featureList).map((feature: any, index:number) => (
                    <Row className="border-bottom px-0 text-break" key={index}>
                        <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                            <p className="text-start mt-2">{featureList[feature].name}</p>
                        </Col>
                        <Col md={2} className="comparsion-img-mob-out text-start border-end">
                            <p className="text-start  fs-4 mt-2"> 
                                {firstCarFeatures?.[feature].value || '-'}
                            </p>
                        </Col>
                        <Col md={2} className="comparsion-img-mob-out text-start ">
                            <p className="text-start  fs-4 mt-2"> 
                                {secondCarFeatures?.[feature].value || '-'}
                            </p>
                        </Col>
                    </Row>
                ))}
        </div >
    );
};

export default CompareFeaturesMob;
