import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Faq from "../../../components/common/Faq";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
import ContactParkAndSell from "../../../components/common/ContactParkAndSell";
import { useEffect, useState } from "react";
import HTTP from "../../../config/axios";

const ParkAndSell = () => {

    const [faqContent, setFaqcontent] = useState('')
    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "Park and Sell", path: "/park-and-sell", active: true, linkClass: "text-primary fw-semibold" }
    ]
  
    useEffect(() => {
  
      const getCmsContent = async () => {
        let sellCarFaq = "park-and-sell-short-faq"
        const response = await HTTP.get(`/api/cms/fetch-one/${sellCarFaq}`);
        const { data: { status, data, msg, error } } = response
        setFaqcontent(data)
      }
      getCmsContent()
  
    }, []);
    return (

        <div className="App">
            <Container fluid className="hero-section who_are_we_banner d-flex">
                <Container className="my-auto py-5 my-5">
                    <Col lg={7} md={12} sm={12} className="py-5 my-5">

                    </Col>
                </Container>
            </Container>
            <Container className="py-3">
                <Breadcrumbs breadcrumbData={breadCrumbList} />
                <Row className="mt-4 ">
                    <Col md={12}>
                        <div className="text-center pb-4">
                            <h2 className="text-primary head-bold">
                                PARK AND SELL
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4 shadow  mx-2 border rounded-15 p-3 h-100">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/bring-car.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col
                        lg={4}
                        md={6}
                        sm={7}
                        xs={7}
                        className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center"
                    >
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Just bring your car to our nearest outlet.</h3>
                            <div className="d-none d-d-md-block d-lg-block pt-3">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-4">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/01.svg`} />
                    </Col>
                </Row>
                <Row className="mb-4  shadow  mx-2 border rounded-15 p-3 h-100">
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block text-end">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/02.svg`} />
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-4">
                            <hr />
                        </div>
                    </Col>
                    <Col
                        lg={4}
                        md={6}
                        sm={7}
                        xs={7}
                        className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center"
                    >
                        <div className="text-lg-center text-md-end text-sm-end">
                            <h3 className="text-dark fw-normal fs-5">We will help you discover the best selling price for your car</h3>
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/selling-price.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4  shadow  mx-2 border rounded-15 p-3 h-100">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/upload_documents.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col
                        lg={4}
                        md={6}
                        sm={7}
                        xs={7}
                        className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center"
                    >
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">We will help you with necessary documentation.</h3>
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-4">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/03.svg`} />
                    </Col>
                </Row>
                <Row className="mb-4  shadow  mx-2 border rounded-15 p-3 h-100">
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block text-end">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/04.svg`} />
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-4">
                            <hr />
                        </div>
                    </Col>
                    <Col
                        lg={4}
                        md={6}
                        sm={7}
                        xs={7}
                        className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center"
                    >
                        <div className="text-lg-center text-md-end text-sm-end">
                            <h3 className="text-dark fw-normal fs-5">We will clean it up for display.</h3>
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/assured_buyback.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4  shadow  mx-2 border rounded-15 p-3 h-100">
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/test-drive.svg`} width={"80%"} />
                        </div>
                    </Col>
                    <Col
                        lg={4}
                        md={6}
                        sm={7}
                        xs={7}
                        className="pt-lg-5 pt-sm-0 pt-xs-0 align-self-center"
                    >
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">Our Experts will showcase it to potential buyers.</h3>
                            <div className="d-none d-d-md-block d-lg-block">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/arrow.svg`} width={"5%"} />
                            </div>
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-4">
                            <hr />
                        </div>
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/05.svg`} />
                    </Col>
                </Row>

                <Row className="mb-4  shadow  mx-2 border rounded-15 p-3 h-100">
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block text-end">
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/06.svg`} />
                    </Col>
                    <Col md={2} className="pt-5 d-none d-d-md-block d-lg-block">
                        <div className="pt-4">
                            <hr />
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={7} xs={7}
                        className="mb-lg-5 pb-lg-5 pt-sm-0 pt-xs-0 align-self-center"
                    >
                        <div className="text-lg-center text-md-start text-sm-start">
                            <h3 className="text-dark fw-normal fs-5">When we sell it, we pay you online. No mediators, No hassle.</h3>
                        </div>
                    </Col>
                    <Col lg={4} md={6} sm={5} xs={5}>
                        <div className="text-center">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/complete-payment.svg`} width={"80%"} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <ContactParkAndSell />
            {faqContent && <Faq cmsContent={faqContent} cmsType="park-and-sell-full-faq" />}
        </div>
    );
}


export default ParkAndSell;