import React, { useEffect, useState } from 'react';
import SpecificationAddEditModal from '../../../components/admin/specification/SpecificationAddEditModal';
import SpecificationTable from '../../../components/admin/specification/SpecificationDataTable';

const ListSpecification: React.FC = () => {
    const [show, setShow] = useState(false);
    const [addEditCount, setAddEditCount] = useState(0)
    const [specificationDetails, setSpecificationDetails] = useState({ id: 0, name: '', keyName: '', unit: '', sort: 0, type: '', options:'', fieldType:'' ,basic:''});
    const handleClose = () => setShow(false);

    const editModal = (specification: any) => {
        setSpecificationDetails(specification)
        setShow(true)
    }

    const newSpecification = () => {
        setShow(true);
        setSpecificationDetails({ id: 0, name: '', keyName: '', unit: '', sort: 0, type: '',options:'', fieldType:'',basic:'' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <SpecificationTable newSpecification={newSpecification} editModalDetails={editModal} addEditCount={addEditCount} />
                            <SpecificationAddEditModal show={show} handleClose={handleClose} specificationDetails={specificationDetails} setAddEditCount={setAddEditCount} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListSpecification;