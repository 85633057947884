import { useEffect, useState } from "react";
import HTTP from "../../config/axios";
import { Container } from "react-bootstrap";
const SellCar = () => {
    const [sellCarBannerCms, setSellCarBannerCms] = useState<any>({});
    useEffect(() => {
        const getCmsContent = async () => {
            let SellCarBanner = "sell-car-banner"
            const response = await HTTP.get(`/api/cms/fetch-one/${SellCarBanner}`);
            const { data: { status, data, msg, error } } = response
            setSellCarBannerCms(data)
        }
        getCmsContent()
    }, [])

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/sell_car_banner.jpg`;

    return (
        <Container fluid className="top-banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
            <Container className="my-auto px-0">
                <div dangerouslySetInnerHTML={{ __html: sellCarBannerCms && sellCarBannerCms.content }}></div>
            </Container>
        </Container>
    );

};

export default SellCar;
