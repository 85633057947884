import { Row, Col, Container } from "react-bootstrap";
import CarBooking from "../../../components/modules/Booking/CarBooking";
import { useState, useEffect } from 'react';
import HTTP from '../../../config/axios';
import CarGridList from '../../../components/modules/Vehicle/CarGridList';
import { encryptLocalStorage } from '../../../config/utils/storage';
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumbs";
const Favourites = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [favourites, setFavourites] = useState([])
    const [favouritesFetched, setFavouritesFetched] = useState(false);

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            setIsLoggedIn(true);
            setFavouritesFetched(true)
        }
    }, []);

    useEffect(() => {
        const getFavourites = async () => {
            if (isLoggedIn) {
                const response = await HTTP.get(`/api/vehicle/favourites/fetch-all`);
                const { data: { status, data, msg, error } } = response
                setFavourites(data)
                setFavouritesFetched(true)
            }
        }
        getFavourites()

    }, [favouritesFetched]);

    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "My Favourites", path: "/my-favourites", active: true, linkClass: "text-primary fw-semibold" }
    ]

    return (
        <div className="App">

            <Container >
                <Row className="pb-4 mt-120" >
                    <Breadcrumbs breadcrumbData={breadCrumbList} />
                    <Col>
                        <div className="text-center pb-4">
                            <h2 className="text-primary head-bold">SHORTLISTED CARS</h2>
                        </div>
                    </Col>
                </Row>
                <Row className="me-0 mb-20">
                    <Col md={12}>
                        {favourites.length !== 0 ? (<CarGridList carList={favourites} favourites={true} />) :
                            (<p className="fs-8 mt-5 text-center text-dark">No car is added to favourites list</p>)
                        }
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default Favourites;