import { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Tab, Image, Form, Tabs } from "react-bootstrap";
import HTTP from "../../../config/axios";
import { getFormatEEEDoMMMyyyy, getFormatTimehhmmaa } from "../../../config/utils/dateFunctions"
import { convertStringToSlug } from "../../../config/utils/helper";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { useNavigate } from "react-router-dom";
import CustomPopover from "../../common/CustomPopover";

const TestDriveList = ({ isMobile }: { isMobile?: boolean }) => {

  const [testDrives, setTestDrives] = useState([]);
  const [currentTab, setCurrentTab] = useState("upcoming")
  const navigate = useNavigate();


  useEffect(() => {
    const handleTestDrive = async () => {
      const testDriveStatus = currentTab == "upcoming" ? 1 : 2;
      const response = await HTTP.get(`/api/vehicle/test-drive/fetch-all/${testDriveStatus}`);
      const { data: { status, data, msg, error } } = response
      if (status == 1) {
        setTestDrives(data)
      } else {
        setTestDrives([])
      }
    }
    handleTestDrive()
  }, [currentTab]);

  const getDetailPage = (detail: any) => {
    const carSlugName = `${detail.year} ${detail.make} ${detail.model} ${detail.variant}`;
    const slugCarName = convertStringToSlug(carSlugName);
    const selectedLocation = encryptLocalStorage.getItem("selectedLocation");
    navigate(`/buy-used-car/${slugCarName}/${detail.vehicleId}/?search-criteria={}`);
  }

  const getNeedHelp = () => {

  }


  return (
    <Tab.Pane eventKey="sideTab_1" className="profile-nav-scroll">
      {!isMobile ? (
        <Row>
          <h2 className="text-dark is-testimonials pb-3">
            Test Drive
          </h2>
          <Tabs defaultActiveKey={currentTab} id="uncontrolled-tab-example"
            onSelect={(selectedTab: any) => setCurrentTab(selectedTab)} >
            <Tab eventKey="upcoming" title="Upcoming">
              <Row className="pt-4">
                {testDrives.length == 0 && <p className="text-dark mb-0">
                  No records found
                </p>}
                {testDrives && testDrives.map((testDriveData: any, index: number) => (
                  <Row key={index} className="pb-3" onClick={() => getDetailPage(testDriveData)} style={{ cursor: 'pointer' }}>
                    <Col xl={2} lg={3} md={4} sm={12} >
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${testDriveData.image}`}
                        className="rounded-circle"
                        height={"120px"}
                        width={"120px"}
                      />
                    </Col>
                    <Col lg={9} md={8} sm={12} xs={12} className="align-self-center ps-4" >
                      <div className="d-flex justify-content-between">
                        <h3 className="text-dark mb-0" >
                          {`${testDriveData.year} ${testDriveData.make} ${testDriveData.model} ${testDriveData.variant}`}
                        </h3>
                        <CustomPopover placement="top"
                          body="<p>  Contact us at:</p>
                    <strong class='text-primary'>1800 123 8090</strong><br />
                   <strong class='text-primary'>+91 73061 12802</strong><br />
                    Or message us on WhatsApp!"

                          component={<p className="text-primary mb-0"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the click from bubbling up to the parent
                            }}> Need&nbsp;help?</p>} />
                      </div>
                      <p className="mb-0">{getFormatEEEDoMMMyyyy(testDriveData.testDriveDate)}</p>
                      <h3 className="text-primary is-testimonials">
                        {getFormatTimehhmmaa(testDriveData.testDriveDate)}
                      </h3>

                    </Col>
                    {testDrives.length >= 2 && <hr className="mt-4" />}
                  </Row>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="completed" title="Completed">
              <Row className="pt-4">
                {testDrives.length == 0 && <p className="text-dark mb-0">
                  No "Test Drive" records found
                </p>}
                {testDrives && testDrives.map((testDriveData: any, index: number) => (
                  <Row key={index} className="pb-3" onClick={() => getDetailPage(testDriveData)} style={{ cursor: 'pointer' }}>
                    <Col xl={2} lg={3} md={4} sm={12} >
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${testDriveData.image}`}
                        className="rounded-circle"
                        height={"120px"}
                        width={"120px"}
                      />
                    </Col>
                    <Col lg={9} md={8} sm={12} xs={12} className="align-self-center ps-4" >
                      <div className="d-flex justify-content-between">
                        <h3 className="text-dark mb-0">
                          {`${testDriveData.year} ${testDriveData.make} ${testDriveData.model} ${testDriveData.variant}`}
                        </h3>
                        <CustomPopover placement="top"
                          body="<p>  Contact us at:</p>
                    <strong class='text-primary'>1800 123 8090</strong><br />
                   <strong class='text-primary'>+91 73061 12802</strong><br />
                    Or message us on WhatsApp!"

                          component={<p className="text-primary mb-0"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the click from bubbling up to the parent
                            }}> Need&nbsp;help?</p>} />
                      </div>
                      <p className="mb-0">{getFormatEEEDoMMMyyyy(testDriveData.testDriveDate)} </p>
                      <h3 className="text-primary is-testimonials">
                        {getFormatTimehhmmaa(testDriveData.testDriveDate)}
                      </h3>
                    </Col>
                    {testDrives.length >= 2 && <hr className="mt-4" />}
                  </Row>
                ))}
              </Row>
            </Tab>
          </Tabs>
        </Row>) : (
        <Row>
          <h2 className="text-dark is-testimonials pb-3">
            Test Drive
          </h2>
          <Tabs defaultActiveKey={currentTab} id="uncontrolled-tab-example"
            onSelect={(selectedTab: any) => setCurrentTab(selectedTab)} >
            <Tab eventKey="upcoming" title="Upcoming">
              <Row className="pt-4">
                {testDrives.length == 0 && <p className="text-dark mb-0">
                  No records found
                </p>}
                {testDrives && testDrives.map((testDriveData: any, index: number) => (
                  <Row
                    key={index}
                    className="pb-3"
                    onClick={() => getDetailPage(testDriveData)}
                    style={{ cursor: "pointer" }}
                  >
                    <Col
                      sm={4}
                      xs={4}
                      col={4}
                      className="d-flex align-items-center"
                    >
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${testDriveData.image}`}
                        className="rounded-circle object-fit-cover"
                        height={"90px"}
                        width={"90px"}
                      />
                    </Col>
                    <Col sm={8} xs={8} col={8} className="px-0">
                      <p className="text-dark mb-0 fw-semibold">
                        {`${testDriveData.year} ${testDriveData.make} ${testDriveData.model} ${testDriveData.variant}`}
                      </p>
                      <small className="mb-0 fs-6">
                        {getFormatEEEDoMMMyyyy(testDriveData.testDriveDate)}{" "}
                      </small>
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-primary mb-0">
                          {getFormatTimehhmmaa(testDriveData.testDriveDate)}
                        </h3>
                        <p
                          className="text-primary mb-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            getNeedHelp();
                          }}
                        >
                          Need&nbsp;help?
                        </p>
                      </div>
                    </Col>
                    {testDrives.length >= 2 && <hr className="mt-4" />}
                  </Row>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="completed" title="Completed">
              <Row className="pt-4">
                {testDrives.length == 0 && <p className="text-dark mb-0">
                  No "Test Drive" records found
                </p>}
                {testDrives && testDrives.map((testDriveData: any, index: number) => (
                  <Row
                    key={index}
                    className="pb-3 "
                    onClick={() => getDetailPage(testDriveData)}
                    style={{ cursor: "pointer" }}
                  >
                    <Col
                      sm={4}
                      xs={4}
                      col={4}
                      className="d-flex align-items-center"
                    >
                      <Image
                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}${testDriveData.image}`}
                        className="rounded-circle object-fit-cover"
                        height={"90px"}
                        width={"90px"}
                      />
                    </Col>
                    <Col sm={8} xs={8} col={8} className="px-0">
                      <p className="text-dark mb-0 fw-semibold">
                        {`${testDriveData.year} ${testDriveData.make} ${testDriveData.model} ${testDriveData.variant}`}
                      </p>
                      <small className="mb-0 fs-6">
                        {getFormatEEEDoMMMyyyy(testDriveData.testDriveDate)}{" "}
                      </small>
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-primary mb-0">
                          {getFormatTimehhmmaa(testDriveData.testDriveDate)}
                        </h3>
                        <p
                          className="text-primary mb-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            getNeedHelp();
                          }}
                        >
                          Need&nbsp;help?
                        </p>
                      </div>
                    </Col>
                    {testDrives.length >= 2 && <hr className="mt-4" />}
                  </Row>
                ))}
              </Row>
            </Tab>
          </Tabs>
        </Row>
      )}
    </Tab.Pane >
  )
}


export default TestDriveList