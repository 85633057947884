import React from "react";
import { Alert, Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface ITextAreaField {
    name: string;
    label: string;
    placeholder?: string;
    control: Control;
    classList?: string;
    rule?: any;
    minLength?: number;
    maxLength?: number;
    clearErrors?: Function;
    rows?: number;
    errText?: string;
    defaultValue?: string;
}

const InputTextAreaField: React.FC<ITextAreaField> = ({
    name,
    label,
    placeholder,
    control,
    classList,
    rule,
    minLength,
    maxLength,
    clearErrors,
    rows,
    defaultValue,
    errText = ''
}) => {

    const handleError = () => {
        if (clearErrors) clearErrors();
    }


    if (rule?.required && typeof rule.required == 'function') {
        rule.required = rule.required(label)
    }

    return (<>

        <div className={classList}>
            <Controller
                name={name}
                control={control}
                rules={rule}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <>
                        {label &&
                            <Form.Label htmlFor={`${name}-id`} className="text-dark fw-semibold fs-6">
                                {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                            </Form.Label>}
                        <Form.Control as="textarea" id={`${name}-id`}
                            {...(rows ? { rows } : {})}
                            value={value || ''}
                            placeholder={placeholder}
                            {...(defaultValue ? { defaultValue } : {})}
                             {...(!isNaN(minLength as number) ? { minLength } : {})}
                            {...(!isNaN(maxLength as number) ? { maxLength } : {})}
                            onChange={(e) => { handleError(); onChange(e.target.value) }} />
                        {error?.message &&
                            <Form.Text className="text-danger">
                                {/* Show custom error if present and required message is not triggeres */}
                                {error.type != 'required' && errText ? errText : error.message}
                            </Form.Text>}
                    </>)
                }
            />

        </div>


    </>)
}


export default InputTextAreaField