import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, Button } from "react-bootstrap";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { calculateEmi, convertStringToSlug, handleAddToCompare, parsePriceToFloat, trimContent } from "../../config/utils/helper";
import { encryptLocalStorage } from "../../config/utils/storage";
import SimpleToast from "./Toast";
import { useContext, useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import TestDrive from "../../pages/module/vehicle/TestDrive";
import Badge from "./Badge";
import { VehicleSaleStatus } from "../../config/utils/helper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HTTP from "../../config/axios";
import { ClientContext } from "../../config/context/clientContext"
import { faHeart as solidHeart, faMapMarkerAlt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';

const CarCard = ({ detail, badge, compare, favourites, isCarousel, fromSection }: { detail: any, badge?: string, compare?: boolean, favourites?: boolean, isCarousel?: boolean, fromSection?: string }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();
  const [showTestDriveModal, setShowTestDriveModal] = useState(false)
  const fullCarName = `${detail.year || ''} ${detail.make} ${detail.model}`
  const carSlugName = `${detail.year || ''} ${detail.make} ${detail.model} ${detail.variant}`
  const shortCarName = fullCarName.length > 25 ? `${fullCarName.substring(0, 25)}...` : fullCarName;
  const [badgeImage, setBadgeImage] = useState(badge)
  const { VEHICLE_SOLD_STATUS, VEHICLE_BOOKED_STATUS } = VehicleSaleStatus
  const [isMobile, setIsMobile] = useState(false)
  const { toast, setToast }: any = useContext(ClientContext);
  const [carImagesArray, setCarImagesArray] = useState<any>([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 565);
  }, []);
  useEffect(() => {
    setIsTablet(window.innerWidth <= 820);
  }, []);

  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    const getCarImages = async () => {
      const response = await HTTP.get(`/api/vehicle/thumbnail-images/${detail.id}`);
      const { data: { status, data, msg, error } } = response
      if (status == 1) {
        setCarImagesArray(data)
      } else {
        setCarImagesArray([])
      }
    }
    getCarImages()
  }, [detail.id]);

  const slugCarName = convertStringToSlug(carSlugName)

  const formattedPrice = (price: any) => {
    let priceFormatted = "";
    let str = ""
    if (price < 1000) {
      priceFormatted = price
    } else if (price >= 1000 && price < 100000) {
      str = parsePriceToFloat(price, 1000, 2)
      priceFormatted = str + " K";
    } else if (price >= 100000 && price < 10000000) {
      str = parsePriceToFloat(price, 100000, 2)
      priceFormatted = str + " Lakhs"
    } else {
      str = parsePriceToFloat(price, 10000000, 2)
      priceFormatted = str + " Crore";
    }
    return priceFormatted;
  };

  const getDetailPage = () => {

    const searchCriteria = searchParams.get('search-criteria')
    const searchUrlPart = searchCriteria ? `?search-criteria=${searchCriteria}` : ``
    navigate(`/buy-used-car/${slugCarName}/${detail.id}/${searchUrlPart}`, { state: { position: location?.state?.position } });
  };

  const verifyAndCompare = (id: string) => {
    const compareList = encryptLocalStorage.getItem("compareCar");
    if ((compareList?.[0]?.id == id) || (compareList?.[1]?.id == id) ||
      (compareList?.[2]?.id == id) || (compareList?.[3]?.id == id)) {
      setToast({ open: true, header: 'Failed', message: 'Car is already in compare list', variant: 'danger' })
    } else {
      handleAddToCompare(id, slugCarName, navigate, location?.state?.position, isMobile)
    }
  }

  const setBadgeForImage = (saleStatus: number) => {
    //add 2nd param as assuredStatus to implement assured badge with these 2 badges
    if (saleStatus == VEHICLE_SOLD_STATUS) {
      return ("sold")
    } else if (saleStatus == VEHICLE_BOOKED_STATUS) {
      return ("booked")
      // } else if (assuredStatus == 1) {
      //   return ("assured")
    } else {
      return ("")
    }
  }

  const formattedKm = (km: number) => {
    if (km < 1000) {
      return km
    } else {
      return `${(km / 1000).toFixed(0)}K`;
    }
  };

  useEffect(() => {
    const getFavourite = async () => {
      const response = await HTTP.get(`/api/vehicle/fetch-single-favourite/${detail.id}`);
      const { data: { status, data, msg, error } } = response
      if (status == 1 && data.length != 0) {
        setIsFavorite(true)
      } else {
        setIsFavorite(false)
      }
    }
    isLoggedIn && getFavourite()
  }, [isLoggedIn, window.location.pathname]);

  const handleNotFavorite = async () => {
    if (isLoggedIn) {
      try {
        const response = await HTTP.post(`/api/vehicle/remove-favourites`, { vehicleId: detail.id });
        const { data: { status, data, msg, error } } = response
        if (status == 1) {
          setIsFavorite(false)
          setToast({ open: true, header: "Success", message: "Removed from favourites successfully", variant: "success" })
        }
      } catch (error) {
        setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
      }
    } else {
      // window.location.href = "/sign-in"
      encryptLocalStorage.setItem("previousLoggedoutLocation", location.pathname)
      navigate("/sign-in")
    }
  }
  const handleFavorite = async () => {
    if (isLoggedIn) {
      try {
        const response = await HTTP.post(`/api/vehicle/add-favourites`, { vehicleId: detail.id });
        const { data: { status, data, message, error } } = response
        if (status == 1) {
          setIsFavorite(true);
          setToast({ open: true, header: "Success", message: "Added to favourites successfully", variant: "success" })
        } else {
          setToast({ open: true, header: "Failed", message: message, variant: "danger" })
        }
      } catch (error) {
        setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
      }

    } else {
      // window.location.href = "/sign-in"
      encryptLocalStorage.setItem("previousLoggedoutLocation", location.pathname)
      navigate('/sign-in')
    }
  }

  return (
    <>
      {isMobile ?
        (
          <Card className="border rounded-15 ">
            <div onClick={() => getDetailPage()}>
              <Badge image={carImagesArray} badge={setBadgeForImage(detail.saleStatus)} isDealOfTheDay={detail.isDealOfTheDay} fromSection={fromSection} isMobile />
            </div>
            <Card.Body className="p-2" onClick={() => getDetailPage()}>
              <Card.Text as="div">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="mb-0 d-inline-block" >
                    <span className="text-dark" title={fullCarName}>{trimContent(fullCarName, 23).toUpperCase()}</span>
                  </h3>
                  <h3 className="text-primary text-end fw-bold fs-5 mb-0" >
                    {(detail.isDealOfTheDay == 1 && detail.dealPrice) ? <>₹{formattedPrice(detail.dealPrice)}</> : <>₹{formattedPrice(detail.price)}</>}

                  </h3>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="text-truncate mb-0 d-inline-block w-50" >
                    <span className="text-dark" title={detail.variant}>{trimContent(detail.variant, 25).toUpperCase()}</span>
                  </h3>
                  <span className="text-dark text-end fw-semibold fs-6 mb-0" >
                    EMI FROM ₹{calculateEmi(detail.isDealOfTheDay == 1 && detail.dealPrice ? detail.dealPrice : detail.price)}
                  </span>
                </div>

                <div className="d-flex align-items-center justify-content-between" >
                  <div className="d-flex">
                    <p className="mb-0  flex-shrink-0">
                      <span className="fs-7 fw-semibold text-secondary">{formattedKm(detail.km)} KM</span>
                    </p>
                    <span className="fw-bolder mx-1 text-secondary">.</span>
                    <p className="mb-0 flex-shrink-0">
                      <span className="fs-7 fw-semibold text-secondary">{detail.fuel.toUpperCase()}</span>
                    </p>
                    <span className="fw-bolder mx-1 text-secondary">.</span>
                    <p className="mb-0 flex-shrink-0">
                      {isTablet ? (<span className="fs-7 fw-semibold text-secondary" title={detail.transmission.toUpperCase()}>{trimContent(detail.transmission.toUpperCase(), 9)}</span>) : <span className="fs-7 fw-semibold text-secondary" title={detail.transmission.toUpperCase()}>{detail.transmission.toUpperCase()}</span>}
                    </p>
                  </div>
                  {detail.isDealOfTheDay == 1 && detail.dealPrice && <del className="fs-6  text-secondary">₹{formattedPrice(detail.price)}</del>}
                </div>
              </Card.Text>
            </Card.Body>

            <Card.Footer className="border-top bg-transparent d-flex justify-content-between">
              <div className="text-secondary small ">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <span className="ps-2" title={detail.outletName}>{trimContent(detail.outletName, 12)}</span>
              </div>

              {detail.assuredStatus == 1 && <div className="assured">
                <FontAwesomeIcon icon={faCheckCircle} className="pl-7" />
                <span className="pl-5">Assured</span>
              </div>}

              <div className="d-flex">
                <div className="pe-3">
                  {isFavorite ?
                    <FontAwesomeIcon icon={solidHeart} onClick={handleNotFavorite} className="text-primary" title="Remove from favourites" />
                    :
                    <FontAwesomeIcon icon={regularHeart} onClick={handleFavorite} title="Add to favourites" />}
                </div>
                {!compare && <div title="Add to compare">
                  <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/compare.svg`} width={"25px"} className="text-end"
                    onClick={e => {
                      e.stopPropagation(); verifyAndCompare(detail.id)
                    }}
                    style={{ cursor: "pointer" }} alt="" />
                </div>}
              </div>
            </Card.Footer>
          </Card>
        ) : (
          <Card
            className={isCarousel ? " rounded-4 mt-1 mb-4" : "rounded-4"} data-label="Popular Assured"
            style={{ cursor: "pointer" }}
          >
            <Row>
              <Col xs={4} sm={4} md={12} lg={12} onClick={() => getDetailPage()} >
                <Badge image={carImagesArray} badge={setBadgeForImage(detail.saleStatus)} isDealOfTheDay={detail.isDealOfTheDay} fromSection={fromSection} isAssured={detail.assuredStatus} />
              </Col>
              <Col xs={8} sm={8} md={12} lg={12}>
                <Card.Body className="" onClick={() => getDetailPage()}>
                  <Card.Text as="div">
                    <div className="d-flex ">
                      <h3 className="me-auto mb-0 d-inline-block text-truncate" >
                        <span className="text-dark" title={fullCarName}>{shortCarName.toUpperCase()}</span>
                      </h3>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="me-auto">
                        <h3 className="mb-0">
                          <span className="text-dark" title={detail.variant}>{trimContent(detail.variant, 12).toUpperCase()}</span>
                        </h3>

                      </div>
                      {detail.assuredStatus == 1 && <div className="assured">
                        <FontAwesomeIcon icon={faCheckCircle} className="pl-7" />
                        <span className="pl-5">Assured</span>
                      </div>}
                    </div>


                    <div className="d-flex" >
                      <p className="mb-0  flex-shrink-0">
                        <span className="fs-7 fw-semibold text-secondary">{formattedKm(detail.km)} KM</span>
                      </p>
                      <span className="fw-bolder mx-1 text-secondary">.</span>
                      <p className="mb-0 flex-shrink-0">
                        <span className="fs-7 fw-semibold text-secondary">{detail.fuel.toUpperCase()}</span>
                      </p>
                      <span className="fw-bolder mx-1 text-secondary">.</span>
                      <p className="mb-0 flex-shrink-0">
                        {isTablet ? (<span className="fs-7 fw-semibold text-secondary" title={detail.transmission.toUpperCase()}>{trimContent(detail.transmission.toUpperCase(), 9)}</span>) : <span className="fs-7 fw-semibold text-secondary" title={detail.transmission.toUpperCase()}>{trimContent(detail.transmission.toUpperCase(), 6)}</span>}
                      </p>
                    </div>
                    <h3 className="text-primary fw-bold fs-5 mb-0" >
                      {(detail.isDealOfTheDay == 1 && detail.dealPrice) ? <>₹{formattedPrice(detail.dealPrice)}</> : <>₹{formattedPrice(detail.price)}</>}
                      {detail.isDealOfTheDay == 1 && detail.dealPrice && <del className="fs-6 p-2 text-secondary">₹{formattedPrice(detail.price)}</del>}
                    </h3>
                    <span className="text-drak fw-semibold is-description"> EMI FROM ₹{calculateEmi(detail.isDealOfTheDay == 1 && detail.dealPrice ? detail.dealPrice : detail.price)}</span>
                  </Card.Text>
                </Card.Body>

                {/* <Card.Footer className="border-top bg-transparent d-flex">
                  <div className="text-secondary small flex-grow-1">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      <span className="ps-2" title={detail.outletName}>{trimContent(detail.outletName, 12)}</span>
                  </div>
                  <div className="pe-3">
                  { isFavorite ? 
                  <FontAwesomeIcon icon={solidHeart} onClick={handleNotFavorite} className="text-primary" title="Remove from favourites" />
                                             : 
                                             <FontAwesomeIcon icon={regularHeart} onClick={handleFavorite} title="Add to favourites" /> }
                  </div>
                  {!compare && <div title="Add to compare">
                      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/compare.svg`} width={"25px"} className="text-end"
                        onClick={e => {
                          e.stopPropagation(); verifyAndCompare(detail.id)
                        }}
                        style={{ cursor: "pointer" }} alt="" />
                    </div>}
              </Card.Footer> */}

                <Card.Footer className="border-top bg-transparent d-flex justify-content-between text-truncate">
                  <div className="text-secondary small ">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <span className="ps-2" title={detail.outletName}>{trimContent(detail.outletName, 10)}</span>
                  </div>



                  <div className="d-flex">
                    <div className="pe-3">
                      {isFavorite ?
                        <FontAwesomeIcon icon={solidHeart} onClick={handleNotFavorite} className="text-primary" title="Remove from favourites" />
                        :
                        <FontAwesomeIcon icon={regularHeart} onClick={handleFavorite} title="Add to favourites" />}
                    </div>
                    {!compare && <div title="Add to compare">
                      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/compare.svg`} width={"25px"} className="text-end"
                        onClick={e => {
                          e.stopPropagation(); verifyAndCompare(detail.id)
                        }}
                        style={{ cursor: "pointer" }} alt="" />
                    </div>}
                  </div>
                </Card.Footer>

              </Col>
            </Row>
          </Card >)}
      <CustomModal isOpen={showTestDriveModal} title="Test Drive"
        component={<TestDrive vehicleId={detail.id as string}
          setShowTestDriveModal={setShowTestDriveModal}
          setToast={setToast} />}
        closeModal={setShowTestDriveModal} />
    </>
  );
};

export default CarCard;