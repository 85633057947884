
import React, { useEffect, useState } from 'react';

import FeaturesAddEditModal from '../../../components/admin/features/FeaturesAddEditModal'
import EnquiryTable from '../../../components/admin/enquiries/EnquiriesDataTable';

const ListEnquiries: React.FC = () => {

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <EnquiryTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListEnquiries;