import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { dateIsToday, dateTimeDistance, getLongFormatOfDateAndTime, getParsedDate } from '../../../config/utils/dateFunctions';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from "../../common/Spinner";
import MultiRadio from '../../form/MultiRadio';
import InputGroupField from '../../form/InputGroupField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSearch } from '@fortawesome/free-solid-svg-icons';
import ImageCellForDataTable from '../../common/ImageCellForDataTable';
import { downloadFileFromApi, priceFormat } from '../../../config/utils/helper';


const  TestDriveDataTable : React.FC<any> = () => {

    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const { control, watch, getValues } = useForm<Record<string, any>>({ defaultValues: { search: "", contactStatus: "All" } });
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [testDrive, setTestDrive] = useState([]);
    const [updatedRow, setUpdatedRow] = useState(false);
    const searchText = watch('search');
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const testDriveStatus = watch("driveStatus")
    const [checkedStatus, setCheckedStatus] = useState<{ [key: number]: boolean }>({});

    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };
    
    const testDriveStatuses = [
        // { label: 'All', value: 'All' },
        { label: 'Not Completed', value: '1' },
        { label: 'Completed', value: '2' },
    ]


    const column: any = [
        {
            name: 'Name',
            selector: (row: { firstName: string; lastName:string }) => `${row.firstName} ${row.lastName}`,
            sortable: true,
            keyName: 'authUser.userProfile.first_name',
        }, 
        {
            name: 'Contact no',
            selector: (row: { phone: string; }) => row.phone,
            keyName: 'mobileNo',
        }, {
            name: 'Vehicle',
            selector: (row: { makeName:string, modelName:string, variantName:string }) => {return(`${row.makeName} ${row.modelName} ${row.variantName}`)},
            keyName: 'makeName',
            wrap: true,
        },{
            name: 'Registration',
            selector: (row: {registration:string}) => row.registration
        },
        {
            name: 'Outlet',
            selector: (row: {outletName:string}) => row.outletName
        },
        {
            name: 'Dealership',
            selector: (row: {dealershipName:string}) => row.dealershipName
        },

        {
            name: <div>Scheduled At</div>,
            selector: (row: {dateTime:string}) => getLongFormatOfDateAndTime(row.dateTime),
            sortable: true,
            wrap: true,
            keyName:'dateTime'
        }
        ,{
            name: 'Updated At',
            sortable: true,
            wrap: true,
            keyName:'updatedAt',
            selector: (row: { updatedAt: string; }) => dateIsToday(row.updatedAt) ? dateTimeDistance(row.updatedAt)
                : getLongFormatOfDateAndTime(row.updatedAt),
        },
        {
            name: 'Complete Status',
            selector: (row: { id: number, status: number }) => <>
                <Form.Check className='ms-5' checked = {checkedStatus[row.id]} type='checkbox' id={row.id + 'completeStatus'}
                    onChange={(e) => {
                        statusChange(row.id, e.target.checked)
                    }}
                /> </>,
        },
        
    ]


    useEffect(() => {
        try {
            const getAllTestDrive = async () => {
                setIsLoading(true);
                const params = {
                    searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                    completeStatus:testDriveStatus
                };
                const response = await HTTP.get(`/api/admin/test-drive/fetch-all/${currentPage}/${perPage}`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                const updatedRowCheckedStatus: { [key: number]: boolean } = {};
                data.rows.forEach((row: any) => {
                    updatedRowCheckedStatus[row.id] = row.status === 2; // Assuming 2 means checked(test Drive Completed)
                    setCheckedStatus(updatedRowCheckedStatus)
                });
                setTestDrive(data.rows)
                setTotalRows(data.count)
            }
            getAllTestDrive()
        }
        catch (e) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsLoading(false)
        }

    }, [currentPage, perPage, searchText, sortDetails,testDriveStatus,updatedRow]);


    //Handle the test drive status change
    const statusChange = async (id: number, checked: boolean) => {
        try {

            setCheckedStatus((prevStatus) => ({
                ...prevStatus,
                [id]: checked,
            }));
            const params = { id: id, status: checked ? 2 : 1 };
            const response = await HTTP.post(`/api/admin/test-drive/updateStatus`, params);
            const { data: { status, message, error } } = response;
            if(status){
                setUpdatedRow(!updatedRow)
            }
            setToast({ open: true, header: status ? 'Success' : "Failed", message: status ? "Updated Successfully" : 'Something went wrong', variant: status ? 'success' : 'danger' })
        }

        catch (e) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
    }


    //Handling the page changes
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };


    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    //Function to download CSV
    const getAllTestDriveExcel = async () => {
        try {
            setIsLoading(true);
            const params = {
                searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                completeStatus:testDriveStatus
            };
            const response = await HTTP.get(`/api/admin/test-drive/fetch-all/excel`, { params, responseType: 'blob', });
            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            downloadFileFromApi(response.data, filename)
            setIsLoading(false);
        }
        catch (error) {
            console.log("Error in enquery download api", error);
            setIsLoading(false);
        }

    }

    return (<>
        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Test Drives</h2></div>
                <div className="p-2 mt-2 text-success" title="Download Excel Report">
                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={getAllTestDriveExcel} style={{ cursor: 'pointer' }} />
                </div>
                <div className="p-2 mt-2 d-flex " style={{ cursor: "pointer" }}>
                    <MultiRadio name="driveStatus" control={control}
                        options={testDriveStatuses} classList="mb-3 pt-0 px-2" selectedValue={watch("driveStatus")} />
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={column}
            data={testDrive}
            striped={true}
            //progressPending={isLoading}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
        <SimpleSpinner show={isLoading} />

    </>)
}

export default TestDriveDataTable