import React, { useState, useEffect } from 'react';
import HTTP from '../../../config/axios';
import InputTextAreaField from '../../form/InputTextAreaField';
import InputTextField from '../../form/InputTextField';
import DropDownField from '../../form/DropDownField';
import { useForm } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SimpleSpinner from '../../common/Spinner';
import SimpleToast from '../../common/Toast';
import { validateOnlySpaces } from '../../../config/validations/rules';



interface IVariantInfo {
  id: number;
  name: string;
  // keyName: string;
  modelId: number | null;
  makeId: number | null;
  modelName: string;
  [key: string]: number | string | null;
}

interface IVariantSpecification {
  setActiveTab: Function,
  vehicleVariantId: Number
  variantDetails: IVariantInfo;
  setSpecificationForm: Function;
  setFeatureForm: Function;
  assignedVehicleIds:number[]
}




const VariantSpecificationModal: React.FC<IVariantSpecification> = ({ setActiveTab, vehicleVariantId, variantDetails, setSpecificationForm, setFeatureForm, assignedVehicleIds }) => {
  const [specificationArray, setSpecificationArray] = useState([]);
  const { handleSubmit, control, clearErrors, reset } = useForm();
  const [isLoading, setIsLoading] = useState(true)
  const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
  const [specificationDetails, setSpecificationDetails] = useState({})
  const [basicDetails, setBasicDetails] = useState({});

  
  
  // TO GROUP THE FIELDS IN RESPECTIVE TYPE GIVEN
  const CUSTOM_GROUPINGS = [
    { id: 'group1', types: [1, 9], name: 'Dimensions & Tyre' },
    { id: 'group2', types: [2, 3], name: 'Engine & Transmission' },
    { id: 'group3', types: [7, 8], name: 'Performance & Fuel' },
    { id: 'group4', types: [4, 5, 6], name: 'Suspension, Steering & Brake' }
  ];





  useEffect(() => {
    try {
      setSpecificationForm(true)
      setFeatureForm(true)
      const getSpecificationData = async () => {
        const params = { basicData: false };
        const response = await HTTP.get('/api/admin/specification/all', { params });
        const { data: { status, data } } = response;
        if (status === 1) {
          setSpecificationArray(data);
        }
      };
      getSpecificationData();
    } catch (err) {
      console.log('error in specification  ')
    }
    finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getVariantSpecDetails(vehicleVariantId)   // calling the api to populate the saved values in the fields while adding a variant 
  }, [setSpecificationArray])



  const onSubmit = async (formData: any) => {

    try {
      const specifications: any = []
      const removedSpecification: any = []
      specificationArray.map((spec: any) => {
        formData[spec.keyName] && specifications.push({ id: spec.id, value: formData[spec.keyName] })
      })
      formData['variantSpecification'] = specifications

      Object.keys(specificationArray).forEach((index: any) => {
        const spec = specificationArray[index];
        if (!formData[spec['keyName']]) {
          removedSpecification.push(spec['keyName']);
        }
      });

      formData['removedSpecification'] = removedSpecification

      if (variantDetails.id) { 
        formData['variantId'] = variantDetails.id
        onEdit(formData)
      }
      else if (vehicleVariantId) {
        formData['variantId'] = vehicleVariantId
        onAdd(formData)
      }
    }
    catch (err) {
      console.log('Error', err)
    }
  };



  const onAdd = async (data: any) => {
    try {
      const response = await HTTP({
        method: 'post',
        url: `/api/admin/variant/addSpecification`,
        data: data,
      });
      console.log("resposne", response)
      const { status, msg, id } = response.data;

 
      getVariantSpecDetails(vehicleVariantId)  // calling the api to get the specifcation details while adding a variant for populating the values
      setSpecificationForm(true)
      setFeatureForm(true)

      if (status == 1) {
        setActiveTab('feature')
      }

      setToast({
        open: true,
        header: status ? 'Success' : 'Failed',
        message: msg ? 'Specification Added succesFully' : 'Something went wrong',
        variant: status ? 'success' : 'danger',
      });
    }
    catch (err) {
      console.log('Error', err)
    }

  }

  const onEdit = async (data: any) => {


    try {
      if (assignedVehicleIds.length > 0) {
        data.vehicleIds = assignedVehicleIds;
      }
      const response = await HTTP({
        method: 'post',
        url: `/api/admin/variant/addSpecification`,
        data: data,
      });
      const { status, msg, variantId: id } = response.data;
      if (status == 1) {
        setActiveTab('feature')
      }
      setToast({
        open: true,
        header: status ? 'Success' : 'Failed',
        message: msg ? 'Specification Updated succesFully' : 'Something went wrong',
        variant: status ? 'success' : 'danger',
      });
    }
    catch (err) {
      console.log('Error', err)
    }

  }

  useEffect(() => {
    if (Object.keys(basicDetails).length == 0) {
      getVariantSpecDetails(vehicleVariantId)
    }
    reset(basicDetails)

  }, [basicDetails])



  
  const getVariantSpecDetails = async (id: any) => {


    const response = await HTTP.get(`/api/admin/variant/getVariantSpecDetails/${id}`);
    const { data: { status, data, msg, error } } = response
    if (Object.keys(data.transformedDetails).length === 0) {
      setBasicDetails(id)
    } else {
      setBasicDetails(
        data.transformedDetails
      );
    }

  }

  const renderInputField = (data: any) => {
    switch (data.fieldType) {
      case 'number':
        return (
          <InputTextField
            label={data.name}
            name={data.keyName}
            type="number"
            control={control}
            min={0}
            clearErrors={clearErrors}
            placeholder={`Enter ${data.name}`}
            unit={data.unit}
          />
        );
      case 'text':
        return (
          <InputTextField
            label={data.name}
            name={data.keyName}
            type="text"
            control={control}
            clearErrors={clearErrors}
            placeholder={`Enter ${data.name}`}
            unit={data.unit}
            rule={{ validate: validateOnlySpaces }}
          />
        );
      case 'decimal':
        return (
          <InputTextField
            label={data.name}
            name={data.keyName}
            type="number"
            control={control}
            step="any"
            min={0}
            placeholder={`Enter ${data.name}`}
            unit={data.unit}
          />
        );
      case 'dropdown':
        const options = JSON.parse(data.options || '[]');
        return (
          <DropDownField
            label={data.name}
            name={data.keyName}
            options={options}
            control={control}
            placeholder={`Select ${data.name}`}
          />
        );
      default:
        return (
          <InputTextField
            label={data.name}
            name={data.keyName}
            type="number"
            control={control}
            min={0}
            clearErrors={clearErrors}
            placeholder={`Enter ${data.name}`}
            unit={data.unit}
          />
        );
    }
  };


  // Group specifications by type
  const groupedSpecifications = CUSTOM_GROUPINGS.reduce((acc: any, typeOption: any) => {
    acc[typeOption.id] = specificationArray.filter((spec: any) => typeOption.types.includes(spec.type));
    return acc;
  }, {});

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(groupedSpecifications).map((typeId: any) => {
          const specs = groupedSpecifications[typeId];
          if (specs.length === 0) return null;

          const typeName = CUSTOM_GROUPINGS.find((option: any) => option.id === (typeId))?.name;

          return (
            <div key={typeId}>
              <h4 className='text-primary mt-3 fw-semibold fs-18'>{typeName}</h4>

              <Row>
                {specs.map((spec: any) => (
                  <Col md={4} key={spec.id}>
                    {renderInputField(spec)}
                  </Col>
                ))}
              </Row>
            </div>
          );
        })}
        <Row>
          <Col md={10}></Col>
          <Col md={2}>
            {!isLoading ? <Button className="mt-3" variant="primary" type="submit">Save Changes</Button> : <></>}
          </Col>
        </Row>
      </Form>
      <SimpleSpinner show={isLoading} />
      <SimpleToast
        show={toast.open}
        header={toast.header}
        message={toast.message}
        variant={toast.variant}
        onClose={() => setToast({ ...toast, open: false })}
      />
    </div>
  );
};

export default VariantSpecificationModal;
