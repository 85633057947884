import { useForm } from "react-hook-form";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import InputTextField from "../form/InputTextField";
import HTTP from "../../config/axios";
import SimpleToast from "./Toast";
import { useNavigate } from "react-router-dom";
import {
  fieldRequired,
  emailPattern,
  phoneNumberPattern,
} from "../../config/validations/rules";
import { encryptLocalStorage } from "../../config/utils/storage";

interface responseType {
  success: boolean;
  error: any;
  data: {
    err?: string;
    otpResult:string;
    status: number;
    error: string;
    message: string;
    type: string;
    data: any;
  };
}

const ContactParkAndSell = () => {

  const navigate = useNavigate();

  const [toast, setToast] = useState({
    open: false,
    header: "",
    message: "",
    variant: "",
  });
  const [phoneVerified, setPhoneVerified] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(false);


  const { register, handleSubmit, formState: { errors }, setError, clearErrors, control, watch, reset,setValue, getValues } = useForm();
  
  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const onSubmit = async (formData: any) => {
    try {
        formData.saleLead = 40
      // const {
      //   data: { data, status, message },
      // }: responseType = await HTTP({
      //   method: "post",
      //   url: '/api/leads/park-and-sell/',
      //   data: formData,
      // });

      // setToast({
      //   open: true,
      //   header: status ? "Success" : "Warning",
      //   message,
      //   variant: status ? "success" : "warning",
      // });

      const response: responseType = await HTTP.post(`/api/leads/park-and-sell/`, formData)
            if (response.data.status === 1) {
                reset();           
                setPhoneVerified(false);    
                setToast({ open: true, header: 'Success', message: 'Our team will contact you soon', variant: 'success' })

            } else {
                setToast({ open: true, header: 'Failed', message: response.data.message || 'Something went wrong', variant: 'danger' })
            }

    } catch (error) {
      setToast({
        open: true,
        header: "Failed",
        message: "Something went wrong",
        variant: "danger",
      });
    }
  };

 const sendOtp = async () => {
    try {
        setIsLoading(true);
        // if(!isLoggedIn) {
        //   navigate("/sign-in");
        //   return;
        // }
        const phone = getValues('mobileNo')
        const name = getValues('name')
        if (!phoneNumberPattern.value.test(phone)) {
          setPhoneVerified(false)
            setError( "phoneError", {
                type: "manual", message: phoneNumberPattern.message,
            });
            setIsLoading(false);
            return;
        }
        if(!name) {
            setError("nameRequired", {
                type: "manual",
                message: "Name is required",
            });
}

        if(!name || !phone) {
            setIsLoading(false);
            return
        }
      
        const response: responseType = await HTTP.post(`/api/park-and-sell-otp`, {phone:phone})
        if (response.data.status === 1) {
            setToast({ open: true, header: 'Success', message: response.data.message, variant: 'success' })
            setPhoneVerified(true);
        } else {
            setToast({ open: true, header: 'Failed', message: response.data.message, variant: 'danger' })
        }
      setIsLoading(false);
    }
    catch (error: any) {
        const { response: { status, data: { recaptchaStatus, err } } } = error
        if (status === 401 && recaptchaStatus === 0) {
            setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
        } else {
            setToast({ open: true, header: 'Error', message: 'Phone number is not valid', variant: 'danger' })
        }
    } finally {
        setIsLoading(false);
    }
};
   useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
       if(token){
         try{
           
            const fetchUser = async () => {
            const response = await HTTP.get(`/api/fetch-profile`);
            const { data: { status, data, msg, error } } = response
            if(status==1){
                setValue('name', `${data.firstName} ${data.lastName}`);
                setValue('mobileNo', data.phone)
                setValue('email', data.email)
            }
          
        }
        fetchUser()

        }
        catch(error){
            console.log("Error in states fetch API", error)
        }
       }
        
   
    }, [])


  return (
    <>
      <Container className="py-5">
        <Row className="text-center">
          <Col md={12}>
            <div className="text-center py-5">
              <h2 className="text-primary head-bold ">
                NEED FURTHER SUPPORT
              </h2>
            </div>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-center">
           { !phoneVerified ? (
            <>
           <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="name"
                  label=""
                  placeholder="Name"
                  clearErrors={clearErrors}
                  control={control}
                  rule={{ required: fieldRequired }}
                />
                {errors.nameRequired && (
                    <Form.Text className="text-danger">
                        {errors.nameRequired.message?.toString()}
                    </Form.Text>
                )}
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="email"
                  label=""
                  placeholder="Email"
                  clearErrors={clearErrors}
                  control={control}
                  rule={{ required: fieldRequired, pattern: emailPattern }}
                />
              </div>
            </Col>
            <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="mobileNo"
                  label=""
                  placeholder="Mobile No "
                  clearErrors={clearErrors}
                  type="tel"
                  control={control}
                  rule={{
                    required: fieldRequired,
                    pattern: phoneNumberPattern,
                  }}
                />
                {errors.phoneRequired && (
                    <Form.Text className="text-danger">
                        {errors.phoneRequired.message?.toString()}
                    </Form.Text>
                )}
              </div>
            </Col>
            </>)
           : (
           <Col md={2}>
              <div className="mb-3">
                <InputTextField
                  name="otp"
                  label=""
                  placeholder="OTP"
                  clearErrors={clearErrors}
                  control={control}
                  max={6}
                  rule={{
                    required: fieldRequired,
                  }}
                />
              </div>
            </Col>)}
            <Col md={4} className="text-start">
              {phoneVerified ? (
            <div className="d-flex justify-content-center">
           <Button variant="primary" type="submit" className="me-2"> Submit</Button>
           <Button variant="primary" onClick={sendOtp}>Resend&nbsp;OTP</Button>
            </div>
              ) : (
                <Button variant="primary" onClick={sendOtp}>
                    Send OTP
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Container>
      <SimpleToast
        show={toast.open}
        header={toast.header}
        message={toast.message}
        variant={toast.variant}
        onClose={() => setToast({ ...toast, open: false })}
      />
    </>
  );
};

export default ContactParkAndSell;
