import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";

const AdminHeader: React.FC = () => {
    return (
        <>
            <div id="content-wrapper" className="d-flex flex-column">

                <div id="content">
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/">
                                <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/pvsLogo.svg`} width="80%" alt="logo"></img>
                            </Link>
                            <button
                                id="sidebarToggleTop"
                                className="btn btn-link d-md-none rounded-circle me-3"
                            >
                                <i className="fa fa-bars" />
                            </button>
                        </div>
                    </nav>
                    <div className="mt-2">
                        <Outlet />
                    </div>

                </div>
            </div>
        </>

    );
};

export default AdminHeader;
