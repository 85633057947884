import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';
import DropDownField from '../../form/DropDownField';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        outletExist: boolean
    };
}
interface IOutletInfo {
    id: number;
    name: string;
    keyName: string;
}

interface AddEditOutletProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    outletDetails: IOutletInfo;
    setCountAddEdit: Function;
}

const AddEditOutlet: React.FC<AddEditOutletProps> = ({ show, handleClose, outletDetails, setCountAddEdit }) => {

    const [dealershipArray, setDealershipArray] = useState([]);

    const [isLoading, setIsLoading] = useState(false)
    const [locationArray, setLocationArray] = useState([])
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, getValues, clearErrors, control, reset, watch } = useForm();

    useEffect(() => {
        reset(outletDetails);
    }, [outletDetails]);

    useEffect(() => {
        const getCarMakeData = async () => {
            const response = await HTTP.get(`/api/location/fetch-all-dealerships`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setDealershipArray(data)
            } else {
                setDealershipArray([])
            }
        }
        getCarMakeData()
    }, [])

    useEffect(() => {
        const getLocation = async () => {
            try {
                const params: any = { status: 1, condition: {dealershipId: getValues('dealershipId')} };
                const locationData = await HTTP.get('/api/location/fetch-all', { params });
                const { data: { status, data, msg, error } } = locationData
                setLocationArray(data)
            }
            catch (error) {
                console.log("Error in Location API")
            }
        }

        getLocation()
    }, [watch('dealershipId')])


    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            let formData: any = {}
            formData.id = outletDetails.id.toString()
            formData.name = submitData.name;
            formData.dealershipId = submitData.dealershipId
            formData.locationId = submitData.locationId
            const { data: { status, message, outletExist } }: responseType = await HTTP({
                method: submitData.id ? 'put' : 'post',
                url: `/api/admin/outlet/${submitData.id ? 'edit' : 'add'}`,
                data: formData
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })

            setIsLoading(false)
            if (!status && outletExist) return // Don't close modal and reset form , if lcoation already exist
            setCountAddEdit((prev: number) => prev + 1)
            handleClose()
            reset()
        }
        catch (error) {
            setIsLoading(false)
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
            console.log("Error on Outlet add/ edit api", error)
        }
    }


    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{outletDetails.id ? 'Update' : 'Add'} Outlet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Outlet Name"
                                placeholder="Enter Outlet name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <DropDownField label="Dealership" name="dealershipId" options={dealershipArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose dealership" />
                        </div>
                        <div className='mb-1'>
                        <div className="mb-3">
                            <DropDownField label="District" name="locationId" options={locationArray} 
                                control={control} rule={{ required: fieldRequired }} 
                                isDisabled={ watch('dealershipId') ? false : true}
                                title={watch('dealershipId') ? '' : 'Select a dealership'}
                                clearErrors={clearErrors} placeholder="Choose a district" />
                        </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {outletDetails.id ? 'Update' : 'Save Changes'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditOutlet;