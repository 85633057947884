import React, { useEffect, useState } from 'react';
import TestDriveDataTable from '../../../components/admin/testDrive/TestDriveDataTable';

const ListTestDrive: React.FC = () => {

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <TestDriveDataTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListTestDrive;