import "bootstrap/dist/css/bootstrap.min.css";
import FindCarForm from "../../../components/modules/Home/FindCarForm";
import LocateUs from "../../../components/modules/Home/LocateUs";
import Features from "../../../components/common/Features";
import Benefits from "../../../components/modules/Home/Benefits";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-bootstrap/Carousel";
import ContactUs from "../../../components/common/ContactUs";
import CarsByLifestyle from "../../../components/modules/Home/CarsByLifestyle";
import Location from "../../../components/modules/Home/HomeLocation";
import Faq from "../../../components/common/Faq";
import Testimonials from "../../../components/common/Testimonial";
import { Link } from "react-router-dom";
import SellCarBanner from "../../../components/common/SellCarBanner";
import AssuredCars from "../../../components/common/AssuredCars";
import CarsByBodyType from "../../../components/modules/Home/CarsByBodyType";
import PopularBrands from "../../../components/common/PopularBrands";
import React, { useState, useEffect } from 'react';
import HTTP from "../../../config/axios";
import { encryptLocalStorage } from "../../../config/utils/storage";
import WhoAreWe from "../../../components/common/WhoAreWeBanner";
import DealOfTheDay from "../../../components/modules/Home/DealOfTheDay";

const Home = () => {
  const [findYourcarCms, setFindYourcarCms] = useState({});
  const [featuresCms, setFeaturesCms] = useState("");
  const [whoAreWeCms, setWhoAreWeCms] = useState<any>({});
  const [whoAreWeMobileCms, setWhoAreWeMobileCms] = useState<any>({});
  const location = encryptLocalStorage.getItem("selectedLocation") || "";
  const [faqCms, setFaqCms] = useState("");
  const [carsForBudgetLifestyle, setCarsForBudgetLifestyle] = useState<any>({});
  const [benefitsCms, setBenefitsCms] = useState("");
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const homeCms = async () => {
      const cmsData = await HTTP.post(`/api/cms/fetch-all`,
        {
          nameList: ["find-your-car-now", "features", "who-are-we", "who-are-we-mobile", "faq-component", "cars-for-every-budget-and-lifestyle", "home-benefits"]
        })
      const { data: { status, data, msg, error } } = cmsData

      data.forEach((cmsContent: any) => {
        if (cmsContent.name === "find-your-car-now") {
          setFindYourcarCms(cmsContent);
        } else if (cmsContent.name === "features") {
          setFeaturesCms(cmsContent);
        } else if (cmsContent.name === "who-are-we") {
          setWhoAreWeCms(cmsContent);
        } else if (cmsContent.name === "who-are-we-mobile") {
          setWhoAreWeMobileCms(cmsContent);
        } else if (cmsContent.name === "faq-component") {
          setFaqCms(cmsContent);
        } else if (cmsContent.name === "cars-for-every-budget-and-lifestyle") {
          setCarsForBudgetLifestyle(cmsContent);
        } else if (cmsContent.name === "home-benefits") {
          setBenefitsCms(cmsContent);
        }
      })
    }

    homeCms()
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 500)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 500);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Carousel className="home-carousel">
        <Carousel.Item>
          {!isMobile ? (<img
            className="d-block  "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/home-banner-1.png`}
            alt="First slide"
          />) : (<img
            className="d-block  "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/mobile-home-banner-1.png`}
            alt="First slide"
          />)}
          <Carousel.Caption>
            <h4 className="text-primary is-sub-heading mt-0" dangerouslySetInnerHTML={{ __html: carsForBudgetLifestyle && carsForBudgetLifestyle.title }}></h4>
            <div dangerouslySetInnerHTML={{ __html: carsForBudgetLifestyle && carsForBudgetLifestyle.content }}></div>
            {/* <div><h1 className="fs-16">
              Discover your perfect match.
            </h1>
              <h1 className="fs-16">
                Browse through our extensive collection of used cars.
              </h1></div> */}
            <Link to={`/buy-used-car`} className="btn btn-primary mt-1">
              Browse now <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {!isMobile ? (<img
            className="d-block "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/home-banner-3.png`}
            alt="Second slide"
          />) : (<img
            className="d-block  "
            src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/mobile-home-banner-2.png`}
            alt="First slide"
          />)}
          <Carousel.Caption>
            <div className="carousel-right col-md-12">
              <h4 className="text-primary is-sub-heading mt-1" dangerouslySetInnerHTML={{ __html: carsForBudgetLifestyle && carsForBudgetLifestyle.title }}></h4>
              {/* <div><h1 className={isMobile ? `fs-6` : ``}>
                Discover your perfect match.
              </h1>
                <h1 className={isMobile ? `fs-6` : ``}>
                  Browse through our extensive collection of used cars.
                </h1></div> */}
              <div dangerouslySetInnerHTML={{ __html: carsForBudgetLifestyle && carsForBudgetLifestyle.content }}></div>
              <Link to={`/buy-used-car`} className="btn btn-primary mt-0">
                Browse now <FontAwesomeIcon icon={faAngleRight} />
              </Link>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel >

      <FindCarForm cmsContent={findYourcarCms} />
      {benefitsCms && <Benefits cmsContent={benefitsCms} />}
      <SellCarBanner />
      <Location />
      {/* {featuresCms && <Features cmsContent={featuresCms} />} */}
      <Features />
      <CarsByLifestyle />
      <PopularBrands />
      <CarsByBodyType />
      <DealOfTheDay />
      <hr className="mb-5" />
      <LocateUs />
      <hr className="mb-5" />
      <AssuredCars />
      {faqCms && <Faq cmsContent={faqCms} />}
      <Testimonials />
      {whoAreWeCms && <WhoAreWe whoAreWeCms={isMobile ? whoAreWeMobileCms : whoAreWeCms} />}
      <ContactUs />

    </div >
  );
};

export default Home;
