import { useState, useEffect, ReactNode, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropDownField from '../../form/DropDownField';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired, validateOnlySpaces } from '../../../config/validations/rules';
import { Row, Col, Nav } from 'react-bootstrap'
import CustomModal from '../../common/CustomModal';
import MultiSelectDropdown from '../../form/MultiDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import VariantAddColour from './VariantAddColour';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';


interface IMake {
    id: number;
    name: string;
    keyName: string;
}

interface IVariantInfo {
    id: number;
    name: string;
    // keyName: string;
    modelId: number | null;
    makeId: number | null;
    modelName: string;
    [key: string]: number | string | null;
}
interface IVariantBAsicDetails {
    show: boolean;
    setActiveTab: Function,
    handleClose: () => void; // Specify the function signature
    variantDetails: IVariantInfo;
    setAddEditCount: Function
    setModelDetails: Function;
    activeTab: String;
    setAssignedVehicleIds: Function;
    assignedVehicleIds: number[];

}

const VariantBAsicDetails: React.FC<IVariantBAsicDetails> = ({ show, handleClose, variantDetails, setAddEditCount, setModelDetails, setActiveTab, activeTab, setAssignedVehicleIds, assignedVehicleIds }) => {


    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch, resetField, setValue } = useForm();
    const [modelArray, setModelArray] = useState([]);
    const [makeArray, setMakeArray] = useState<IMake[]>([]);
    const [transmissionType, setTransmissionType] = useState<IMake[]>([]);
    const [fuelType, setFuelType] = useState<IMake[]>([]);
    const [showModal, setShowModal] = useState(false);
    //const [vehicleCount, setVehicleCount] = useState(0);
    const [editFormData, setEditFormData] = useState<any>([])
    const [modalContent, setModalContent] = useState<React.JSX.Element>()
    const [specificationArray, setSpecificationArray] = useState([])
    const [featureForm, setFeatureForm] = useState(false);
    const [specificationForm, setSpecificationForm] = useState(false)
    const [vehicleVariantId, setVehicleVariantId] = useState(0)
    const [colours, setColours] = useState([])
    const [addedColours, setAddedColours] = useState<number[]>([]) // to keep varaint colour details fetched from DB
    const [showColourModal, setShowColourModal] = useState(false)
    const [colourFieldTitle, setColourFieldTitle] = useState('Select a model')
    const hasPageBeenRendered = useRef(false)
    const formModelId = watch('modelId')
    const make = watch('makeId')
    const [bodyTypes, setBodyTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        delete variantDetails.colours
        reset(variantDetails); // colour field will be populated separately with addedColours value
        setVehicleVariantId(variantDetails.id) // set variant id and pass to variant specification component and features component
        setFeatureForm(variantDetails.id != 0 ? true : false) // to make the tab disable 
        setSpecificationForm(variantDetails.id != 0 ? true : false)

    }, [variantDetails, activeTab, show]);



    //Storing the current Model to check with the updating Model Id values 
    const currentModel = variantDetails.modelId

    //Storing the current Make to check with the updating Make Id values 
    const currentMake = variantDetails.makeId

    useEffect(() => {
        if (variantDetails.makeId && makeArray.length) setValue('makeId', variantDetails.makeId)
    }, [makeArray, transmissionType, fuelType])

    useEffect(() => {
        if (variantDetails.modelId && modelArray.length) setValue('modelId', variantDetails.modelId)
    }, [modelArray])

    useEffect(() => {
        if (variantDetails.bodyTypeId && bodyTypes.length) setValue('bodyTypeId', variantDetails.bodyTypeId)
    }, [bodyTypes])

    useEffect(() => {
        const getSpecificationData = async () => {
            try {
                const params = { basicData: true }
                const response = await HTTP.get(`/api/admin/specification/all`, { params });
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setSpecificationArray(data)
                }
            }

            catch (error) {
                console.log('Error in fetching variant data', error)
            }
            finally {
                setIsLoading(false);
            }
        }
        getSpecificationData()
    }, []);



    useEffect(() => {
        if (variantDetails?.id && makeArray.length && modelArray.length && transmissionType.length && fuelType.length && colours.length &&
            make && formModelId && formColours?.length) {
            hasPageBeenRendered.current = true
        }

        if (!variantDetails?.id) { // add form
            hasPageBeenRendered.current = true
        }
    }, [makeArray, modelArray, transmissionType, fuelType, colours])




    useEffect(() => {
        const getCarMakeData = async () => {
            const response = await HTTP.get(`/api/make/all`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                resetField('modelId', {
                    keepDirty: false,
                    keepError: false,
                    keepTouched: false,
                    defaultValue: null
                })
                setMakeArray(data)
            } else {
                setMakeArray([])
            }
        }
        getCarMakeData()
    }, []);

    useEffect(() => {
        const getAllBodyTypes = async () => {
            try {
                const params: any = {}
                const response = await HTTP.get(`/api/admin/vehicle/body-types/fetch-all-types`, { params });
                const { data: { status, data, msg, error } } = response
                setBodyTypes(status ? data : [])
            }
            catch (error) {
                console.log('Error in Body Types API', error)
            }
        }
        getAllBodyTypes()
    }, []);

    useEffect(() => {
        const getTransmissionType = async () => {
            try {
                const params: any = {};
                const response = await HTTP.get(`/api/admin/transmission/fetch-all-transmission`, { params });
                const { data: { status, data, msg, error } } = response
                setTransmissionType(status ? data : [])
            }
            catch (error) {
                console.log('Error in transmission API', error)
            }


        }


        const getFuelTypeData = async () => {
            const params: any = {};
            // if (currentVehicleDetails?.fuel) {
            //     params.fuelId = currentVehicleDetails?.fuel
            // }
            try {
                const response = await HTTP.get(`/api/admin/fuel/fetch-all-fuel`, { params });
                const { data: { status, data, msg, error } } = response
                setFuelType(status ? data : [])
            }
            catch (error) {
                console.log("Error in Fuel API ")
            }
        }
        getTransmissionType()
        getFuelTypeData()


    }, [])


    useEffect(() => {
        const handleMakeChange = async () => {


            const response = await HTTP.get(`/api/model/get-by-make-id/${make}`);
            const { data: { status, data, msg, error } } = response;
            if (status == 1) {
                // resetField('modelId', {
                //     keepDirty: false,
                //     keepError: false,
                //     keepTouched: false,
                //     defaultValue: null
                // })
                setModelArray(data)
                if (hasPageBeenRendered.current) {

                    setValue('modelId', '')
                    setValue('colours', [])
                    setColours([])
                }
            } else {
                setModelArray([])
            }

        };
        make && handleMakeChange()
    }, [make])


    useEffect(() => {
        const getVehicleByVariant = async () => {
            try {
                const resposne = await HTTP.get(`/api/vehicle/fetch-vehicles-by-variant/${variantDetails.id}`)

                const data = resposne.data.data.vehicles;
                setAddedColours(resposne.data.data.colourIds)
                const ids = data.map((vehicle: any) => vehicle.id);
                setAssignedVehicleIds(ids)
            } catch (error) {
                console.log(error)
            }
        }
        variantDetails && show && getVehicleByVariant()
        !show && reset()
    }, [variantDetails, show, activeTab])




    useEffect(() => {
        const getColoursData = async () => {
            try {
                const modelId = formModelId
                const response = await HTTP.get(`/api/admin/model/colours/${modelId}`);
                const { data: { status, data, msg, error } } = response
                if (status) {
                    if (hasPageBeenRendered.current) setValue("colours", [])
                    setColours(data.map((colour: any) => {
                        return { value: colour.id, label: colour.name }
                    }))

                } else {
                    setColours([])
                }

                setColourFieldTitle(data.length == 0 ? 'No colour available for selected model. Add new colour ' : '')
            }
            catch (error) {
                console.log('Error in Color API')
            }


        }
        formModelId && getColoursData()
    }, [formModelId])

    const formColours = watch('colours')


    useEffect(() => { // to set added colours from vehcile_variant table in edit form
        if (addedColours?.length && colours?.length && !hasPageBeenRendered.current) {
            setValue('colours', colours.filter((colour: { value: number, label: string }) => {
                return addedColours.includes(colour.value)
            }))
        }
    }, [addedColours, colours, activeTab])

    // to add newly linked colour to selected colour list
    const appendColour = (newColour: any) => {
        const selectedColours = watch('colours')
        newColour && setValue('colours', [...selectedColours, newColour])
    }





    // Api to populate the saved value while adding the variant
    const getVariantSpecDetails = async (variant: any) => {
        const { id, makeId, modelId, name } = variant

        const response = await HTTP.get(`/api/admin/variant/getVariantSpecDetails/${id}`);
        const { data: { status, data, msg, error } } = response

        if (Object.keys(data.transformedDetails).length === 0) {
            reset(variant)
        } else {
            const updatedData = {
                ...variant,
                ...data.transformedDetails
            }
            reset(updatedData)
            // setBasicDetails({
            //     ...variant,
            //     ...data.transformedDetails
            // });
        }


    }

    useEffect(() => {
        getVariantSpecDetails(variantDetails)
        //reset(basicDetails)   // setting the values when basicDetail state changes

    }, [variantDetails, setSpecificationArray])









    const renderInputField = (data: any) => {
        switch (data.fieldType) {

            case "number":
                return <InputTextField label={data.name} name={data.keyName} type="number"
                    control={control} min={0}
                    clearErrors={clearErrors} placeholder={` Enter ${data.name}`} unit={data.unit} />

            case "text":
                return <InputTextField label={data.name} name={data.keyName} type="text"
                    control={control}
                    clearErrors={clearErrors} placeholder={` Enter ${data.name}`} unit={data.unit}
                    rule={{ validate: validateOnlySpaces }} />
            case "decimal":
                return <InputTextField label={data.name} name={data.keyName}
                    type="number" control={control}
                    step={'any'}
                    min={0} placeholder={`Enter ${data.name}`} unit={data.unit} />

            case "dropdown":
                const options = JSON.parse(data.options || "[]");
                return (
                    <DropDownField
                        label={data.name} name={data.keyName} options={options} control={control}
                        placeholder={`Select ${data.name}`} />
                );

            default:
                return <InputTextField label={data.name} name={data.keyName} type="number"
                    control={control} min={0}
                    clearErrors={clearErrors} placeholder={` Enter ${data.name}`} unit={data.unit} />;
        }
    }


    const onSubmit = async (formData: any) => {
        try {

            const specifications: any = []
            const removedSpecification: any = []
            specificationArray.map((spec: any) => {
                formData[spec.keyName] && specifications.push({ id: spec.id, value: formData[spec.keyName], keyName: spec.keyName })
            })

            formData['variantSpecification'] = specifications

            Object.keys(specificationArray).forEach((index: any) => {
                const spec = specificationArray[index];
                if (!formData[spec['keyName']]) {
                    removedSpecification.push(spec['keyName']);
                }
            });

            formData['removedSpecification'] = removedSpecification

            if (vehicleVariantId) {
                //api to check if variant is assigned to any vehicle
                const vehicleResponse = await HTTP.get(`/api/vehicle/fetch-vehicles-by-variant/${variantDetails.id}`);
                const { data: { status, data, msg, error } } = vehicleResponse;
                //setVehicleCount(data.count);
                if (data.count > 0) { // if vehicle count is greater than zero show a confirmation modal before edit
                    setShowModal(true);
                    //Checking the make or model is changed for the variant
                    if (formData.makeId != currentMake || formData.modelId != currentModel) {
                        setModalContent(<p className='text-primary'>Please note: Updating either Make or Model may create a new variant if the variant doesn't exist for the given Make & Model. Are you sure you want to proceed?</p>)
                        formData['changeInModel'] = 1 //A flag to create new variant if model or make is updated
                    } else {
                        setModalContent(<p className='text-primary'>Updating this variant will be effected on {data.count} vehicles. Are you sure you want to proceed?</p>)
                        formData['changeInModel'] = 0
                    }

                    setEditFormData(formData)
                } else {// if vehicle is zero directly call edit api 

                    onEdit(formData)
                }
            } else {// api to add new Variant
                onAdd(formData)
            }
        } catch (err) {
            console.error("Error:", err);
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
        } finally {
            // reset(variantDetails);
            // reset(); // Reset form fields
        }
    };


    const onAdd = async (formData: any) => {
        try {

            formData.colours = formData.colours.map((colour: any) => colour.value)
            const response = await HTTP({
                method: 'post',
                url: `/api/admin/variant/add`,
                data: formData,
            });

            // Handle response
            const { status, msg, variantId: id, makeId, modelId, name } = response.data;

            setToast({
                open: true,
                header: status ? 'Success' : 'Failed',
                message: msg ? msg : 'Something went wrong',
                variant: status ? 'success' : 'danger',
            });

            // Increment count if successful
            if (status) {
                setVehicleVariantId(id)
                setAddEditCount((prev: number) => prev + 1)
            }
            if (id) {
                setModelDetails(response.data)
                setFeatureForm(true)
                setSpecificationForm(true)
                setActiveTab('specification')
            }
        }
        catch (err) {
            console.log(err)
            setToast({
                open: true,
                header: 'Failed',
                message: 'Something went wrong',
                variant: 'danger',
            })
        }

    }

    const onEdit = async (formData: any) => {

        try {

            if (assignedVehicleIds?.length > 0) {
                formData.vehicleIds = assignedVehicleIds;
            }
            formData.colours = formData.colours.map((colour: any) => colour.value)
            formData.removedColours = addedColours.filter((colourId: number) => {
                return !formData.colours.includes(colourId)
            })

            const response = await HTTP({
                method: 'put',
                url: `/api/admin/variant/edit`,
                data: formData,
            });

            // Handle response
            const { status, msg } = response.data;
            if (status == 1) {
                setActiveTab('specification')
                setAddEditCount((prev: number) => prev + 1)
                getVariantSpecDetails(variantDetails)
                setModelDetails(formData)
                //editspecificationDetails(formData) // passing the formData to the function to get basic specifcation data from listVehiclevariant
            }

            setToast({
                open: true,
                header: status ? 'Success' : 'Failed',
                message: msg ? msg : 'Something went wrong', variant: status ? 'success' : 'danger',
            });

            // Increment count if successful
        }
        catch (err) {
            console.log(err)
            setToast({
                open: true,
                header: 'Failed',
                message: 'Something went wrong',
                variant: 'danger',
            })

        }

    }


    const closeModal = () => {
        reset()
        setValue('colours', [])
        handleClose()
        setActiveTab('basic')
        setShowModal(false)
    }

    const handleConfirmClick = async () => {

        onEdit(editFormData)
        setActiveTab('specification')
        setShowModal(false)

    }


    // To remove all selected options from multiselect
    const removeAllColours = (options: { value: number, label: string }[]) => {
        //  setValue('colours', options)
    }






    return (
        
        <div>
            {!isLoading ? <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4}>
                        <InputTextField
                            name="name"
                            label="Variant Name"
                            placeholder="Enter Variant name"
                            clearErrors={clearErrors}
                            control={control}
                            rule={{ required: fieldRequired }}
                        />
                    </Col>
                    <Col md={4}>
                        <DropDownField
                            label="Make"
                            name="makeId"
                            options={makeArray}
                            control={control}
                            rule={{ required: fieldRequired }}
                            clearErrors={clearErrors}
                            placeholder="Choose make"
                        />
                    </Col>
                    <Col md={4}>
                        <DropDownField
                            label="Model"
                            name="modelId"
                            options={modelArray}
                            control={control}
                            rule={{ required: fieldRequired }}
                            clearErrors={clearErrors}
                            placeholder="Choose model"
                        />
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <DropDownField label="Body Types" name="bodyTypeId" options={bodyTypes}
                                control={control} rule={{ required: fieldRequired }}
                                clearErrors={clearErrors} placeholder="Choose a body type" />
                        </div>
                    </Col>
                    <Col md={4}>

                        <Form.Label htmlFor={`colours-id`} className="text-dark fw-semibold fs-6">
                            Colours<span className="text-danger fw-bold">*</span>
                        </Form.Label>
                        <Row>
                            <Col md={10}>

                                <MultiSelectDropdown name="colours"
                                    options={colours} selectedValues={watch('colours')}
                                    setValues={removeAllColours} control={control}
                                    rule={{ required: fieldRequired }} clearErrors={clearErrors}
                                    placeholder="Choose colours" />
                            </Col>
                            <Col md={2} className="text-end">
                                <Button variant="success" size="sm" style={{ padding: '7px 14px' }} onClick={() => setShowColourModal(true)}
                                    title={colourFieldTitle} disabled={!formModelId}>
                                    <FontAwesomeIcon icon={faAdd} />
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                    {specificationArray.map((data: any) => (
                        <Col md={4} key={data.id}>
                            {renderInputField(data)}
                        </Col>
                    ))}


                </Row>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Close
                    </Button>
                    {variantDetails.id !== 0 ? (
                        <Button variant="primary" type="submit">
                            Update
                        </Button>
                    ) : (
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    )}
                </Modal.Footer>
            </Form> : <></>}


            <CustomModal
                isOpen={showModal}
                title="Update Variant"
                component={modalContent}
                closeModal={() => setShowModal(false)}
                buttonList={
                    <>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        <Button variant="primary" onClick={handleConfirmClick}>Confirm</Button>
                    </>
                }
            />
            {
                showColourModal && <VariantAddColour show={showColourModal} modelId={formModelId}
                    handleClose={() => setShowColourModal(false)}
                    colours={colours} setColours={setColours} appendColour={appendColour} />
            }
            <SimpleSpinner show={isLoading} />

            <SimpleToast
                show={toast.open}
                header={toast.header}
                message={toast.message}
                variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })}
            />
        </div>
    )
}

export default VariantBAsicDetails