
import React, { useEffect, useState } from 'react';

import ReferAndEarnTable from '../../../components/admin/enquiries/ReferAndEarnTable';

const ListReferAndEarn: React.FC = () => {
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <ReferAndEarnTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListReferAndEarn;