import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropDownField from '../../form/DropDownField';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        modelExist: boolean
    };
}
interface IModelInfo {
    id: number;
    name: string;
    //keyName: string;
    makeId: number | null;
    makeName: string;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    modelDetails: IModelInfo;
    setAddEditCount: Function
}

const AddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, modelDetails, setAddEditCount }) => {
    const [makeArray, setMakeArray] = useState([]);
    useEffect(() => {
        reset(modelDetails);
    }, [modelDetails]);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset } = useForm();

    useEffect(() => {
        const getCarMakeData = async () => {
            const response = await HTTP.get(`/api/make/all`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setMakeArray(data)
            } else {
                setMakeArray([])
            }
        }
        getCarMakeData()
    }, [])

    const onSubmit = async (formData: any) => {

        // if (formData.makeId == 0) {
        //     setToast({ open: true, header: 'Failed', message: 'Please select make name', variant: 'danger' })
        //     return
        // }
        try {
            const { data: { status, message, modelExist } }: responseType = await HTTP({
                method: modelDetails.id ? 'put' : 'post',
                url: `/api/admin/model/${modelDetails.id ? 'edit' : 'add'}`,
                data: formData,
            })
            setToast({ open: true, header: status ? 'Success' : 'Failed', message: message || 'Something went wrong', variant: status ? 'success' : 'danger' })
            if (!status && modelExist) return // Don't close modal and reset form , if lcoation already exist
            setAddEditCount((prev: number) => prev + 1)
            handleClose()
            reset()
        }
        catch (err) {
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
        }
        // finally{
        //     handleClose()
        //     reset()
        // }

    }

    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modelDetails.id ? "Update Model" : "Add Model"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Model Name"
                                placeholder="Enter Model name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>

                        <div className='mb-1'>
                            <DropDownField label="Make" name="makeId" options={makeArray} control={control}
                                rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose make" />
                        </div>
                        <div className='mb-1'>
                            <InputTextField name="id" label=""
                                placeholder="" type="hidden" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>

                        <Button variant="primary" type="submit">
                            {modelDetails.id ? "Update Model" : "Add Model"}
                        </Button>


                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditModal;