import axios, { InternalAxiosRequestConfig } from "axios";
import { encryptLocalStorage } from "./utils/storage";
import { roles } from "./utils/helper";

const HTTP = axios.create({
  headers: {
    Authorization: encryptLocalStorage.getItem("jwtToken"),
  },
});

HTTP.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = encryptLocalStorage.getItem("jwtToken"); // Retrieve the JWT token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (encryptLocalStorage.getItem("selectedLocation")) {
    config.headers["Selected-Location"] = encryptLocalStorage.getItem("selectedLocation");
  }
  return config;
});

HTTP.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {response: { status, headers, config}} = error;
    const originalRequest = config;
    
    if (status === 401 && headers.hasOwnProperty("token-expired") && headers["token-expired"] == 1) {
      return onTokenExpire(originalRequest);
    }

    return Promise.reject(error);
  }
);
let failedQueue: any = [];

function onTokenExpire(originalRequest: any) {
  

  const processQueue = (error: any, token = null) => {
    failedQueue.forEach(
      ({ resolve, reject }: { resolve: any; reject: any }) => {
        error ? reject(error) : resolve(token);
      }
    );

    failedQueue = [];
  };

  if (encryptLocalStorage.getItem("isRefreshing") === true) {

    return new Promise((resolve: any, reject: any) => {
      failedQueue.push({ resolve, reject });
    })
      .then((token: any) => {
        originalRequest.headers["Authorization"] = `Bearer ${token}`;
        return HTTP(originalRequest);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    
    originalRequest._retry = true;
    encryptLocalStorage.setItem("isRefreshing", "true");
    return new Promise((resolve: any, reject: any) => {
      HTTP.post(
        "/api/refresh-token",
        {},
        {
          headers: {
            refreshToken: encryptLocalStorage.getItem("refreshToken") || "",
          },
        }
      )
        .then((response: any) => {
          const { success, token } = response.data;
          encryptLocalStorage.setItem("jwtToken", token);
          HTTP.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          originalRequest.headers["Authorization"] = `Bearer ${token}`;
          processQueue(null, token);
          resolve(HTTP(originalRequest));
        })
        .catch((err: any) => {
          console.log("Token Expired", err)
          const userInfo = encryptLocalStorage.getItem("userInfo")
          return window.location.href = userInfo && userInfo.roleUUID == roles.SUPER_ADMIN ?
                                    "/admin/sign-in" : "/sign-in"
          //reject(err);
        })
        .then(() => {
          encryptLocalStorage.setItem("isRefreshing", "false");
        });
    });
  }
}

export default HTTP;
