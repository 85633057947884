import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HTTP from "../../config/axios";
import { useNavigate, createSearchParams } from 'react-router-dom';

// const Features: React.FC<any> = ({ cmsContent }) => {
//     const [featuresCms, setFeaturesCms] = useState(cmsContent);
const Faq: React.FC<any> = ({ cmsContent, cmsType }) => {
    const [faqCms, setFaqCms] = useState(cmsContent);
    const navigate = useNavigate();
    useEffect(() => {
        const getFaq = async () => {
            const name = "faq-component";
            const cmsData = await HTTP.get(`/api/cms/fetch-one/${name}`)
            const { data: { status, data, msg, error } } = cmsData
            setFaqCms(data)
        }
        !cmsContent && getFaq()
    }, [])
    const handleFaqClick = () => {
        if (!cmsType) cmsType = "default"
        const searchQuery = createSearchParams({
            type: cmsType
        }).toString()
        navigate({
            pathname: "/faq",
            search: searchQuery
        })
    }
    return (
        <div className="App">
            <Container>
                <Row className="my-5 faq">
                    <Col lg={3} md={12} className="border-end text-dark border-end-sm-0">
                        <div className="has-vertical-text">
                            <h2 className="verticaltext ">FAQs</h2>
                        </div>
                    </Col>
                    <Col lg={9} md={12} className="px-4">
                        <div dangerouslySetInnerHTML={{ __html: faqCms && faqCms.content }}></div>

                        <div className="pt-3">
                            <Button variant="primary" onClick={handleFaqClick}>
                                View all <FontAwesomeIcon icon={faAngleRight} />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Faq;
