import { useEffect, useState } from "react"
import HTTP from "../../../config/axios"
import { Col, Image, Row } from "react-bootstrap"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const CarDentMap: React.FC<any> = ({ id }: { id: string }) => { 


    const [markers, setMarkers] = useState< Array <any >>([])
    const [selectedDent, setSelectedDent] = useState<any>("")
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const getAllDentDetails = async() => {
            try{
                const response = await HTTP.get(`/api/vehicle/fetch-dent-data/${id}`)
                const { data: { status, dentData } } = response
    
                if(status){
                    setMarkers(dentData.map((data:any) => {
                        const dentData = JSON.parse(data.dent_data)
                        dentData.id = data.id
                        dentData.sort = data.sort
                        dentData.image = `${process.env.REACT_APP_CLOUDFRONT_URL}/${dentData.image}`
                        return dentData
                    }))
                }else{
                    setMarkers([])
                }
            }
            catch(err){
                console.log("Error in  dent fetch api", err);
            }
        }
        getAllDentDetails()
    },[window.location.pathname])

    useEffect(() => {
        setSelectedDent(markers[0])
    }, [markers])

    useEffect(() => {
        setIsMobile(window.innerWidth <= 575);
      }, []);

    return (
    <>
        { markers.length > 0 && 
        <div className="ms-3">         
            <Col >
                <p className="text-dark fs-5">  {selectedDent?.description} </p>
                <div className="image-container">
                    <img
                    src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/dent_map_placeholder.png`}
                    alt="Your Image"
                    className="image w-100"
                    />
                    {markers.map((mark, index) => (
                        <div className={`pointer ${mark.id == selectedDent?.id ? 'active': ''}`} key={index}
                        onClick={() => setSelectedDent(mark)}
                        style={{ top: mark.top+'%', left: mark.left+'%', cursor: 'pointer' }}>{ index + 1}</div>
                    ))}
                </div>
            </Col>
             <p className="text-secondary fs-7"> <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp;Click on a pointer on dent map to view dent details</p>
            <Col >
                {/* <div className="pointer active" >{ selectedDent?.sort}</div> */}
                <Image src={selectedDent?.image} alt="Preview" className={isMobile ? `dent-preview w-100` : `dent-preview w-75`}/>
            </Col>
        </div>
        }
    </>)

}
export default CarDentMap

