import React, { Fragment, useEffect, useState } from "react";
import InputTextField from "../../form/InputTextField";
import { useForm } from "react-hook-form";
import { fieldRequired } from "../../../config/validations/rules";
import { Accordion, Col, Nav, Row, Tab, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import HTTP from "../../../config/axios";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft,faWrench, faCar, faCarBurst, faCarOn, faCarSide, faCheckCircle, faGauge, faGear, faGears, faSearch, faStar, faTimesCircle, faDownload } from "@fortawesome/free-solid-svg-icons";
import InputGroupField from "../../form/InputGroupField";
import CarDentMap from "./CarDentMap";
import { faRProject } from "@fortawesome/free-brands-svg-icons";
import Overview from "./Inspection/Overview";
import SimpleSpinner from '../../common/Spinner';
import { InspectionTypeOptions } from "../../../config/utils/helper";
import { prefix } from "react-bootstrap/lib/utils/bootstrapUtils";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface SidebarModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const InspectionModal: React.FC<SidebarModalProps> = ({ isOpen, onClose, id }) => {

  const { formState: { errors }, clearErrors, control, watch } = useForm();
  const navigate = useNavigate();

  const [inspectionList, setInspectionList] = useState<any>([]);
  const [fullCarName, setFullCarName] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // Initialize state for active key
  const [activeKey, setActiveKey] = useState('first');
  
  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (!token) {
      navigate('/sign-in')
    }
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 575);
  }, []);

  //Generate the PDF 
  const  generatePdf = async() => {
    try{
      setIsLoading(true)
      const {data} =  await HTTP.post(`/api/vehicle/pdf-report`,{'vehicleId':id}, { responseType: 'blob' });  
      // Create a temporary anchor element to initiate the download
      const pdfBlob =  new Blob([data], { type: 'application/pdf' });
      // Create a temporary URL to the Blob
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${fullCarName}.pdf`;
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    }catch(e){
      console.log(e)
    }finally{
      setIsLoading(false)  
    }
  }


  useEffect(() => {
    const getCarInspections = async () => {
      const inspectionResponse = await HTTP.get(
        `/api/vehicle/inspection/fetch-all/${id}`
      );
      const {
        data: { status, data, msg, error },
      } = inspectionResponse;
      if (status == 1) {
        setInspectionList(data);
      } else {
        setInspectionList([]);
      }
    };
    getCarInspections();
  }, []);

    useEffect(() => {
    const getCarDetails = async () => {
      const response = await HTTP.get(`/api/vehicle/fetch-one/${id}`);
      const { data: { status, data, msg, error } } = response
      if (status == 1) {
        setFullCarName(`${data.year || ''} ${data.make} ${data.model} ${data.variant}`)
      }

    }
    getCarDetails()
  }, []);

  const searchedData = watch('searchFeature') || '';

  useEffect(() => {
    const renderFilteredSpecifications = () => {

      setSearchQuery(searchedData);
    };
    renderFilteredSpecifications();
  }, [searchedData]);

  const renderInspData = (inspectionType: number) => {
    const filteredData = inspectionList.filter((item: any) => item.inspectionType === inspectionType);
    if (filteredData.length === 0) {
      return <p className="px-3 mt-2">No report found</p>;
    }
    return ( 
      <div>
        {filteredData.map((inspection: any, index: number) => (
          <div key={index} className={`w-100 text-start border-bottom px-3 engine-accordian`}>
             <Accordion defaultActiveKey="0">
               <Accordion.Item eventKey={`${index}`}>
                <Accordion.Header className="custom-accordionheader">{inspection.name}  {inspection.imperfection_note ==='' ? (<FontAwesomeIcon className="ps-2 mt-1 text-success" icon={faCheckCircle} />) : (<FontAwesomeIcon className="ps-2 mt-1 text-danger" icon={faTimesCircle} />)}  </Accordion.Header>
                  <Accordion.Body className="px-0 pt-1 pb-0">
                  {inspection.note !='' && 
                    <Fragment>
                      <div className="d-flex  align-items-center inspection-details">
                        <span className=" inspection-details-font text-secondary">Fixed</span>
                        <hr/>
                        <div>
                          <FontAwesomeIcon className="ps-2 mt-1 text-success inspection-details-font" icon={faWrench} />
                        </div>
                      </div>
                      <pre style={{ whiteSpace: 'break-spaces' }} className='text-success'><span className="inspection-note">{inspection.note}</span></pre>
                    </Fragment>
                  }
                  {inspection.tested_note !='' && 
                    <>
                      <div className="d-flex align-items-center inspection-details">
                        <span className=" inspection-details-font text-secondary">Tested</span>
                        <hr/>
                        <div>
                          <FontAwesomeIcon className="ps-2 mt-1 text-success inspection-details-font" icon={faCheckCircle} />
                        </div>
                      </div>
                    <pre style={{ whiteSpace: 'break-spaces' }} className='text-secondary'><span className="inspection-note">{inspection.tested_note}</span></pre>
                    </>
                  }
                  {inspection.imperfection_note !='' && 
                    <>
                      <div className="d-flex align-items-center inspection-details">
                        <span className=" inspection-details-font text-secondary">Imperfection</span>
                        <hr/>
                        <div>
                          <FontAwesomeIcon className="ps-2 mt-1 text-danger inspection-details-font" icon={faTimesCircle} />
                        </div>
                        
                      </div>
                    <pre style={{ whiteSpace: 'break-spaces' }} className='text-danger'><span className="inspection-note">{inspection.imperfection_note}</span></pre>
                    </>
                  }
                  </Accordion.Body>
                 </Accordion.Item>
              </Accordion>
              </div>
        ))}
      </div>
    );
  };

  const handleSelect = (key:any) => {
     // Update state with the active tab key
     setActiveKey(key)
  };

  const NavigationHeader = () => {
    return (
      <>
        {InspectionTypeOptions.map(({id,name,icons}:{id:number,name:string,icons:any}, index:number) => {
          if(id > 1){
            return ( //Removed the first element using slice
              <Nav.Item key={index+1} >
                <Nav.Link eventKey={id+1} className="border-bottom">
                  <div className=" text-center py-2">
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/icons/${icons}`} style= {(activeKey == `${id+1}`)?{}:{filter:'grayscale(100%) contrast(0.5)'}} alt={name}></img>
                    <p className="my-2">{name}</p>
                  </div>
                </Nav.Link>
              </Nav.Item>
            )
          } 
        })}
      </>
    );
  };

  useEffect(()=>{
    NavigationHeader()
  },[activeKey])

  const   TabItems = () => {
    return (
      <>
        {InspectionTypeOptions.map((item:{id:number,name:string}, index:number) => (//Removed the first element using slice
            <Tab.Pane key={index+1} eventKey = {item.id+1} >
              <div className="border-top scrol-cnt ">
                <div className="w-100 text-start ">
                  <h3 className="text-start text-primary  fw-bold faq-answer px-3 pt-3">
                    {item.name}
                  </h3>
                  <div className="w-100 text-start border-top ">
                    {renderInspData(item.id)}
                  </div>
                </div>
              </div>
            </Tab.Pane>
        ))}
      </>
    );
  };

  return (
    <Modal
      show={isOpen}
      size={"lg"}
      onHide={() => onClose}
      fullscreen="fullscreen"
      dialogClassName={isMobile ? `modal-90w` : `modal-90w h-100`}
      aria-labelledby="example-custom-modal-styling-title"
      className="featuer-modal "
    >
  
      <Modal.Body className={isMobile ? `bg-white p-0` : `bg-white`}>
       <div className="mt-3"> 
       <div className="d-flex align-items-center justify-content-between ms-3">
          <div className="d-flex align-items-center">
          <div>
            <FontAwesomeIcon icon={faAngleLeft} className={isMobile ? `fs-3 mb-2` : `fs-4 mb-2`} onClick={onClose} style={{ cursor: "pointer" }}/>
          </div>
          <div className="ms-3"> <h2 className={isMobile ? `ps-2 fs-5` : `fs-4 mb-1`}>INSPECTION REPORT</h2>
            <h3 className={isMobile ? `fs-6 ps-2` :`fs-6`} >{fullCarName}</h3>
          </div>
          </div>
          <Button variant=""  className="justify-content-end" size="sm" onClick={generatePdf}><FontAwesomeIcon icon={faDownload} className="text-primary" /><span className="text-primary"><br/>PDF</span></Button>    
       </div>

          <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={handleSelect} >
            <div className={isMobile ? `mt-3 d-flex` : `mt-3 row`}>
              <Col lg={3} md={4} sm={5} xs={5} className="pe-0 ps-0 border-end border-bottom border-top border-start-0 custom-dent scroll-side-cnt">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first" className="border-bottom">
                      <div className=" text-center py-2">
                        <FontAwesomeIcon icon={faStar} className="fs-2" />
                        <p className="my-2">Overview</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="border-bottom">
                      <div className=" text-center py-2">
                        <FontAwesomeIcon icon={faCarBurst} className="fs-2" />
                        <p className="my-2">Dent Map</p>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <NavigationHeader/>
                </Nav>
              </Col>
              <Col lg={9} md={8} sm={7} xs={7} className="ps-0 pe-0 border-top-0 " >
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="border-top scrol-cnt ">
                      <div className="w-100 text-start ">
                        <div className="w-100 text-start ">
                        <Overview  inspectionList={inspectionList}  />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="border-top scrol-cnt ">
                      <div className="w-100 text-start ">
                        <p className="text-start text-primary head-text fw-bold faq-answer px-3 pt-3">
                          DENT MAP
                        </p>
                        <CarDentMap id={id} isMobile />
                      </div>
                    </div>
                  </Tab.Pane>
                  <TabItems/>
                </Tab.Content>
              </Col>
            </div>
          </Tab.Container>
        </div>
        <SimpleSpinner show={isLoading} />
      </Modal.Body>
    </Modal>
  );
};

export default InspectionModal;
