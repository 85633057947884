import AdminHeader from './AdminHeader';
import AdminFooter from './AdminFooter';
import AuthenticatedRoute from '../components/common/AuthenticatedRoute';
import { roles } from '../config/utils/helper';


const {SUPER_ADMIN} = roles
const Layout = () => {

    return (
        <>
            <div id="wrapper">
                <AdminHeader />
            </div>
            <AdminFooter />
        </>
    );
};

const AdminLayout = () => {
    return <AuthenticatedRoute allowedRole={SUPER_ADMIN} ><Layout/></AuthenticatedRoute>
}


export default AdminLayout;