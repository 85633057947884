import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import BodyTypeTable from '../../../components/admin/bodyType/BodyTypeDataTable';
import BodyTypeAddEditModal from '../../../components/admin/bodyType/BodyTypeAddEditModal';

const ListBodyType: React.FC = () => {
    const [show, setShow] = useState(false);
    const [bodyTypeDetails, setBodyTypeDetails] = useState({ id: 0, name: '', keyName: '', image: '' });
    const handleClose = () => setShow(false);
    const [addEditCount, setAddEditCount] = useState(0)

    const editModal = (make: any) => {
        setBodyTypeDetails(make)
        setShow(true)
    }

    const newBodyType = () => {
        setShow(true);
        setBodyTypeDetails({ id: 0, name: '', keyName: '', image: '' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <BodyTypeTable newBodyType={newBodyType} showModal={editModal} handleClose={handleClose} show={show} addEditCount={addEditCount} />
                            <BodyTypeAddEditModal show={show} handleClose={handleClose} bodyTypeDetails={bodyTypeDetails} setAddEditCount={setAddEditCount} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListBodyType;