import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@usedcars.dev1/wbc-ckeditor5-custom-build';
import { encryptLocalStorage } from './storage';
import  "../../css/content-styles.css"


interface IeditorProps {
    data: string;
    onChange: Function;
    label?: string;
    uploadUrl?: string;
    uploadPath?: string;
}
const Ckeditor5: React.FC<IeditorProps> = ({data, onChange, label, uploadUrl, uploadPath}) => {


  const uploadApi = `${window.location.protocol}//${window.location.host}/api${uploadUrl}`

 

  const editorConfig: any = {
    htmlSupport: {
      allow: [
          {
            name: /.*/,       // Match any HTML element
            attributes: true, // Allow attributes on HTML elements
            classes: true,    // Allow classes on HTML elements
            styles: true      // Allow inline styles on HTML elements
          }
      ],
      disallow: [ {name:'script'}] // Disallow the <script> element
    },

    mediaEmbed: { previewsInData: true}, // render a visual preview of that media content instead of showing the link
    image: {
      upload: {
        types: ['jpeg', 'jpg', 'png'],
      },
    },
    fontSize: { 
      options: [6,7,8,9,'default',10,10.5,11,12,13,14,15,16,18,20,22,24,26,28,32,36,40,44,48]
    },

    simpleUpload: {
      // The URL that the images are uploaded to.
      uploadUrl: uploadApi,

      // Enable the XMLHttpRequest.withCredentials property.
      // withCredentials: true,

      // Headers sent along with the XMLHttpRequest to the upload server.
      headers: {
        Path: uploadPath || '',
        Authorization: 'Bearer ' + encryptLocalStorage.getItem("jwtToken") || '',
          // RefreshToken: SecureStorage.getItem('refreshToken')
      },
    } ,
  }

  return (<>
     {label && <p className="text-dark fw-semibold mb-0 fs-6">Content</p>}
     <div className='ck-content'>
      <CKEditor
          id={uploadPath}
          editor={ ClassicEditor }
          data={data}
          onReady={ ( editor ) => {
            console.log( "CKEditor5 React Component is ready to use!", editor );
          } }
          onChange={ ( event: any, editor: any ) => {
            const data = editor.getData();
            onChange(data)
            console.log( { event, editor, data } );
          } }
          config={editorConfig}
        />
     </div>
      
    
    </>);
}


export default Ckeditor5
