import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";

interface SimpleSpinnerProps {
    show: boolean;
}

const SimpleSpinner: React.FC<SimpleSpinnerProps> = ({ show }) => {
    return (
        <Modal
            show={show}
            centered
            backdrop="static"
            keyboard={false}
            contentClassName="bg-transparent border-0"
        >
            <Modal.Body className="d-flex justify-content-center align-items-center">
                <div className="loader">
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SimpleSpinner;
