import { useState, useEffect } from "react";
import HTTP from "../../config/axios";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const BuyCar = () => {
    // const [buyCarBannerCms, setBuyCarBannerCms] = useState<any>({});
    // useEffect(() => {
    //     const getCmsContent = async () => {
    //         let BuyCarBanner = "buy-car-banner"
    //         const response = await HTTP.get(`/api/cms/fetch-one/${BuyCarBanner}`);
    //         const { data: { status, data, msg, error } } = response
    //         setBuyCarBannerCms(data)
    //     }
    //     getCmsContent()
    // }, [])

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/buy_car_banner.jpg`;

    return (
        <Container fluid className="top-banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
            {/* <Container className="my-auto">
                <div dangerouslySetInnerHTML={{ __html: buyCarBannerCms && buyCarBannerCms.content }}></div>
            </Container> */}
            <div className="my-auto container">
                <div className="py-3 col-md-6">
                    <h5 className="text-primary is-sub-heading mb-1 head-bold">
                        BUY CAR
                    </h5>
                    <h1 className="text-light">
                    Book your Dream car now !
                    </h1>
                    <p className="text-light is-description">
                        Through our website, you can purchase your dream car in 5 minutes.
                    </p>
                    {/* <p>
                        <a className="btn btn-primary" href="/buy-used-car">View more<svg className="svg-inline--fa fa-angle-right " aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></a>
                    </p> */}
                    {/* <Link to="/buy-used-car"><Button variant="primary" type="submit">
                        View more <FontAwesomeIcon icon={faAngleRight} />
                    </Button></Link> */}
                     <Link to={`/buy-used-car`} className="btn btn-primary">
                        View more <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                </div>
            </div>
        </Container>
    );

};

export default BuyCar;
