import DataTable from 'react-data-table-component';   
import { PaginationChangeRowsPerPage } from 'react-data-table-component/dist/DataTable/types';
    
interface ReportDataProps {
    reportData:any
    handlePageChange:PaginationChangeRowsPerPage | undefined
    handlePerRowsChange:PaginationChangeRowsPerPage | undefined
}

const ModelWiseReport: React.FC<ReportDataProps> = ({reportData, handlePageChange, handlePerRowsChange}):any => {
    
    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: <div>Make</div>,
            selector: (row: { make: string; }) => row.make,
            sortable: false,
            keyName: 'make'
        },
        {
            name: <div>Model</div>,
            wrap: true,
            sortable: false,
            keyName: 'model',
            width: "17%",
            selector: (row: {model: string}) => row.model,

        },
        {
            name: 'Count',
            selector: (row: { vehicleCount: string; }) => row.vehicleCount,
            sortable: false,
        },
    ];

    return (<>
        <br></br>
        <DataTable
                columns={columns}
                data={reportData.rows}
                striped={true}
                pagination
                paginationServer
                paginationTotalRows={reportData.count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover={true}
                customStyles={customStyles}
            />
        
    </>)

}
export default ModelWiseReport