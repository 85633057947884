import React, { useEffect, useState } from "react";
import InputTextField from "../../form/InputTextField";
import { useForm } from "react-hook-form";
import { fieldRequired } from "../../../config/validations/rules";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import HTTP from "../../../config/axios";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import InputGroupField from "../../form/InputGroupField";
import {FeatureTypeOptions} from  "../../../config/utils/helper"

interface SidebarModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const FeaturesModal: React.FC<SidebarModalProps> = ({ isOpen, onClose, id }) => {

  const { formState: { errors }, clearErrors, control, watch } = useForm();
  const navigate = useNavigate();

  const [featuresList, setFeaturesList] = useState<any>([]);
  const [fullCarName, setFullCarName] = useState('');
  const [isMobile, setIsMobile] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState<number>(1);

  useEffect(() => {
    const token = encryptLocalStorage.getItem("jwtToken");
    if (!token) {
       navigate('/sign-in') 
    }
  }, []);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 575);
  }, []);
  

  useEffect(() => {
    const getCarFeatures = async () => {
      const featuresResponse = await HTTP.get(
        `/api/vehicle/features/fetch-all/${id}`
      );
      const {
        data: { status, data, msg, error },
      } = featuresResponse;
      if (status == 1) {
        setFeaturesList(data);
      } else {
        setFeaturesList([]);
      }
    };
    getCarFeatures();
  }, []);

  useEffect(() => {
    const getCarDetails = async () => {
        const response = await HTTP.get(`/api/vehicle/fetch-one/${id}`);
        const { data: { status, data, msg, error } } = response
        if (status == 1) {
          setFullCarName(`${data.year || ''} ${data.make} ${data.model} ${data.variant}`)
        }

    }
    getCarDetails()
}, []);


  const renderFeatures = (type: number) => {
    const filteredFeatures = featuresList
    .filter((feature:any) => {
      return feature.name.toLowerCase().includes(searchQuery.toLowerCase())
    })
    .filter((feature:any) => type == feature.type)
    if (filteredFeatures.length === 0) {
      return <div className="w-100 text-start border-bottom px-3">
                <p className="text-start text-dark is-description mt-2 mb-0">Not available</p>
             </div>;
    }
    return filteredFeatures.map((feature: any, index: number) => (
        <div key={index} className="w-100 text-start border-bottom px-3">
          <p className="text-start text-dark is-description mt-2 mb-0">{feature.name}</p>
          <p className="text-start is-description">{feature.value} {feature.unit}</p>
        </div>
      ));
  };
  
  const searchedData = watch('searchFeature') || '';

  useEffect(() => {
    const renderFilteredSpecifications = () => {

      setSearchQuery(searchedData);
      handleSearch(searchedData)
    };
    renderFilteredSpecifications();
  }, [searchedData]); 

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    console.log("setSearchQuery",query)
    let newActiveTabKey = 2; // Default tab
    // if (featuresList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [1].includes(spec.type))) {
    //   newActiveTabKey = 1;
   if (featuresList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [2].includes(spec.type))) {
      newActiveTabKey = 2;
    } else if (featuresList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [3].includes(spec.type))) {
      newActiveTabKey = 3;
    } else if (featuresList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [4].includes(spec.type))) {
      newActiveTabKey = 4;
    }
    else if (featuresList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [5].includes(spec.type))) {
      newActiveTabKey = 5;
    }
    else if (featuresList.some((spec: any) => (spec.name.toLowerCase().includes(query.toLowerCase()) || spec.value.toLowerCase().includes(query.toLowerCase())) && [6].includes(spec.type))) {
      newActiveTabKey = 6;
    }

    setActiveTab(newActiveTabKey);

  };
  
  const NavigationHeader = () => {
    return (
      <>
        {FeatureTypeOptions.map((item:{id:number,name:string}, index:number) => (
          <Nav.Item key={index}>
            <Nav.Link eventKey={item.id} className="border-bottom">
              <div className="text-start d-flex align-items-center">
                <p className="text-start my-2">{item.name}</p>
              </div>
            </Nav.Link>
          </Nav.Item>
        ))}
      </>
    );
  };

  const TabItems = () => {
    return (
      <>
        {FeatureTypeOptions.map((item:{id:number,name:string}, index:number) => (
            <Tab.Pane key={index} eventKey = {item.id}>
              <div className="border-end border-bottom border-top scrol-cnt ">
                <div className="w-100 text-start ">
                  <p className="text-start head-text fw-bold faq-answer px-3 pt-3">
                    {item.name}
                  </p>
                  {renderFeatures(item.id)}
                </div>                
              </div>
            </Tab.Pane>
        ))}
      </>
    );
  };
  
  const handleTabSelect = (selectedTab: any) => {
    setActiveTab(selectedTab);
  };


  return (
    <Modal
      show={isOpen}
      size={"lg"}
      onHide={() => onClose}
      fullscreen="fullscreen"
      dialogClassName={isMobile ? `modal-90w` : `modal-90w h-100`}
      aria-labelledby="example-custom-modal-styling-title"
      className="featuer-modal "
    >
      <Modal.Body  className={isMobile ? `bg-white p-0` :`bg-white`}>
        <div className="mt-3">

          <div className="d-flex align-items-center ms-3">
            <div>
              <FontAwesomeIcon icon={faAngleLeft} className={isMobile ? `fs-3 mb-2` : `fs-4 mb-2`} onClick={onClose} style={{ cursor: "pointer" }}/>
            </div>     
          <div className="ms-3">
          <h2 className={isMobile ? `ps-2 fs-5` : `fs-4 mb-1`}>FEATURES</h2>
           <h3 className={isMobile ? `fs-6 ps-2` :`fs-6`} >{fullCarName}</h3>
          </div>          
          </div>
          <InputGroupField name="searchFeature" label="" defaultValue={""}
                            placeholder="Search any feature" addOn={faSearch} clearErrors={clearErrors}
                            control={control}  classList={isMobile ? `form-control-sm m-2` :`form-control-sm`} />
          <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeTab} onSelect={handleTabSelect} >
            <div className={isMobile ? `mt-3 d-flex` : `mt-3 row`}>
              <Col lg={3} md={4} sm={5} xs={5} className="pe-0 ps-0 border-end border-bottom border-top border-start-0">
                <Nav variant="pills" className="flex-column">
                  <NavigationHeader/>
                </Nav>
              </Col>
              <Col lg={9} md={8} sm={7} xs={7} className="ps-0 pe-0 border-end border-bottom border-top-0 " >
                <Tab.Content>
                  <TabItems/>
                </Tab.Content>
              </Col>
            </div>
          </Tab.Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FeaturesModal;
