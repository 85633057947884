import React, { useEffect, useState } from 'react';
import Bloglist from '../../../components/admin/blogs/BlogListTable';

const ListBlogs: React.FC = () => {
   
    return (
        <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <Bloglist  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListBlogs;