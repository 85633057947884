import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { isTemplateLiteralToken } from 'typescript';
import { InspectionTypeOptions } from "../../../../config/utils/helper";

const Overview: React.FC<any> = ({ inspectionList }) => {

  const [overViewList, setOverList] = useState<any[]>([]);
  const [fixedData,setFixedData] = useState<any>()
  const [notFixedData,setNotFixedData] = useState<any>()
  const [highlightData,setHighlightData] = useState<any>()
  const [showAll ,setShowAll] = useState(false)

  useEffect(() => {
    const getOverViewData =()=>{

      // Filter inspection data based on inspection types
      const fixed= inspectionList.map((item:any,index:number)=>{
        let currentindex = 0;
        if(item.note!='' && item.inspectionType > 0){
          currentindex++
          return (
            <div key ={index}>
              {(currentindex ==1)?<hr/>:''}
              <p className="text-start text-primary px-3 fw-normal">
                {InspectionTypeOptions[item.inspectionType].name} - {item.name}
              </p>
              <pre style={{ whiteSpace: 'break-spaces', fontFamily: "'Exo 2', sans-serif" }} className="px-3 pb-0">{item.note}</pre> 
            </div>)
        }
      })

      const notFixed= inspectionList.map((item:any,index:number)=>{
          let currentindex = 0;
          if(item.imperfection_note!=''&& item.inspectionType > 0){
            currentindex++
            return (
              <div key ={index}>
                {(currentindex ==1)?<hr/>:''}
                <p className="text-start text-primary px-3 fw-normal">
                  {InspectionTypeOptions[item.inspectionType].name} - {item.name}
                </p>
                <pre style={{ whiteSpace: 'break-spaces', fontFamily: "'Exo 2', sans-serif" }} className="px-3 pb-0 ">{item.imperfection_note}</pre> 
              </div>
              )
          }
    
      })

      // Filter Highlight data 
      const highlight= inspectionList.map((item:any,index:number)=>{
        let currentindex = 0;
        if(item.note!='' && item.inspectionType == 0){
          currentindex++
          return (
            <div key ={index}>
              {(currentindex ==1)?<hr/>:''}
              <pre style={{ whiteSpace: 'break-spaces', fontFamily: "'Exo 2', sans-serif" }} className="px-3 pb-0 mb-3">{item.note}</pre> 
            </div>)
        }
      })
      
      setHighlightData(highlight)
      setFixedData(fixed)
      setNotFixedData(notFixed)
      
    }
    inspectionList.length && getOverViewData()
  }, [inspectionList]);

  const itemToDisplay = showAll? overViewList : overViewList.slice(0,3)
    return (
      <div className={`w-100 text-start engine-accordian`}>
      {itemToDisplay.map((item: any, index: number) => (
          <div key={index}>
            <p className="px-3 pt-0"> {item.name}</p>
            {index === 2 && overViewList.length > 3 && !showAll && (
              <div className="px-3 pt-0">
                <button className="btn btn-primary" onClick={() => setShowAll(true)}>View All</button>
              </div>)}
          <p className={`px-3 pb-2 ${index === 2 && overViewList.length > 3 && !showAll ? 'border-bottom' : 'border-bottom'}`}></p>
          </div>
        ))}
        <div className=''>
          <p className="text-start text-primary head-text fw-bold faq-answer px-3 pt-3">
            HIGHLIGHTS
          </p>
          {highlightData}
          
          <p className="text-start text-primary head-text fw-bold faq-answer px-3 pt-1 ">
            FIXED
          </p>
          {fixedData}
        <hr />

          
          <p className="text-start text-primary head-text fw-bold faq-answer px-3 pt-0 mt-4">
            IMPERFECTIONS
          </p>
          {notFixedData}

        </div>
      </div>
    );
};

export default Overview;
