import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SimpleSpinner from '../../common/Spinner';
import Checkbox from '../../form/Checkbox';


const FuelTable: React.FC<any> = ({ newFuel, showModal, addEditCount }) => {
    const [vehicleFuelData, setVehicleFuelData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedFuel = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');

    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    const columns: any = [
        {
            name: 'Name',
            selector: (row: { name: any; }) => row.name,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedFuel ? <> <Button variant="success" size="sm" className="me-3" onClick={() => editFuel(row)} title="Edit info">
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                        <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id)} title="Remove">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}

                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        const getAllFuel = async () => {
            setIsLoading(true)
            interface QueryParams {
                searchText?: string;
                sortDetails?: {};
                status?: number;
            }
            const params: QueryParams = {};
            if (sortDetails) {
                params.sortDetails = JSON.stringify(sortDetails)
            }

            params.searchText = currentSearchText
            params.status = archivedFuel ? 0 : 1;
            const response = await HTTP.get(`/api/admin/fuel/fetch-all/${currentPage}/${perPage}`, { params: params });
            const { data: { status, data, msg, error, totalRecords } } = response;
            setVehicleFuelData(data.rows);
            setTotalRows(data.count)
            setIsLoading(false)
        }
        getAllFuel()
    }, [currentSearchText, currentPage, perPage, searchText, sortDetails, removedIds, archivedFuel, addEditCount]);

    const editFuel = (model: any) => {
        showModal(model)
    }

    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }

    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false);
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/fuel/remove/${selectedRecord}`);
        const { data: { status, data, totalCount, message, type, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: 'Fuel type deleted successfully', variant: 'success' })
        } else if (status == 0 && type == "assigned") {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: message, variant: 'danger' })

        } else {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }

    const handleRecover = async () => {
        setOpenActivateModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/fuel/recover/${selectedRecord}`);
        const { data: { status, data, totalCount, msg, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: 'Fuel type recovered successfully', variant: 'success' })
        } else {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }

    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    return (<>

        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Fuel types</h2></div>
                <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <Checkbox name="archived" control={control} label="Show removed fuel types"
                        classList="" /></div>
                <div className="p-2">
                    <Button variant="success" onClick={newFuel}>
                        Add fuel type
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={vehicleFuelData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete fuel type"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover fuel type"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
        {/* <SimpleSpinner show={isLoading} /> */}
    </>)
}

export default FuelTable;