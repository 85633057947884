import React, { useState } from 'react';
import ColourTable from '../../../components/admin/colours/ColourDataTable';
import ColourAddEditModal from '../../../components/admin/colours/ColourAddEditModal';

const ListColours: React.FC = () => {
    const [show, setShow] = useState(false);
    const [countAddEdit, setCountAddEdit] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [colourDetails, setColourDetails] = useState({ id: 0, name: '', keyName: '', hexCode:'' });
    const newModal = () => {
        setShow(true);
        setColourDetails({ id: 0, name: '', keyName: '', hexCode:'' })
    }
    const handleClose = () => setShow(false);

    const editModal = (color: any) => {

        setColourDetails(color)
        setShow(true)
    }

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <ColourTable newModal={newModal} showModal={editModal} handleClose={handleClose} countAddEdit={countAddEdit} totalRows={totalRows} setTotalRows={setTotalRows} />
                            <ColourAddEditModal show={show} handleClose={handleClose} colourDetails={colourDetails} setCountAddEdit={setCountAddEdit} totalRows={totalRows} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListColours;