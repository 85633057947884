import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import DropDownField from '../../form/DropDownField';
import CustomModal from '../../common/CustomModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { InputType, SpecificationTypeOptions } from '../../../config/utils/helper';
import Checkbox from '../../form/Checkbox';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        msg: string,
        type: string
    };
}
interface ISpecificationInfo {
    id: number;
    name: string;
    keyName: string;
    options:string;
    basic:string;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    specificationDetails: ISpecificationInfo;
    setAddEditCount: Function
}

const AddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, specificationDetails, setAddEditCount }) => {
    
    useEffect(() => {
        reset(specificationDetails);
    }, [specificationDetails]);

    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch, setValue } = useForm();
    const [dropdownOptions, setDropdownOptions] = useState<any[]>([])
    const [dropdownOptionsHtml, setDropdownOptionsHtml] = useState<JSX.Element[]>([]);
    const [showModal, setShowModal] = useState(false);
    const currentOptions: Array<[]> = (specificationDetails.options !== null || specificationDetails.options !== undefined) && specificationDetails.options !== '' ? JSON.parse(specificationDetails?.options) : []
    const [modalContent, setModalContent] = useState<JSX.Element>(<></>)
    const [formDatas, setFormDatas] = useState<any>()

    const onSubmit = async (data: any) => {
        try {
            //Adding DropDown option to form as an array of objects
            if (dropdownOptions.length > 0) {
                const options: any = [...currentOptions] //Append old options to the new  Array
                dropdownOptions.forEach((element: any, index: number) => { //Appending the new options to the newArray
                    const option = {
                        id: index + 1 + currentOptions.length,
                        keyName: data[element.label].toLowerCase().replace(/ /g, '_'), //Converting space into '_'
                        name: data[element.label]
                    }
                    options.push(option)
                });
                data['options'] = JSON.stringify(options)
            }

            if (data.sort == 0) {
                setError("sortError", {
                    type: "manual", message: "Sort Order is required",
                });
                return
            }

            if (data.id !== 0) {
                data.status = 1;
                const specificationDetails: responseType = await HTTP.put(`/api/admin/specification/edit`, data);
                if (specificationDetails.data.status === 1) {
                    setToast({ open: true, header: 'Success', message: (specificationDetails.data.msg) ? specificationDetails.data.msg : 'Specification updated successfully', variant: 'success' })
                    setAddEditCount((prev: number) => prev + 1)
                    handleClose()
                    reset()
                    setDropdownOptions([])
                    setDropdownOptionsHtml([])
                } else {
                    setToast({ open: true, header: 'failed', message: (specificationDetails.data.msg) ? specificationDetails.data.msg : 'Something went wrong', variant: 'danger' })
                }
            } else {
                setShowModal(true);
                setModalContent(<p className='text-primary'>Please note: Option Type cannot be modified later once saved.</p>)
                setFormDatas(data)
            }
        }
        catch (error) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            

        }

    }

    const closeModal = () => {
        setDropdownOptions([])
        setDropdownOptionsHtml([])
        handleClose()
        reset()
    }

    const closeCustomModal = () => {
        setShowModal(false);
    }

    const handleConfirmClick = async () => {
        try {

            const specificationDetails: responseType = await HTTP.post(`/api/admin/specification/add`, formDatas);
            if (specificationDetails.data.status === 1) {
                setFormDatas('')
                setToast({ open: true, header: 'Success', message: 'Specification created successfully', variant: 'success' })
                setAddEditCount((prev: number) => prev + 1)
                handleClose()
                reset()
            } else {
                setToast({ open: true, header: 'failed', message: specificationDetails.data.msg, variant: 'danger' })
            }

        } catch (err) {
            setToast({ open: true, header: 'failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            closeCustomModal()
        }

    }

    //Create a dynamic option
    useEffect(() => {
        const inputType = watch('fieldType')
        if (inputType === 'dropdown' && specificationDetails.id == 0) {
            addOption()
        } else {
            setDropdownOptionsHtml([])
            setDropdownOptions([])
        }
    }, [watch('fieldType')])

    //Create a new option for dropdown
    const addOption = () => {
        let currentDiv: JSX.Element;
        let currentIndex: number = currentOptions && currentOptions.length > 0 ? currentOptions.length : 0; //To get the current length of options if there is already options are saved


        const hasEmptyInput = dropdownOptionsHtml.some(option => {
            const inputElement = document.getElementById(option.props.id)?.querySelector('input');
            return inputElement ? inputElement.value.trim() === '' : false;
        });


        if (hasEmptyInput) {
            setToast({
                open: true, header: 'failed', message: 'Please fill in all existing options before adding a new one.',
                variant: 'danger'
            })
            return
        }

        if (dropdownOptionsHtml.length > 0) {
            currentDiv = dropdownOptionsHtml[dropdownOptionsHtml.length - 1]
            currentIndex = parseInt(currentDiv.props.id.split('-')[1]); // Splits the string by '-' and takes the second part
        }

        const newOption = (

            <div className='d-flex' key={`option-${currentIndex + 1}`} id={`option-${currentIndex + 1}`}>
                <InputTextField
                    key={dropdownOptionsHtml.length + 1} // Ensure unique key for each element
                    name={`option-${currentIndex + 1}`}
                    label={`Option ${currentIndex + 1}`}
                    placeholder=""
                    classList='flex-grow-1 options'
                    clearErrors={clearErrors}
                    rule={{ required: fieldRequired }}
                    control={control}
                />
                {(dropdownOptionsHtml.length > 0 || (currentOptions && currentOptions.length > 0)) &&
                    <FontAwesomeIcon className='mt-5 ms-2 text-danger' icon={faClose} onClick={() => removeOption(currentIndex + 1)} />
                }
            </div>

        );

        setDropdownOptionsHtml(prevOptions => [...prevOptions, newOption]);
        const option = { label: `option-${currentIndex + 1}` }
        setDropdownOptions(prev => [...prev, option]);

    };

    //Function to remove option
    const removeOption = (id: number) => {
        setDropdownOptionsHtml(prevOptions => prevOptions.filter((item, i) => item.props.id !== `option-${id}`));
        setDropdownOptions(prevOptions => prevOptions.filter((item, i) => item.label !== `option-${id}`));
    };


    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{specificationDetails.id ? "Update Specification" : "Add Specification"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Name"
                                placeholder="Enter Specification name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <InputTextField name="unit" label="Unit"
                                placeholder="Enter Specification unit" clearErrors={clearErrors}
                                control={control} />
                        </div>
                        <div className='mb-1'>
                            <DropDownField name="type" label="Type" options={SpecificationTypeOptions}
                                placeholder="Select type" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <DropDownField name="fieldType" label="Option Type" options={InputType}
                                placeholder="Select an option type" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} isValueKeyname={true} isDisabled={specificationDetails.id > 0}
                            />
                        </div>
                        <div className='text-end mt-3'>
                            {(dropdownOptionsHtml.length > 0 || (currentOptions && currentOptions.length > 0)) &&
                                <Button variant='primary' className="text-end" onClick={addOption}>Add Option</Button>}
                        </div>

                        {/* Current Option */}
                        {currentOptions && currentOptions.length > 0 && currentOptions.map((option: any, index: number) => (
                            <>
                                <div key={index + 1}>
                                    <InputTextField name={`option-${index + 1}`} label={`Option ${index + 1}`}
                                        placeholder="" clearErrors={clearErrors} disabled={true}
                                        control={control} />
                                </div>
                                {setValue(`option-${index + 1}`, option.name)}
                            </>
                        ))}

                        {/* Newly added Option */}
                        {dropdownOptionsHtml.length > 0 && dropdownOptionsHtml.map((option, index) => (
                            <div key={index}>{option}</div>
                        ))}

                        <div className='mb-1'>
                            <InputTextField name="sort" label="Sort Order" type="number" min={1}
                                placeholder="Enter sort order" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                            {errors.sortError && (
                                <Form.Text className="text-danger">
                                    {errors.sortError.message?.toString()}
                                </Form.Text>
                            )}
                        </div>
                        <div className="mx-1 mt-3" style={{ cursor: "pointer" }}>
                            <Checkbox name="basic" control={control} label="Add to basic details"
                                classList="" /></div>
                        <div className='mb-1'>
                            <InputTextField name="id" label=""
                                placeholder="" type="hidden" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button type="submit">{specificationDetails.id ? "Update" : "Add"}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
            <CustomModal isOpen={showModal} title={`Warning`}
                component={modalContent} closeModal={() => setShowModal(false)}
                buttonList={<>
                    <Button variant="secondary" onClick={closeCustomModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleConfirmClick}>
                        Confirm
                    </Button>
                </>} />
        </>
    );
}

export default AddEditModal;