import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


const ParkAndSellBanner = () => {

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/park_and_sell_banner.jpg`;

    return (
        <Container fluid className="top-banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
            <Container className="my-auto">
                <Col md={6} className="py-3">
                    <h5 className="text-primary is-sub-heading mb-1 head-bold">PARK AND SELL</h5>
                    <h1 className="text-light">Introducing premium vehicle park and sell</h1>
                    <Link to="/park-and-sell" className="btn btn-primary">
                        Know more <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                </Col>
            </Container>
        </Container>
    );
};

export default ParkAndSellBanner;