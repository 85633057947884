import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import StarRating from "./StarRating";
import HTTP from "../../config/axios";
import { getFormatMMMddyyy } from "../../config/utils/dateFunctions"
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "react-multi-carousel/lib/styles.css"
import React from "react";
import { trimContent } from "../../config/utils/helper"
import { Link } from "react-router-dom";

const Testimonials = () => {
    interface TestimonialInterface {
        id: number
        date: string;
        name: string;
        image: string;
        testimonial: string;
        rating: number;
        title: string;
    }

    const [testimonialArray, setTestimonialArray] = useState<TestimonialInterface[]>([]);
    const carouselRef = React.useRef<any>(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(5)

    useEffect(() => {
        const getTestimonialData = async () => {
            const response = await HTTP.get(`/api/cms/testimonials/fetch-all/${currentPage}/${perPage}`)
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setTestimonialArray(data)
            } else {
                setTestimonialArray([])
            }
        }
        getTestimonialData()
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1100, min: 767 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    const CustomLeftArrow = () => (
        <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} size={'1x'} />
        </button>
    );

    const CustomRightArrow = () => (
        <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
            <FontAwesomeIcon icon={faChevronRight} size={'1x'} />
        </button>
    );

    return (
        <Container className="py-5 px-3">
            <Row>
                <div className="text-center pb-4">
                    <h2 className="text-primary head-bold">TESTIMONIALS</h2>
                </div>
            </Row>
            <Row>
                <Carousel
                    className="home-type-carousel"
                    swipeable={true}
                    draggable={true}
                    responsive={responsive}
                    ssr={true} // render carousel on server-side.
                    infinite={true}
                    ref={carouselRef}
                    autoPlay={false}
                    autoPlaySpeed={4000}
                    keyBoardControl={true}
                    arrows={false}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-50-px"
                >
                    {testimonialArray.map((testimonial) => (
                        <Col md={12} key={testimonial.id}>
                            <div className="d-flex">
                                <div className="mt-1">
                                    <StarRating rating={testimonial.rating} />
                                </div>
                                <p className="ps-3 mb-0 is-description text-dark">{getFormatMMMddyyy(testimonial.date)}</p>
                            </div>
                            <div>
                                <h3 className="is-testimonials text-dark mb-0">
                                    {testimonial.name}
                                </h3>
                                <p className="mb-0 text-dark fw-bold">{trimContent(testimonial.title, 40)}</p>
                                <br />
                            </div>
                            <div className="d-flex me-2">
                                <Image src={`${process.env.REACT_APP_S3_BASE_URL}/${testimonial.image ? testimonial.image : "testimonial/testimonial_image/avatar.png"}`} height={"100px"} />
                                <p className="text-dark testimonial-text-padding text-break">
                                    {trimContent(testimonial.testimonial, 130)} {testimonial.testimonial.length > 130 && <Link to={`/testimonials#section-${testimonial.id}`}>Read more</Link>}
                                </p>
                            </div>
                        </Col>
                    ))}
                </Carousel>
            </Row>
            <Row className="mt-3">
                <Col md={6} sm={6} xs={6} className="  flex-row-reverse align-items-center  d-flex ">
                    <CustomLeftArrow />
                </Col>
                <Col md={6} sm={6} xs={6} className="align-items-center  d-flex">
                    <CustomRightArrow />
                </Col>
            </Row>
        </Container>
    );
};

export default Testimonials;
