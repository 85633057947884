import React, { useEffect, useState } from 'react';
import FuelDataTable from '../../../components/admin/fuel/FuelDataTable';
import FuelAddEditModal from '../../../components/admin/fuel/FuelAddEditModal';
import { Button } from 'react-bootstrap';

const ListFuel: React.FC = () => {
    const [show, setShow] = useState(false);
    const [fuelDetails, setFuelDetails] = useState({ id: 0, name: '', keyName: '' });
    const [addEditCount, setAddEditCount] = useState(0)
    const handleClose = () => setShow(false);

    const editModal = (make: any) => {
        setFuelDetails(make)
        setShow(true)
    }

    const newFuel = () => {
        setShow(true);
        setFuelDetails({ id: 0, name: '', keyName: '' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <FuelDataTable newFuel={newFuel} showModal={editModal}  addEditCount={addEditCount}/>
                            <FuelAddEditModal show={show} handleClose={handleClose} fuelDetails={fuelDetails} setAddEditCount={setAddEditCount}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListFuel;