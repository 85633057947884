import React from "react";
import { Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface IDropDownField {
    name: string;
    label?: string;
    options: { id?: number, keyName?: string | number; name: string }[] | number[] | string[];
    control: Control;
    placeholder?: string;
    classList?: string;
    rule?: any;
    clearErrors?: Function;
    onChange?: (value: string) => void;
    errText?: string;
    isValueKeyname?: boolean;
    isDisabled?: boolean;
    title?: string;
}

const DropDownField: React.FC<IDropDownField> = ({
    name,
    label,
    options,
    control,
    placeholder = '',
    classList,
    rule,
    clearErrors,
    onChange,
    errText = '',
    isValueKeyname,
    isDisabled = false,
    title
}) => {
    const handleError = () => {
        if (clearErrors) clearErrors();
    };

    if (rule?.required && typeof rule.required === "function") {
        rule.required = rule.required(label);
    }

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>, onChange: Function) => {
        const selectedValue = event.target.value;

        if (onChange) {
            onChange(selectedValue);
        }
        handleError();
    };

    // Check if value set to the dropdown(from DB) is in options list
    // if not use default value
    const verifyAndSetValue = (value: number | string) => {

        const hasValueInOptions = options?.length && options.some((option:any) => {
            if (typeof option == 'object') {
                return isValueKeyname ? option.keyName == value : option.id == value;
            } else {
                return option == value;
            }
        });

        return hasValueInOptions ? value : 'default'
    }

    const OptionList = () => {
        interface OptionObject {
            id?: number;
            keyName?: string | number;
            name: string;
        }

        type Option = OptionObject | string | number;

        return <>
            {options && options.length &&
                options.map((option: Option) => {
                    if (typeof option === 'object') {
                        // Object case: Option is an OptionObject
                        const optionValue = isValueKeyname ? option.keyName : option.id
                        return (
                            <option key={optionValue} value={optionValue}>
                                {option.name}
                            </option>
                        );
                    } else {
                        // String or number case: Option is a string or number
                        return (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        );
                    }
                })
            }
        </>;
    };


    return (
        <div className={classList}>
            <Controller
                name={name}
                control={control}
                rules={rule}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <>
                        {label &&
                            <Form.Label htmlFor={`${name}-id`} className="text-dark fw-semibold fs-6">
                                {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                            </Form.Label>}
                        <Form.Select
                            id={`${name}-id`}
                            name={name}
                            onChange={(e) => handleDropdownChange(e, onChange)}
                            value={ verifyAndSetValue(value) }
                            disabled={isDisabled}
                            title={title}
                        >
                            <option value="default" disabled> {placeholder ? placeholder : `Select ${label}`}</option>
                            <OptionList />
                        </Form.Select>
                        {error?.message &&
                            <Form.Text className="text-danger">
                                {/* Show custom error if present and required message is not triggeres */}
                                {error.type != 'required' && errText ? errText : error.message}
                            </Form.Text>}
                    </>
                )}
            />
        </div>
    );
};

export default DropDownField;