import React, { useEffect, useState } from 'react';
import VehicleDataTable from '../../../components/admin/vehicle/VehcileDataTable';
import { Button } from 'react-bootstrap';

const ListVehicle: React.FC = () => {
    const [show, setShow] = useState(false);
    const [vehicleDetails, setVehicleDetails] = useState({ id: 0, name: '', keyName: '' });
    const handleClose = () => setShow(false);

    const editModal = (make: any) => {
        setVehicleDetails(make)
        setShow(true)
    }

    const newVehicle = () => {
        setShow(true);
        setVehicleDetails({ id: 0, name: '', keyName: '' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <VehicleDataTable showModal={editModal} handleClose={handleClose} show={show} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListVehicle;