import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroupField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import FileUpload from '../../form/FileUpload';
import { Image } from 'react-bootstrap';
import SimpleSpinner from '../../common/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        makeExist: boolean
    };
}
interface IMakeInfo {
    id: number;
    name: string;
    // keyName: string;
    logo: string;
}

interface AddEditMakeProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    makeDetails: IMakeInfo;
    setCountAddEdit: Function;
    totalRows: number;
}

const AddEditMake: React.FC<AddEditMakeProps> = ({ show, handleClose, makeDetails, setCountAddEdit, totalRows }) => {

    const [imageURL, setImageURL] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm();
    const uploadedImage = watch('uploadedImage')

    useEffect(() => {
        reset(makeDetails);
        setImageURL(makeDetails.logo ? `${process.env.REACT_APP_CLOUDFRONT_URL}${makeDetails.logo}` : '')
    }, [makeDetails]);

    useEffect(() => {
        uploadedImage && setImageURL(URL.createObjectURL(uploadedImage) || '')
    }, [uploadedImage])

    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append('id', submitData.id)
            formData.append('name', submitData.name);
            // formData.append('keyName', submitData.keyName);
            formData.append('image', uploadedImage)

            const { data: { status, message, makeExist } }: responseType = await HTTP({
                method: submitData.id ? 'put' : 'post',
                url: `/api/admin/make/${submitData.id ? 'edit' : 'add'}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })
            setIsLoading(false)

            if (!status && makeExist) return // Don't close modal and reset form , if lcoation already exist
            setCountAddEdit((prev: number) => prev + 1)
            handleClose()
            reset()

        }
        catch (error) {
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
            handleClose()
            reset()
            setIsLoading(false)
        }
    }
    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        {makeDetails.id === 0 && <Modal.Title>Add Make</Modal.Title>}
                        {makeDetails.id !== 0 && <Modal.Title>Update Make</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputGroupField name="name" label="Make Name"
                                placeholder="Enter Make name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <FileUpload name="uploadedImage" label="Make Image" accept=".jpeg,.jpg,.png"
                                control={control} rule={{ required: makeDetails.id == 0 ? fieldRequired : false }} />
                            <p className="text-secondary fs-7"><FontAwesomeIcon icon={faExclamationCircle} /> Upload image in png/jpg/jpeg format with 450 * 200 pixel, and less than 2 MB size </p>
                            {/* <Form.Text className="text-black-50">Only Jpeg, png and jpg formats accepted</Form.Text> */}
                        </div>
                        <div>
                            {imageURL && <Image src={imageURL} width={"30%"} />}
                        </div>

                        <InputGroupField name="id" label=""
                            placeholder="" type="hidden" clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {makeDetails.id ? 'Update' : 'Save Changes'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditMake;
