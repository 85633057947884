import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faLock, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import DropDownField from '../../form/DropDownField';

const ClientTable: React.FC<any> = ({ newClient, showModal, handleClose, show }) => {
    const [clientData, setClientData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm<Record<string, any>>({ defaultValues: { clientStatusType: 1, search: "" } });
    const [updateAction, setUpdateAction] = useState<number | null>(null);
    const [clientCurrentStatus, setClientCurrentStatus] = useState(null);
    const clientStatusType = watch("clientStatusType")
    const currentSearchText = watch('search');
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const defaultClientStatus = 1;

    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    const columns: any = [
        {
            name: 'Name',
            selector: (row: { firstName: any, lastName: any }) => row.firstName + " " + row.lastName,
            sortable: true,
        },
        {
            name: 'Phone number',
            cell: (row: { phone: any; }) => row.phone
        },
        {
            name: 'Email',
            cell: (row: { email: any; }) => row.email
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {clientStatusType == 1 ? <>   <Button variant="success" size="sm" className="me-3" onClick={() => editClient(row)} title="Edit user info">
                        <FontAwesomeIcon icon={faPen} />
                    </Button>

                        <Button variant="danger" size="sm" className="me-3" onClick={() => handleDeleteClick(row.id, 0)} title="Delete user">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>

                        <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id, 2)} title="Block user">
                            <FontAwesomeIcon icon={faLock} />
                        </Button>
                    </> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id, 1)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>
                    }
                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        const getAllClient = async () => {
            interface QueryParams {
                searchText?: string;
                sortDetails?: {};
            }
            const params: QueryParams = {};
            params.searchText = currentSearchText
            if (sortDetails) {
                params.sortDetails = JSON.stringify(sortDetails)
            }
            const response = await HTTP.get(`/api/admin/client/fetch-all/${clientStatusType}/${currentPage}/${perPage}`, { params: params });
            const { data: { status, data, msg, error, totalRecords } } = response;
            setClientData(data.rows);
            setTotalRows(data.count)
        }
        getAllClient()
    }, [show, currentPage, perPage, currentSearchText, sortDetails, removedIds, clientCurrentStatus, clientStatusType]);

    const editClient = (client: any) => {
        showModal(client)
    }

    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    const handleDeleteClick = (id: number, status: number) => {
        setSelectedRecord(id);
        setUpdateAction(status)
        setIsOpenModal(true)
    }
    const handleActivateClick = (id: number, status: number) => {
        setSelectedRecord(id);
        setUpdateAction(status)
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setUpdateAction(null)
        setIsOpenModal(false)
        setOpenActivateModal(false)
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        setOpenActivateModal(false)
        setIsLoading(true);
        if (updateAction === 0) {
            const response = await HTTP.patch(`/api/admin/client/remove/${selectedRecord}`);
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setRemovedIds([...removedIds, selectedRecord])
                setIsLoading(false);
                setToast({ open: true, header: 'Success', message: 'Client removed successfully', variant: 'success' })
            } else {
                setIsLoading(false);
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

            }
        } else if (updateAction === 1) {
            const response = await HTTP.patch(`/api/admin/client/recover/${selectedRecord}`);
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setRemovedIds([...removedIds, selectedRecord])
                setIsLoading(false);
                setToast({ open: true, header: 'Success', message: 'Client recovered successfully', variant: 'success' })
            } else {
                setIsLoading(false);
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

            }
        }
        else {
            const response = await HTTP.patch(`/api/admin/client/block/${selectedRecord}`);
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setRemovedIds([...removedIds, selectedRecord])
                setIsLoading(false);
                setToast({ open: true, header: 'Success', message: 'Client blocked successfully', variant: 'success' })
            } else {
                setIsLoading(false);
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

            }
        }

        setIsLoading(false);
    }

    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    const clientStatusTypeArray = [
        {
            id: 1,
            name: "Active"
        },
        {
            id: 0,
            name: "Removed"
        },
        {
            id: 2,
            name: "Blocked"
        }
    ]

    return (<>
        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Clients</h2></div>
                <div className="p-2">
                    <DropDownField label="" name="clientStatusType" options={clientStatusTypeArray} control={control} placeholder="Choose type" />
                </div>
                <div className="p-2">
                    <Button variant="success" onClick={newClient}>
                        Add client
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={clientData}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Client"
            component={<p>Are you sure you want to continue?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover client"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
    </>)
}

export default ClientTable;
