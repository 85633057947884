import DataTable from 'react-data-table-component';   
import AgeReportTable from './AgeReportDataTable';
import ModelWiseReport from './ModelWiseReportData';
import SearchCountOnModel from './SearchCountOnModel'
import { PaginationChangeRowsPerPage } from 'react-data-table-component/dist/DataTable/types';
import EnquiryReportDataTable from './EnquiryReportDataTable';
import VehicleSoldReport from './VehicleSoldReport';

interface ReportDataProps {
    reportData:any
    reportTypes:string
    handlePageChange:PaginationChangeRowsPerPage | undefined
    handlePerRowsChange:PaginationChangeRowsPerPage | undefined
}


const ReportTable: React.FC<ReportDataProps> = ({reportData,reportTypes,handlePageChange,handlePerRowsChange}) => {
    
    switch(reportTypes){
        case 'publishingAge':
            return <AgeReportTable reportData= {reportData} 
                        handlePageChange= {handlePageChange} 
                        handlePerRowsChange ={handlePerRowsChange} />
        case 'modelWiseCount':
            return <ModelWiseReport reportData= {reportData} 
                        handlePageChange= {handlePageChange} 
                        handlePerRowsChange ={handlePerRowsChange} />
        case 'searchedVehicle':
            return <SearchCountOnModel reportData= {reportData} 
                        handlePageChange = {handlePageChange} 
                        handlePerRowsChange ={handlePerRowsChange} />
        
        case 'enquiryVehicle':
            return <EnquiryReportDataTable reportData={reportData}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange} />
        
        case 'soldVehicles':
            return <VehicleSoldReport reportData={reportData}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange} />


                

        default:
            return null
    }


}
export default ReportTable