import { Container,Row,Col,Form,Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import InputTextField from "../../../components/form/InputTextField";
import { fieldRequired,emailPattern } from "../../../config/validations/rules";
import HTTP from "../../../config/axios";
import SimpleSpinner from "../../../components/common/Spinner";
import SimpleToast from "../../../components/common/Toast";

const ForgottenPassword = ()=>{
    const { register, handleSubmit, formState: { errors }, setError, clearErrors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    
    const onSubmit = async (data: any) => {
        try{
            setIsLoading(true)
            const response = await HTTP.post('/api/reset-your-password',data)
            setToast({open: true, header: response.data.status?'Success':"Failed", message: response.data.message?response.data.message:'Something went wrong', variant: response.data.status?'success':'danger' })
        }   
        catch(err){
            setToast({open: true, header:'Failed',message:'Something went wrong',variant:'danger'})
        } 
        finally{
            setIsLoading(false)
        }
    }

    return(<>
        <div className="App mt-4">
            <Container className="pt-5">
                <Row className="my-5">
                    <h2 className="text-center text-primary">Forgot Password</h2>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        < div className="mb-3">
                            <InputTextField name="loginId" label="To reset your password, please enter your Login ID "
                                placeholder=""  clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired, pattern: emailPattern }} />
                        </div>
                        < div className="mb-3">
                            <Button type="submit">Email Reset Instructions</Button>
                        </div>
                    </Form>
                </Row>
            </Container>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </div>
        
    </>)
}

export default ForgottenPassword