import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        dealershipExist: boolean
    };
}
interface IDealershipInfo {
    id: number;
    name: string;
    keyName: string;
}

interface AddEditDealershipProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    dealershipDetails: IDealershipInfo;
    setCountAddEdit: Function;
}

const AddEditDealership: React.FC<AddEditDealershipProps> = ({ show, handleClose, dealershipDetails, setCountAddEdit }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm();

    useEffect(() => {
        reset(dealershipDetails);
    }, [dealershipDetails]);


    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            let formData: any = {}
            formData.id = dealershipDetails.id.toString()
            formData.name = submitData.name;
            const { data: { status, message, dealershipExist } }: responseType = await HTTP({
                method: submitData.id ? 'put' : 'post',
                url: `/api/admin/dealership/${submitData.id ? 'edit' : 'add'}`,
                data: formData
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })

            setIsLoading(false)
            if (!status && dealershipExist) return // Don't close modal and reset form , if lcoation already exist
            setCountAddEdit((prev: number) => prev + 1)
            handleClose()
            reset()

        }
        catch (error) {
            setIsLoading(false)
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
            console.log("Error on Dealership add/ edit api", error)
        }
    }


    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{dealershipDetails.id ? 'Update' : 'Add'} Dealership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Dealership Name"
                                placeholder="Enter Dealership name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {dealershipDetails.id ? 'Update' : 'Save Changes'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditDealership;