import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useState, useEffect, Fragment } from "react";
import HTTP from "../../../config/axios";

const HowitWorks = () => {
    const [howItWorksMobile, sethowItWorksMobile] = useState<any>({});
    const [howItWorksWeb, sethowItWorksWeb] = useState<any>({});
    const [isMobile, setIsMobile] = useState(false)
    const [isActive, setIsActive] = useState(false);

    // useEffect(() => {
    //     const getCmsContent = async () => {
    //         let sellCarHowWork = 'sellcar-how-it-works'
    //         const response = await HTTP.get(`/api/cms/fetch-one/${sellCarHowWork}`);
    //         const { data: { status, data, msg, error } } = response
    //         sethowItWorks(data)
    //     }
    //     getCmsContent()
    // }, [])

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 775)
        window.addEventListener('resize', handleResize);
        setIsMobile(window.innerWidth <= 775);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const howItWorksCms = async () => {
            const cmsData = await HTTP.post(`/api/cms/fetch-all`,
                {
                    nameList: ["sellcar-how-it-works-web", "sellcar-how-it-works-mobile"]
                })
            const { data: { status, data, msg, error } } = cmsData

            data.forEach((cmsContent: any) => {
                if (cmsContent.name === "sellcar-how-it-works-web") {
                    sethowItWorksWeb(cmsContent);
                } else if (cmsContent.name === "sellcar-how-it-works-mobile") {
                    sethowItWorksMobile(cmsContent);
                }
            })
        }

        howItWorksCms()
    }, []);

    const handleButtonClick = () => {
        setIsActive(!isActive);
    };

    return (
        <div className="App">
            <Container className="pb-2">
                <Row>
                    <div className="text-center pb-4 pt-3">
                        <h2 className="text-primary head-bold">
                            HOW IT WORKS <span className={isActive ? "arrow-down active" : "arrow-down"} style={{ cursor: "pointer" }} onClick={handleButtonClick}></span>
                        </h2>
                    </div>
                </Row>
                {!isActive && <Fragment>
                    {!isMobile ? (
                        <div dangerouslySetInnerHTML={{ __html: howItWorksWeb && howItWorksWeb.content }}>
                        </div>) :
                        (<div dangerouslySetInnerHTML={{ __html: howItWorksMobile && howItWorksMobile.content }}>
                        </div>)}
                </Fragment>}
            </Container>
        </div>
    );
};

export default HowitWorks;
