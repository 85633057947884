import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";

const Benefits = () => {

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/why_choose_banner.jpg`;

    return (
        <div className="App mt-4">
            <Container fluid className="top-banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col md={12}>
                        <h6 className="text-primary mb-1 head-bold">BENEFITS</h6>
                        <h1 className="text-light">Why choose us ?</h1>
                        <Row>
                            <Col md={6} className="pt-4 ">
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/benefits/webuy.png`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        We buy all cars
                                    </h6>
                                </div>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/benefits/doorstep.png`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Free door step evaluation
                                    </h6>
                                </div>
                                <div className="d-flex">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/benefits/hour.png`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        One hour payment
                                    </h6>
                                </div>
                            </Col>
                            <Col md={6} className="pt-4 ">
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/benefits/exchange.png`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Exchange options
                                    </h6>
                                </div>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/benefits/finance.png`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        We take care of finance closure
                                    </h6>
                                </div>
                                <div className="d-flex">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/benefits/rc.png`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Assured RC transfer
                                    </h6>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Container>
        </div>
    );
};

export default Benefits;
