import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputTextField from "../../form/InputTextField"
import DropDownField from "../../form/DropDownField"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { fieldRequired, phoneNumberPattern, emailPattern, minLength, maxLength, validateDob, pinCodePattern, validatePAN } from '../../../config/validations/rules';
import HTTP from "../../../config/axios";
import SimpleToast from "../../common/Toast";
import SimpleSpinner from "../../common/Spinner";
import EmiCalculator from "./EmiCalculator";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import { encryptLocalStorage } from "../../../config/utils/storage";

const LoanApplicationForm = () => {

    const defaultFormValues = {
        address: "",
        districtId: "",
        dob: "",
        email: "",
        gender: "",
        name: "",
        panNumber: "",
        phone: "",
        pincode: "",
        stateId: ""
    }

    const [successMsg, setSuccessMsg] = useState(false)
    const [states, setStates] = useState([])
    const [districts, setDistricts] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [emiCalculator, setEmiCalculator] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const genderOptions = [{ id: 1, name: "Male", keyname: "male" }, { id: 2, name: "Female", keyname: "female" }]
    const { control, handleSubmit, formState: { errors }, clearErrors, setError, watch, setValue, reset, getValues } = useForm<Record<string, any>>({ defaultValues: { address: '' } });

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        const storedData = encryptLocalStorage.getItem("loanApplicationData");
        encryptLocalStorage.removeItem("loanApplicationData");
        if (storedData) {
            console.log(storedData)
            reset(storedData);
        }
    }, []);

    useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 565); 
    };

    handleResize();


    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


    const onSubmit = async (data: any) => {

        try {
            setIsLoading(true);
            const response = await HTTP.post(`/api/finance/add-loan-application`, data);
            if (response.data.status === 1) {
                setSuccessMsg(true);
                setToast({ open: true, header: 'Success', message: 'Application submitted successfully. Our team will contact you', variant: 'success' })
                setEmiCalculator(true);
            } else {
                setError(response.data.type ? response.data.type : "serverError", {
                    type: "manual", message: response.data.message,
                });
                setToast({ open: true, header: 'Failed', message: response.data.message, variant: 'danger' })

            }
        }
        catch (err) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsLoading(false);
            reset(defaultFormValues);
            setIsLoading(false);
        }

    }
    useEffect(() => {
        try {
            const getAllStates = async () => {
                const response = await HTTP.post(`/api/booking/states/fetch-all`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setStates(data)
                }
            }
            getAllStates()
        }
        catch (error) {
            console.log("Error in states fetch API", error)
        }
    }, []);

    const selectedStateId = watch('stateId')
    useEffect(() => {
        try {
            setValue('districtId', 'default'); setDistricts([])
            const getAllDistrict = async () => {
                const response = await HTTP.post(`/api/booking/districts/fetch-all/${selectedStateId}`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setDistricts(data)
                }
            }
            selectedStateId && getAllDistrict()
        }
        catch (error) {
            console.log("Error in states fetch API", error)
        }
    }, [selectedStateId]);

    const breadCrumbList = [
        { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
        { title: "Finance", path: "/finance", active: false, linkClass: "breadcrumb-text" },
        { title: "Loan Application", path: "/finance/loan-application", active: true, linkClass: "text-primary fw-semibold" }
    ]

    const handleSignIn = () => {
        const formValues = getValues();
        encryptLocalStorage.setItem("loanApplicationData", JSON.stringify(formValues));
        encryptLocalStorage.setItem("previousLoggedoutLocation",currentLocation.pathname+"#application-form")
        navigate("/sign-in")
    }



    return (
        <div className="App">
            <Breadcrumbs breadcrumbData={breadCrumbList} />
            {!emiCalculator ? <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="py-3">

                        <Col md={12} >
                            <div className="text-center pb-4">
                                {/* <h3 className="text-primary ">Fill the loan application form</h3>
                                <h2 className="text-dark ">Update the details</h2> */}
                                <h2 className="text-primary ">Fill the loan application form</h2>
                            </div>
                        </Col>

                        {/* {successMsg && <span className="text-success fw-normal"> Application submitted successfully</span>} */}
                        <Col md={6}>
                            <div className="mb-1">
                                <Row className="g-3">
                                    <Col md={12}>
                                        <InputTextField name="name" label="Name" control={control} upperCase={true}
                                            clearErrors={clearErrors} rule={{ required: fieldRequired }} placeholder=" Enter Name " />
                                    </Col>
                                    <Col>
                                        <p className="text-secondary "><FontAwesomeIcon icon={faExclamationCircle} /> Please enter your name as it appears on your PAN card</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Row className="g-3">
                                <Col md={12}>
                                    <InputTextField name="phone" type="tel" max={10}
                                        label="Phone number" control={control}
                                        rule={{ required: fieldRequired, pattern: phoneNumberPattern }} placeholder=" Enter ph.no " />
                                </Col>
                                <Col>
                                    <p className="text-secondary "><FontAwesomeIcon icon={faExclamationCircle} /> Please enter the phone number linked to your PAN card</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <InputTextField name="email" type="email" label="Email"
                                    placeholder=" Email Address" clearErrors={clearErrors}
                                    control={control} rule={{ required: fieldRequired, pattern: emailPattern }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <InputTextField name="panNumber" type="text" label="Loan applicant PAN number"
                                    placeholder="PAN Number" clearErrors={clearErrors} upperCase={true} maxLength={10}
                                    control={control} rule={{ required: fieldRequired, pattern: validatePAN }} />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-3">
                                <InputTextField name="dob" type="date" label="Date of birth"
                                    clearErrors={clearErrors}
                                    control={control} rule={{ required: fieldRequired, validate: (value: any) => validateDob(value) }} />
                            </div>
                        </Col><Col md={3}>
                            <div className="mb-3">
                                <DropDownField name="gender" label="Gender"
                                    options={genderOptions} placeholder="Choose Gender"
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <InputTextField name="address" type="text" label="Address"
                                    clearErrors={clearErrors} placeholder=" Enter Address "
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-3">
                                <InputTextField name="pincode" type="text" label="Pin code"
                                    clearErrors={clearErrors} placeholder=" Enter Pin code "
                                    control={control} rule={{ required: fieldRequired, pattern: pinCodePattern }} />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-3">
                                <DropDownField name="stateId" label="State"
                                    options={states} placeholder=" State name "
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <DropDownField name="districtId" label="District"
                                    options={districts} placeholder="District name"
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                        </Col>
                        <Row className={`${isMobile ? 'text-center' : 'justify-content-end'} pe-0`}>
      {isLoggedIn ? (
        <Button className="col-md-2 mx-2" variant="primary" type="submit">
          Proceed
        </Button>
      ) : (
        <Button className="col-md-2 mx-2" variant="primary" onClick={handleSignIn}>
          Proceed
        </Button>
      )}
    </Row>

                    </Row>
                </Form>
            </Container> :
                <EmiCalculator />
            }
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </div >
    );
};

export default LoanApplicationForm;
