import { Row, Tab } from "react-bootstrap"
import { Link } from "react-router-dom"
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HelpSupport = () => {

  return (
    <Tab.Pane eventKey="sideTab_4">
      <Row>
        <h2 className="text-dark is-testimonials pb-3">
          Help & Support
        </h2>
        <p className="text-primary">Get help</p>
        <p>Buyer support</p>
        <hr />
        <p>Seller support</p>
        <p className="text-primary">Conversation Archives</p>
        <p>Resolved queries</p>
        <p className="text-primary">Find answers</p>
        <p><Link to="/faq" style={{ cursor: "pointer" }} className="text-secondary">FAQs</Link></p>
        <hr />
        <p><Link to="/terms-and-conditions" target="blank" style={{ cursor: "pointer" }} className="text-secondary">Terms and conditions</Link></p>
        <hr />
        <p> <Link to="https://www.popularmaruti.com/network?locationtab=usedcars" target="_blank" style={{ cursor: "pointer" }} className="text-primary">Contact Us</Link></p>
        <p> <Link to="/privacy-policy"  target="blank" style={{ cursor: "pointer" }} className="text-secondary">Privacy policy</Link></p>
        <p>  <Link to='tel:18001238090'><FontAwesomeIcon className="align-self-center pe-3" icon={faPhone} />1800 123 8090</Link></p>
      </Row>
    </Tab.Pane>
  )
}


export default HelpSupport