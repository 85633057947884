import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ImageCellForDataTable from '../../common/ImageCellForDataTable';
import Checkbox from '../../form/Checkbox';
import { downloadFileFromApi } from '../../../config/utils/helper';


interface QueryParams {
    searchText?: string;
    sortDetails?: {};
}

interface IgetAllLocation {
    page: number, limit: number,
    searchText?: string,
    sortColumn?: { sortColumn: string, sortDirection: string }
}

const LocationTable: React.FC<any> = ({ newModal, showModal, handleClose, countAddEdit, totalRows, setTotalRows, setSortOrder }) => {
    const [locationData, setLocationData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedLocations = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            keyName: 'name'
        },
        {
            name: 'Address',
            selector: (row: { address: string; }) => row.address,
            sortable: true,
            keyName: 'address'
        },
        {
            name: 'Dealership',
            selector: (row: { dealership: string; }) => row.dealership,
            sortable: true,
            keyName: 'dealership'
        },
        {
            name: 'Image',
            cell: (row: { image: string; }) => <ImageCellForDataTable value={row.image} maxWidth="60px" />,
            center: true,
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedLocations ? <> <Button variant="success" size="sm" className="me-3" onClick={() => editLocation(row)} title="Edit info">
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                        <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id)} title="Remove">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}

                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        try {
            const getAllLocations = async () => {
                setIsLoading(true)
                const locationStatus = archivedLocations ? 0 : 1
                const params = { currentPage, perPage, sortDetails: JSON.stringify(sortDetails), searchText: currentSearchText, status: locationStatus };
                const response = await HTTP.get(`/api/admin/location/fetch-all`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setLocationData(data);
                setTotalRows(totalRecords)
                if (!currentSearchText) {
                    setSortOrder(totalRecords)
                }
            }
            getAllLocations()
        }
        catch (error) {
            console.log("Error in location fetch api")
        }
        finally {
            setIsLoading(false)
        }
    }, [countAddEdit, currentPage, perPage, searchText, sortDetails, removedIds, archivedLocations, currentSearchText]);

    const getAllLocationsExcel = async () => {
        try{
            setIsLoading(true)
            const locationStatus = archivedLocations ? 0 : 1
            const params = { sortDetails: JSON.stringify(sortDetails), searchText: currentSearchText, status: locationStatus };
            const response = await HTTP.get(`/api/admin/location/fetch-all/excel`, { params, responseType: 'blob', });

            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            downloadFileFromApi(response.data, filename)
            setIsLoading(false);
        }
        catch(error){
            console.error('Error downloading Excel file:', error);
            setIsLoading(false);
        }
         
    }

    //Invokng the edit modal for editing the location
    const editLocation = (location: any) => {
        showModal(location)
    }

    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    //handling Delete of locations
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }
    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }
    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false);
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/location/remove/${selectedRecord}`);
        const { data: { status, error, message, type } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message, variant: 'success' })
        } else if (status == 0 && type == "assigned") {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: message, variant: 'danger' })

        } else {
            setToast({ open: true, header: 'Failed', message, variant: 'danger' })
        }
        setIsLoading(false);
    }

    const handleRecover = async () => {
        setOpenActivateModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/location/recover/${selectedRecord}`);
        const { data: { status, data, totalCount, msg, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setIsLoading(false);
            setToast({ open: true, header: 'Success', message: 'Location recovered successfully', variant: 'success' })
        } else {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }

    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    return (<>


        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Locations</h2></div>
                {/* <div className="p-2 mt-2 text-success" title="Download Excel Report">
                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={getAllLocationsExcel} style={{cursor:'pointer'}} /> 
                </div> */}
                <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <Checkbox name="archived" control={control} label="Show removed locations"
                        classList="" /></div>
                <div className="p-2">
                    <Button variant="success" onClick={newModal}>
                        Add location
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>
        <DataTable
            columns={columns}
            data={locationData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete location"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover location"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />


    </>)
}

export default LocationTable;