
import { Row, Col } from "react-bootstrap";
import { ComparisonContext } from "../../../../config/context/comparisonContext";
import { useContext } from "react";


const CompareEngineMob = () => {

    const { compareDetails, setCompareDetails } = useContext(ComparisonContext);

    const carData = compareDetails.first || compareDetails.second || compareDetails.third || compareDetails.fourth
    const engineSpecList = carData?.specs?.[2] // index 1 have engine specific records
    const firstCarSpecs = compareDetails?.first?.specs?.[2]
    const secondCarSpecs = compareDetails?.second?.specs?.[2]


    return (
        <div className="App">

            {engineSpecList && Object.keys(engineSpecList).map((spec: any, index: number) => (
                <Row className="border-bottom px-0 text-break" key={index}>
                    <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                        <p className="text-start mt-2">{engineSpecList[spec].name}</p>
                    </Col>
                    <Col md={2} className="comparsion-img-mob-out text-start border-end">
                        <p className="text-start text-dark is-description mt-2">{firstCarSpecs?.[spec].value || '-'}</p>
                    </Col>
                    <Col md={2} className="comparsion-img-mob-out text-start">
                        <p className="text-start text-dark is-description mt-2">{secondCarSpecs?.[spec].value || '-'}</p>
                    </Col>
                </Row>
            )) }
            
        </div >
    );
};

export default CompareEngineMob;
