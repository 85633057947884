import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from "react-bootstrap";
import HTTP from '../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { encryptLocalStorage } from '../../config/utils/storage';
import { useNavigate, useLocation } from "react-router-dom";

const SearchBox = ({ searchIcon, showSearchModal }: { searchIcon?: boolean, showSearchModal?: Function }) => {

    const [searchData, setSearchData] = useState([])
    const locationName = encryptLocalStorage.getItem("selectedLocation")
    const [searchValue, setSearchValue] = useState('');
    const [isMobile, setIsMobile] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const searchResultsRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 565);
    }, []);

    const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        if (value) {
            try {
                const response = await HTTP.get(`/api/vehicle/site-search/${value}`)
                const { data: { status, data, totalCount, msg, error } } = response
                if (status == 1) {
                    setSearchData(data)
                } else {
                    setSearchData([])
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const handleSearchclick = (item: any) => {

        let searchCriteria = {};
        let makeKeyName = "";
        let modelKeyName = "";
        if (item.type == "make") {
            searchCriteria = { "makeList": [item.key_name],"modelList": [], otherMakeList: [item.key_name] }
            makeKeyName = item. key_name;
        } else if (item.type == "model") {
            searchCriteria = { "makeList": [item.make_key_name], "modelList": [item.key_name], otherMakeList: [item.make_key_name] }
            makeKeyName = item.make_key_name;
            modelKeyName = item.key_name;
        } else if (item.type == "fuel") {
            searchCriteria = { "fuelType": [item.key_name] };
        } else if (item.type == "transmission") {
            searchCriteria = { "transmissionType": [item.key_name] };
        } else if (item.type == "bodyType") {
            searchCriteria = { "bodyType": [item.key_name] };
        } else {
            searchCriteria = searchCriteria = {};
        }

        if(makeKeyName !=''){
            saveVehicleSearches(makeKeyName, modelKeyName);
        }
        
        const filteredCriteria = getFilteredCriteria(searchCriteria)
        setSearchValue('')
        if(showSearchModal) showSearchModal(false)
        
        navigate(`/buy-used-car?search-criteria=${JSON.stringify(filteredCriteria)}&from=search-box `)
    }

    const saveVehicleSearches = async (makeKeyName: string, modelKeyName: string) => {
        try {
            const response = await HTTP.post('api/vehicle/saveVehicleSearches', {
                makeKeyName: makeKeyName,
                modelKeyName: modelKeyName
            });
        } catch (error) {
            console.error('Error saving search', error);
            throw(error)
        }
    }

    const getFilteredCriteria = (searchCriteria: any) => {
        let count = 1;
        for (const key in searchCriteria) {
            count = count + 1
            const arrString = JSON.stringify(searchCriteria[key]);
            if (arrString === JSON.stringify(["select"]) || arrString === JSON.stringify("select") || arrString === JSON.stringify(["[,]"])) {
                delete searchCriteria[key];
            }
        }
        if(encryptLocalStorage.getItem('selectedLocation')){
            searchCriteria.selectedLocation = encryptLocalStorage.getItem('selectedLocation')
        }
        return searchCriteria;
    }
    
    const handleClickOutside = (event:any) => {
        if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
          setSearchValue(''); // Close the dropdown by clearing the search value
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    useEffect(() => {
        setSearchValue('');
      }, [location.pathname]);

    return (
        <>
            <InputGroup className="custom-search w-100">
                {!searchIcon && <InputGroup.Text id="basic-addon3">
                    <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>}
                <Form.Control
                    placeholder="search"
                    onKeyUp={handleKeyUp}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    width={isMobile ? "100%" : "30%"}
                />
            </InputGroup>
            {searchData.length > 0 && (
                <div ref={searchResultsRef} className={`search-results ${searchValue.length > 0 ? 'active' : ''}`}>
                    <ul className="search-list-ul">
                        {searchData.map((item: any, index: number) => {
                            let displayName;
                           displayName = `<p class="mb-0">${item.name} in ${item.type }</p>`
                            
                            // if (item.type == "make") {
                            //     displayName = `<p class="mb-0"><b>${item.name}</b> in make</p>`;
                            // } else if (item.type == "model") {
                            //     displayName = `<p class="mb-0><b>${item.make_name} ${item.name}</b> in model</p>`;
                            // } else if (item.type == "fuel") {
                            //     displayName = `<p class="mb-0><b>${item.name}</b> in fuel</p>`;
                            // } else if (item.type == "transmission") {
                            //     displayName = `<p class="mb-0><b>${item.name}</b> in transmission</p>`;
                            // } else if (item.type == "bodyType") {
                            //     displayName = `<p class="mb-0><b>${item.name}</b> in body type</p>`;
                            // } else {
                            //     displayName = `<p class="mb-0><b>${item.name}</b></p>`;
                            // }
                            return (
                                <li
                                    className="search-list-li"
                                    key={index}
                                    dangerouslySetInnerHTML={{ __html: displayName }}
                                    onClick={() => handleSearchclick(item)}
                                ></li>
                            )
                        })}
                    </ul>
                </div>
            )}

            {searchValue.length > 0 && searchData.length == 0 && (
                <div className={`search-results active`}>
                    <ul className="search-list-ul">
                        <li className="search-list-li" key="0" > No Search Results Found</li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default SearchBox;
