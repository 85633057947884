import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ModelDataTable from '../../../components/admin/model/VehicleModelTable';
import AddEditModal from '../../../components/admin/model/addEditModal';

const ModelDashboard: React.FC = () => {
    const [show, setShow] = useState(false);
    const [addEditCount, setAddEditCount] = useState(0)
    const [modelDetails, setModelDetails] = useState({ id: 0, name: '', makeId: null, makeName: '' });
    const newModel = () => {
        setShow(true);
        setModelDetails({ id: 0, name: '', makeId: null, makeName: '' })
    }
    const handleClose = () => setShow(false);

    const editModal = (model: any) => {
        setModelDetails(model)
        setShow(true)
    }

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                    {/* Topbar */}

                    <div className="container-fluid">
                        <div>
                            <ModelDataTable newModel={newModel} editModalDetails={editModal} addEditCount={addEditCount} />
                            <AddEditModal show={show} handleClose={handleClose} modelDetails={modelDetails} setAddEditCount={setAddEditCount} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModelDashboard;