import React, { useEffect, useState } from 'react';
import TransactionDataTable from '../../../components/admin/transaction/TransactionDataTable';

const ListTransaction: React.FC = () => {

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <TransactionDataTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListTransaction;