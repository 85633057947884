import { useEffect, useState } from "react";
import { Button, Col, Nav, Row, Tab, Image, Form, Tabs } from "react-bootstrap";
import { getFormatEEEDoMMMyyyy, getFormatTimehhmmaa } from "../../../config/utils/dateFunctions";
import HTTP from "../../../config/axios";
import { convertStringToSlug, parsePriceToFloat } from "../../../config/utils/helper";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { useNavigate } from "react-router-dom";
import CustomPopover from "../../common/CustomPopover";

const BookingList = ({ isMobile }: { isMobile?: boolean }) => {

  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const hanldeBooking = async () => {
      const response = await HTTP.get(`/api/booking/fetch-all`);
      const { data: { status, data, msg, error } } = response
      if (status == 1) {
        setBookings(data)
      }
    }
    hanldeBooking()
  }, [])

  const formattedPrice = (price: any) => {
    let priceFormatted = "";
    let str = ""
    if (price < 1000 ) {
      priceFormatted = price
    } else if (price >= 1000 && price < 100000) {
      str = parsePriceToFloat(price, 1000, 2)
      priceFormatted = str + " K";
    } else if (price >= 100000 && price < 10000000) {
      str = parsePriceToFloat(price, 100000, 2)
      priceFormatted = str + " Lakhs"
    } else {
      str = parsePriceToFloat(price, 10000000, 2)
      priceFormatted = str + " Crore";
    }
    return priceFormatted;
  };

  const getDetailPage = (detail: any) => {
    const carSlugName = `${detail.year} ${detail.make} ${detail.model} ${detail.variant}`;
    const slugCarName = convertStringToSlug(carSlugName);
    navigate(`/buy-used-car/${slugCarName}/${detail.vehicleId}/?search-criteria={}`);
  }


  return (
    <Tab.Pane eventKey="sideTab_2" className="profile-nav-scroll">
      {!isMobile ? (
        <Row className="pt-4">
          <h2 className="text-dark is-testimonials pb-3">
            Bookings
          </h2>
          {bookings.length ? bookings.map((bookingsData: any) => (
            <Row key={bookingsData.id} className="pb-3" onClick={() => getDetailPage(bookingsData)} style={{ cursor: 'pointer' }}>
              <Col xl={2} lg={3} md={4} sm={4} >
                <Image
                  src={`${process.env.REACT_APP_CLOUDFRONT_URL}${bookingsData.image}`}
                  className="rounded-circle"
                  height={"120px"}
                  width={"120px"}
                />
              </Col>
              <Col
                lg={9}
                md={8}
                sm={8}
                className="align-self-center ps-4"
              >
                <div className="d-flex justify-content-between">
                  <h3 className="text-dark mb-0" >
                    {`${bookingsData.year} ${bookingsData.make} ${bookingsData.model} ${bookingsData.variant}`}
                  </h3>
                  
                  <CustomPopover placement="top"
                    body="<p>  Contact us at:</p>
                    <strong class='text-primary'>1800 123 8090</strong><br />
                   <strong class='text-primary'>+91 73061 12802</strong><br />
                    Or message us on WhatsApp!"

                    component={<p className="text-primary mb-0"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click from bubbling up to the parent
                      }}> Need&nbsp;help?</p>} />
                 
              
                </div>
                <p className="mb-0">{getFormatEEEDoMMMyyyy(bookingsData.bookingDate)} - {getFormatTimehhmmaa(bookingsData.bookingDate)}</p>
                <h3 className="text-primary is-testimonials">
                  ₹{formattedPrice(bookingsData.price)}
                </h3>
              </Col>
              {bookings.length >= 2 && <hr className="mt-4" />}
            </Row>
          )) :
            (<Row className="pt-4">
              <p className=" text-dark">No records found </p>
            </Row>)
          }
        </Row>) : (
        <Row >
          <h2 className="text-dark is-testimonials pb-3">
            Bookings
          </h2>

          {bookings.length == 0 && <p className="text-dark mb-0">
            No records found
          </p>}
          {bookings && bookings.map((bookingsData: any) => (
            <Row
              key={bookingsData.id}
              className="pb-3 "
              onClick={() => getDetailPage(bookingsData)}
              style={{ cursor: "pointer" }}
            >
              <Col
                sm={4}
                xs={4}
                col={4}
                className="d-flex align-items-center"
              >
                <Image
                  src={`${process.env.REACT_APP_CLOUDFRONT_URL}${bookingsData.image}`}
                  className="rounded-circle object-fit-cover"
                  height={"90px"}
                  width={"90px"}
                />
              </Col>
              <Col sm={8} xs={8} col={8} className="px-0">
                <p className="text-dark mb-0 fw-semibold">
                  {`${bookingsData.year} ${bookingsData.make} ${bookingsData.model} ${bookingsData.variant}`}
                </p>
                <small className="mb-0 fs-6">
                  {getFormatEEEDoMMMyyyy(bookingsData.bookingDate)} <br /> {getFormatTimehhmmaa(bookingsData.bookingDate)}
                </small>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="text-primary mb-0">
                    ₹{formattedPrice(bookingsData.price)}
                  </h3>
                  <CustomPopover placement="top"
                    body="<p>  Contact us at:<br /></p>
                    <strong class='text-primary'>1800 123 8090</strong><br />
                    <strong class='text-primary'>+91 73061 12802</strong><br />
                    Or message us on WhatsApp!"

                    component={<p className="text-primary mb-0"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent the click from bubbling up to the parent
                      }}> Need&nbsp;help?</p>} />
               
                </div>
              </Col>
              {bookings.length >= 2 && <hr className="mt-4" />}
            </Row>
          ))}
        </Row>



      )}
    </Tab.Pane>
  )
}


export default BookingList