import { faAngleRight, faLocationDot, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import HTTP from "../../config/axios";
import { encryptLocalStorage } from "../../config/utils/storage";
import { useNavigate } from "react-router-dom";

const PointsToConsider = () => {

    interface IReferAndEarn {
        id: number;
        title: string;
        name: string;
        content: string;
    }

    const location = encryptLocalStorage.getItem("selectedLocation") || "";
    const [cmsContent, setCmsContent] = useState<IReferAndEarn[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getPointsToConsider = async () => {
            const pointsToConsiderData = await HTTP.post(`/api/cms/fetch-all`,
                {
                    nameList: ["points-to-consider"]
                })
            const { data: { status, data, msg, error } } = pointsToConsiderData
            if (status == 1) {
                setCmsContent(data)
            } else {
                setCmsContent([])
            }
        }
        getPointsToConsider()
    }, []);

    const handleAssuredClick = () => {
        const searchCriteria = { "assuredList": 1 };
        navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}`)
    }

    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/points_to_consider_banner.jpg`;

    return (
        <>
            <Container fluid className="top-banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto">
                    <Col md={12}>
                        <h3 className="text-primary mb-1">Points to consider</h3>
                        <h2 className="text-light">Precautions While Buying A Used Car</h2>
                        <p className="text-light">Decided to buy a used car? Well, you are not the only one, millions of Indians are now opting for used cars and if we look at statistics the
                            last two years have seen a surge in the used car market as more and more people are now opting for private transport. Whatever your
                            reason be, we want you to know that you've made the right choice</p>
                        <p className="text-light">However, in the market, not everything is what it seems to be and you should always have a vigilant eye to spot which car seller is a
                            fraud and which is not. On those lines we want you to be apprised of a few precautions that you should undertake when buying a used
                            car</p>
                        <Button variant="primary" type="submit" onClick={handleAssuredClick} >
                            See assured cars <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                    </Col>
                </Container>
            </Container>
            {cmsContent.map((pointsContent) => (
                <div key={pointsContent.id} dangerouslySetInnerHTML={{ __html: pointsContent.content }}></div>
            ))}
        </>
    );
}

export default PointsToConsider;