import { useState, useEffect, useRef } from 'react';
import { Button, Image, Form, Modal } from 'react-bootstrap';
import FileUpload from '../../form/FileUpload';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import Cropper, { ReactCropperElement } from "react-cropper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number;
        error: string;
        msg: string;
        type: string;
        sortExist: boolean
    };
}
interface IImageInfo {
    id: number;
    sortOrder: string | number;
    imageUrl: string;
    uuid: string;
    vehicleId: string | number;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    imageDetails: IImageInfo;
    setAddEditCount: Function;
    setToast: Function;
    setIsLoading: Function
}

const AddEditModal: React.FC<AddEditModalProps> = ({ show, setToast, setIsLoading, handleClose, imageDetails, setAddEditCount }) => {
    const [editImage, setEditImage] = useState(false)
    const [originalImage, setOriginalImage] = useState<string | Blob>('')
    const [thumbImage, setthumbImage] = useState<string | Blob>('')
    const { handleSubmit, formState: { errors }, watch, clearErrors, control, reset, getValues } = useForm<Record<string, any>>({defaultValues: {sortOrder:'', uploadedImage:''}});
    const cropperRef = useRef<ReactCropperElement>(null);
    const [imageURL, setImageURL] = useState('')
    const uploadedImage = watch('uploadedImage')

    useEffect(() => {
        reset(imageDetails);
    }, [imageDetails]);

    useEffect(() => {
        if (uploadedImage) {
            setImageURL(URL.createObjectURL(uploadedImage) || '')
            setEditImage(true)
        }
        else {
            setEditImage(false)
            setImageURL(imageDetails.imageUrl ? `${process.env.REACT_APP_CLOUDFRONT_URL}${imageDetails.imageUrl}` : '')
        }
    }, [uploadedImage])

    useEffect(() => {
        checkValidation()
    }, [watch('sortOrder')])


    const cropImage = async () => {
        const cropper: any = cropperRef.current?.cropper;
        const originalImage = urlToBlob(cropper.getCroppedCanvas({ width: 1200, height: 750 })?.toDataURL())
        const thumbnailImage = urlToBlob(cropper.getCroppedCanvas({ width: 400, height: 250 })?.toDataURL())
        setOriginalImage(originalImage)
        setthumbImage(thumbnailImage)
    };


    const urlToBlob = (dataURL: string) => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeType = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: mimeType });
    }

    const checkValidation = () => {
        if(imageDetails.sortOrder == 1 && watch('sortOrder') != 1){
            setToast({
                open: true, header: 'Warning',
                message: 'Sort number 1 cannot be altered', variant: 'danger'
            })
            return false
        }

        return true
    }

    const onSubmit = async (data: any) => {

        try {
            const isValidationPass = checkValidation()
            if(!isValidationPass) return false

            setIsLoading(true)
            const formData = new FormData()
            formData.append('id', imageDetails.id.toString())
            formData.append('vehicleImage', originalImage || '')
            formData.append('vehicleThumbnailImage', thumbImage || '')
            formData.append('currentImage', imageDetails.imageUrl)
            formData.append('currentThumbImage', imageDetails.imageUrl.replace('/original/', '/thumbnail/'))
            formData.append('uuid', imageDetails.uuid)
            formData.append('vehicleId', imageDetails.vehicleId.toString())
            formData.append('sortOrder', data.sortOrder)
            const { data: { status, msg, err, sortExist } }: responseType = await HTTP({
                method: imageDetails.id ? 'put' : 'post',
                url: `/api/admin/vehicle/image/${imageDetails.id ? 'edit' : 'add'}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setToast({
                open: true, header: status ? 'Success' : 'Error',
                message: msg, variant: status ? 'success' : 'danger'
            })
            setIsLoading(false) 

            if (!status && sortExist) return // Don't close modal and reset form , if lcoation already exist
            setAddEditCount((prev: number) => prev + 1)
            setEditImage(false)
            setImageURL('')
            setOriginalImage('')
            setthumbImage('')
            handleClose()
            reset()
        }
        catch (err) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
            console.log(err)
        }
        // finally {
        //     setIsLoading(false)
        //     setEditImage(false)
        //     setImageURL('')
        //     handleClose()
        //     reset()
        // }

    }

    const closeModal = () => {
        handleClose()
        setEditImage(false)
        setImageURL('')
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{imageDetails.id ? 'Update' : 'Add'} Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="sortOrder" label="Sort Order" type="number" min={1}
                                placeholder="Enter sort order" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <FileUpload name="uploadedImage" label="Vehicle Image" accept=".jpeg,.jpg,.png"
                                control={control} rule={{ required: imageDetails.id == 0 ? fieldRequired : false }} />
                            {/* <Form.Text className="text-black-50">Only Jpeg, png and jpg formats accepted</Form.Text> */}
                            <p className="text-dark fs-7"><FontAwesomeIcon icon={faExclamationCircle} /> Upload car images in png/jpg/jpeg format with 1150 * 750 pixel and less than 2 MB size</p>
                        </div>
                        <div>
                            {imageDetails.imageUrl && !editImage && <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}${imageDetails.imageUrl} `} width={"100%"} />}
                        </div>
                        {editImage && <Cropper
                            ref={cropperRef}
                            crop={cropImage}
                            src={imageURL}// Replace with your image URL
                            style={{ width: 460, height: 300 }}
                            aspectRatio={1180 / 750}
                            // minCropBoxHeight={750}
                            // minCropBoxWidth={1180} // Set the desired aspect ratio
                            guides={true} // Show cropping guidelines
                        />}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            {imageDetails.id ? 'Update' : 'Save'}
                        </Button>


                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddEditModal;