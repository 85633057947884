import { useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";

const VehicleMainImage = ({ image, badge, isDealOfTheDay, isAssuredStatus }: { image: string, badge: string, isDealOfTheDay?: number, isAssuredStatus?:number }) => {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(window.innerWidth <= 565);
    }, []);

    const badgeList: any = {
        // assured: 'assured_badge',
        sold: 'sold_orange_badge',
        booked: 'booked_blue_badge'
    }
    const badgeUrl = process.env.REACT_APP_CLOUDFRONT_URL + `/public/${badgeList[badge]}.png`
    return (
        <>
            <div className="ribbons-wrapper">
                <div className="ribbon">
                    {isDealOfTheDay == 1 && <span className="ribbon1"><span className="deal">Deal of <br /> the day</span></span>}

                </div>
            </div>

            
          <div className="container px-0" style={{ position: 'relative', display: 'inline-block' }}>
                <Image src={image} width={"100%"} className="mb-3" />
                <div className={isMobile ? "details-sale-badge-mobile" : "details-sale-badge"}>
                    {badge !== "" && <Image src={badgeUrl} width={isMobile ? "100%" : "50%"} />}
                </div>
            </div>
        
            
            <div className="">
                <div className={isMobile ? "details-assured-badge-mobile" : "details-assured-badge"}>
                    {isAssuredStatus == 1 &&  <Image src={process.env.REACT_APP_CLOUDFRONT_URL + `/public/assured_badge.png`} width={isMobile ? "100%" : "50%"} />}
                </div>
            </div>
               
         
        </>
    )
}
export default VehicleMainImage