import ComparisonMob from "../../../components/modules/Vehicle/CompareMobile/ComparisonPageMob";
import ComparisonDesktop from "../../../components/modules/Vehicle/CompareDesktop/ComparisonPage";
import { useEffect, useState } from "react";


const Comparison = () => {

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
         
    setIsMobile(window.innerWidth <= 565);
     
  }, []);


  return (
    <>
      { isMobile ? <ComparisonMob /> : <ComparisonDesktop />}
    </>
  );
};

export default Comparison;
