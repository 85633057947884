import { Row, Col, Form, Container, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import HTTP from '../../../config/axios';
import InputTextAreaField from '../../form/InputTextAreaField';
import Checkbox from '../../form/Checkbox';
import { InspectionTypeOptions } from '../../../config/utils/helper';

interface IVehicleDetails {
    vehicleId: string | number
    setToast: Function,
    setIsLoading: Function
    setActiveTab: Function
}
interface InspectionFormDetails {
    [key: string]: string; // Assuming all values are strings, adjust as per your data structure
}

const InspectionForm: React.FC<IVehicleDetails> = ({ vehicleId, setToast, setIsLoading, setActiveTab }) => {

    const { handleSubmit, formState: { errors }, setError, clearErrors, getValues, control, reset, resetField, watch, register, setValue } = useForm();
    const [inspectionsArray, setInspectionsArray] = useState([])
    const [inspectionType, setInspectionType] = useState([]);

    useEffect(() => {
        if (inspectionsArray.length) {
            const getVehicleInspectionDetails = async () => {
                try {
                    const params = { vehicleId: vehicleId }
                    const response = await HTTP.get(`/api/admin/vehicle/inspectionDetails`, { params });
                    const { data: { status, data, msg, error } } = response
                    reset(data)
                }
                catch (error) {
                }
            }
            getVehicleInspectionDetails()
        }
    }, [inspectionsArray])

    useEffect(() => {

        const getInspectionsData = async () => {
            try {
                const response = await HTTP.get(`/api/admin/inspections/all`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setInspectionsArray(data)
                    const inspec:any = JSON.parse(JSON.stringify(InspectionTypeOptions));
                    InspectionTypeOptions.forEach((itemInspection:any,index:any)=>{
                        const inspectionItem:any=[]
                        data.forEach((item:any)=>{

                            if(item.type==itemInspection.id){
                                inspectionItem.push(item)
                            }
                             
                        })
                        inspec[index]['inspection_item'] = inspectionItem
                    })
                    inspec.splice(1,1) //To remove Overview
                    setInspectionType(inspec)
                    const optionStruct: any = {}
                    // data.map((inspection:any,index:number) => {
                    //     const options = [{name:'Not Avaliable',id:JSON.stringify({id:inspection.id,value:0})},{name:'Yes',id:JSON.stringify({id:inspection.id,value:1})},{name:'No',id:JSON.stringify({id:inspection.id,value:2})}]
                    //     optionStruct[inspection.keyName] = options;
                    // })
                    // setInspectionsOptions(optionStruct)

                }
            }
            catch (error) {
                console.log(error)
            }
        }
        getInspectionsData()
        //getVehicleInspectionDetails() - Commneted for to fix the data populate in the form 

    }, []);

    const onSubmit = async (inspectionData: any) => {
        try {
            setIsLoading(true);
            const formData: any[] = [];
            inspectionsArray.forEach((data: any) => {

                const noteKey = `${data.keyName}_${data.type}_note`;
                const imperfectionNote = `${data.keyName}_${data.type}_imperferction_note`;
                const testedNote = `${data.keyName}_${data.type}_tested_note`;
                const idKey = `${data.keyName}_${data.type}_id`;

                if (!inspectionData[noteKey] && !inspectionData[idKey] &&  !inspectionData[testedNote] &&  !inspectionData[imperfectionNote]) {
                    return false;
                }
                const inspectionIdKey = `${data.keyName}_${data.type}_inspectionId`;
                const checkboxKey = `${data.keyName}_checkbox`;
                const inspectionObj: any = {};
                inspectionObj[inspectionData[inspectionIdKey]] = {
                    note: (inspectionData[noteKey])?inspectionData[noteKey].trim():'',
                    //fixed: inspectionData[checkboxKey] || 0,
                    imperfection_note:(inspectionData[imperfectionNote])?inspectionData[imperfectionNote].trim():'',
                    tested_note:(inspectionData[testedNote])?inspectionData[testedNote].trim():'',
                    id: inspectionData[idKey]
                };
                formData.push(inspectionObj);
            });

            const reqBody = { vehicleId: vehicleId, vehicleInspections: formData };
            const response = await HTTP.post(`/api/admin/vehicle/inspections/add`, reqBody);
            const { data: { status, message } } = response;
            setToast({ open: true, header: status ? 'Success' : 'Failed', message, variant: status ? 'success' : 'danger' });
            if (status) {
                setActiveTab('basic');
            }
        } catch (error) {
            console.error(error);
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' });
        } finally {
            setIsLoading(false);
        }
    };

    return (<>
        <Container fluid>
            <h1>Vehicle Inspections</h1>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {inspectionType.map((data: any) => (
                        <div key={data.id}>
                            
                            <Row>
                            <h5 className='text-primary fw-semibold mb-0  mt-3'>{data.name}</h5>
                            {data.inspection_item.map((inspection:any, index: number) => (
                                <Col md={6} key={index} >
                                    {(inspection.type)?
                                        <>
                                            <h4 className=' fw-semibold mt-3 '>{inspection.label}</h4>
                                            <Row>
                                                <Col md={4}>
                                                    <InputTextAreaField label="Fixed" name={`${inspection.keyName}_${inspection.type}_note`} control={control} clearErrors={clearErrors} placeholder={`Add note`} />
                                                </Col>
                                                <Col md={4}>
                                                    <InputTextAreaField label="Imperfections" name={`${inspection.keyName}_${inspection.type}_imperferction_note`} control={control} clearErrors={clearErrors} placeholder={`Add note`} />
                                                </Col>
                                                <Col md={4}>
                                                    <InputTextAreaField label="Tested" name={`${inspection.keyName}_${inspection.type}_tested_note`} control={control} clearErrors={clearErrors} placeholder={`Add note if its tested`} />
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <Row>
                                            <Col md={6} className='mt-3'>
                                                <InputTextAreaField label="" name={`${inspection.keyName}_${inspection.type}_note`} control={control} clearErrors={clearErrors} placeholder={`Add highlights of the vehicle`} />
                                            </Col>
                                        </Row>

                                    }
                                    

                                    {/* <div>
                                        <Checkbox name={`${inspection.keyName}_checkbox`} control={control} label="Tested as ok" classList="" />
                                    </div> */}
                                    <input type="hidden" {...register(`${inspection.keyName}_${inspection.type}_inspectionId`)} value={inspection.id} />
                                    <input type="hidden" {...register(`${inspection.keyName}_${inspection.type}_id`)} />
                                </Col>
                            ))}
                            </Row>
                        </div>
                        
                    ))}
                </Row>
                <Button type="submit" className='mt-3'>Save</Button>
            </Form>
        </Container>

    </>)
}
export default InspectionForm