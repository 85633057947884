import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface ITextField {
    name: string;
    label: string;
    placeholder?: string;
    control?: Control;
    classList?: string;
    type?: string;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    rule?: any;
    defaultValue?: string;
    clearErrors?: Function;
    disabled?: boolean;
    addOn?: any;
    errText?: string;
    groupText?: string;
}

const InputGroupField: React.FC<ITextField> = ({
    name,
    label,
    placeholder,
    control,
    classList,
    type = "text",
    min,
    max,
    minLength,
    maxLength,
    rule,
    clearErrors,
    defaultValue,
    disabled = false,
    addOn = '',
    errText = '',
    groupText = ''
}) => {

    const handleError = () => {
        if (clearErrors) clearErrors();
    }


    if (rule?.required && typeof rule.required == 'function') {
        rule.required = rule.required(label)
    }

    return (<>

        <div className={classList}>
            <Controller
                name={name}
                control={control}
                rules={rule}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <>
                        {label && 
                            <Form.Label htmlFor={`${name}-id`} className="text-dark fw-semibold fs-6">
                                {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                            </Form.Label>}
                        <InputGroup className={classList}>
                            <InputGroup.Text id="basic-addon3">
                                { addOn ? <FontAwesomeIcon icon={addOn} /> : groupText}
                            </InputGroup.Text>
                            <Form.Control type={type} id={`${name}-id`}
                                placeholder={placeholder}
                                disabled={disabled}
                                value={value || ''}
                                {...(defaultValue ? { defaultValue } : {})}
                                {...(!isNaN(min as number) ? { min } : {})} // Add 'min' attribute if min is valid { ...(min ? {min:min} : {}) }
                                {...(!isNaN(max as number) ? { max } : {})}
                                {...(!isNaN(minLength as number) ? { minLength } : {})}
                                {...(!isNaN(maxLength as number) ? { maxLength } : {})}
                                onChange={(e) => { handleError(); onChange(e) }} />
                        </InputGroup>
                        {error?.message &&
                            <Form.Text className="text-danger">
                                {/* Show custom error if present and required message is not triggeres */}
                                {error.type != 'required' && errText ? errText : error.message}
                            </Form.Text>}
                    </>)
                }
            />

        </div>


    </>)
}


export default InputGroupField