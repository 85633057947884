import CarPayment from "../../../components/modules/Booking/CarPayment";


const Payment = () => {
    return (
        <div className="App">
            <CarPayment />
        </div>
    );
}

export default Payment;