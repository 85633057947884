import React, { ReactNode, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface ICheckBox {
    name: string;
    label: string | ReactNode;
    control: Control;
    classList?: string;
    selectedValue?: number;
    rule?: any;
    clearErrors?: Function;
    errText?: string;
}

const CheckBox: React.FC<ICheckBox> = ({
    name,
    label,
    control,
    classList,
    selectedValue = 0,
    rule,
    clearErrors,
    errText
}) => {

    if (rule?.required && typeof rule.required == 'function') {
        rule.required = rule.required(label)
    }


    return (<>

        <div className={classList}>
            <Controller
                name={name}
                control={control}
                rules={rule}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) =>
                    <>
                        <Form.Check
                            type='checkbox'
                            id={`${name}-${label}`}
                            label={<span>
                                {label} {rule?.required && <span className="text-danger fw-bold">*</span>}
                            </span>}
                            value={value || 0 }
                            onChange={(e) => {
                                onChange(e.target.checked ? 1 : 0)
                            }}
                            checked={value==1}
                        />
                        {error?.message && <Form.Text className="text-danger">{errText || error.message}</Form.Text>}
                    </>
                }
            />

        </div>


    </>)
}


export default CheckBox