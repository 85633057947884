import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import InputTextAreaField from '../../form/InputTextAreaField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import FileUpload from '../../form/FileUpload';
import { Image } from 'react-bootstrap';
import SimpleSpinner from '../../common/Spinner';
import Cropper, { ReactCropperElement } from "react-cropper";
import DropDownField from '../../form/DropDownField';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        locationExist: boolean
    };
}
interface ILocationInfo {
    id: number;
    name: string;
    keyName: string;
    image: string;
    sortOrder: number;
    address: string;
}

interface AddEditLocationProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    locationDetails: ILocationInfo;
    setCountAddEdit: Function;
}

const AddEditLocation: React.FC<AddEditLocationProps> = ({ show, handleClose, locationDetails, setCountAddEdit }) => {

    const [editImage, setEditImage] = useState(false)
    const [locationImage, setLocationImage] = useState<string | Blob>('')
    const [imageURL, setImageURL] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [dealershipArray, setDealershipArray] = useState([])
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const cropperRef = useRef<ReactCropperElement>(null);

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm();
    const uploadedImage = watch('uploadedImage')

    useEffect(() => {
        reset(locationDetails);
        setImageURL(locationDetails.image ? `${process.env.REACT_APP_CLOUDFRONT_URL}${locationDetails.image}` : '')
    }, [locationDetails]);

    useEffect(() => {
        if (uploadedImage) {
            setImageURL(URL.createObjectURL(uploadedImage) || '')
            setEditImage(true)
        }
        else {
            setEditImage(false)
            setImageURL(locationDetails.image ? `${process.env.REACT_APP_CLOUDFRONT_URL}${locationDetails.image}` : '')
        }
    }, [uploadedImage])

    useEffect(() => {
        const getCarMakeData = async () => {
            const response = await HTTP.get(`/api/location/fetch-all-dealerships`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setDealershipArray(data)
            } else {
                setDealershipArray([])
            }
        }
        getCarMakeData()
    }, [])


    const cropImage = async () => {
        const cropper: any = cropperRef.current?.cropper;
        const originalImage = urlToBlob(cropper.getCroppedCanvas({ width: 100, height: 100 })?.toDataURL())
        setLocationImage(originalImage)
    };


    const urlToBlob = (dataURL: string) => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeType = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        return new Blob([arrayBuffer], { type: mimeType });
    }

    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append('id', locationDetails.id.toString())
            formData.append('name', submitData.name);
            formData.append('keyName', submitData.keyName);
            formData.append('address', submitData.address);
            formData.append('dealershipId', submitData.dealershipId);
            formData.append('sortOrder', locationDetails.sortOrder.toString())
            formData.append('image', locationImage)
            formData.append('currentImage', locationDetails.image)


            const { data: { status, message, locationExist } }: responseType = await HTTP({
                method: submitData.id ? 'put' : 'post',
                url: `/api/admin/location/${submitData.id ? 'edit' : 'add'}`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message, variant: status ? 'success' : 'danger'
            })

            setIsLoading(false)
            if (!status && locationExist) return // Don't close modal and reset form , if lcoation already exist
            setCountAddEdit((prev: number) => prev + 1)
            handleClose()
            reset()

        }
        catch (error) {
            setIsLoading(false)
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
            console.log("Error on Location add/ edit api", error)
        }
    }


    const closeModal = () => {
        handleClose()
        reset()
    }

    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{locationDetails.id ? 'Update' : 'Add'} Location</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Location Name"
                                placeholder="Enter Location name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <InputTextAreaField name="address" label="Address"
                                placeholder="Enter Address" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        <div className='mb-1'>
                            <DropDownField label="Dealership" name="dealershipId" options={dealershipArray} control={control} rule={{ required: fieldRequired }} clearErrors={clearErrors} placeholder="Choose dealership" />
                        </div>
                        <div className='mb-1'>
                            <FileUpload name="uploadedImage" label="Location Image" accept=".jpeg,.jpg,.png"
                                control={control} rule={{ required: locationDetails.id == 0 ? fieldRequired : false }} />
                            {/* <Form.Text className="text-black-50">Only Jpeg, png and jpg formats accepted</Form.Text> */}
                            <Form.Text className="text-black-50">Upload image in png/jpg/jpeg format with 100 * 100 pixel and less than 2 MB size</Form.Text>
                        </div>
                        <div>
                            {imageURL && !editImage && <Image src={imageURL} width={"30%"} />}
                        </div>
                        {editImage && <Cropper
                            ref={cropperRef}
                            crop={cropImage}
                            src={imageURL}// Replace with your image URL
                            style={{ width: 200, height: 200 }}
                            aspectRatio={1/1}
                            // minCropBoxHeight={200}
                            // minCropBoxWidth={200} // Set the desired aspect ratio
                            guides={true} // Show cropping guidelines
                        />}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" >
                            {locationDetails.id ? 'Update' : 'Save Changes'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default AddEditLocation;