import {Row,Col, Form,Container,Button} from 'react-bootstrap'
import DropDownField from '../../form/DropDownField';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import { fieldRequired, validateDecimalPlaces, validateOnlySpaces } from '../../../config/validations/rules';
import { useEffect, useState } from "react";
import HTTP from '../../../config/axios';


interface IVehicleDetails {
    vehicleId: string | number
    setToast:Function,
    setIsLoading:Function
    setActiveTab:Function
}

const FeatureForm:React.FC<IVehicleDetails> = ({vehicleId,setToast,setIsLoading,setActiveTab})=>{
    
    const { handleSubmit, formState: { errors }, setError, clearErrors,getValues, control, reset,resetField, watch } = useForm();
    const [featuresArray,setFeaturesArray] = useState([])
    const [featuresOptions,setFeaturesOptions] = useState<any>({});
    const [featureFormDetails,setFeatureFormDetails] = useState<{[key: string]: any}>({});
    const option = [{name:'Not Avaliable',id:1},{name:'Yes',id:2},{name:'No',id:3}]

    useEffect(()=>{
        reset(featureFormDetails);
    },[featureFormDetails])
    useEffect(() => {

        const getFeaturesData = async () => {
            try{
                const response = await HTTP.get(`/api/admin/features/all`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {

                    setFeaturesArray(data)
                    // const optionStruct:any = {}
                    // data.map((feature:any,index:number) => {
                    //     const options = [{name:'Not Avaliable',id:JSON.stringify({id:feature.id,value:0})},{name:'Yes',id:JSON.stringify({id:feature.id,value:1})},{name:'No',id:JSON.stringify({id:feature.id,value:2})}]
                    //     optionStruct[feature.keyName] = options;
                    // })
                    // setFeaturesOptions(optionStruct)
                }
            }
            catch(error){
                console.log('error in feature API ')
            }
             
        }
        
        const getVehicleFeatureDetails = async () => {
            try{
                const params ={vehicleId:vehicleId}
                const response = await HTTP.get(`/api/admin/vehicle/featureDetails`,{params});
                const { data: { status, data, msg, error } } = response

                if (status == 1 && data.length) {

                    const currentlySelectedOption:any = {}
                    data.map((feature:any) => {
                        currentlySelectedOption[feature.keyName] = feature.value
                    })
                    setFeatureFormDetails(currentlySelectedOption)

                }
            }
            catch(error){

            }
             
        }
        getFeaturesData()
        getVehicleFeatureDetails()

    }, []);

    const renderInputField = (data:any) =>{
        switch(data.fieldType){
            case "number":
                return <InputTextField label={data.label} name={data.keyName} type="number"
                    control={control}  min={0} 
                    clearErrors={clearErrors} placeholder={` Enter ${data.label}`} />

            case "text":
                return <InputTextField label={data.label} name={data.keyName} type="text"
                    control={control} 
                    clearErrors={clearErrors} placeholder={` Enter ${data.label}`} unit={data.unit}
                    rule={{ validate: validateOnlySpaces }}/>

            case "decimal":
                return <InputTextField  label={data.label} name={data.keyName}
                        type="number" control={control} 
                        step = {'any'} 
                        min={0} placeholder={`Enter ${data.label}`} />

            case "dropdown":   
                const options = JSON.parse(data.options || "[]");
                return (
                    <DropDownField
                        label={data.label} name={data.keyName} options={options} control={control}
                          placeholder={`Select ${data.label}`}   />
                );

            default:
                return (
                    <DropDownField label={data.label} name={data.keyName} options={option} control={control}  clearErrors={clearErrors} placeholder={`Choose ${data.label}`}/> 
                );
        }
    }


    const onSubmit = async(formData:any)=>{
        try{
            setIsLoading(true);
            const features:any = []
            
            featuresArray.forEach((item:any) => {
                formData[item.keyName] &&  features.push({ id: item.id, value: formData[item.keyName],keyName:item.keyName })
            })

            const removedFeatures:any = []
            // Iterate over keys of featureFormDetails
            Object.keys(featureFormDetails).forEach((key) => {
                // Check if key exists in featuresArray
                const foundItem:any = features.find((item:any) => item.keyName === key);
                if (!foundItem) {
                    // Push the  item to removed feature 
                    removedFeatures.push(key);
                }
            });

            const reqBody = {vehicleId:vehicleId,vehicleFeatures:features, removedFeatures:removedFeatures}
            const response = await HTTP.post(`/api/admin/vehicle/features/add`,reqBody);
            const { data: { status, data, message, error } } = response
            setToast({ open: true, header: status?'Success':'Failed',
                        message, variant: status ?'success':'danger' })
            if(status){
                setActiveTab('specification') 
            }
            
        }
        catch(error){
            console.log(error)
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally{
            setIsLoading(false);
        }
    }
    return(<>
                <Container  fluid>
        <h1>Vehicle Features</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                {featuresArray.map((data:any,index:number)=>(
                    
                    <Col md={4} key={index}>
                         {renderInputField(data)}
                    </Col>  
                ))}                
           </Row>
           <Button type="submit" className='mt-3'>Save</Button>
        </Form>
    </Container>
    
    </>)
}
export default FeatureForm