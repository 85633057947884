import { Row, Tab } from "react-bootstrap"


const Finance = () => {

    window.location.href='/finance';
    return (
        <Tab.Pane eventKey="sideTab_5">
            <Row></Row>
        </Tab.Pane>
    )
}


export default Finance