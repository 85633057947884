import { useState } from "react";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css";
import { faEnvelope, faKey } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import HTTP from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import { fieldRequired, emailPattern } from '../../../config/validations/rules';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { encryptLocalStorage } from "../../../config/utils/storage";
import SimpleToast from "../../../components/common/Toast";
import SimpleSpinner from "../../../components/common/Spinner";
import InputGroupField from "../../../components/form/InputGroupField";

const SignIn = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const { executeRecaptcha } = useGoogleReCaptcha();
    interface responseType {
        success: number;
        message: string;
        data: {
            recaptchaStatus?: number;
            err?: string;
            status: number,
            message: string,
            token: string,
            refreshToken: string;
            userInfo: {
                email: string,
                firstName: string,
                lastName: string,
                role: number,
                status: number
            }
        };
    }
    const { register, handleSubmit, formState: { errors }, setError, clearErrors, control } = useForm();
    const onSubmit = async (data: any) => {

        try {
            setIsLoading(true);
            data.siteToken = executeRecaptcha ? await executeRecaptcha('adminSignIn') : '';
            const response: responseType = await HTTP.post(`/api/admin/sign-in`, data)
            if (response.data.status === 1) {
                encryptLocalStorage.setItem("jwtToken", response.data.token);
                encryptLocalStorage.setItem("userInfo", JSON.stringify(response.data.userInfo));
                encryptLocalStorage.setItem("refreshToken", response.data.refreshToken);
                navigate("/admin/vehicles");
            } else if (response.data.status === 0) {
                setToast({ open: true, header: 'Failed', message: response.data.message, variant: 'danger' })
            } else {
                setError("password", {
                    type: "manual",
                    message: response.data.message,
                });
            }
            setIsLoading(false);
        }
        catch (error: any) {
            const { response: { status, data: { recaptchaStatus, err } } } = error
            if (status === 401 && recaptchaStatus === 0) {
                setToast({ open: true, header: 'Recaptcha', message: err || '', variant: 'danger' })
            } else {
                setError("password", {
                    type: "manual", message: "Incorrect username or password",
                });
            }
            setIsLoading(false);
        }
    };

    return (
        <div className="Auth-form-container">
            <form className="Auth-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="Auth-form-content">
                    <h3 className="Auth-form-title">Admin Login</h3>
                    <div className="form-group mt-3">
                        <InputGroupField name="email" label="Email ID"
                            placeholder="Enter Email ID" addOn={faEnvelope} clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired, pattern: emailPattern }} />
                    </div>
                    <div className="form-group mt-3">
                        <InputGroupField name="password" label="Password" type="password"
                            placeholder="Enter Password" addOn={faKey} clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />
                    </div>
                    <div className="d-grid gap-2 mt-5">
                        <Button variant="primary" type="submit" >
                            Sign in
                        </Button>
                    </div>
                </div>
            </form>
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </div>
    );
}

export default SignIn;