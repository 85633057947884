

import React, { useEffect } from 'react';
// Add this declaration at the top of your file
declare global {
    interface Window {
      loadChat360Bot: (botId: string) => void;
    }
  }
  
  
  const Chat360Widget = ({ botId }: { botId: string }) => {
    useEffect(() => {
      const loadChat360Bot = () => {
        const s = document.createElement("script");
        s.async = true;
        s.type = 'text/javascript';
        s.src = "https://app.chat360.io/widget/chatbox/common_scripts/script.js";
        s.onload = () => {
          window.loadChat360Bot(botId);
        };
        s.onerror = (err) => {
          console.error(err);
        };
        document.body.appendChild(s);
      };
  
      loadChat360Bot();
  
      // Cleanup function
      return () => {
        // Remove the script when the component unmounts
        const script = document.querySelector('script[src="https://app.chat360.io/widget/chatbox/common_scripts/script.js"]');
        if (script) {
          script.remove();
        }
      };
    }, [botId]);
  
    return null; // You can change this if you want to render something
  };
  
  export default Chat360Widget;
  