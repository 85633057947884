import React from 'react';
import { Controller, Control, useFormState, FieldError } from 'react-hook-form';
import { Form, InputGroup } from 'react-bootstrap';

// Define the IDualInputField interface
interface IDualInputField {
    name1: string;
    name2: string;
    label: string;
    control: Control<Record<string, any>>;
    defaultValue1?: string;
    defaultValue2?: string;
    placeholder1?: string;
    placeholder2?: string;
    clearErrors?: () => void;
    unit1?: string; // Unit for the first input
    unit2?: string; // Unit for the second input
    type?: string;
    step?: number | string;
    rule?: any;
    errText?: string;
}

// Define the DualInputField component
const DualInputField: React.FC<IDualInputField> = ({
    name1,
    name2,
    label,
    type = "text",
    control,
    defaultValue1,
    defaultValue2,
    placeholder1,
    placeholder2,
    clearErrors,
    unit1,
    unit2,
    step,
    rule,
    errText = '',
}) => {
    const { errors } = useFormState({ control });

    const handleError = () => {
        if (clearErrors) clearErrors();
    };

    if (rule?.required && typeof rule.required === 'function') {
        rule.required = rule.required(label);
    }

    // Function to extract the error message from FieldError
    const getErrorMessage = (error: FieldError | undefined): string => {
        if (error && 'message' in error) {
            return error.message || '';
        }
        return '';
    };

    // Extract error messages for both fields
    const error1 = getErrorMessage(errors[name1] as FieldError | undefined);
    const error2 = getErrorMessage(errors[name2] as FieldError | undefined);

    // Combine error messages to display a unified error message if both fields have errors
    const unifiedError = error1 || error2 || (errText && error1 && error2 ? errText : '');

    return (
        <div className="mb-3">
            {label && (
                <Form.Label htmlFor={`${name1}-id`} className="text-dark fw-semibold fs-6">
                    {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                </Form.Label>
            )}
            <InputGroup>
                <Controller
                    name={name1}
                    control={control}
                    rules={rule}
                    defaultValue={defaultValue1}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                            <Form.Control
                                type={type}
                                id={`${name1}-id`}
                                value={value || ''}
                                placeholder={placeholder1}
                                {...(step !== undefined ? { step } : {})}
                                onChange={(e) => {
                                    handleError();
                                    onChange(e.target.value);
                                }}
                            />
                            {unit1 && (
                                <span className="input-group-text">
                                    {unit1}@
                                </span>
                            )}
                        </>
                    )}
                />
                <Controller
                    name={name2}
                    control={control}
                    rules={rule}
                    defaultValue={defaultValue2}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                            <Form.Control
                                type={type}
                                id={`${name2}-id`}
                                value={value || ''}
                                placeholder={placeholder2}
                                {...(step !== undefined ? { step } : {})}
                                onChange={(e) => {
                                    handleError();
                                    onChange(e.target.value);
                                }}
                            />
                            {unit2 && (
                                <span className="input-group-text">
                                    {unit2}
                                </span>
                            )}
                        </>
                    )}
                />
            </InputGroup>
            {unifiedError && (
                <Form.Text className="text-danger">
                    {unifiedError}
                </Form.Text>
            )}
        </div>
    );
}

export default DualInputField;
