import React, { lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter, useParams, useLocation } from "react-router-dom";
import SignInSignUp from "../../pages/module/authentication/signInSignUp";

import SellCar from "../../pages/module/vehicle/SellCar";
import Home from "../../pages/module/home/home";
import LocationPage from "../../pages/module/location/location";
import PublicLayout from "../../layouts/PublicLayout";
import DefaultLayout from "../../layouts/DefaultLayout";
import Finance from "../../pages/module/finance/Finance"
import ScrollToTop from "./ScrollToTop";
import LoanApplication from "../../pages/module/finance/LoanApplication";
import Profile from "../../pages/module/profile/profile"
import Aboutus from "../../pages/common/AboutUs"
import Benefit from "../../pages/common/benefits"
import Compare from "../../pages/module/vehicle/Compare"
import Details from "../../pages/module/vehicle/Details"
import InteriorView from "../../pages/module/vehicle/InteriorView"
import ReferAndEarn from "../../pages/common/ReferAndEarn";
import Career from "../../pages/common/Career";
import PointsToConsider from "../../pages/common/PointsToConsider";
import Insurance from "../../pages/common/Insurance";
import HowPopularWorks from "../../pages/common/HowPopularWorks";
import NotFound from "../../pages/common/NotFound";
import ParkAndSell from "../../pages/module/vehicle/ParkAndSell";
import Checkout from "../../pages/module/vehicle/Checkout";
import RoadToJoy from "../../pages/module/vehicle/RoadToJoy"
import Booking from "../../pages/module/booking/Booking";
import CmsForm from "../../pages/module/backend/CmsForm";
import Faq from "../../pages/common/Faq";
import TermsAndConditions from "../../pages/common/TermsAndConditions";
import PrivacyPolicy from "../../pages/common/PrivacyPolicy";
import { encryptLocalStorage } from "../../config/utils/storage"
import Dashboard from "../../pages/module/admin/dashboard";
import AdminLayout from "../../layouts/AdminLayout";
import MakeDashboard from "../../pages/module/admin/listMake";
import ModelDashboard from "../../pages/module/admin/listModel";
import { roles } from '../../config/utils/helper'
import AuthenticatedRoute from "./AuthenticatedRoute";
import ListClient from "../../pages/module/admin/listClient"
import ListFuel from "../../pages/module/admin/listFuel";
import ListTransmission from "../../pages/module/admin/listTransmissions";
import ListBodyType from "../../pages/module/admin/listBodyTypes";
import VariantDashboard from "../../pages/module/admin/ListVehicleVariants";
import ListSpecification from "../../pages/module/admin/listSpecification";
import ListLocations from "../../pages/module/admin/listLocations";
import AdminLogin from "../../pages/module/admin/signIn";
import AdminDefaultLayout from "../../layouts/AdminDefaultLayout";
import ListFeatures from "../../pages/module/admin/listFeatures";
import ListVehicles from "../../pages/module/admin/listVehicle";
import VehicleAddEdit from "../../components/admin/vehicle/vehicleAddEdit";
import ListColours from "../../pages/module/admin/listColours";
import Favourites from "../../pages/module/favourites/listFavourites";
import ForgottenPassword from "../../pages/module/authentication/forgotPassword";
import ResetPassword from "../../pages/module/authentication/resetPassword";
import Payment from "../../pages/module/vehicle/Payment";
import CarOrder from "../modules/Booking/CarOrder";
import ListEnquiries from "../../pages/module/admin/listEnquiries";
import ListCms from "../../pages/module/admin/listCms";
import CmsAddEdit from "../admin/cms/CmsAddEdit";
import Testimonial from "../../pages/module/admin/listTestimonial";
import TestimonialPage from "../../pages/common/Testimonials"
import DentMap from "../../pages/dent_map";
import Comparison from "../../pages/module/vehicle/Comparison";
import HassleFreeReturn from "../../pages/common/Return";
import ListLoanApplication from "../../pages/module/admin/listLoanApplication";
import ListDealerships from "../../pages/module/admin/listDealership";
import ListOutlets from "../../pages/module/admin/listOutlets";
import Contactus from "../../pages/common/ContactUs"
import Blog from "../../pages/module/blog/Blog";
import ReferAndEarnForm from "../../pages/common/ReferAndEarnForm";
import ListReferAndEarn from "../../pages/module/admin/listReferAndEarn";
import Enquiry from "../../pages/common/Enquiry";
import Brochure from "../../pages/common/Brochure";
import RefundPolicy from "../../pages/common/RefundPolicy";
import ListBlogs from "../../pages/module/admin/listBlogs";
import BlogAddEdit from "../admin/blogs/BlogsAddEdit";
import Blogs from "../modules/Blog/Blogs";
import ListInspections from "../../pages/module/admin/listInspections";
import ListReport from "../../pages/module/admin/report";
import ListBooking from "../../pages/module/admin/listBooking";
import ListTransaction from "../../pages/module/admin/listTransactions";
import ListTestDrive from "../../pages/module/admin/listTestDrive";
import BookingConfig from "../../pages/module/admin/BookingConfigurations";



// to restrict caching of lazy-loaded components 
const BuyCar = lazy(() => import(`../../pages/module/vehicle/BuyCar`))
const ImageView360 = lazy(() => import(`../../pages/360_degree_poc`))


const { SUPER_ADMIN, BRANCH_ADMIN, EVALUATOR, SALES_EXECUTIVE, CUSTOMER } = roles
const adminRouterRoles = [SUPER_ADMIN];
const clientRouterRoles = [SUPER_ADMIN, BRANCH_ADMIN, EVALUATOR, SALES_EXECUTIVE, CUSTOMER]


const RoutesComponent: React.FC = () => {
    // const routeParams = useParams();

    // const locationParam = routeParams.location;
    // const { pathname } = window.location

    // if (locationParam) {
    //     encryptLocalStorage.setItem("selectedLocation", locationParam); //setting localstorage with encryption
    // }

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path='*' element={<NotFound />} />
                <Route element={<AdminDefaultLayout />}>
                    <Route path="/admin/sign-in" element={<AdminLogin />} />
                </Route>
                <Route element={<AdminLayout />}>

                    <Route path="/admin" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <Dashboard /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/vehicles" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListVehicles /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/vehicleEdit" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <VehicleAddEdit /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/vehicleAdd" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <VehicleAddEdit /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/make" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <MakeDashboard />  </AuthenticatedRoute>
                    } />
                    <Route path="/admin/model" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ModelDashboard /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/variants" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <VariantDashboard /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/clients" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListClient /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/fuel" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListFuel /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/transmission" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListTransmission /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/body-type" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListBodyType /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/specification" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListSpecification /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/features" key="/admin/features" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListFeatures /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/locations" key="/admin/locations" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListLocations /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/colours" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListColours /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/all-enquiries" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListEnquiries /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/loan-application" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListLoanApplication /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/cms" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListCms /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/cms-add-edit" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <CmsAddEdit /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/testimonial" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <Testimonial /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/dealerships" key="/admin/dealerships" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListDealerships /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/outlets" key="/admin/outlets" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListOutlets /> </AuthenticatedRoute>
                    } />
                     <Route path="/admin/refer-and-earn" key="/admin/refer-and-earn" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListReferAndEarn /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/blog" key="/admin/blog" element={
                       <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListBlogs /> </AuthenticatedRoute>
                   } />
                   <Route path="/admin/blog-add-edit" key="/admin/blog-add-edit" element={
                       <AuthenticatedRoute allowedRole={adminRouterRoles} > <BlogAddEdit /> </AuthenticatedRoute>
                   } />
                   <Route path="/admin/inspections" key="/admin/inspections" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListInspections /> </AuthenticatedRoute>
                   } /> 
                   <Route path="/admin/report" key="/admin/report" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListReport /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/bookings" key="/admin/bookings" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListBooking /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/transactions" key="/admin/transactions" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListTransaction /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/bookingConfig" key="/admin/bookingConfig" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <BookingConfig /> </AuthenticatedRoute>
                    } />
                    <Route path="/admin/test-drives" key="/admin/test-drives" element={
                        <AuthenticatedRoute allowedRole={adminRouterRoles} > <ListTestDrive /> </AuthenticatedRoute>
                    } />
                    

                </Route>
                <Route element={<PublicLayout />}>
                    <Route path="/sign-in" element={<SignInSignUp />} />
                    <Route path="/cms" element={<CmsForm />} />
                    <Route path="/forgot-password" element={<ForgottenPassword />} />
                </Route>
                <Route element={<DefaultLayout />}>
                    <Route path="/" element={<Home />} />
                    {/* Start of Routes with authentication required */}
                    <Route path="/profile" element={<AuthenticatedRoute allowedRole={clientRouterRoles} ><Profile /></AuthenticatedRoute>}></Route>
                    <Route path="/checkout" element={<AuthenticatedRoute allowedRole={clientRouterRoles} ><Checkout /></AuthenticatedRoute>}></Route>
                    <Route path="/car/order/:orderNo" element={<AuthenticatedRoute allowedRole={clientRouterRoles} ><CarOrder /></AuthenticatedRoute>}></Route>
                    <Route path="/car/payment/:orderNo" element={<AuthenticatedRoute allowedRole={clientRouterRoles} ><Payment /></AuthenticatedRoute>}></Route>
                    <Route path="/booking" element={<AuthenticatedRoute allowedRole={clientRouterRoles} ><Booking /></AuthenticatedRoute>}></Route>
                    <Route path="/my-favourites" element={<AuthenticatedRoute allowedRole={clientRouterRoles} ><Favourites /></AuthenticatedRoute>}></Route>
                    <Route path="/refer-and-earn/refer-a-friend" element={<AuthenticatedRoute allowedRole={clientRouterRoles} ><ReferAndEarnForm /></AuthenticatedRoute>}></Route>
                    {/* End of Routes with authentication required */}
                    <Route path="/buy-used-car" element={<Suspense fallback={<div>Loading...</div>}><BuyCar /></Suspense>} />
                    {/* <Route path="/buy-used-car/:locationParam" element={<Suspense fallback={<div>Loading...</div>}><BuyCar /></Suspense>} /> */}
                    <Route path="/buy-used-car/:slugCarName/:vehicleId/" element={<Details />}></Route>
                    {/* <Route path="/buy-used-car/:locationParam/:slugCarName/:vehicleId/" element={<Details />}></Route> */}
                    <Route path="/sell-your-car" element={<SellCar />} />
                    <Route path="/all-locations" element={<LocationPage />} />
                    <Route path="/finance" element={<Finance />}></Route>
                    <Route path="/finance/loan-application" element={<LoanApplication />}></Route>
                    <Route path="/about-us" element={<Aboutus />}></Route>
                    <Route path="/benefits" element={<Benefit />}></Route>
                    <Route path="/compare-car/:firstCarName/:firstCarId" element={<Compare />}></Route>
                    <Route path="/compare-car/:firstCarName/:secondCarName/:firstCarId/:secondCarId" element={<Compare />}></Route>
                    <Route path="/interior-view/:id" element={<InteriorView />}></Route>
                    <Route path="/refer-and-earn" element={<ReferAndEarn />}></Route>
                    <Route path="/career" element={<Career />}></Route>
                    <Route path="/points-to-consider" element={<PointsToConsider />}></Route>
                    <Route path="/insurance" element={<Insurance />}></Route>
                    <Route path="/how-popular-works" element={<HowPopularWorks />}></Route>
                    <Route path="/park-and-sell" element={<ParkAndSell />}></Route>
                    <Route path="/road-to-joy" element={<RoadToJoy />}></Route>
                    <Route path="/faq" element={<Faq />}></Route>
                    <Route path="/image-view-360" element={<Suspense fallback={<div>Loading...</div>}><ImageView360 /></Suspense>}></Route>
                    <Route path="/testimonials" element={<TestimonialPage />}></Route>
                    <Route path="/dent_map" element={<DentMap />}></Route>
                    <Route path="/comparison" element={<Comparison />}></Route>
                    <Route path="/hassle-free-return" element={<HassleFreeReturn />}></Route>
                    <Route path="/contact-us" element={<Contactus />}></Route>
                    <Route path="/blog/:id/:keyName" element={<Blog />}></Route>
                     <Route path="/blogs" element={<Blogs />}></Route>
                    <Route path="/enquiry" element={<Enquiry />}></Route>
                    <Route path="/brochure" element={<Brochure />}></Route>
                    <Route path="/refund-policy" element={<RefundPolicy />}></Route>
                </Route>
                <Route path='/reset-password/token/:accessToken' element={<ResetPassword />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            </Routes>
        </BrowserRouter>
    );
};

export default RoutesComponent;
