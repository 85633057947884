import { Col, Row, Container } from "react-bootstrap"


const CarPayment = () => {
    return (
        <Container className="py-5">
            <Row className="justify-content-center">
            <Col md={9}>
                    <div className="text-center pb-4">
                        <h2 className="text-primary ">Redirecting to payment gateway</h2>
                        <h3 className="text-dark">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit duis orci scelerisque dui porttitor. Et faucibus dui eu eget.
                        </h3>
                    </div>
                </Col>

            </Row>

        </Container>
    )
}


export default CarPayment