import React, { useEffect, useState } from 'react';
import ClientDataTable from '../../../components/admin/client/ClientDataTable';
import ClientAddEditModal from '../../../components/admin/client/ClientAddEditModal';
import { Button } from 'react-bootstrap';

const ListClients: React.FC = () => {
    const [show, setShow] = useState(false);
    const [clientDetails, setClientDetails] = useState({ id: 0, firstName: '', lastName: '', phone: 0, email: '' });
    const handleClose = () => setShow(false);

    const editModal = (make: any) => {
        setClientDetails(make)
        setShow(true)
    }

    const newClient = () => {
        setShow(true);
        setClientDetails({ id: 0, firstName: '', lastName: '', phone: 0, email: '' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        {/* <div className="d-sm-flex align-items-center justify-content-between">
                            <h2 className="mb-0 text-gray">Clients</h2>
                        </div> */}
                        <div>
                            <ClientDataTable newClient={newClient} showModal={editModal} handleClose={handleClose} show={show} />
                            <ClientAddEditModal show={show} handleClose={handleClose} clientDetails={clientDetails} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListClients;