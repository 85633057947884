import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ImageCellForDataTable from '../../common/ImageCellForDataTable';
import SimpleSpinner from '../../common/Spinner';
import Checkbox from '../../form/Checkbox';


const MakeTable: React.FC<any> = ({ newMake, showModal, countAddEdit, handleClose, show }) => {
    const [vehicleMakeData, setVehicleMakeData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [totalRows, setTotalRows] = useState(0);
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedMake = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');

    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    const columns: any = [
        {
            name: 'Name',
            selector: (row: { name: any; }) => row.name,
            sortable: true,
        },
        {
            name: 'Logo',
            cell: (row: { logo: any; }) => row.logo && <ImageCellForDataTable value={row.logo} maxHeight='46px' />,
            center: true,
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedMake ? <><Button variant="success" size="sm" className="me-3" onClick={() => editMake(row)} title="Edit info" >
                        <FontAwesomeIcon icon={faPen} />
                    </Button> <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id)} title="Remove make" >
                            <FontAwesomeIcon icon={faTrash} />  </Button></> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}
                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        const getAllMake = async () => {
            setIsLoading(true)
            interface QueryParams {
                searchText?: string;
                sortDetails?: {};
                status?: number;
            }
            const params: QueryParams = {};
            params.searchText = currentSearchText
            if (sortDetails) {
                params.sortDetails = JSON.stringify(sortDetails)
            }
            params.status = archivedMake ? 0 : 1;
            const response = await HTTP.get(`/api/admin/make/fetch-all/${currentPage}/${perPage}`, { params: params });
            const { data: { status, data, msg, error, totalRecords } } = response;
            setVehicleMakeData(data.rows);
            setTotalRows(data.count)
            setIsLoading(false)
        }
        getAllMake()
    }, [countAddEdit, show, currentPage, perPage, sortDetails, removedIds, archivedMake, currentSearchText]);

    const editMake = (make: any) => {
        showModal(make)
    }

    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }
    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false)
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/make/remove/${selectedRecord}`);
        const { data: { status, data, totalCount, message, error, type } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setIsLoading(false);
            setToast({ open: true, header: 'Success', message: 'Make deleted successfully', variant: 'success' })
        } else if (status == 0 && type == "assigned") {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: message, variant: 'danger' })

        } else {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }


    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    const handleRecover = async () => {
        setOpenActivateModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/make/recover/${selectedRecord}`);
        const { data: { status, data, totalCount, msg, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setIsLoading(false);
            setToast({ open: true, header: 'Success', message: 'Make recovered successfully', variant: 'success' })
        } else {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }


    return (<>

        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Vehicle make</h2></div>
                <div className="p-2 mt-2"><Checkbox name="archived" control={control} label="Show removed make"
                    classList="" /></div>
                <div className="p-2">
                    <Button variant="success" onClick={newMake}>
                        Add make
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={vehicleMakeData}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete make"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />

        <CustomModal isOpen={openActivateModal} title="Recover make"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />

        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
        {/* <SimpleSpinner show={isLoading} /> */}

    </>)
}

export default MakeTable;