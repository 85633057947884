import DataTable from 'react-data-table-component';   
import { PaginationChangeRowsPerPage } from 'react-data-table-component/dist/DataTable/types';
    
interface ReportDataProps {
    reportData:any
    handlePageChange:PaginationChangeRowsPerPage | undefined
    handlePerRowsChange:PaginationChangeRowsPerPage | undefined
}

const AgeReportTable: React.FC<ReportDataProps> = ({reportData, handlePageChange, handlePerRowsChange}):any => {
    
    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Location',
            selector: (row: { location: string; }) => row.location,
            sortable: true,
            keyName: 'location'
        },
        {
            name: <div>Model</div>,
            wrap: true,
            sortable: true,
            keyName: 'makeName',
            width: "17%",
            selector: (row: { make: string, model: string, variant: string}) => `${row.make} ${row.model} ${row.variant}`,

        },
        {
            name: 'Registration',
            selector: (row: { registration: string; }) => row.registration,
            sortable: false,
        },

        {
            name: 'Manafacture Year',
            selector: (row: { year: number; }) => row.year,
            sortable: false,
        },
        {
            name: 'KM',
            selector: (row: { km: number; }) => row.km,
            sortable: false,
        },
        {
            name: 'Price',
            selector: (row: { price: number; }) => row.price,
            sortable: false,  
        },
        {
            name: 'Color',
            selector: (row: { colour: string; }) => row.colour,
            sortable: false,  
        }
    ];

    return (<>
        <br></br>
        <DataTable
                columns={columns}
                data={reportData.rows}
                striped={true}
                
                pagination
                paginationServer
                paginationTotalRows={reportData.count}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover={true}
                customStyles={customStyles}
            />
        
    </>)

}
export default AgeReportTable