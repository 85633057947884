import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Location from "../../../components/modules/Location/Location"
import LocationSearch from "../../../components/modules/Location/LocationSearch";
import React, { useContext, useEffect, useState } from 'react';
import HTTP from "../../../config/axios";
import { ClientContext } from '../../../config/context/clientContext';
import { encryptLocalStorage } from "../../../config/utils/storage";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { setSelectedLocation } from "../../../config/utils/helper";

const LocationPage = () => {
  const [searchName, setSearchName] = useState(null)
  const [locationArray, setLocationArray] = useState([])
  const [locationOptions, setLocationOptions] = useState([])
  const [locationLabel, setLocationLabel] = useState<{[key:string]: string}>({})
  const { locationContext, setLocationContext, locationOptContext, setLocationOptContext }: any = useContext(ClientContext);

  useEffect(() => {
    const getAllLocation = async () => {
      const locationData = await HTTP.get(`/api/location/fetch-all`, { params: { status: 1 } });
      const { data: { status, data, msg, error } } = locationData

      if (status == 1) {
        const selectedOptions:any = [] ; // to fill multi-select with already selected locations
        const storedLocations:any = encryptLocalStorage.getItem('selectedLocation')

        setLocationArray(data)
        const options = data.map((option:any, index: number) => {

          if(storedLocations?.length > 0 && locationOptContext.length == 0){

            if(storedLocations.includes(option.keyName)){  
              selectedOptions.push({value: option.keyName, label: option.name})
            }
            if(data.length == index+1){ // add to locationOption context in last iteration
              setLocationOptContext(selectedOptions)
            }
          }
          return {value: option.keyName, label: option.name}
        })
        options.unshift({value: '', label: 'All'})
        setLocationOptions(options)
      } else {
        setLocationArray([])
      }
      const labels: {[key:string]: string} = {}

      data.forEach((location: any) => {
          labels[location.keyName] = location.name
      })

      setLocationLabel(labels)
    }
    getAllLocation()
  }, []);



  const LocationList = () => {

    const removeLocation = (locationToRemove: any) => {
     
      if(locationToRemove !=""){ //Checking the remove option is 'All'
        
          const updatedLocations: string[] = locationContext.filter((location: any) => {
              if(location !== locationToRemove && location.value !=='') {
                  return location
              }
          });
          setLocationContext(updatedLocations)
          setLocationOptContext(locationOptContext.filter((location: any) => {
              if(location.value !== locationToRemove && location.value!=='') {
                  return location
              }
          }))
          setSelectedLocation(updatedLocations);
      } else{
          setLocationContext([])
          setLocationOptContext([])
          setSelectedLocation([]);
      }

  };
    return <Row className="flex-sm-nowrap flex-md-wrap flex-lg-wrap overflow-auto">
        {
            locationContext && locationContext.map((option: any, index: number) => {

                return <div className="p-2-custom w-auto m-2 shadow-sm" key={index}>
                    <span className="me-auto">{locationLabel[option] || 'All'}</span>
                    <FontAwesomeIcon icon={faX} className="text-gray fs-7 ms-2 link-pointer" onClick={() => removeLocation(option)} />
                </div>
            })
        }
    </Row>

}



  return (
    <div className="App">
      <Container className="mb-5">
        <LocationSearch setSearchName={setSearchName} locationOptions={locationOptions} />
        <div className="d-flex justify-content-between horizontal-scrollable" >
                        {locationContext.length > 0 && <LocationList />}
                    </div>
        <Location searchName={searchName} showAll={true} locationList={locationArray} />
      </Container>
    </div>
  );
}

export default LocationPage;