import React, { useEffect, useState } from 'react';
import CmsTable from '../../../components/admin/cms/CmsTable';
import { Button } from 'react-bootstrap';
import LocationAddEditModal from '../../../components/admin/location/LocationAddEditModal';

const ListCms: React.FC = () => {
   
    return (
        <div id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <CmsTable  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListCms;