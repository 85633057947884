import { useEffect, useState } from "react";
import HTTP from "../../config/axios";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface IWhyChoose {
  name: string;
  title: string;
  content: string;
}

const Features: React.FC<any> = () => {
  const [whyChooseUs1, setWhyChooseUs1] = useState<any>([]);
  const [whyChooseUs2, setWhyChooseUs2] = useState<any>([]);
  const [whyChooseUs3, setWhyChooseUs3] = useState<any>([]);
  const [whyChooseUs4, setWhyChooseUs4] = useState<any>([]);
  const [whyChooseUs5, setWhyChooseUs5] = useState<any>([]);
  const [whyChooseUs6, setWhyChooseUs6] = useState<any>([]);
  const [cmsMobileData, setCmsMobileData] = useState<IWhyChoose[]>([]);
  const [isMobile, setIsMobile] = useState(false)
  const carouselRef = React.useRef<any>(null);

  const responsive = {
    mobile: {
      breakpoint: { max: 775, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 775)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 775);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect(() => {
  //   const getFeatures = async () => {
  //     const name = "features";
  //     const cmsData = await HTTP.get(`/api/cms/fetch-one/${name}`)
  //     const { data: { status, data, msg, error } } = cmsData
  //     setFeaturesCms(data)
  //   }
  //   !cmsContent && getFeatures()
  // }, [])

  useEffect(() => {
    const howItWorksCms = async () => {
      const cmsData = await HTTP.post(`/api/cms/fetch-all`,
        {
          nameList: ["why-choose-us-1", "why-choose-us-2", "why-choose-us-3", "why-choose-us-4", "why-choose-us-5", "why-choose-us-6"]
        })
      const { data: { status, data, msg, error } } = cmsData
      setCmsMobileData(data)

      data.forEach((cmsContent: any) => {
        if (cmsContent.name === "why-choose-us-1") {
          setWhyChooseUs1(cmsContent);
        } else if (cmsContent.name === "why-choose-us-2") {
          setWhyChooseUs2(cmsContent);
        } else if (cmsContent.name === "why-choose-us-3") {
          setWhyChooseUs3(cmsContent);
        } else if (cmsContent.name === "why-choose-us-4") {
          setWhyChooseUs4(cmsContent);
        } else if (cmsContent.name === "why-choose-us-5") {
          setWhyChooseUs5(cmsContent);
        } else if (cmsContent.name === "why-choose-us-6") {
          setWhyChooseUs6(cmsContent);
        }
      })
    }

    howItWorksCms()
  }, []);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleNextClick = () => {

    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const CustomLeftArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
      <FontAwesomeIcon icon={faChevronLeft} size={'1x'} />
    </button>
  );

  const CustomRightArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
      <FontAwesomeIcon icon={faChevronRight} size={'1x'} />
    </button>
  );


  return (
    <Container>
      {!isMobile ? (
        <>
          <Row className="my-4">
            <Col md="12">
              <div className="text-center pb-4">
                {/* <h3 className="text-primary ">
                  Lorem Ipsum dolar
                </h3> */}
                <h2 className="text-primary head-bold">
                  WHY CHOOSE US ?
                </h2>
              </div>
            </Col>
            <Col md="4" className="" dangerouslySetInnerHTML={{ __html: whyChooseUs1 && whyChooseUs1.content }}></Col>
            <Col md="4" className="" dangerouslySetInnerHTML={{ __html: whyChooseUs2 && whyChooseUs2.content }}></Col>
            <Col md="4" className="" dangerouslySetInnerHTML={{ __html: whyChooseUs3 && whyChooseUs3.content }}></Col>
          </Row>
          <Row className="my-4">
            <Col md="4" className="" dangerouslySetInnerHTML={{ __html: whyChooseUs4 && whyChooseUs4.content }}></Col>
            <Col md="4" className="" dangerouslySetInnerHTML={{ __html: whyChooseUs5 && whyChooseUs5.content }}></Col>
            <Col md="4" className="" dangerouslySetInnerHTML={{ __html: whyChooseUs6 && whyChooseUs6.content }}></Col>
          </Row>
        </>
      ) : (
        <>
          <div className="my-4">
            <div className="text-center pb-4">
              <h2 className="text-primary ">
                Why Choose us ?
              </h2>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <Col sm={1} xs={1} className="align-items-center  d-sm-flex ">
              <CustomLeftArrow />
            </Col>
            <Col sm={10} xs={10}>
              <Carousel
                className="home-type-carousel"
                swipeable={true}
                draggable={true}
                responsive={responsive}
                ref={carouselRef}
                ssr={true} // render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                arrows={false}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "tabletSmall"]}
                itemClass="carousel-item-padding-40-px"
              >
                {cmsMobileData.map((data, index: number) => (

                  <div style={{ height: "100%", padding: "10px" }} key={index} dangerouslySetInnerHTML={{ __html: data.content && data.content }}></div>
                ))}
              </Carousel>
            </Col>
            <Col sm={1} xs={1} className="align-items-center  d-sm-flex">
              <CustomRightArrow />
            </Col>
          </div>
        </>
      )
      }
    </ Container >
  );
};

export default Features;
