import { useState, useEffect } from "react";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import HTTP from "../../../config/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const FinancePartners = () => {

    interface IFinancePartners {
        id: number;
        logo: string;
    }
    const [financePartners, setFinancePartners] = useState<IFinancePartners[]>([]);
    const carouselRef = React.useRef<any>(null);
        const [isMobile, setIsMobile] = useState(false)

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
            slidesToSlide: 1,
            partialVisibilityGutter: 20
        }
    };
       useEffect(() => {
         
        setIsMobile(window.innerWidth <= 565);
         
      }, []);
    useEffect(() => {
        const getBodyTypeData = async () => {
            const response = await HTTP.get(`/api/finance/fetch-all`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setFinancePartners(data)
            } else {
                setFinancePartners([])
            }
        }
        getBodyTypeData()
    }, []);

    const CustomLeftArrow = () => (
        <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} size={isMobile ? '1x' : '2x'}/>
        </button>
    );

    const CustomRightArrow = () => (
        <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
            <FontAwesomeIcon icon={faChevronRight} size={isMobile ? '1x' : '2x'} />
        </button>
    );

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.previous();
        }
    };

    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
        }
    };

    return (
        <Container>
            <Row className={`${isMobile?'mt-5':'my-5'} pt-4`}>
                <Col md={1} className=" d-none flex-row-reverse align-items-center  d-md-flex ">
                    <CustomLeftArrow />
                </Col>
                <Col col={12} md={3} lg={2} className="border-end me-0 text-center">
                    <h2 className="text-dark">Finance partners</h2>
                </Col>
                <Col col={12} md={7} lg={8} className="ms-0 text-center has-popular-brand-carousel">
                    <Carousel
                        partialVisible={true}
                        className="home-type-carousel"
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        ssr={true} // render carousel on server-side.
                        infinite={true}
                        ref={carouselRef}
                        arrows={false}
                       autoPlay={isMobile}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        itemClass="carousel-item-padding-40-px"
                            showDots={false}
                    >
                        {financePartners.map((data) => (
                            <div key={data.id}>
                                <Image src={process.env.REACT_APP_CLOUDFRONT_URL + data.logo} height={"50px"} className="my-3" />
                            </div>
                        ))}
                    </Carousel>
                </Col>
                <Col md={1} className="d-none  align-items-center  d-md-flex">
                    <CustomRightArrow />
                </Col>
            </Row>
            {isMobile && 
                <Row className="mb-5"> 
                    <Col md={6} sm={6} xs={6} className="  flex-row-reverse align-items-center  d-flex ">
                        <CustomLeftArrow />
                    </Col>
                    <Col md={6} sm={6} xs={6} className="align-items-center  d-flex">
                        <CustomRightArrow />
                    </Col> 
                </Row>
            }
        </Container>
    );
};

export default FinancePartners;
