import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputGroupField from "../../components/form/InputGroupField";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HTTP from "../../config/axios";

const Insurance = () => {
    interface IInsurance {
        id: number;
        title: string;
        name: string;
        content: string;
    }
    const [cmsContent, setCmsContent] = useState<IInsurance[]>([]);
    useEffect(() => {
        const getInsurance = async () => {
            const insuranceData = await HTTP.post(`/api/cms/fetch-all`,
                {
                    nameList: ["insurance"]
                })
            const { data: { status, data, msg, error } } = insuranceData
            if (status == 1) {
                setCmsContent(data)
            } else {
                setCmsContent([])
            }
        }
        getInsurance()
    }, []);
    const { watch, control } = useForm();
    return (
        <>
            <Container className="pt-5 pb-5">
                <Col md={5}>
                    <InputGroupField name="search" label=""
                        placeholder="Search" addOn={faSearch}
                        control={control} />
                </Col>
                <Row className="pt-4">
                    <Col md={8}>
                        {cmsContent.map((insuranceContent) => (
                            <div key={insuranceContent.id} dangerouslySetInnerHTML={{ __html: insuranceContent.content }}></div>
                        ))}
                    </Col>
                    <Col md={4}>
                        <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/complete-payment.svg`} height={"250px"} />
                    </Col>
                </Row>
                <Link to={""} className="btn btn-primary">
                    Follow
                </Link>
            </Container>
        </>
    );
}

export default Insurance;