import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import HTTP from "../../config/axios";
import { useSearchParams } from 'react-router-dom';

const Faq = () => {

    interface IFaq {
        id: number;
        title: string;
        name: string;
        content: string;
    }

    const [cmsArray, setCmsArray] = useState<IFaq[]>([]);
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const getFaq = async () => {
            const cmsData = searchParams.get('type')
            let type = ""
            if (cmsData == "default" || !cmsData) {
                type = "faq-page"
            } else {
                type = cmsData
            }
            const fqaData = await HTTP.post(`/api/cms/fetch-all`,
                {
                    nameList: [type]
                })
            const { data: { status, data, msg, error } } = fqaData
            if (status == 1) {
                setCmsArray(data)
            } else {
                setCmsArray([])
            }
        }
        getFaq()
    }, []);
    return (
        <>
            <Container fluid className="hero-section who_are_we_banner d-flex mt-90">
                <Container className="my-auto">
                    <Col md={12} className="text-center my-5 py-4">
                        <h1 className="text-light mb-0">FAQs</h1>
                    </Col>
                </Container>
            </Container>
            <Container>
                {cmsArray.map((fqaData) => (
                    <Row className="py-5 label" key={fqaData.id}>
                        <div dangerouslySetInnerHTML={{ __html: fqaData.content }}></div>
                    </Row>
                ))}
            </Container>
        </>
    );
}

export default Faq;
