import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { equalCharCheck, equalTo, fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';
import InputTextField from '../../form/InputTextField';
import InputGroupField from '../../form/InputGroupField';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Ckeditor5 from '../../../config/utils/ckeditor5';
import DropDownField from '../../form/DropDownField';

import { CmsTypes } from '../../../config/utils/helper'

const { PAGE_BLOCK, TEXT_BLOCK } = CmsTypes

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string,
        cmsExist: boolean
    };
}
interface IcmsInfo {
    id: number;
    name: string;
    keyName: string;
    hexCode: string;
}



const CmsAddEdit: React.FC = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [editorData, setEditorData] =  useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id')
    const navigate = useNavigate()


    const typeOptions = [
        { id: PAGE_BLOCK, name: 'Page Block'},
        { id: TEXT_BLOCK, name: 'Text Block'}
    ]

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch , getValues} = useForm();

    const nameValue = watch('name') || getValues('name')
    const uploadPath = `cms/images/${nameValue || 'title-no'}`
    const uploadUrl = `/admin/cms/image-upload`

    useEffect(() => {
         try{
            const getCms = async () => {
                const response = await HTTP.get(`/api/admin/cms/fetch-one/${id}`)
                const { data: { status, data, error } } = response;
                reset(data)
                setEditorData(data.content)
            }

            id && getCms()
         }

         catch(err){
            console.log("Error in cms fetch one api")
         }
        

    }, []);



    const onSubmit = async (submitData: any) => {
        try {
            setIsLoading(true)
            submitData.content = editorData
            const { data: { status } }: responseType = await HTTP({
                method: id ? 'put' : 'post',
                url: `/api/admin/cms/${ id ? 'edit' : 'add'}`,
                data: submitData
            })

            setToast({
                open: true, header: status ? 'Success' : 'Failed',
                message: id ? 'CMS Updated Successfully' : 'CMS Added Successfully', 
                variant: status ? 'success' : 'danger'
            })
            setTimeout(() => navigate(`/admin/cms`), 2000)
        }
        catch (error) {
            setToast({
                open: true, header: "Failed",
                message:"Something went wrong", variant: "danger",
            });
            console.log("Error on cms add/ edit api", error)
        }
        finally {
            setIsLoading(false)
        }

    }


 

    return (
        <>
        <Container  fluid>
            <h1> { id ? 'Update' : 'Add'} cms</h1>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}> 
                    <InputTextField name="title" label="Title"
                        placeholder="Enter Title" clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired }} />
                </Col>
                <Col md={{ span: 6, offset: 6 }}> </Col>

                <Col md={6}> 
                    <InputTextField name="name" label="Name"
                        placeholder="Enter Name" clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired }} />
                </Col>
                <Col md={{ span: 6, offset: 6 }}> </Col>

                <Col md={6}> 
                    <DropDownField name="type" label="Type" options={typeOptions}
                        placeholder="Select Type" clearErrors={clearErrors}
                        control={control} rule={{ required: fieldRequired }} />
                </Col>
                <Col md={{ span: 6, offset: 6 }}> </Col>

                <Col className='mt-1'> 
                    <Ckeditor5 data={editorData} 
                        onChange={setEditorData} label="Content"
                        uploadPath={uploadPath} uploadUrl={uploadUrl} /> 
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Button variant="primary" type="submit" className='mt-3' >
                        {id ? 'Update' : 'Save Changes'}
                    </Button>
                </Col>
                <Col md={{ span: 6, offset: 6 }}> </Col>
            </Row>
            </Form>
        </Container>
            
               
             
            <SimpleSpinner show={isLoading} />
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
        </>
    );
}

export default CmsAddEdit;