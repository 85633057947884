import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import InspectionsAddEditModal from '../../../components/admin/inspections/InspectionsAddEditModal'
import InspectionsDataTable from '../../../components/admin/inspections/InspectionsDataTable'

const ListInspections: React.FC = () => {
    const [show, setShow] = useState(false);
    const [addEditCount, setAddEditCount] = useState(0)
    const [inspectionsDetails, setInspectionsDetails] = useState({ id: 0, name: '', keyName: '', type: '' });

    const handleClose = () => setShow(false);

    const editModal = (inspections: any) => {
        setInspectionsDetails(inspections)
        setShow(true)
    }

    const newInspections = () => {
        setShow(true);
        setInspectionsDetails({ id: 0, name: '', keyName: '', type: '' })
    }
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <InspectionsDataTable newInspections={newInspections} editInspectionDeatils={editModal} addEditCount={addEditCount} />
                            <InspectionsAddEditModal show={show} handleClose={handleClose} inspectionDetails={inspectionsDetails} setAddEditCount={setAddEditCount} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListInspections;