import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";

const DocumentsRequired = () => {
    return (
        <div className="App">
            <Container>
                <Row className="py-5">
                    <Col md={12}>
                        <div className="text-center pb-4">
                            <h2 className="text-primary head-bold">DOCUMENTS TO BRING ALONG WITH YOU</h2>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="text-center pt-4">
                            <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/document.svg`} width={"70%"} />
                        </div>
                    </Col>
                    <Col md={6} className="align-self-center">
                        <div>
                            <ul className="text-dark">
                                <li className="pb-4">
                                    Original Registration Certificate (RC)­
                                </li>
                                <li className="pb-4">Valid Insurance Certificate</li>
                                <li className="pb-4"> Duplicate Keys</li>
                                <li className="pb-4">OEM Service Manual</li>
                                <li className="pb-4">
                                    Extended Warranty Certificate (if any­)
                                </li>
                                <li>Battery Warranty Card</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DocumentsRequired;
