import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '../../form/Checkbox';

import { CmsTypes } from '../../../config/utils/helper'
import { Link, useNavigate } from 'react-router-dom';

const { PAGE_BLOCK, TEXT_BLOCK } = CmsTypes




const CmsTable: React.FC<any> = () => {
    const [cmsData, setCmsData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [totalRows, setTotalRows] = useState(0)
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedCmsRecords = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');
    const navigate = useNavigate();

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Title',
            selector: (row: { title: string; }) => row.title,
            sortable: true,
            keyName: 'title'
        },
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            keyName: 'name'
        },
        {
            name: 'Type',
            selector: (row: { type: number; }) => row.type == PAGE_BLOCK ? 'Page Block' : 'Text Block',
            sortable: true,
            keyName: 'type'
        },

        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedCmsRecords ? <>
                        <Link to={`/admin/cms-add-edit?id=${row.id}`}>
                            <Button variant="success" size="sm" className="me-3" title="Edit info">
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                        </Link>

                        <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id)} title="Remove">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>

                    </> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}

                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        try {
            const getAllCms = async () => {
                setIsLoading(true)
                const cmsRecordStatus = archivedCmsRecords ? 0 : 1
                const params = { currentPage, perPage, sortDetails: JSON.stringify(sortDetails), searchText: currentSearchText, status: cmsRecordStatus };
                const response = await HTTP.get(`/api/admin/cms/fetch-all`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setCmsData(data);
                setTotalRows(totalRecords)

            }
            getAllCms()
        }
        catch (error) {
            console.log("Error in cms fetch api")
        }
        finally {
            setIsLoading(false)
        }
    }, [currentPage, perPage, sortDetails, removedIds, archivedCmsRecords, currentSearchText]);




    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    //handling Delete of cms
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }
    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }
    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false);
    }

    const handleCmsRecordStatus = async (isDelete = true) => {
        setIsOpenModal(false)
        setOpenActivateModal(false)
        setIsLoading(true);

        const response = await HTTP.put(`/api/admin/cms/edit`, { id: selectedRecord, status: isDelete ? 0 : 1 });
        const { data: { status, error, message } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: 'Cms Removed Successfully', variant: 'success' })
        } else {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        setIsLoading(false);
    }



    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    const addCms = () => {

        navigate('/admin/cms-add-edit')
    }

    return (<>


        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">CMS List</h2></div>
                <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <Checkbox name="archived" control={control} label="Show removed CMS"
                        classList="" /></div>
                <div className="p-2">
                    <Button onClick={addCms} variant="success"> Add new CMS </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>
        <DataTable
            columns={columns}
            data={cmsData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete cms"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleCmsRecordStatus(true)}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover cms"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleCmsRecordStatus(false)}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />


    </>)
}

export default CmsTable;