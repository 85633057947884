import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StarRating = ({ rating }: { rating: number }) => {
    const yellowStars = rating;
    const grayStars = 5 - rating;

    return (
        <div className="star-rating">
            {[...Array(yellowStars)].map((_, index) => (
                <span key={index} className="star yellow">
                    <FontAwesomeIcon icon={faStar} />
                </span>
            ))}
            {[...Array(grayStars)].map((_, index) => (
                <span key={index + yellowStars} className="star gray">
                    <FontAwesomeIcon icon={faStar} />
                </span>
            ))}
        </div>
    );
};

export default StarRating;