import { useEffect, useState } from 'react';
// import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import { useParams } from 'react-router-dom';
import HTTP from '../../../config/axios';

const InteriorView = () => {
    const [interiorImage, setInteriorImage] = useState("");
    const routeParams = useParams();
    const id = Number(routeParams.id)
    useEffect(() => {
        const getInteriorView = async () => {
            const response = await HTTP.get(`/api/vehicle/interior-image/${id}`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setInteriorImage(data.imageUrl)
            } else {
                setInteriorImage("")
            }
        }
        getInteriorView()
    }, []);
    return (
        <div className="App">
            {
                // interiorImage && (<ReactPhotoSphereViewer src={interiorImage} height={'100vh'} width={"100%"} container={''}></ReactPhotoSphereViewer>)
            }

        </div>
    );
}

export default InteriorView;