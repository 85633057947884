import React, { useEffect, useState } from 'react';
import BookingDataTable from '../../../components/admin/booking/BookingDataTable';

const ListBooking: React.FC = () => {

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <BookingDataTable />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListBooking;