import React, { useEffect, useState } from 'react';
import TestimonialDataTable from '../../../components/admin/testimonial/TestimonialTable';
import { Button } from 'react-bootstrap';
import TestimonialAddEditModal from '../../../components/admin/testimonial/TestimonialAddEditModal';

const TestimonialDashboard: React.FC = () => {
    const [show, setShow] = useState(false);
    const [countAddEdit, setCountAddEdit] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [testimonialDetails, setTestimonialDetails] = useState({ id: 0, date: '', name: '', image: '', title: '', testimonial: '', rating: '' });
    const newTestimonial = () => {
        setShow(true);
        setTestimonialDetails({ id: 0, date: '', name: '', image: '', title: '', testimonial: '', rating: '' })
    }
    const handleClose = () => setShow(false);

    const editModal = (testimonial: any) => {
        setTestimonialDetails(testimonial)
        setShow(true)
    }

    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                    {/* Topbar */}

                    <div className="container-fluid">
                        <div>
                            <TestimonialDataTable newTestimonial={newTestimonial} showModal={editModal} handleClose={handleClose} countAddEdit={countAddEdit} totalRows={totalRows} setTotalRows={setTotalRows} />
                            <TestimonialAddEditModal show={show} handleClose={handleClose} testimonialDetails={testimonialDetails} setCountAddEdit={setCountAddEdit} totalRows={totalRows} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialDashboard;