import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Image, Row, Col } from "react-bootstrap";
import {
  faAngleRight,
  faCar,
  faGift,
  faCheck,
  faCircleQuestion,
  faCommentDollar,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";



const ProfileNav = ({ firstName, profileImagePath, currentTab }: { firstName: string, profileImagePath: string, currentTab: string }) => {


  const getCamelCaseUsername = () => {
    return firstName.charAt(0).toUpperCase + firstName.slice(1)
  }
  
  return (
    <Nav variant="pills"
      className="flex-md-column pt-4 border-end ps-0" >


      {/* Personal Information  sideTab_0 */}
      <Nav.Item className="border-bottom py-2">
        <Nav.Link eventKey="sideTab_0" className="is-description">
          <div >
          <Row>
             <Col lg={4} md={12}>
              <Image
              src={profileImagePath ? `${process.env.REACT_APP_S3_BASE_URL}/${profileImagePath}` : `${process.env.REACT_APP_CLOUDFRONT_URL}/public/pro_pic.png`}
              className="rounded-circle"
              height={"80px"}
              width={"80px"}
            />
             </Col>
           <Col lg={8} md={12} className="d-flex">
            <h3 className="align-self-center mb-0 text-dark fw-semibold text-break"> Hello, {firstName.toUpperCase()}
            </h3>
            </Col>
          </Row>
          </div>
        </Nav.Link>
      </Nav.Item>

      {/* Test drive sideTab_1 */}
      <Nav.Item className="border-bottom py-2">
        <Nav.Link eventKey="sideTab_1" className=" is-description" >
          <div className="d-flex">
            <FontAwesomeIcon
              className="align-self-center pe-3"
              icon={faAngleRight}
            />
            Test drive
          </div>
          <div className="d-flex justify-content-between">
            <p className="fs-7 ps-4">
              View and manage your test drives
            </p>
            <FontAwesomeIcon className="pt-2" icon={faAngleRight} />
          </div>
        </Nav.Link>
      </Nav.Item>

      {/* Bookings sideTab_2 */}
      <Nav.Item className="border-bottom py-2">
        <Nav.Link eventKey="sideTab_2" className=" is-description">
          <div className="d-flex">
            <FontAwesomeIcon className="align-self-center pe-3" icon={faCar} />
            Bookings
          </div>
          <div className="d-flex justify-content-between">
            <p className="fs-7 ps-4">
              See booking status and complete <br /> paperwork
            </p>
            <FontAwesomeIcon className="pt-2" icon={faAngleRight} />
          </div>
        </Nav.Link>
      </Nav.Item>

      {/* Sell orders sideTab_3 */}
      <Nav.Item className="border-bottom py-2">
        <Nav.Link eventKey="sideTab_3" className=" is-description" >
          <div className="d-flex">
            <FontAwesomeIcon className="align-self-center pe-3" icon={faHistory} />
            Sell orders
          </div>
          <div className="d-flex justify-content-between">
            <p className="fs-7 ps-4">
              View, track and manage your sell orders
            </p>
            <FontAwesomeIcon className="pt-2" icon={faAngleRight} />
          </div>
        </Nav.Link>
      </Nav.Item>

      {/* Help & Support sideTab_4 */}
      <Nav.Item className="border-bottom py-2">
        <Nav.Link eventKey="sideTab_4" className=" is-description" >
          <div className="d-flex">
            <FontAwesomeIcon className="align-self-center pe-3" icon={faCircleQuestion} />
            Help & Support
          </div>
          <div className="d-flex justify-content-between">
            <p className="fs-7 ps-4">
              Need help, chat with us?
            </p>
            <FontAwesomeIcon className="pt-2" icon={faAngleRight} />
          </div>
        </Nav.Link>
      </Nav.Item>

      {/* Finance  sideTab_5*/}
      <Nav.Item className="border-bottom py-2">
        <Nav.Link eventKey="sideTab_5" className=" is-description" >
          <div className="d-flex">
            <FontAwesomeIcon className="align-self-center pe-3" icon={faCommentDollar} />
            Finance
          </div>
          <div className="d-flex justify-content-between">
            <p className="fs-7 ps-4">
              Check eligibility and apply for finance
            </p>
            <FontAwesomeIcon className="pt-2" icon={faAngleRight} />
          </div>
        </Nav.Link>
      </Nav.Item>

      {/* Refer and earn sideTab_6 */}
      <Nav.Item className="py-2">
        <Nav.Link eventKey="sideTab_6" className=" is-description" >
          <div className="d-flex">
            <FontAwesomeIcon className="align-self-center pe-3" icon={faGift} />
            Refer and earn
          </div>
          <div className="d-flex justify-content-between">
            <p className="fs-7 ps-4">
              Refer your friends
            </p>
            <FontAwesomeIcon className="pt-2" icon={faAngleRight} />
          </div>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )
}


export default ProfileNav;