import React from "react";
import { Alert, Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";

interface ITextField {
    name: string;
    label?: string;
    placeholder?: string;
    control?: Control<Record<string, any>>;
    classList?: string;
    type?: string;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    rule?: any;
    defaultValue?: string;
    clearErrors?: Function;
    disabled?: boolean;
    errText?: string;
    upperCase?: boolean;
    step?: number | string;
    unit?: string;  // Added unit property
}

const InputTextField: React.FC<ITextField> = ({
    name,
    label,
    placeholder,
    control,
    classList,
    type = "text",
    min,
    max,
    minLength,
    maxLength,
    rule,
    clearErrors,
    defaultValue,
    disabled = false,
    errText = '',
    upperCase = false,
    step,
    unit,  // Added unit prop
}) => {

    const handleError = () => {
        if (clearErrors) clearErrors();
    }

    if (rule?.required && typeof rule.required == 'function') {
        rule.required = rule.required(label);
    }

    return (
        <div className={`input-group ${classList}`}>
            {label && (
                <Form.Label htmlFor={`${name}-id`} className="text-dark fw-semibold fs-6">
                    {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                </Form.Label>
            )}
            <Controller
                name={name}
                control={control}
                rules={rule}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <>
                        <div className="input-group">
                            <Form.Control
                                type={type}
                                id={`${name}-id`}
                                value={upperCase ? (value || '').toUpperCase() : value || ''}
                                placeholder={placeholder}
                                disabled={disabled}
                                {...(defaultValue ? { defaultValue } : {})}
                                {...(!isNaN(min as number) ? { min } : {})}
                                {...(!isNaN(max as number) ? { max } : {})}
                                {...(step !== undefined ? { step } : {})}
                                {...(!isNaN(minLength as number) ? { minLength } : {})}
                                {...(!isNaN(maxLength as number) ? { maxLength } : {})}
                                onChange={(e) => {
                                    const newValue = upperCase ? e.target.value.toUpperCase() : e.target.value;
                                    handleError();
                                    onChange(newValue);
                                }}
                            />
                            {unit && unit.trim() !== '' && (
                                <span className="input-group-text" id={`${name}-unit`}>
                                    {unit}
                                </span>
                            )}
                        </div>
                        {error?.message && (
                            <Form.Text className="text-danger">
                                {error.type !== 'required' && errText ? errText : error.message}
                            </Form.Text>
                        )}
                    </>
                )}
            />
        </div>
    );
}

export default InputTextField;
