import { createContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import CompareCard from "../../../components/modules/Vehicle/CompareCard"
// import CompareMobileCard from "../../../components/modules/Vehicle/CompareMobileCard";
import { useParams } from 'react-router-dom';
import HTTP from "../../../config/axios";



export const VehicleContext = createContext<any>({ vehicleDetails: '', setVehicleDetails: () => { } });

const CompareCars = () => {

    const { firstCarId, secondCarId } = useParams();

    const [isMobile, setIsMobile] = useState(false)
    const [vehicleDetails, setVehicleDetails] = useState<any>({})

    
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 775)
        window.addEventListener('resize', handleResize);
        setIsMobile(window.innerWidth <= 775);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

 


    return (
        <>
        <VehicleContext.Provider value={{vehicleDetails, setVehicleDetails}} >
            {/* {   
                isMobile ? 
                 
                <CompareMobileCard /> :    */}

                <Container className="pt-5">
                    <h2 className="text-primary">Car Comparison</h2>
                    <Row className="my-5">
                        <CompareCard id={firstCarId !== '0' && firstCarId?.length ? firstCarId : ''} carPosition="first" />
                        {/* 2nd car */}
                        <CompareCard id={secondCarId !== '0' && secondCarId?.length ? secondCarId : ''} carPosition="second" />
                    </Row>
                </Container>
            {/* } */}
        </VehicleContext.Provider>
            
        </>
    );
}

export default CompareCars;
