import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect, ReactNode } from 'react';

interface Imodal {
    isOpen: boolean;
    closeModal: Function;
    component: ReactNode;
    title?: string;
    dialogClass?: string;
    buttonList?: string | ReactNode
}

const CustomModal = ({ isOpen, closeModal, component, title, dialogClass = "", buttonList = "" }: Imodal) => {

    return (
        <>
            <Modal
                show={isOpen}
                onHide={() => closeModal(false)}
                dialogClassName={dialogClass}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold">{title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {component}
                </Modal.Body>
                <Modal.Footer>
                    {buttonList}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CustomModal;