import { useEffect, useRef, useState } from "react";
import { Col, Row, Container, Image, Button } from "react-bootstrap";
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import InputTextField from "../../form/InputTextField";
import { useForm } from "react-hook-form";
import InputTextAreaField from "../../form/InputTextAreaField";
import { Form } from "react-bootstrap";
import { faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileUpload from "../../form/FileUpload";
import HTTP from "../../../config/axios";
import { useSearchParams } from "react-router-dom";
import { fieldRequired } from "../../../config/validations/rules";
import CustomModal from "../../common/CustomModal";
import SimpleSpinner from "../../common/Spinner";
import CustomPopover from "../../common/CustomPopover";



interface IVehicleDetails {
    vehicleId:  string
    setToast:Function,
    setIsLoading:Function,
    vehicleUuid:string
}


const DentMap:React.FC<IVehicleDetails> = ({vehicleId,setToast,setIsLoading,vehicleUuid})=>{

    const dentImgInput = useRef<any>()
    
    const [currentMark, setCurrentMark] = useState({})
    const [showForm, setShowForm] = useState(false)
    const [showDentMap, setShowDentMap] = useState(false)
    const [selectedDent, setSelectedDent] = useState<any>("")
    const [isSuccess, setSuccess] = useState(false)
    const [hasDentImage, setHasDentImage] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedImage, setSelectedImage] = useState("")
    const [prevData, setPrevData] = useState<any>({})
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [dentCount, setDentCount] = useState(0) // Actual dent record count in table

    
    const [markers, setMarkers] = useState< Array <any >>([])
    const { control, handleSubmit, watch, reset } = useForm<Record<string, any>>({defaultValues:{description:'', dent_image:''}}) 
    // useState < Array <any >> ([
    //                                 {
    //                                     top: 10, //10% of the image relative size from top
    //                                     left: 50, //50% of the image relative size from left
    //                                     image: ''
    //                                 },
    //                             ]);

    useEffect(() => {

        // Show first dent image details when dent map is view on first time
        if(showDentMap){
            setSelectedDent(markers[0])
        }

    },[markers])


    useEffect(() => {
        getAllDentDetails()
    }, [showDentMap])


    const currentSelectImg = watch('dent_image')

    useEffect(() => {
        currentSelectImg && uploadImageValidation(currentSelectImg)
    }, [currentSelectImg])


    const getAllDentDetails = async() => {
        try{
            const response = await HTTP.get(`/api/admin/fetch-dent-data/${vehicleUuid}`)
            const { data: { status, dentData } } = response

            if(status){
                setMarkers(dentData.map((data:any) => {
                    const dentData = JSON.parse(data.dent_data)
                    dentData.id = data.id
                    dentData.image = `${process.env.REACT_APP_CLOUDFRONT_URL}/${dentData.image}`
                    return dentData
                }))

                if(dentData.length){
                    setHasDentImage(true)
                    setDentCount(dentData.length)
                }
            } 

            if(dentData.length == 0){
                setMarkers([])
            }
        }
        catch(err){
            console.log("Error in  dent fetch api", err);
        }
    }

    const getSelectedDentDetails = async(sort: number) => {
        try{
            const response = await HTTP.get(`/api/admin/fetch-dent-data/fetch-one/${vehicleUuid}/${sort}`)
            const { data: { status, data } } = response

            if(status){
                setSuccess(false)
                setIsEdit(true)
                setShowForm(true)
                const dentData = JSON.parse(data.dent_data)
                setPrevData(data)
                setSelectedImage(`${process.env.REACT_APP_CLOUDFRONT_URL}/${dentData.image}`)
                reset({description: dentData.description})
                const lastIndex = markers.length - 1
                if(markers.length > dentCount){
                    markers.pop()
                    setMarkers([...markers])
                }
            }else{
                reset({description:'', dent_image:''})
            }
        }
        catch(err){
            console.log("Error in  dent fetch api", err);
        }
    }

    const uploadImageValidation = (file: any) => {
             
        if (file) {
            const { name, size, type } = file;
    
            const fileExt = file.name.split('.').pop();
            if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type) ||
                !['jpeg', 'jpg', 'png'].includes(fileExt.toLowerCase())) {
                 setToast({ open: true, header: 'Failed', message: 'Only files with these extensions are allowed: jpg, jpeg, png.', variant: 'danger' })
                return
            }

            setSelectedImage(URL.createObjectURL(file) || '')
        }
        
    };


    const deleteDent = async() => {
        try{
            setIsLoading(true)
            const response = await HTTP.patch(`/api/admin/delete-dent-data/${vehicleUuid}/${prevData.id}/${prevData.sort}`)
            const { data: { status, data } } = response

            if(status){
                getAllDentDetails()
                setSuccess(true)
                resetDentPage()
                setDentCount(dentCount - 1)
                setToast({ open: true, header: 'Deleted', message: 'Deleted dent successfully', variant: 'success' })
            } else {
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
            }
            setIsLoading(false)
        }
        catch(error){
            setIsLoading(false)
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
    }




    const viewDentMap = () => { 
        setShowDentMap(true)
        resetDentPage()
        getAllDentDetails()
    }

    const resetDentPage = () => {
        setSuccess(false)
        setShowForm(false)
        reset({description:'', dent_image:''})
        setIsEdit(false)
        setSelectedImage('')
        setPrevData('')
        setOpenDeleteModal(false)
    }

    const DentMarker = () => {

        // To add pointers selected in markers array
        const handleAddMarker = (marker:any) => {

            // If current form is not submitted, remove current selected pointer from array
            // this prevent duplicate pointer and allow moving pointer along image until final selection
            if(showForm && !isEdit){ 
                markers.pop()
            }
 
            if(isEdit){
                const index = prevData.sort -1; // index of editing dent pointer
                setMarkers((prev:any) => {
                    prev[index] = marker
                    return [...prev]
                })
            } else {
                setMarkers([...markers, marker])
            }
            
            
            setSuccess(false)
            setShowForm(true)
            setCurrentMark(marker)
          };


          const CustomMarker = (props: any) => {

            // console.log("props ===================", props);
            
            return (
                <p className="pointer" onClick={()=>getSelectedDentDetails(props.itemNumber + 1)}>
                    {props.itemNumber + 1}
                </p>
            );
        };

          
        return <Col md={6} sm={12}>
            <Row>
                <h3 className="text-dark fw-semibold mb-0 is-testimonials"> Mark dents on car   </h3>
                <p className="text-secondary fs-7">
                    <CustomPopover header="Dent map workflow" 
                    body="<ul>
                            <li>To add dent, click on image below to mark and add dent details</li>
                            <li>To edit dent, click on a pointer on map and edit dent details </li>
                            <li>To change dent pointer on edit, click on incorrect pointer and click on the correct position</li>
                        </ul>" 
                    component={<FontAwesomeIcon icon={faExclamationCircle} />} />
                    &nbsp; Help tips
                 </p>

            </Row>

            <div className="image-container">
                <ImageMarker 
                    src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/dent_map_placeholder.png`}
                    markers={markers}
                    onAddMarker={handleAddMarker}
                    markerComponent={CustomMarker}
                />
            </div>
        </Col>
    }

    const DentForm = () => {
        
        const [searchParams] = useSearchParams();

        const addMarker = async(data:any) => {
            try {
               
                setIsLoading(true)
                const {image} = JSON.parse(prevData.dent_data || '{}')
                const dentFormdata = {
                    ...(isEdit ? { ...currentMark, image: data.dent_image ? '' : image} : 
                                    {...currentMark}),
                    description: data.description,
                }


                const formData = new FormData()
                formData.append('id', isEdit ? prevData.id : '')
                formData.append('vehicleId', vehicleId   )
                formData.append('sort', isEdit ? prevData.sort : markers.length.toString())
                formData.append('dentFormdata', JSON.stringify(dentFormdata))
                formData.append('dentImage', data.dent_image || '')
                formData.append('vehicleUUID', searchParams.get('vehicle') as string)

                const response = await HTTP.post(`/api/admin/${isEdit?'edit':'add'}-dent-data`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                const { data: { status } } = response
        
                if(status){

                    setToast({ open: true, header: isEdit?'Edited':'Added', 
                                message: `Dent ${isEdit?'edited':'added'} successfully`, variant: 'success' })
                    
                    // add form data to dent object
                    if(isEdit){
                        const index = prevData.sort -1; // index of editing dent pointer
                        setMarkers((prev:any) => {
                            prev[index] = { ...prev[index], ...data}
                            return [... prev]
                        })
                    } else {
                        setMarkers((prev:any) => {
                            const lastIndex = prev.length - 1
                            const lastDent = { ...prev[lastIndex],  ...data}
                            return [...prev.slice(0, lastIndex), lastDent]
                        })
                        setDentCount(dentCount + 1)
                    }
                     
                    setSuccess(true)
                    resetDentPage()

                    // setToast({ open: true, header: 'Success', message: 'Dent details successfully saved', variant: 'success' })
                }else{
                    setToast({ open: true, header: 'Failed', message: 'Something went wrong. Please try again', variant: 'danger' })
                }
                setIsLoading(false)
            }
            catch(err){
                setIsLoading(false)
                console.log("Error in dent submit api", err);
                
            }
        }


        const uploadImageValidation = (file: any) => {
             
            if (file) {
                const { name, size, type } = file;
        
                const fileExt = file.name.split('.').pop();
                if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type) ||
                    !['jpeg', 'jpg', 'png'].includes(fileExt.toLowerCase())) {
                     setToast({ open: true, header: 'Failed', message: 'Only files with these extensions are allowed: jpg, jpeg, png.', variant: 'danger' })
                    return
                }
            }
            
        };

        return <Form onSubmit={handleSubmit(addMarker)}>
                    <Row>
                    <div className="mb-3">
                        <InputTextAreaField label="Description" name="description" control={control} rule={{ required: fieldRequired}}/>
                    </div>
                    </Row>
                    <Row>
                        <Col md={6} sm={12}>
                            <div className="mb-3">
                                <FileUpload label="Upload Dent Image" name="dent_image" 
                                    control={control} accept=".jpeg,.jpg,.png" rule={{ required: isEdit ? false : fieldRequired}}/>
                            </div>
                        </Col>
                        <Col md={6} sm={12}>
                            { selectedImage && <Image src={selectedImage} alt="Preview" style={{maxWidth: '200px', maxHeight: '200px' }} />}
                        </Col>
                         
                    </Row>
                    <Row >
                        <Col md={isEdit?4:6}  sm={12} >
                            <div className="mt-4 w-100">
                                <Button type="submit" variant="primary" className="w-100"> Save </Button>
                            </div>
                        </Col>
                        { isEdit && 
                            <Col md={4} sm={12} >
                                <div className="mt-4">
                                    <Button type="button" onClick={()=>setOpenDeleteModal(true)} className="w-100" variant="outline-primary"> Delete</Button>
                                </div>
                            </Col>}
                        <Col md={isEdit?4:6} sm={12} >
                            {
                                markers.length > 0 && hasDentImage && // Show 'View' button only after adding atleast one dent image or vehicle have atleast one image
                                <div className="mt-4">
                                    <Button type="button" onClick={()=>viewDentMap()} className="w-100" variant="outline-primary"> View</Button>
                                </div>
                            }
                        </Col>
                         
                    </Row>
                    {/* Hidden input file upload field */}
                    {/* <input ref={dentImgInput} onChange={handleImageChange}
                        type="file" style={{display: 'none'}} /> */}
                </Form>
    }


    const DentView = () => {

        return <Col md={6} sm={12}>
            
                <p className="text-secondary fs-7"> <FontAwesomeIcon icon={faExclamationCircle} />
                &nbsp;Click on a pointer on dent map to view dent details</p>
             
                    <div className="image-container">
                        <img
                        src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/dent_map_placeholder.png`}
                        alt="Your Image"
                        className="image"
                        />
                        {markers.map((mark, index) => (
                            <div className={`pointer ${mark.id == selectedDent.id ? 'active': ''}`} key={index}
                            onClick={() => setSelectedDent(mark)}
                            style={{ top: mark.top+'%', left: mark.left+'%', cursor: 'pointer' }}>{index +1}</div>
                        ))}
                        
                    </div>
                </Col>
    }

    const DentDetails = () => {

        return <Col md={6} sm={12}>
                    <Row>
                        <Image className="dent-preview" src={selectedDent?.image} alt="Preview"/>
                    </Row>
                    <Row>
                        <div className="mt-2">
                            <p className="text-dark"> 
                            <span className="text-primary"> Description: </span> {selectedDent?.description}
                            </p>
                        </div>
                    </Row>
                    <Row>
                        <Col md={6} sm={12} >
                            <div className="mt-5">
                                <Button variant="primary" onClick={()=>setShowDentMap(false)} className="w-100"> Add Another Dent</Button>
                            </div>
                        </Col>
                        <Col md={6} sm={12}> </Col>
                    </Row>
                </Col>
    }



    return (
        <div className="App"  >
        <Container className=" pb-5"  >
            { !showDentMap ? <>
            {/* <Row><h2 className="text-dark  pb-4">Create Dent Map</h2></Row> */}
            <Row>

                <DentMarker />

            <Col md={6} sm={12}>
            <Row>
                <h3 className="text-dark pb-4 fw-semibold is-testimonials"> {isEdit?'Edit':'Add'} dent details </h3>
            </Row>
            {/* Display form to add dent */}
            {
                showForm && <DentForm />
            }
            {/* Dent form is removed after form submit and prompt to click on image for another dent submit */}
            {
                !showForm && !isSuccess && <p className="text-primary"> <ul>
                <li>To add dent, click on dent map to mark and add dent details</li>
                <li>To edit dent, click on a pointer on map and edit dent details </li>
                <li>To change dent pointer on edit, click on incorrect pointer and click on the correct position</li>
            </ul></p>
            }

            {
                isSuccess && <>
                <p className="text-success fw-bold pt-4"><FontAwesomeIcon icon={faCheck} className="me-3" />Dent details added successfully.  </p>
                <p className="text-dark fw-semibold pt-0">Click on dent map to add another dent. </p>
                </>
            }

            {
                markers.length > 0 && !showForm && 
                    <Row>
                        <div className="mt-5">
                            <Button type="button" onClick={()=>viewDentMap()}  variant="outline-primary"> View Dent Map</Button>
                        </div>
                    </Row>
            }
                {/* { selectedImage && <Image src={selectedImage} alt="Preview" style={{maxWidth: '400px', maxHeight: '400px' }}  />} */}
            
                </Col>
      
        </Row>
            </> :
            <>
            <Row><h2 className="text-dark pb-4 is-testimonials">Dent Map Preview</h2></Row>
            <Row>
                <DentView />
                <DentDetails />
            </Row>
            </>

            }
                
            
      </Container>

      <CustomModal isOpen={openDeleteModal} title="Delete dent"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setOpenDeleteModal} buttonList={<>
                <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={deleteDent}>
                    Confirm
                </Button></>} />

      </div>
    );
}


export default DentMap