import DateTimePicker from "../../../components/form/DateTimePicker";
import { useForm } from "react-hook-form";
import { fieldRequired } from '../../../config/validations/rules';
import HTTP from "../../../config/axios";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
interface ITestDrive {
    vehicleId: string;
    setToast: Function;
    setShowTestDriveModal: Function
}
// Test drive modal content
const TestDrive = ({ vehicleId, setToast, setShowTestDriveModal }: ITestDrive) => {
    const { control, handleSubmit, setValue } = useForm()

    const onSubmit = async (submitData: any) => {
        try {
            submitData.dateTime = new Date(submitData.dateTime)
            submitData.vehicleId = vehicleId
            const response = await HTTP.post("/api/vehicle/test-drive/add", submitData)
            const { data: { status, data, message, error } } = response
            setToast({ open: true, header: 'Test Drive', message: message, variant: (status) ? 'success' : 'danger' })
            setShowTestDriveModal(false)
        }
        catch (error) {
            setShowTestDriveModal(false)
            console.log("Error in onsubmit test drive api", error)
            setToast({ open: true, header: 'Test Drive', message: 'Something went wrong. Please try again', variant: 'danger' })
        }

    }
    const currentDate = new Date();

    // Get the individual components of the date and time
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours() + 1).padStart(2, '0');

    // Format the date and time without seconds
    const minDateTime = `${year}-${month}-${day}T${hours}:00`;


    return (<Form onSubmit={handleSubmit(onSubmit)}>
        <Container>
            <Row className="mb-4">Please select the preferred date and time of visit.</Row>
            <Row>
                <Col xs={8} sm={8} md={8} lg={8}>
                    <DateTimePicker name="dateTime" min={minDateTime} placeholder="Please select a date and time" label="" rule={{ required: fieldRequired }} control={control} /></Col>
                <Col xs={4} sm={4} md={4} lg={4}>
                    <Button className="btn btn-primary" type="submit"> Save </Button>
                </Col>
            </Row>
        </Container>
    </Form>)
}

export default TestDrive