import React, { createContext, useState, ReactNode, useEffect } from 'react';

interface ClientContextProps {
    locationContext: string[];
    setLocationContext: React.Dispatch<React.SetStateAction<string[]>>;
    locationOptContext: {value:string,label:string}[];
    setLocationOptContext: React.Dispatch<React.SetStateAction<{value:string,label:string}[]>>;
    toast: { open: boolean, header: string, message: string, variant: string },
    setToast: React.Dispatch<React.SetStateAction<{ open: boolean, header: string, message: string, variant: string } >>;
    isMobile: boolean,
    setIsMobile:  React.Dispatch<React.SetStateAction<boolean>>
}

const ClientContext = createContext<ClientContextProps>({   locationContext: [], 
                                                            setLocationContext: () => { },
                                                            locationOptContext: [], 
                                                            setLocationOptContext: () => { },
                                                            toast: { open: false, header: '', message: '', variant: '' },
                                                            setToast: () => { },
                                                            isMobile: false,
                                                            setIsMobile: () => { }
                                                        });

const ClientContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [locationContext, setLocationContext] = useState<string[]>([]);
    const [locationOptContext, setLocationOptContext] = useState<{value:string,label:string}[]>([])
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [isMobile, setIsMobile] = useState(false)

    return (
        <ClientContext.Provider value={{ locationContext, setLocationContext, 
                                        locationOptContext, setLocationOptContext,
                                        toast, setToast,
                                        isMobile, setIsMobile }}>
            {children}
        </ClientContext.Provider>
    );
};

export { ClientContext, ClientContextProvider };
