import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CarCard from "../../common/CarCard";

interface ICarGrid {
    id: number;
    km: number;
    image: string;
    fuel: string;
    transmission: string;
    price: any;
    make: string;
    model: string;
    variant: string;
    year: number;
}

const CarGridList = ({ carList, favourites }: { carList: ICarGrid[], favourites?: boolean }) => {
    return (<>
        <Row xs={1} className="g-4">
            {carList.map((card, cardIndex) => (
                <Col key={cardIndex} md={favourites ? 6 : 6} lg={favourites ? 6 : 6} xl={favourites ? 4 : 4}>
                    <CarCard detail={card} compare={false} favourites={favourites} />
                </Col>
            ))}
        </Row>
    </>);
};

export default CarGridList;
