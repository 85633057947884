import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";
import Carousel, {DotProps} from "react-multi-carousel";
const Badge = ({ image, badge, isMobile, isDealOfTheDay, fromSection,isAssured }: { image: any, badge: string, isMobile?: boolean, isDealOfTheDay?: number, fromSection?: string ,isAssured?:number}) => {

    // if (badge == "assured") {
    //     setBadgeIcon(process.env.REACT_APP_CLOUDFRONT_URL + `/public/assured_badge.png`)
    // } else if (badge == "sold") {
    //     setBadgeIcon(process.env.REACT_APP_CLOUDFRONT_URL + `/public/sold_badge.png`)
    // } else if (badge == "booked") {
    //     setBadgeIcon(process.env.REACT_APP_CLOUDFRONT_URL + `/public/booked_badge.png`)
    // }
    const badgeList: any = {
        // assured: 'assured_badge',
        sold: 'sold_orange_badge.png',
        booked: 'booked-badge-mobile.svg'
    }
    const badgeUrl = process.env.REACT_APP_CLOUDFRONT_URL + `/public/${badgeList[badge]}`

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1100 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1..
        },
        tablet: {
            breakpoint: { max: 1100, min: 767 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    const CustomDot: React.FC<DotProps> = ({ onClick, ...rest }) => {
        const { active } = rest;
        return (
            <li
                onClick={(e) => {
                    e.stopPropagation();
                    if (onClick) onClick();
                }}
            >
                <button className={`custom-dot-button-for-card ${active ? 'active' : ''}`}/>
            </li>
        );
    };

    return (
        <>
          
                <Carousel
                    className="home-type-carousel"
                    swipeable={true}
                    draggable={true}
                    responsive={responsive}
                    // ref={carouselRef}
                    ssr={true} // render carousel on server-side.
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    arrows={false}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px"
                    showDots={true}
                    customDot={<CustomDot />}
                >
                    {image.slice(0, 7).map((data: any) => (
                        
                        <>
                            <Fragment key={data.id}>
                                <div className="ribbons-wrapper">
                                    <div className="ribbon">
                                        {isDealOfTheDay == 1 && <span className="ribbon1"><span className="deal">Deal of <br /> the day</span></span>}
                                    </div>
                                </div>

                            </Fragment>
                            <div key={data.id} className={isMobile ? "mobile-card-image-outer" : "web-card-image-outer"}>
                                <Card.Img variant="top" src={`${process.env.REACT_APP_CLOUDFRONT_URL}${data.imageUrl}`} className={isMobile ? "object-fit-cover h-100 w-100 rounded-top-15" : "h-100 object-fit-cover rounded-top-15"} />

                                <div className="sale-badge">
                                    {badge !== "" && <Image src={badgeUrl} width={"25%"} />}
                                </div>


                            </div >

                        </>
                    ))
                    }

                </Carousel >

        </>
    )
}
export default Badge