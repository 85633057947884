import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import VariantDataTable from '../../../components/admin/variants/VehicleVariantTable';
import AddEditModal from '../../../components/admin/variants/VariantAddEditModal';
import HTTP from '../../../config/axios';
import { log } from 'console';

const VariantDashboard: React.FC = () => {
    const [show, setShow] = useState(false);
    const [modelDetails, setModelDetails] = useState({ id: 0, name: '', modelId: null, modelName: '', makeId: null, bodyTypeId:null });
    const [addEditCount, setAddEditCount] = useState(0)
    const [featureDetails,setFeatureDetails] = useState({})

    const newVariant = () => {
        setShow(true);
        const details = { id: 0, name: '', modelId: null, modelName: '', makeId: null, bodyTypeId: null }
        //getVariantSpecDetails(details)
        setModelDetails(details)
    }
    const handleClose = () => setShow(false);

    const editModal = async (variant: any) => {
        setModelDetails(variant)
        setShow(true)
    }

    //Function tofetch variant and speifcation details
    // const getVariantSpecDetails = async (variant: any) => {

    //     const response = await HTTP.get(`/api/admin/variant/getVariantSpecDetails/${variant.id}`);
    //     const { data: { status, data, msg, error } } = response
    //     if (Object.keys(data.transformedDetails).length === 0) {
    //         setModelDetails(variant)
    //     } else {
    //         setModelDetails({
    //             ...variant,
    //             ...data.transformedDetails
    //         });
    //     }

    // }


    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <div className="container-fluid">
                        <div>
                            <VariantDataTable newVariant={newVariant} editModalDetails={editModal} addEditCount={addEditCount} />
                            {show && <AddEditModal show={show} handleClose={handleClose} variantDetails={modelDetails} featureDetails={featureDetails} setAddEditCount={setAddEditCount} setModelDetails={setModelDetails} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VariantDashboard;