import React, { useCallback, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import HTTP from "../../../config/axios";
import { encryptLocalStorage } from "../../../config/utils/storage";
import LogIn from "../../../components/admin/authentication/login"
import { useNavigate } from "react-router-dom";
import { roles } from "../../../config/utils/helper";

const SignIn = () => {
    // const token = encryptLocalStorage.getItem("jwtToken");
    // if (token) {
    //     window.location.replace("/admin");
    // }
    const [siteKey, setSiteKey] = useState('');
    const [siteToken, setSiteToken] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        const userInfo = encryptLocalStorage.getItem("userInfo")
        
        if (token && userInfo && userInfo.roleUUID == roles.SUPER_ADMIN) {
          navigate('/admin/vehicles')
        }
      },[])

    useEffect(() => {
        const getSiteKey = async () => {
            try {
                const response = await HTTP.get('/api/fetch-site-key')
                const { status, siteKey } = response.data
                if (status && siteKey) {
                    setSiteKey(siteKey)
                }
            }
            catch (err) {
                console.log('Check Error from get site key', err)
            }
        }
        // encryptLocalStorage.clear()
        getSiteKey()
    }, [])


    const handleVerify = useCallback((token: string) => {
        setSiteToken(token);
    }, [])

    return (
        <div className="App" style={{ overflow: 'hidden' }}>
            {siteKey &&
                <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                    <Container fluid>
                        <Row className="vh-100">
                            <Col className="p-3" style={{ overflow: 'hidden' }}>
                                <LogIn />

                            </Col>
                        </Row>
                    </Container>
                </GoogleReCaptchaProvider>
            }

        </div>
    );
}

export default SignIn;
