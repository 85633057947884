import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import LoanApplicationForm from "../../../components/modules/Finance/LoanApplicationForm";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const LoanApplication = () => {
    const loanApplicationRef = useRef<HTMLDivElement>(null);
    const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/eligibility_banner.jpg`;
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash === '#application-form' && loanApplicationRef.current) {
            loanApplicationRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [location]);

    return (
        <div className="App">
            <Container fluid className="banner-class-fit d-flex" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                <Container className="my-auto mt-60">
                    <Col md={12}>
                        <h5 className="text-primary is-sub-heading mb-1">
                            Check eligibility
                        </h5>
                        <h1 className="text-light">
                            Financing made possible for every car buyer
                        </h1>
                        <p className="text-light is-description">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis massa aliquam lectus et nulla sit quis. Dignissim urna aliquet vestibulum
                            sed urna, quis augue risus rhoncus.
                        </p>
                        <Row>
                            {/* <Col md={4}>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/tick.svg`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Completely online
                                    </h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/tick.svg`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Same day approval
                                    </h6>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/tick.svg`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Hassle free process
                                    </h6>
                                </div>
                            </Col> */}
                            <Col md={3}>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/tick.svg`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Upto 80% Funding
                                    </h6>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/tick.svg`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Same day approval
                                    </h6>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/tick.svg`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Hassle free process
                                    </h6>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="d-flex mb-4">
                                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/tick.svg`} width={"65px"} />
                                    <h6 className="ps-4 align-self-center text-light">
                                        Partnered with all major finance companies
                                    </h6>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Container>
            <div ref={loanApplicationRef}>
                <LoanApplicationForm />
            </div>
        </div>
    );
}

export default LoanApplication;
