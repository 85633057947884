import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputTextField from '../../form/InputTextField';
import { useForm } from 'react-hook-form';
import HTTP from '../../../config/axios';
import { fieldRequired } from '../../../config/validations/rules';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from '../../common/Spinner';

interface responseType {
    success: boolean;
    error: any;
    data: {
        err?: string;
        status: number,
        error: string,
        message: string,
        type: string
        fuelExist: boolean
    };
}
interface IFuelInfo {
    id: number;
    name: string;
    // keyName: string;
}

interface AddEditModalProps {
    show: boolean;
    handleClose: () => void; // Specify the function signature
    fuelDetails: IFuelInfo;
    setAddEditCount: Function
}

const FuelAddEditModal: React.FC<AddEditModalProps> = ({ show, handleClose, fuelDetails, setAddEditCount }) => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        reset(fuelDetails);
    }, [fuelDetails]);



    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })

    const { handleSubmit, formState: { errors }, setError, clearErrors, control, reset, watch } = useForm<Record<string, any>>();

    const onSubmit = async (formData: any) => {
        try {
            setIsLoading(true)
            const { data: { status, message, fuelExist } }: responseType = await HTTP({
                method: fuelDetails.id ? 'put' : 'post',
                url: `/api/admin/fuel/${fuelDetails.id ? 'edit' : 'add'}`,
                data: formData,
            })
            setIsLoading(false)
            setToast({ open: true, header: status ? 'Success' : 'Failed', message: message || 'Something went wrong', variant: status ? 'success' : 'danger' })
            if (!status && fuelExist) return // Don't close modal and reset form , if lcoation already exist
            setAddEditCount((prev: number) => prev + 1)
            handleClose()
            reset()
        }
        catch (err) {
            setIsLoading(false)
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
        }
        // finally{
        //     setIsLoading(false)
        //     handleClose()
        //     reset()
        // }
    }

    const closeModal = () => {
        handleClose()
        reset()
    }


    return (
        <>
            <Modal show={show} onHide={() => closeModal()}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        {fuelDetails.id === 0 && <Modal.Title>Add fuel type</Modal.Title>}
                        {fuelDetails.id !== 0 && <Modal.Title>Update fuel type</Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-1'>
                            <InputTextField name="name" label="Name"
                                placeholder="Enter name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div>
                        {/* <div className='mb-1'>
                            <InputTextField name="keyName" label="Key name"
                                placeholder="Enter key name" clearErrors={clearErrors}
                                control={control} rule={{ required: fieldRequired }} />
                        </div> */}
                        <InputTextField name="id" label=""
                            placeholder="" type="hidden" clearErrors={clearErrors}
                            control={control} rule={{ required: fieldRequired }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModal()}>
                            Close
                        </Button>
                        {fuelDetails.id !== 0 &&
                            <Button variant="primary" type="submit">
                                Update
                            </Button>}
                        {fuelDetails.id == 0 &&
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>}

                    </Modal.Footer>
                </Form>
            </Modal>
            <SimpleToast show={toast.open} header={toast.header}
                message={toast.message} variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })} />
            <SimpleSpinner show={isLoading} />
        </>
    );
}

export default FuelAddEditModal;

