import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";
import Benefits from "../../components/common/Benefits"
import React, { useState, useEffect } from 'react';
import HTTP from "../../config/axios";

const Benefit = () => {

    interface IAboutUs {
        id: number;
        title: string;
        name: string;
        content: string;
    }

    const [cmsArray, setCmsArray] = useState<IAboutUs[]>([]);
    useEffect(() => {
        const getBenefits = async () => {
            try{
                const aboutUsData = await HTTP.post(`/api/cms/fetch-all`,
                {
                    nameList: ["benefits"]
                })
                const { data: { status, data, msg, error } } = aboutUsData
                if (status == 1) {
                    setCmsArray(data)
                } else {
                    setCmsArray([])
                }
            }
            catch(error){
                console.log("error in CMs fetch API")
            }
            
        }
        getBenefits()
    }, []);
    return (
        <>
           <Benefits cmsContent={cmsArray[0]}/> 
        </>
    );
}

export default Benefit;
