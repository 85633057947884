import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import CarCard from "./CarCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"
import HTTP from "../../config/axios";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientContext } from "../../config/context/clientContext"
import { encryptLocalStorage } from "../../config/utils/storage";
import { useNavigate } from "react-router-dom";

const AssuredCars = () => {
  interface AssuredInterface {
    id: number;
    km: number;
    image: string;
    fuel: string;
    transmission: string;
    price: any;
    make: string;
    model: string
    variant: string;
    year: number;
  }

  const navigate = useNavigate();
  const [assuredArray, setAssuredArray] = useState<AssuredInterface[]>([]);
  const carouselRef = React.useRef<any>(null);
  const { locationContext }: any = useContext(ClientContext);
  const [isMobile, setIsMobile] = useState(false)
  let locationName = encryptLocalStorage.getItem("selectedLocation");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1650 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 1650, min: 767 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1100, min: 767 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    tabletSmall: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  useEffect(() => {
    const getAssuredData = async () => {
      const response = await HTTP.post(`/api/vehicle/search`,
        {
          searchCriteria: { assuredList: 1, selectedLocation: locationName }, perPage: 12, currentPage: 1,
          getTotal: false
        })
      const { data: { status, data, totalCount, msg, error } } = response
      if (status == 1) {
        setAssuredArray(data)
      } else {
        setAssuredArray([])
      }
    }
    getAssuredData()
  }, [locationContext]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 565)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 565);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleNextClick = () => {

    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const CustomLeftArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
      <FontAwesomeIcon icon={faChevronLeft} size={'2x'} />
    </button>
  );

  const CustomRightArrow = () => (
    <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
      <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
    </button>
  );

  const handleSearchCar = () => {
    const searchCriteria = { "assuredList": 1 };
    navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}`)
  }

  return (
    <>
      {assuredArray.length > 0 &&
        <Container fluid >
          <div className="text-center">
            <h2 className="text-primary pb-lg-4 pb-2 head-bold">POPULAR ASSURED CARS</h2>
          </div>
          {!isMobile ? (<Row>
            <Col md={2} className=" d-none flex-row-reverse align-items-center  d-md-flex ">
              <CustomLeftArrow />
            </Col>
            <Col md={8}>
              <Carousel
                className="home-type-carousel"
                swipeable={true}
                draggable={true}
                arrows={false}
                ref={carouselRef}
                responsive={responsive}
                ssr={true} // render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={4000}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "tabletSmall"]}
                itemClass="carousel-item-padding-50-px"
              >
                {assuredArray.map((data) => (
                  <div key={data.id} className="px-2" >
                    <CarCard detail={data} isCarousel={true} fromSection="assured"/>
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col md={2} className="d-none  align-items-center  d-md-flex">
              <CustomRightArrow />
            </Col>
          </Row>) : (
            <React.Fragment>
              {assuredArray.slice(0, 3).map((data) => (
                <div key={data.id} className="pb-3">
                  <CarCard detail={data} />
                </div>
              ))}
              <div className="text-center">
                <Button onClick={() => handleSearchCar()}>
                  View More
                </Button>
              </div>
            </React.Fragment>
          )}

        </Container >}
    </>
  );
};

export default AssuredCars;
