import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
    return (
        <>
            <Container fluid className="hero-section who_are_we_banner d-flex">
                <Container className="my-auto">
                    <Col md={12} className="text-center my-5 py-4">
                        <h1 className="text-light mb-0">Privacy Policy</h1>
                    </Col>
                </Container>
            </Container>
            <Container className="my-5">
            <p className="fw-bold">Privacy Policy</p>

           <p>This Privacy Policy applies to Popular Maruti which is the 'Website' created and maintained by Popular Vehicles and Services Ltd. (“PVSL”), an authorized dealer of Maruti cars manufactured by Maruti Suzuki India Ltd., for informing the various services provided by PVSL, for availing online booking of Maruti Vehicles by registering in the Website and making advance payment (“Services”) through the payment gateway service providers whose link is provided in the Website.</p> 

            <p className="fw-bold"> Statement of Privacy: </p>
            <p>Popular Maruti respects and protects the privacy of the individuals (“Users”) who register their personal information and use the Services through the Website. This Policy describes how we collect, use, protect or otherwise handle the User's information on Popular Maruti. This Privacy Policy has been compiled in compliance with and in governance with the provisions of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. </p>

            <p className="fw-bold">Types of Information collected:</p>
            <p>While visiting or ordering or registering on our website, as appropriate, you may be asked to enter your name, email address, mailing address, birth date, gender, occupation, phone number, etc. We collect information from you when you fill out a form or enter information on our Website; when you, register and make a booking order, sign up for our newsletter, respond to a survey or marketing communication, surf the Website to avail of any other Services. </p>

            <p className="fw-bold">Purpose and Usage of such information: </p>
            <p>Once you register or sign in for our Services, you are not anonymous to us. Popular Maruti uses Information to quickly process your transactions and fulfill your requests for certain products and services and contact you about special and new products and services. We might send you information about special promotions or offers by voice calls, SMS, e-mail, or WhatsApp platforms. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting </p>

            <p className="fw-bold">Information Sharing and Disclosure:</p>
            <p>Popular Maruti will not share or disclose the User's Personal Information to anyone, except in the following circumstances: </p>
            <ul style={{ listStyleType: 'circle' }}>
                <li>When we have consent from the User to share the information.</li>
                <li>To provide the product or service the user has requested.</li>
                <li>When required under a court summons or orders or legal process.</li>
            </ul>
            <p className="fw-bold">Security practices and procedures:</p>
            <p>User's personal information is password-protected for his privacy and security. We have taken adequate measures to have secure access to the User's personal data. </p>

            <p className="fw-bold">Cookie Policy</p>
            <p>Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you spend the most time on, and websites you visit). </p>
            <p> The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.</p>
            <p>Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not, so that we can provide a better experience for you. </p>

            <p className="fw-bold">Disclaimer on third-party sites:</p>
            <p>If you click on any of the third-party website links available in Popular Maruti, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for these third-party sites. </p>

            <p className="fw-bold">Changes/ Updates to this Policy:</p>
            <p>Popular Maruti may edit this policy or may change our privacy practices from time to time. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates. </p>

            <p className="fw-bold">Grievance Officer:</p>
            <p>In accordance with Information Technology Act 2000 and rules made thereunder, the name and contact details of the Grievance Officer are provided below: </p>
            <p>Mr. Varun Varrier <br/>
            Popular Vehicles and Services Ltd <br/>
            Kuttukaran Centre, Mamangalam,<br/>
            Cochin 682025 <br/>
            Phone: +91-484 2341134 <br/>
            Email: varun.varrier@popularv.com </p>
            <p>The Grievance Officer shall address any discrepancies and grievances of the User with respect to collections and processing of information. Any queries about this Privacy Policy you can also  </p>
            <p>email us at <br/>
             varun.varrier@popularv.com</p>
            <p className="fw-bold">Jurisdiction:</p>
            <p> If you choose to visit the Website, your visit and any dispute over privacy is subject to this Policy and the Website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</p>
            </Container>
        </>
    );
}

export default PrivacyPolicy;
