import React from 'react';

const Dashboard: React.FC = () => {
    return (
        <div id="wrapper">

            <div id="content-wrapper" className="d-flex flex-column">
                {/* Main Content */}
                <div id="content">
                    {/* Topbar */}

                    <div className="container-fluid">
                        <div className="d-sm-flex align-items-center justify-content-between">
                            <h2 className="mb-0 text-gray">Dashboard</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;