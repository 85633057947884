import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '../../form/Checkbox';

const DealershipTable: React.FC<any> = ({ newModal, showModal, handleClose, countAddEdit, totalRows, setTotalRows, setSortOrder }) => {
    const [dealershipData, setDealershipData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedDealerships = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            keyName: 'name'
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedDealerships ? <> <Button variant="success" size="sm" className="me-3" onClick={() => editDealership(row)} title="Edit info">
                        <FontAwesomeIcon icon={faPen} />
                    </Button>
                        <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id)} title="Remove">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}

                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        try {
            const getAllDealerships = async () => {
                setIsLoading(true)
                const dealershipStatus = archivedDealerships ? 0 : 1
                const params = { currentPage, perPage, sortDetails: JSON.stringify(sortDetails), searchText: currentSearchText, status: dealershipStatus };
                const response = await HTTP.get(`/api/admin/dealership/fetch-all`, { params });
                const { data: { status, data, msg, error, totalRecords } } = response;
                setDealershipData(data);
                setTotalRows(totalRecords)
                if (!currentSearchText) {
                    setSortOrder(totalRecords)
                }
            }
            getAllDealerships()
        }
        catch (error) {
            console.log("Error in dealership fetch api")
        }
        finally {
            setIsLoading(false)
        }
    }, [countAddEdit, currentPage, perPage, searchText, sortDetails, removedIds, archivedDealerships, currentSearchText]);

    //Invokng the edit modal for editing the dealership
    const editDealership = (dealership: any) => {
        showModal(dealership)
    }

    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    //handling Delete of dealerships
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }
    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }
    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false);
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/dealership/remove/${selectedRecord}`);
        const { data: { status, error, message, type } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message, variant: 'success' })
        } else if (status == 0 && type == "assigned") {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: message, variant: 'danger' })

        } else {
            setToast({ open: true, header: 'Failed', message, variant: 'danger' })
        }
        setIsLoading(false);
    }

    const handleRecover = async () => {
        setOpenActivateModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/dealership/recover/${selectedRecord}`);
        const { data: { status, data, totalCount, msg, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setIsLoading(false);
            setToast({ open: true, header: 'Success', message: 'Dealership recovered successfully', variant: 'success' })
        } else {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }

    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    return (<>


        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Dealerships</h2></div>
                <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <Checkbox name="archived" control={control} label="Show removed dealerships"
                        classList="" /></div>
                <div className="p-2">
                    <Button variant="success" onClick={newModal}>
                        Add dealership
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>
        <DataTable
            columns={columns}
            data={dealershipData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete dealership"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover dealership"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />


    </>)
}

export default DealershipTable;