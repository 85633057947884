import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Pagination } from 'react-bootstrap';
import BlogCard, { BlogCardProps } from '../../../components/modules/Blog/BlogCard';
import HTTP from '../../../config/axios';
import Breadcrumbs from '../../../components/common/Breadcrumbs';

const BlogList: React.FC = () => {
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const breadCrumbList = [
    { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
    { title: "Blogs", path: "/blogs", active: true, linkClass: "text-primary fw-semibold" }
  ];
  const backgroundImageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}/public/banner/about_us_banner.jpg`;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    scrollToTop();
  };

  useEffect(() => {
    const getAllBlog = async () => {
      setIsLoading(true);
      try {
        const params = { currentPage, perPage, status: 1 };
        const response = await HTTP.get(`/api/blog/fetch-all/${currentPage}/${perPage}`, { params });
        const { data: { status, data, totalRecords } } = response;
        setBlogData(data);
        setTotalRows(totalRecords);
      } catch (error) {
        console.log("Error in blog fetch api", error);
      } finally {
        setIsLoading(false);
      }
    };
    getAllBlog();
  }, [perPage, currentPage]);
  


  const totalPages = Math.ceil(totalRows / perPage)


  const PrevBtn = ()=>{
    return(
      <Pagination.Prev
        disabled={currentPage === 1}
        // onClick={() =>
        //   setCurrentPage(prevPage =>prevPage === 0 ? 0 : prevPage - 1
        //   )}
        onClick={() => handlePageChange(currentPage - 1)}
          >
        «
      </Pagination.Prev>
    )
  }



  const NextBtn = ()=>{
    return(
      <Pagination.Next
        disabled={currentPage === totalPages}
        // onClick={() =>
        // setCurrentPage(prevPage => prevPage === totalRows ? totalRows : prevPage + 1)}
        onClick={() => handlePageChange(currentPage + 1)}
        >
        »
      </Pagination.Next>
    )
  }

  return (
    <section>
      <Container className='p-3 mt-120'>
        <Breadcrumbs breadcrumbData={breadCrumbList} />
        <Row   className="justify-content-center">
          {blogData.map((blog: BlogCardProps, index: number) => (
            <Col  key={index} xs={12} md={6} lg={4}>
              <BlogCard {...blog} />
            </Col>
          ))}
        </Row>
      </Container>
      <Pagination className='justify-content-center'>
        <PrevBtn/>
        {Array.from({length:totalPages}).map(
          (_, index) => (
            <Pagination.Item
              key={index}
              active={currentPage === index + 1}
              // onClick={() => setCurrentPage(index + 1)}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          )
        )}
        <NextBtn/>
      </Pagination>
    </section>
  );
};

export default BlogList;