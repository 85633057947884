
import { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ComparisonContext } from "../../../../config/context/comparisonContext";
import { useSearchParams } from "react-router-dom";
import HTTP from "../../../../config/axios";

const CompareOverviewMob = () => {
    const [searchParams] = useSearchParams();
    const firstCarId = searchParams.get('firstCar');
    const secondCarId = searchParams.get('secondCar');

    const { compareDetails, setCompareDetails } = useContext(ComparisonContext);
    const firstCarData = compareDetails.first || {}
    const secondCarData = compareDetails.second || {}


    
    // useEffect(() => {
    //     console.log("compareDetails =============", compareDetails);
        
    // },[compareDetails])

    const firstCarSpecs = firstCarData.specs || {}
    const secondCarSpecs = secondCarData.specs || {}


    const textColor = (position:string, spec: string ) => {
        const currentSpecValue = Number(compareDetails?.[position]?.['specs']?.[spec]?.['value'])|| 0  
        const firstSpecValue = Number(compareDetails?.['first']?.['specs']?.[spec]?.['value'])|| 0 
        const secondSpecValue = Number(compareDetails?.['second']?.['specs']?.[spec]?.['value'])|| 0 


        if(currentSpecValue >= firstSpecValue && currentSpecValue >= secondSpecValue){
                return 'text-start text-success is-description mt-2'
            }else{
                return 'text-start text-dark is-description mt-2'
            }
    }
    

    return (
        <div className="App">
            <Row className="border-bottom px-0 text-break">
                <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                    <p className="text-start mt-2">Fuel type</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start border-end">
                    <p className="text-start text-dark is-description mt-2">{firstCarData.fuel || "-"}</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start ">
                    <p className="text-start text-dark is-description mt-2">{secondCarData.fuel || "-"}</p>
                </Col>
            </Row>
            <Row className="border-bottom px-0 text-break">
                <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                    <p className="text-start mt-2">Mileage-City (kmpl)</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start border-end">
                    <p className={ textColor('first','mileage-(arai)')} >{firstCarSpecs['mileage-(arai)']?.value || "-"}</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start ">
                    <p className={ textColor('second','mileage-(arai)')}>{secondCarSpecs['mileage-(arai)']?.value || "-"}</p>
                </Col>
            </Row>
            <Row className="border-bottom px-0 text-break">
                <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                    <p className="text-start mt-2">Fuel Capacity (litres)</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start border-end">
                    <p className={ textColor('first','fuel-tank-capacity')}>{firstCarSpecs['fuel-tank-capacity']?.value || "-"}</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start ">
                    <p className={ textColor('second','fuel-tank-capacity')}>{secondCarSpecs['fuel-tank-capacity']?.value || "-"}</p>
                </Col>
            </Row>
            <Row className="border-bottom px-0 text-break">
                <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                    <p className="text-start mt-2">Seating Capacity</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start border-end">
                    <p className={ textColor('first','seating-capacity')}>{firstCarSpecs['seating-capacity']?.value || "-"}</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start ">
                    <p className={ textColor('second','seating-capacity')}>{secondCarSpecs['seating-capacity']?.value || "-"}</p>
                </Col>
            </Row>
            <Row className="border-bottom px-0 text-break">
                <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                    <p className="text-start mt-2">Transmission Type</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start border-end">
                    <p className="text-start text-dark is-description mt-2">{firstCarData.transmission || "-"}</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start ">
                    <p className="text-start text-dark is-description mt-2">{secondCarData.transmission || "-"}</p>
                </Col>
            </Row>
            <Row className="border-bottom px-0 text-break">
                <Col md={2} className=" comparsion-img-mob-out text-start border-end">
                    <p className="text-start mt-2 text-break">Engine Displacement(CC)</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start border-end">
                    <p className={ textColor('first','displacement')}>{firstCarSpecs['displacement']?.value || "-"}</p>
                </Col>
                <Col md={2} className="comparsion-img-mob-out text-start ">
                    <p className={ textColor('second','displacement')}>{secondCarSpecs['displacement']?.value || "-"}</p>
                </Col>
            </Row>
        </div >
    );
};

export default CompareOverviewMob;
