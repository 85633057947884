import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useContext } from "react";
import { Button, Col, Container, Nav, Row, Tab, Image } from "react-bootstrap";
import CarCard from "../../common/CarCard";
import HTTP from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { ClientContext } from "../../../config/context/clientContext"

const CarsByBodyType = () => {

  const [isTablet, setIsTablet] = useState(false)
  const navigate = useNavigate();
  let locationName = encryptLocalStorage.getItem("selectedLocation");
  interface BodyTypeInterface {
    id: number;
    km: number;
    image: string;
    fuel: string;
    transmission: string;
    price: any;
    makeAndModel: string;
    variant: string;
  }
  interface IFilter {
    name: string,
    keyName: string,
    image: string
  }
  const [bodyType, setBodyType] = useState<IFilter[]>([]);
  const [bodyTypeContent, setBodyTypeContent] = useState<BodyTypeInterface[]>([]);
  const [bodyTypeIndex, setBodyTypeIndex] = useState(0);
  const [selectedTabKeyName, setSelectedTabKeyName] = useState("");
  const { locationContext }: any = useContext(ClientContext);


  useEffect(() => {
    const minWidth = window.innerWidth >= 768;
    const maxWidth = window.innerWidth <= 1268;
    setIsTablet(minWidth && maxWidth);

  }, []);

  useEffect(() => {
    const getBodyTypeData = async () => {

      const response = await HTTP.get(`/api/body-type/fetch-all`);
      const { data: { status, data, msg, error } } = response
      if (status == 1) {
        getCarsByBodyTypes(data[0].keyName, 0)
        setBodyType(data)
      } else {
        setBodyType([])
      }
    }
    getBodyTypeData()
  }, [locationContext]);

  const getCarsByBodyTypes = async (keyName: any, index: number) => {
    const response = await HTTP.post(`/api/vehicle/search`,
      {
        searchCriteria: { bodyType: [keyName], selectedLocation: locationName }, perPage: 3, currentPage: 1,
        getTotal: true
      })
    const { data: { status, data, totalCount, msg, error } } = response
    if (status == 1 && data.length > 0) {
      setBodyTypeContent(data)
      setBodyTypeIndex(index)
      setSelectedTabKeyName(keyName);
    } else {
      setBodyTypeContent([])
      setSelectedTabKeyName("");
    }
  }

  const handleSearchCar = (keyName: string) => {
    const searchCriteria = { "bodyType": [keyName] };
    navigate(`/buy-used-car?search-criteria=${JSON.stringify(searchCriteria)}`)
  }

  return (
    <div className="App">
      {isTablet ?
        <Container fluid>
          <Tab.Container id="left-tabs-example" defaultActiveKey="sideTab_0">
            <Row className="mb-5">
              <div className="text-center pb-4">
                <h2 className="text-primary head-bold">CARS BY BODY TYPES </h2>
              </div>
              <Col sm={12} md={isTablet ? 4 : 3} className="">
                <div className="nav-pills-scroll">
                  <Nav
                    variant="pills"
                    className="flex-md-column border-md-end ps-0"
                  >
                    {bodyType.map((carBody, index) => (
                      <Nav.Item key={carBody.keyName} className="border-bottom"
                        onClick={() => getCarsByBodyTypes(carBody.keyName, index)}>
                        <Nav.Link eventKey={`sideTab_${index}`} className=" is-description">
                          <Image
                            src={`${process.env.REACT_APP_CLOUDFRONT_URL}${carBody.image}`}
                            className="rounded-square icon-thumbnail"
                          /> {carBody.name}

                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col md={isTablet ? 8 : 9}>
                <Tab.Content>
                  <Tab.Pane eventKey={`sideTab_${bodyTypeIndex}`}>
                    {isTablet && isTablet ? (<Row >
                      {bodyTypeContent.slice(0, 2).map((card, index) => (
                        <Col md={6} sm={12} key={index} className="pb-3">
                          <CarCard detail={card} />
                        </Col>
                      ))}
                    </Row>
                    ) : (
                      <Row >
                        {bodyTypeContent.map((card, index) => (
                          <Col md={4} sm={12} key={index} className="pb-3">
                            <CarCard detail={card} />
                          </Col>
                        ))}
                      </Row>)}
                  </Tab.Pane>
                </Tab.Content>
                <div className="text-center pt-3">
                  {selectedTabKeyName !== "" ? (<Button variant="primary" type="submit" onClick={() => handleSearchCar(selectedTabKeyName)}>
                    View all <FontAwesomeIcon icon={faAngleRight} />
                  </Button>)
                    :
                    (<p className="fs-4 mt-5 text-dark">No cars available with the selected body type at this location</p>)}
                </div>
              </Col>
            </Row>
          </Tab.Container>

        </Container>
        :
        <Container >
          <Tab.Container id="left-tabs-example" defaultActiveKey="sideTab_0">
            <Row className="mb-5">
              <div className="text-center pb-4">
                <h2 className="text-primary head-bold">CARS BY BODY TYPES </h2>
              </div>
              <Col sm={12} md={isTablet ? 4 : 3} className="">
                <div className="nav-pills-scroll">
                  <Nav
                    variant="pills"
                    className="flex-md-column border-md-end ps-0"
                  >
                    {bodyType.map((carBody, index) => (
                      <Nav.Item key={carBody.keyName} className="border-bottom"
                        onClick={() => getCarsByBodyTypes(carBody.keyName, index)}>
                        <Nav.Link eventKey={`sideTab_${index}`} className=" is-description">
                          <Image
                            src={`${process.env.REACT_APP_CLOUDFRONT_URL}${carBody.image}`}
                            className="rounded-square icon-thumbnail"
                          /> {carBody.name}

                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              </Col>
              <Col md={isTablet ? 8 : 9}>
                <Tab.Content>
                  <Tab.Pane eventKey={`sideTab_${bodyTypeIndex}`}>
                    {isTablet && isTablet ? (<Row >
                      {bodyTypeContent.slice(0, 2).map((card, index) => (
                        <Col md={6} sm={12} key={index} className="pb-3">
                          <CarCard detail={card} />
                        </Col>
                      ))}
                    </Row>
                    ) : (
                      <Row >
                        {bodyTypeContent.map((card, index) => (
                          <Col md={4} sm={12} key={index} className="pb-3">
                            <CarCard detail={card} />
                          </Col>
                        ))}
                      </Row>)}
                  </Tab.Pane>
                </Tab.Content>
                <div className="text-center pt-3">
                  {selectedTabKeyName !== "" ? (<Button variant="primary" type="submit" onClick={() => handleSearchCar(selectedTabKeyName)}>
                    View all <FontAwesomeIcon icon={faAngleRight} />
                  </Button>)
                    :
                    (<p className="fs-4 mt-5 text-dark">No cars available with the selected body type at this location</p>)}
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </Container>}

    </div>
  );
};

export default CarsByBodyType;
